import { CustomSkeleton } from "@hipdevteam/afglobalcomponents";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import React from "react";
import "../../layouts/sidebar/core_sidebar.css";

const SideBarSkeleton = () => {
    return (
        <>
            <Box className="logo_container">
                <CustomSkeleton variant="text" height={32} width="90%" />
            </Box>
            <List className="sidebar_menu">
                <ListItem button>
                    <CustomSkeleton variant="rectangular" height={32} width="100%" />
                </ListItem>

                <ListItem button className="sidebar_submenu_container">
                    <Box className="sidebar_menu_item">
                        <CustomSkeleton variant="rectangular" height={32} width="100%" />
                    </Box>
                    <List className="sidebar_submenu">
                        <ListItem button>
                            <ListItemIcon sx={{ paddingLeft: "1rem" }} />
                            <CustomSkeleton variant="rectangular" height={32} width="100%" />
                        </ListItem>
                    </List>
                </ListItem>
            </List>

            <List className="sidebar_menu settings_menu">
                <ListItem button className="sidebar_submenu_container">
                    <Box className="sidebar_menu_item">
                        <CustomSkeleton variant="rectangular" height={32} width="100%" />
                    </Box>
                    <List className="sidebar_submenu">
                        <br />
                        <ListItem button>
                            <CustomSkeleton variant="rectangular" height={32} width="100%" />
                        </ListItem>
                        <ListItem button>
                            <CustomSkeleton variant="rectangular" height={32} width="100%" />
                        </ListItem>
                        <ListItem button>
                            <CustomSkeleton variant="rectangular" height={32} width="100%" />
                        </ListItem>
                        <ListItem button>
                            <CustomSkeleton variant="rectangular" height={32} width="100%" />
                        </ListItem>
                    </List>
                </ListItem>
            </List>
            <Box className="user_logout" sx={{ backgroundColor: "common.white" }}>
                <Box className="dark_toggle">
                    <CustomSkeleton variant="rectangular" height={32} width="100%" />
                </Box>
                <Box className="user_logout" sx={{ backgroundColor: "background.paper" }}>
                    <Box className="dark_toggle">
                        <CustomSkeleton variant="rectangular" height={32} width="100%" />
                    </Box>
                    <Box className="logout">
                        <Box>
                            <CustomSkeleton variant="circular" height={42} width={42} />
                        </Box>
                        <CustomSkeleton variant="rectangular" height={42} width={120} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default SideBarSkeleton;
