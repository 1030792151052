/* eslint-disable default-param-last */
import * as ACTION from "./geogridApiAuthActionType";

const initialState = {
    tableData: [],
    isSuccess: false,
    loading: true
};

const geogridApiAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.GET_GEOGRID_API_AUTH_DATA_TABLE:
            return {
                ...state,
                tableData: [],
                isSuccess: false,
                loading: true
            };
        case ACTION.GET_GEOGRID_API_AUTH_DATA_TABLE_SUCCESS:
            return {
                ...state,
                tableData: action.payload.data,
                isSuccess: true,
                loading: false
            };
        case ACTION.GET_GEOGRID_API_AUTH_DATA_TABLE_FAILED:
            return {
                ...state,
                tableData: [],
                isSuccess: false,
                loading: false
            };

        default:
            return state;
    }
};

export default geogridApiAuthReducer;
