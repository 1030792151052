import { CustomButton } from "@hipdevteam/afglobalcomponents";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import NotFoundImage from "../../assets/images/Error/404.png";

const NotFound = () => {
    const history = useHistory();
    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "fitContent",
                            width: "100%",
                            textAlign: "center",
                            marginTop: "50px"
                        }}
                    >
                        <Box>
                            <img src={NotFoundImage} alt="" />
                            <Typography variant="h3" color="text.fill">
                                Opps! Somethings gone missing.... 404
                            </Typography>
                            <Typography variant="h5" color="text.secondary">
                                Sorry, but the page you are looking for was either not found or dose
                                not exist. Try refreshing the page or click the button below to go
                                back to the Dashboard.{" "}
                            </Typography>
                            <Box sx={{ padding: "0 38%", marginTop: "1rem" }}>
                                <CustomButton
                                    variant="contained"
                                    color="secondary"
                                    title="Back to Dashboard"
                                    size="large"
                                    type="submit"
                                    customClass="auth__verification__btn"
                                    handleButton={() => history.push("/dashboard")}
                                    endIcon={<ArrowForwardIcon />}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFound;
