/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { LicenseInfo } from "@mui/x-license-pro";
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { getCookie, setCookie, setCookieForRefresh } from "./helpers/Cookie";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./statemanagement/store";

const { REACT_APP_ACCESS_TOKEN, REACT_APP_ACCESS_TOKEN_VALIDITY, REACT_APP_REFRESH_TOKEN } =
    process.env;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return new Promise((resolve) => {
            const originalRequest = error.config;
            const refreshToken = getCookie(REACT_APP_REFRESH_TOKEN);
            if (error.response && error.response.status === 401 && refreshToken) {
                originalRequest._retry = true;
                const details = {
                    refresh_token: refreshToken
                };

                let formBody = [];
                for (const property in details) {
                    const encodedKey = encodeURIComponent(property);
                    const encodedValue = encodeURIComponent(details[property]);
                    formBody.push(`${encodedKey}=${encodedValue}`);
                    formBody.push(`email=${getCookie("EMAIL")}`);
                    formBody.push(`uuid=${getCookie("UUID")}`);
                }
                formBody = formBody.join("&");
                const response = fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: formBody
                })
                    .then((res) => res.json())
                    .then((res) => {
                        setCookie(
                            REACT_APP_ACCESS_TOKEN,
                            res.access.token,
                            REACT_APP_ACCESS_TOKEN_VALIDITY
                        );
                        setCookieForRefresh(REACT_APP_REFRESH_TOKEN, res.refresh.token);
                        originalRequest.headers.Authorization = `Bearer ${res.access.token}`;
                        return axios(originalRequest);
                    })
                    .catch((e) => {
                        return Promise.reject(e);
                    });
                resolve(response);
            }

            if (
                error.response &&
                (error.response.status === 400 ||
                    error.response.status === 500 ||
                    error.response.status === 502 ||
                    error.response.status === 501)
            ) {
                throw error.response.data;
            }

            // eslint-disable-next-line no-promise-executor-return
            return Promise.reject(error);
        });
    }
);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY);

if (process.env.REACT_APP_PRODUCTION_MODE === "true") {
    disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
if (document.getElementById("root")) {
    document.getElementById("dom-loader")?.remove();
    root.render(
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <App />
            </GoogleOAuthProvider>
            {/* </PersistGate> */}
        </Provider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
