import { all, call, put, takeEvery } from "redux-saga/effects";
import { getUserListApi } from "../../../api/adminApi";
import * as ACTION from "./adminUserActionTypes";

function* getUsersListSaga(action) {
    try {
        const response = yield call(getUserListApi, action.payload);

        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.GET_USERS_DATA_TABLE_SUCCESS,
                payload: responseData
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_USERS_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* adminUserWatcher() {
    yield takeEvery(ACTION.GET_USERS_DATA_TABLE, getUsersListSaga);
}

export default function* adminUserSaga() {
    yield all([adminUserWatcher()]);
}
