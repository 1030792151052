import { sendRequestWithToken } from "./rootApi";

export const authCredentialsGetApi = async (params) => {
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/api-auth/${params.auth_type}`
    );
};

export const authCredentialsUpdateApi = async (params) => {
    return sendRequestWithToken("post", `${process.env.REACT_APP_API_BASE_URL}/api-auth/`, params);
};
