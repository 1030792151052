import {
    CustomButton,
    CustomCheckBox,
    CustomLabel,
    CustomTextField,
    CustomTextFieldPassword
} from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, SvgIcon, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useGoogleLogin } from "@react-oauth/google";
import storage from "local-storage-fallback";
import { useEffect, useState } from "react";
// import GoogleLogin from "react-google-login";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { checkCookie, setCookie, setCookieForRefresh, setOtherCookie } from "../../helpers/Cookie";
import {
    attemptLogin,
    googleLogin,
    googleSignIn,
    loginSuccess
} from "../../statemanagement/login/loginAction";
import AuthError from "./AuthError";
// import Logo from "./images/logo.png";
import { AgencyFrameworkLight } from "../../assets/images/logo/AgencyFrameworkLogo";
import ShowAlerts from "../../helpers/ShowAlerts";
import { ALERT_TYPE_ERROR } from "../../utils/Constant";
import "./style/auth.css";

const {
    REACT_APP_ACCESS_TOKEN,
    REACT_APP_ACCESS_TOKEN_VALIDITY,
    REACT_APP_REFRESH_TOKEN,
    REACT_APP_EMAIL_VERIFIED_STATUS
} = process.env;

const style = {
    "&::before": {
        position: "absolute",
        content: '" "',
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        background: "rgba(27, 20, 100, 0.85)"
    }
};

const GoogleIcon = () => {
    return (
        <SvgIcon
            width="18"
            height="18"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ marginTop: "4px", marginRight: "0", marginLeft: "0" }}
        >
            <g fill="#000" fillRule="evenodd">
                <path
                    d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                    fill="#EA4335"
                />
                <path
                    d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                    fill="#4285F4"
                />
                <path
                    d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                    fill="#FBBC05"
                />
                <path
                    d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                    fill="#34A853"
                />
                <path fill="none" d="M0 0h18v18H0z" />
            </g>
        </SvgIcon>
    );
};
const Login = (props) => {
    const {
        attemptLoginAction,
        googleLoginAction,
        googleSigninAction,
        checkLoginSuccess,
        user,
        isResetPasswordSuccess
    } = props;
    const theme = useTheme();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);
    const [signIn, setSignIn] = useState({
        showPassword: true
    });
    const [rememberMeCheck, setRememberMeCheck] = useState(false);

    const validator = yup.object().shape({
        email: yup.string().email("Invalid email").required("Enter Email"),
        password: yup.string().required("Enter password")
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: "onChange", resolver: yupResolver(validator) });

    // watch("email");

    useEffect(() => {
        if (checkCookie(process.env.REACT_APP_ACCESS_TOKEN)) {
            history.push("/dashboard");
        }
    }, []);

    useEffect(() => {
        if (user.error) {
            setErrorStatus(true);
            setLoading(false);
        } else {
            setErrorStatus(false);
        }
    }, [user.error]);

    useEffect(() => {
        if (user.loginSuccessStatus) {
            setLoading(false);

            if (user.user.email_verified && user.user.email_verified != 1) {
                history.push("/user/email-verification");
                return false;
            }

            if (checkCookie(process.env.REACT_APP_ACCESS_TOKEN)) {
                const redirectUrl = storage.getItem(process.env.REACT_APP_REDIRECT_TO);
                if (redirectUrl == null || redirectUrl == undefined || redirectUrl == "") {
                    window.location.replace("/dashboard");
                    return false;
                }
                window.location.replace(storage.getItem(process.env.REACT_APP_REDIRECT_TO));
            }
        }
    }, [user.loginSuccessStatus]);

    const handleClickShowPassword = () => {
        setSignIn({
            ...signIn,
            showPassword: !signIn.showPassword
        });
    };

    const onSubmit = (data) => {
        if (
            !data ||
            !data.email ||
            data.email === "" ||
            data.email.length === 0 ||
            !data.password ||
            data.password === "" ||
            data.password.length === 0
        ) {
            return;
        }

        setLoading(true);
        attemptLoginAction(data);
        if (rememberMeCheck) {
            const u = document.getElementById("email").value;
            const p = document.getElementById("password").value;
            localStorage.userName = u;
            localStorage.password = p;
        }
        if (!rememberMeCheck) {
            localStorage.userName = "";
            localStorage.password = "";
        }
    };

    function checkRememberMe() {
        if (
            localStorage.userName &&
            localStorage.password &&
            localStorage.userName !== "" &&
            localStorage.password !== ""
        ) {
            return true;
        }
        if (
            (localStorage.userName && localStorage.password) ||
            (localStorage.userName === "" && localStorage.password === "")
        ) {
            return false;
        }
    }

    const onGoogleLogInSuccess = (resp) => {
        storage.setItem(process.env.REACT_APP_REDIRECT_TO, "/dashboard");
        const data = {
            email: resp.profileObj.email,
            first_name: resp.profileObj.givenName,
            last_name: resp.profileObj.familyName,
            google_id: resp.profileObj.googleId
        };

        googleLoginAction(data, (response) => {
            if (response.status) {
                setCookie(
                    REACT_APP_ACCESS_TOKEN,
                    response.tokens.access.token,
                    REACT_APP_ACCESS_TOKEN_VALIDITY
                );
                setCookieForRefresh(REACT_APP_REFRESH_TOKEN, response.tokens.refresh.token);
                setOtherCookie(REACT_APP_EMAIL_VERIFIED_STATUS, response.data.email_verified === 1);
                checkLoginSuccess(response);
                storage.setItem("modePostfix", response.uuid);

                const redirectUrl = storage.getItem(process.env.REACT_APP_REDIRECT_TO);
                if (redirectUrl == null || redirectUrl == undefined || redirectUrl == "") {
                    window.location.replace("/dashboard");
                    return false;
                }
                window.location.replace(storage.getItem(process.env.REACT_APP_REDIRECT_TO));
            } else {
                ShowAlerts("Failed Google Login", ALERT_TYPE_ERROR);
            }
        });
    };

    const onGoogleLogInFailed = (resp) => {
        console.log(resp);
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            googleSigninAction({ access_token: tokenResponse.access_token }, (response) => {
                if (response.status) {
                    setCookie(
                        REACT_APP_ACCESS_TOKEN,
                        response.tokens.access.token,
                        REACT_APP_ACCESS_TOKEN_VALIDITY
                    );
                    setCookieForRefresh(REACT_APP_REFRESH_TOKEN, response.tokens.refresh.token);
                    setOtherCookie(
                        REACT_APP_EMAIL_VERIFIED_STATUS,
                        response.data.email_verified === 1
                    );
                    checkLoginSuccess(response);
                    storage.setItem("modePostfix", response.uuid);

                    const redirectUrl = storage.getItem(process.env.REACT_APP_REDIRECT_TO);
                    if (!redirectUrl) {
                        window.location.replace("/dashboard");
                        return false;
                    }
                    window.location.replace(redirectUrl);
                } else {
                    ShowAlerts("Failed Google Login", ALERT_TYPE_ERROR);
                }
            });
        },
        onError: (errResponse) => {
            return errResponse;
        },
        scope: "email profile"
    });

    return (
        <Box sx={style} className="auth__wrapper">
            <Box className="auth__inner">
                <Box className="auth__inner__container">
                    <Box className="auth__inner__top">
                        {/* <img src={Logo} alt="agency_framework" /> */}
                        {AgencyFrameworkLight}
                        <Typography
                            className="auth__have__account"
                            color={theme.palette.text.primary}
                            variant="body2"
                            component="p"
                        >
                            Don’t Have an Account?
                            <Box component="span" sx={{ paddingLeft: "4px" }}>
                                <Link to="/registration">
                                    <Typography
                                        color={theme.palette.secondary.main}
                                        variant="buttonLarge"
                                    >
                                        Sign Up
                                    </Typography>
                                </Link>
                            </Box>
                        </Typography>
                    </Box>
                    {errorStatus === true && (
                        <AuthError
                            type="error"
                            errorText={user.errorMessage ? user.errorMessage : ""}
                        />
                    )}
                    {isResetPasswordSuccess === true && (
                        <AuthError type="success" errorText="Password Changed. Please login" />
                    )}
                    <Box className="auth__form__wr">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <CustomTextField
                                className="single__text__field"
                                size="large"
                                label="Email"
                                id="email"
                                color="secondary"
                                register={register("email", {
                                    required: "Email is required",
                                    pattern: /^\S+@\S+$/i,
                                    value: checkRememberMe() ? localStorage.userName : ""
                                })}
                                errors={errors}
                            />
                            {!!errors.email && (
                                <CustomLabel variant="subtitle2" align="left" color="error">
                                    {errors.email.message}
                                </CustomLabel>
                            )}

                            <CustomTextFieldPassword
                                show={signIn.showPassword}
                                id="password"
                                handleClickShowPassword={handleClickShowPassword}
                                type={signIn.showPassword ? "password" : "text"}
                                className="single__text__field"
                                size="large"
                                label="Password"
                                register={register("password", {
                                    required: "Password is required",
                                    value: checkRememberMe() ? localStorage.password : ""
                                })}
                            />

                            {!!errors.password && (
                                <CustomLabel variant="subtitle2" align="left" color="error">
                                    {errors.password.message}
                                </CustomLabel>
                            )}

                            <Box className="check__forgot__wr">
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <CustomCheckBox
                                        color="primary"
                                        checked={rememberMeCheck}
                                        handleChange={() => {
                                            setRememberMeCheck(!rememberMeCheck);
                                            /* setRememberme(e) */
                                        }}
                                    />
                                    <Typography color={theme.palette.primary.dark} variant="body2">
                                        Remember Me
                                    </Typography>
                                </Box>

                                <Link to="/forgot-password">
                                    <Typography color={theme.palette.primary.main} variant="body2">
                                        Forgot your password?
                                    </Typography>
                                </Link>
                            </Box>
                            <CustomButton
                                variant="contained"
                                color="secondary"
                                loader={loading}
                                title="Login"
                                size="large"
                                type="submit"
                                customClass="auth__sign__in__btn"
                            />
                            <Typography
                                className="sign__with__email"
                                color={theme.palette.text.secondary}
                                variant="caption"
                                component="p"
                            >
                                Or Sign In with email
                            </Typography>
                        </form>
                        <CustomButton
                            variant="outlined"
                            color="secondary"
                            sx={{
                                boxShadow: "none",
                                "& .MuiButton-startIcon": {
                                    // marginTop: "4px",
                                    marginRight: "0",
                                    marginLeft: "0"
                                }
                            }}
                            title="Sign In With Google"
                            size="large"
                            type="button"
                            startIcon={<GoogleIcon />}
                            handleButton={handleGoogleLogin}
                            customClass="auth__sign__in__btn_google"
                        />
                        {/* <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Sign In With Google"
                            className="auth__google__btn"
                            onSuccess={onGoogleLogInSuccess}
                            onFailure={onGoogleLogInFailed}
                            cookiePolicy="single_host_origin"
                        /> */}
                    </Box>
                </Box>
            </Box>
            <Box className="auth__footer">
                <Typography
                    className="auth__footer__copy__right"
                    color={theme.palette.primary.contrastText}
                    variant="overline"
                    component="p"
                >
                    @ {new Date().getFullYear()} Agency Framework - All Rights Reserved
                </Typography>

                <Box className="footer__link">
                    <Link to="/forgot-password">
                        <Typography
                            color={theme.palette.primary.contrastText}
                            variant="overline"
                            component="span"
                        >
                            Privacy Policy
                        </Typography>
                    </Link>
                    <Link to="/forgot-password">
                        <Typography
                            color={theme.palette.primary.contrastText}
                            variant="overline"
                            component="span"
                        >
                            terms and conditions
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: state.loginReducer,
    isResetPasswordSuccess: state.resetPasswordReducer.isResetPasswordSuccess
});

const mapDispatchToProps = (dispatch) => ({
    attemptLoginAction: (params) => dispatch(attemptLogin(params)),
    googleLoginAction: (data, callback) => dispatch(googleLogin(data, callback)),
    googleSigninAction: (data, callback) => dispatch(googleSignIn(data, callback)),
    checkLoginSuccess: (data) => dispatch(loginSuccess(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
