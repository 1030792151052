import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    appStatusChangeApi,
    createAppApi,
    getAppDataForEditApi,
    getAppListApi,
    updateAppApi
} from "../../../api/adminApi";
import * as ACTION from "./AdminAppActionType";

function* getAppsListSaga(action) {
    try {
        const response = yield call(getAppListApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            yield put({
                type: ACTION.GET_APPS_DATA_TABLE_SUCCESS,
                payload: responseData
            });
        } else {
            yield put({
                type: ACTION.GET_APPS_DATA_TABLE_FAILED,
                payload: []
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_APPS_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* createAppSaga(action) {
    try {
        const response = yield call(createAppApi, action.payload);
        const responseData = response.data;

        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* getAppDataForEditSaga(action) {
    try {
        const response = yield call(getAppDataForEditApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* updateAppDataSaga(action) {
    try {
        const response = yield call(updateAppApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* changeAppStatusSaga(action) {
    try {
        const response = yield call(appStatusChangeApi, action.payload);
        const responseData = response.data;

        action.callback(responseData);
    } catch (error) {
        action.callback(error);
    }
}

function* adminAppsWatcher() {
    yield takeEvery(ACTION.GET_APPS_DATA_TABLE, getAppsListSaga);
    yield takeEvery(ACTION.APP_CREATE, createAppSaga);
    yield takeEvery(ACTION.APP_DATA_GET_FOR_EDIT, getAppDataForEditSaga);
    yield takeEvery(ACTION.APP_DATA_UPDATE, updateAppDataSaga);
    yield takeEvery(ACTION.ADMIN__APP_STATUS_CHANGE, changeAppStatusSaga);
}

export default function* adminAppSaga() {
    yield all([adminAppsWatcher()]);
}
