/* eslint-disable react/no-unescaped-entities */
import { CustomButton, CustomLabel, CustomTextField } from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import ShowAlerts from "../../helpers/ShowAlerts";
import { sendForgetPasswordEmail } from "../../statemanagement/resetPassword/resetPasswordActions";
import { ALERT_TYPE_ERROR } from "../../utils/Constant";
import ForgetPasswordVerification from "./ForgetPasswordVerification";
import "./style/auth.css";

const style = {
    "&::before": {
        position: "absolute",
        content: '" "',
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        background: "rgba(27, 20, 100, 0.85)"
    }
};

const ForgetPasswordForm = (props) => {
    const { sendForgetPasswordEmailData } = props;
    const [isEmailSend, setIsEmailSend] = useState(false);
    const [email, setEmail] = useState("");

    const validator = yup.object().shape({
        email: yup.string().email("Invalid email").required("Enter Email")
    });

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({ mode: "onChange", resolver: yupResolver(validator) });

    watch("email");

    const onSubmit = (data) => {
        sendForgetPasswordEmailData(data, (response) => {
            if (response.status) {
                setEmail(data.email);
                setIsEmailSend(true);
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
            }
        });
    };

    if (isEmailSend) {
        return <ForgetPasswordVerification email={email} />;
    }

    return (
        <Box sx={style} className="auth__wrapper">
            <Box className="auth__inner">
                <Box className="auth__inner__container">
                    <Box className="auth__reset__wr">
                        <Box className="auth__inner__top__extra">
                            <Typography
                                className="extra__type__heading"
                                variant="h4"
                                component="h4"
                            >
                                Reset your password
                            </Typography>
                            <Typography className="extra__type__para" variant="body1" component="p">
                                Enter the email address associated with your account and we'll send
                                you a code to reset your password.
                            </Typography>
                        </Box>
                        <Box className="auth__form__wr">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <CustomTextField
                                    className="single__text__field"
                                    label="Email"
                                    register={register("email")}
                                    errors={errors}
                                />
                                {!!errors.email && (
                                    <CustomLabel variant="subtitle2" align="left" color="error">
                                        {errors.email.message}
                                    </CustomLabel>
                                )}

                                <CustomButton
                                    variant="contained"
                                    color="secondary"
                                    title="Send Reset Code"
                                    size="large"
                                    type="submit"
                                    customClass="auth__sign__in__btn"
                                />
                                <Typography
                                    className="sign__or__email"
                                    variant="button small"
                                    component="p"
                                >
                                    or <Link to="/login">Sign In </Link>
                                </Typography>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="auth__footer">
                <Typography className="auth__footer__copy__right" variant="p" component="p">
                    @ {new Date().getFullYear()} Agency Framework - All Rights Reserved
                </Typography>

                <Box className="footer__link">
                    <Link to="/forgot-password">
                        <Typography variant="overline" component="span">
                            Privacy Policy
                        </Typography>
                    </Link>
                    <Link to="/forgot-password">
                        <Typography variant="overline" component="span">
                            terms and conditions
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendForgetPasswordEmailData: (params, callback) =>
            dispatch(sendForgetPasswordEmail(params, callback))
    };
};

export default connect(null, mapDispatchToProps)(ForgetPasswordForm);
