import { CustomButton, CustomDrawer, CustomTabs } from "@hipdevteam/afglobalcomponents";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalHeader from "../../../../components/local/LocalHeader/LocalHeader";
import ShowAlerts from "../../../../helpers/ShowAlerts";
import { syncGhlAdSourceTableAndColumn } from "../../../../statemanagement/Admin/Ghl/GhlAction";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../../utils/Constant";
import AdSourceTableAdd from "./forms/AdSourceTableAdd";
import DataTableFacebook from "./tables/DataTableFacebook";
import DataTableGhl from "./tables/DataTableGhl";
import DataTableGoogle from "./tables/DataTableGoogle";

const AdSourceSetting = (props) => {
    const { syncGhlAdSource } = props;
    const [activeTab, setActiveTab] = useState(0);
    const [tabName, setTabName] = useState("Facebook");
    const [loading, setLoading] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const components = [
        {
            name: "Dashboard",
            link: "/Dashboard"
        },
        {
            name: "Admin",
            link: "/admin/agencies"
        },
        { name: "Ad Source", link: "#" }
    ];

    const tabData = [
        {
            number: 0,
            label: "Facebook"
        },
        {
            number: 1,
            label: "Google"
        },
        {
            number: 2,
            label: "GHL"
        }
    ];

    const tabPanelData = [
        {
            number: 0,
            label: "Facebook",
            content: <DataTableFacebook />
        },
        {
            number: 1,
            label: "Google",
            content: <DataTableGoogle />
        },
        {
            number: 2,
            label: "GHL",
            content: <DataTableGhl />
        }
    ];

    useEffect(() => {
        if (activeTab === 0) {
            setTabName("Facebook");
        }
        if (activeTab === 1) {
            setTabName("Google");
        }
        if (activeTab === 2) {
            setTabName("GHL");
        }
    }, [activeTab]);

    const handleGhlAdSourceSync = () => {
        setLoading(true);
        syncGhlAdSource({}, (response) => {
            if (response.status) {
                ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
                setActiveTab(1);
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
                setActiveTab(1);
            }
            setLoading(false);
        });
    };

    const localHeaderButtons = () => {
        return (
            <>
                <CustomButton
                    title={
                        <Typography variant="buttonLarge">
                            {loading ? "Syncing..." : "Sync"}
                        </Typography>
                    }
                    handleButton={handleGhlAdSourceSync}
                    color="secondary"
                    variant="contained"
                    startIcon={<SyncIcon />}
                    size="large"
                    loader={loading}
                />
                <CustomButton
                    title={<Typography variant="buttonLarge">Add New Table</Typography>}
                    handleButton={() => setIsDrawerOpen(true)}
                    color="secondary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    size="large"
                />
            </>
        );
    };

    return (
        <Box>
            <Container
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: "10px"
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box sx={{ width: 1, mb: "30px" }}>
                            <LocalHeader
                                components={components}
                                headerText="Report | Ad Source"
                                buttons={localHeaderButtons}
                            />
                        </Box>
                        <CustomTabs
                            setActiveTab={setActiveTab}
                            tabData={tabData}
                            tabPanelData={tabPanelData}
                        />
                    </Grid>
                </Grid>
            </Container>
            <CustomDrawer
                drawerOpen={isDrawerOpen}
                setDrawerOpen={setIsDrawerOpen}
                customClass={isDrawerOpen ? "active" : ""}
                heading="Add Table"
            >
                <AdSourceTableAdd setDrawerOpen={setIsDrawerOpen} />
            </CustomDrawer>
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        syncGhlAdSource: (payload, callback) =>
            dispatch(syncGhlAdSourceTableAndColumn(payload, callback))
    };
};

export default connect(null, mapDispatchToProps)(AdSourceSetting);
