import GothamProBlack from "../assets/fonts/GothamPro/woff/GothamProBlack.woff";
import GothamProBlackItalic from "../assets/fonts/GothamPro/woff/GothamProBlackItalic.woff";
import GothamProBoldItalic from "../assets/fonts/GothamPro/woff/GothamProBoldItalic.woff";
import GothamProItalic from "../assets/fonts/GothamPro/woff/GothamProItalic.woff";
import GothamProLight from "../assets/fonts/GothamPro/woff/GothamProLight.woff";
import GothamProLightItalic from "../assets/fonts/GothamPro/woff/GothamProLightItalic.woff";
import GothamProMedium from "../assets/fonts/GothamPro/woff/GothamProMedium.woff";
import GothamProMediumItalic from "../assets/fonts/GothamPro/woff/GothamProMediumItalic.woff";
import GothamProRegular from "../assets/fonts/GothamPro/woff/GothamProRegular.woff";

export const families = `
              @font-face {
                font-family: 'Gotham Pro Black';
                src: url(${GothamProBlack}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
              }
              @font-face {
                    font-family: 'Gotham Pro Black Italic';
                    src: url(${GothamProBlackItalic}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }
                  @font-face {
                    font-family: 'Gotham Pro Bold Italic';
                    src: url(${GothamProBoldItalic}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }
                  @font-face {
                    font-family: 'Gotham Pro Italic';
                    src: url(${GothamProItalic}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }
                  @font-face {
                    font-family: 'Gotham Pro Light';
                    src: url(${GothamProLight}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }
                  @font-face {
                    font-family: 'Gotham Pro Light Italic';
                    src: url(${GothamProLightItalic}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }
                  @font-face {
                    font-family: 'Gotham Pro Medium';
                    src: url(${GothamProMedium}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }
                  @font-face {
                    font-family: 'Gotham Pro Medium Italic';
                    src: url(${GothamProMediumItalic}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }
                  @font-face {
                    font-family: 'Gotham Pro Regular';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: url(${GothamProRegular}) format('woff2');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }

            `;
export const typographyStyle = () => {
    return {
        fontFamily: "Gotham Pro Regular",
        h1: {
            fontFamily: "Gotham Pro Black !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "6rem !important",
            lineHeight: "7rem !important",
            textTransform: "capitalize !important"
        },
        h2: {
            fontFamily: "Gotham Pro Black !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "3.375rem !important",
            lineHeight: "4rem !important",
            textTransform: "capitalize !important"
        },
        h3: {
            fontFamily: "Gotham Pro Black !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "2rem !important",
            lineHeight: "3rem !important",
            textTransform: "capitalize !important"
        },
        h4: {
            fontFamily: "Gotham Pro Black !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "1.5rem !important",
            lineHeight: "2.25rem !important",
            textTransform: "capitalize !important"
        },
        h5: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "1.125rem !important",
            lineHeight: "2rem !important",
            textTransform: "capitalize !important"
        },
        h6: {
            fontFamily: "Gotham Pro Black !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.875rem !important",
            lineHeight: "1.3125rem !important",
            letterSpacing: "0.15px !important",
            textTransform: "capitalize !important"
        },
        subtitle1: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "1.125rem !important",
            lineHeight: "2rem !important",
            letterSpacing: "0.15px !important"
        },
        subtitle2: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.875rem !important",
            lineHeight: "1.3125rem !important",
            letterSpacing: "0.1px "
        },
        body1: {
            fontFamily: "Gotham Pro Regular !important",
            fontSize: "1rem !important",
            lineHeight: "1.75rem !important"
        },
        body2: {
            fontFamily: "Gotham Pro Regular !important",
            fontSize: "0.875rem !important",
            lineHeight: "1.5rem !important"
        },
        bodyBold1: {
            fontFamily: "Gotham Pro Medium !important",
            fontSize: "1rem !important",
            lineHeight: "1.75rem !important",
            letterSpacing: "1.5px !important"
        },
        bodyBold2: {
            fontFamily: "Gotham Pro Black !important",
            fontSize: "0.875rem !important",
            lineHeight: "1.5rem !important",
            letterSpacing: "1.5px !important"
        },
        caption: {
            fontFamily: "Gotham Pro Regular !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            fontSize: ".75rem !important",
            lineHeight: "1.3125rem !important",
            letterSpacing: "0.4px !important"
        },
        overline: {
            fontFamily: "Gotham Pro Regular !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            fontSize: "0.75rem !important",
            lineHeight: "1.5rem !important",
            letterSpacing: "1px !important",
            textTransform: "uppercase !important"
        },
        buttonLarge: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.9375rem !important",
            lineHeight: "1.625rem !important",
            letterSpacing: "0.46px !important",
            textTransform: "uppercase !important"
        },
        buttonMedium: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.875rem !important",
            lineHeight: "1.5rem !important",
            textTransform: "uppercase !important"
        },
        buttonSmall: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.8125rem !important",
            lineHeight: "1.375rem !important",
            textTransform: "uppercase !important"
        },

        inputLabel: {
            fontFamily: "Gotham Pro Regular !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.75rem !important",
            lineHeight: "1.125rem !important",
            letterSpacing: "0.15px !important"
        },
        helperText: {
            fontFamily: "Gotham Pro Regular !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.75rem !important",
            lineHeight: "1.25rem !important",
            letterSpacing: "0.4px !important"
        },
        inputText: {
            fontFamily: "Gotham Pro Regular !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "1rem !important",
            // lineHeight: "1.5rem",
            letterSpacing: "0.15px !important"
        },
        avatarInitial: {
            fontFamily: "Poppins !important",
            fontStyle: "Regular !important",
            fontWeight: "normal !important",
            fontSize: "1.25rem !important",
            lineHeight: "2.5rem !important",
            textTransform: "capitalize !important"
        },
        chip: {
            fontFamily: "Gotham Pro !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            fontSize: "1rem !important",
            lineHeight: "1.125rem !important",
            letterSpacing: "0.16px !important"
        },
        tootTip: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.625rem !important",
            lineHeight: "0.875rem !important"
        },
        alertTitle: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "1rem !important",
            lineHeight: "150% !important",
            letterSpacing: "0.15px !important"
        },
        tableHeader: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.875rem !important",
            lineHeight: "1.5rem !important",
            letterSpacing: "0.17px !important"
        },
        badgeLabel: {
            fontFamily: "Gotham Pro Medium !important",
            fontStyle: "normal !important",
            fontWeight: "normal !important",
            fontSize: "0.75rem !important",
            lineHeight: "1.25rem !important",
            letterSpacing: "0.14px !important"
        }
    };
};
