import * as ACTION from "./signupActionTypes";

const initialState = {
    registerUser: [],
    isSignUpSuccess: false,
    isLoading: false,
    errorMessage: "",
    error: false
};

const signupReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SIGNUP_ATTEMPT:
            return {
                ...state,
                isSignUpSuccess: false,
                registerUser: [],
                isLoading: true,
                errorMessage: "",
                error: false
            };
        case ACTION.SIGNUP_SUCCESS:
            return {
                ...state,
                registerUser: action.payload.data,
                isLoading: false,
                isSignUpSuccess: true,
                errorMessage: "",
                error: false
            };
        case ACTION.SIGNUP_SUCCESS_STEP_TWO:
            return {
                ...state,
                registerUser: action.payload,
                isLoading: false,
                isSignUpSuccess: true,
                errorMessage: "",
                error: false
            };
        case ACTION.SIGNUP_ERROR:
            return {
                ...state,
                isSignUpSuccess: false,
                isLoading: false,
                errorMessage: action.payload,
                error: true
            };
        case ACTION.SIGNUP_SUCCESS_FALSE:
            return {
                ...state,
                isSignUpSuccess: false
            };

        default:
            return state;
    }
};

export default signupReducer;
