import * as ACTION from "./s3FileUploadActionType";

export const uploadS3FileAction = (payload, callback) => ({
    type: ACTION.S3_FILE_UPLOAD,
    payload,
    callback
});

export const Test = (payload, callback) => ({
    type: ACTION.S3_FILE_UPLOAD,
    payload,
    callback
});
