import * as ACTION from "./apiAuthActionTypes";

const initialState = {
    authData: null,
    isLoading: false,
    getSuccess: false,
    getData: false
};

const apiAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.AUTH_CREDENTIALS_GET:
            return { ...state, getData: true };

        default:
            return state;
    }
};

export default apiAuthReducer;
