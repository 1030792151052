import {
    CustomButton,
    CustomSelectBox,
    CustomTextField,
    SwitchWithLabel
} from "@hipdevteam/afglobalcomponents";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import ShowAlerts from "../../helpers/ShowAlerts";
import {
    editTeamMember,
    getAppFeatures,
    getTeam,
    getTeamDataTable
} from "../../statemanagement/team/teamAction";
import * as CONSTANR from "../../utils/Constant";

const EditTeam = (props) => {
    const {
        apps,
        uuid,
        setUuid,
        setDrawerOpen,
        getAppFeaturesData,
        editTeamMemberData,
        getTeamData,
        getTeamDataTableList
    } = props;
    const { handleSubmit, reset } = useForm();
    const [getTeamSuccess, setGetTeamSuccess] = useState(false);
    const [appList, setAppList] = useState(null);
    const [loadAppList, setLoadAppList] = useState(false);
    const [icon, setIcon] = useState({
        af_schema: false,
        af_secret_shop: false,
        af_ghl_report: false
    });
    const [appActive, setAppActive] = useState({
        af_schema: false,
        af_secret_shop: false,
        af_ghl_report: false
    });

    const [features, setFeatures] = useState({
        af_schema: [],
        af_secret_shop: [],
        af_ghl_report: []
    });
    const [isError, setIsError] = useState(false);
    const [schemaActiveFeatures, setSchemaActiveFeatures] = useState({});
    const [activeSecretShopFeatures, setSecretShopActiveFeatures] = useState({});
    const [personalData, setPersonalData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        role: CONSTANR.USER_ROLE_USER
    });

    useEffect(() => {
        if (apps.isSuccess) {
            setAppList(apps.apps);
            setLoadAppList(true);
        }
    }, [apps.isSuccess]);

    const roles = [
        {
            name: "Limited User",
            id: CONSTANR.USER_ROLE_USER
        },
        {
            name: "Agency User",
            id: CONSTANR.USER_ROLE_AGENCY_USER
        },
        {
            name: "Agency Admin",
            id: CONSTANR.USER_ROLE_ADMIN
        }
    ];

    const handleRoleChange = (e) => {
        setPersonalData({
            ...personalData,
            role: e.target.value
        });
    };

    const getSelectBox = () => {
        return (
            <>
                <CustomSelectBox
                    name="Select Role"
                    label="Role*"
                    options={roles}
                    value={personalData.role}
                    handleChange={handleRoleChange}
                    color="secondary"
                    error={isError && personalData.role === ""}
                />
                {isError && personalData.role === "" && (
                    <Typography component="span" variant="subtitle2" color="error">
                        Please Select a role
                    </Typography>
                )}
            </>
        );
    };

    useEffect(() => {
        if (uuid !== "") {
            getTeamData(uuid, (response) => {
                if (response.status) {
                    const { data } = response;
                    setPersonalData({
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                        phone_number: data.phone_number,
                        role: data.role
                    });
                    getSelectBox();

                    if (
                        data.role === CONSTANR.USER_ROLE_USER ||
                        data.role === CONSTANR.USER_ROLE_AGENCY_USER
                    ) {
                        setAppActive(data.apps);
                        setIcon(data.apps);

                        setFeatures(data.app_features);
                        Object.keys(data.user_app_features).forEach(function (key) {
                            if (key === "af_schema") {
                                setSchemaActiveFeatures(data.user_app_features.af_schema);
                            }
                            if (key === "af_secret_shop") {
                                setSecretShopActiveFeatures(data.user_app_features.af_secret_shop);
                            }
                        });
                    }

                    setGetTeamSuccess(true);
                }
            });
        }
    }, [uuid]);

    const getAppAndFeatureData = () => {
        const appAndFeatureData = [];

        Object.keys(appActive).map((keyName) => {
            const featuteData = [];
            if (keyName === "af_schema") {
                if (appActive.af_schema === true) {
                    features.af_schema.forEach((item) => {
                        if (
                            schemaActiveFeatures[item] === undefined ||
                            schemaActiveFeatures[item] === true
                        ) {
                            featuteData.push(item);
                        }
                    });
                    appAndFeatureData[keyName] = featuteData;
                }
            } else if (keyName === "af_secret_shop") {
                if (appActive.af_secret_shop === true) {
                    features.af_secret_shop.forEach((item) => {
                        if (
                            activeSecretShopFeatures[item] === undefined ||
                            activeSecretShopFeatures[item] === true
                        ) {
                            featuteData.push(item);
                        }
                    });
                    appAndFeatureData[keyName] = featuteData;
                }
            } else if (keyName === "af_ghl_report") {
                if (appActive.af_ghl_report === true) {
                    appAndFeatureData[keyName] = featuteData;
                }
            }

            return featuteData;
        });

        return appAndFeatureData;
    };

    const handleInputChange = (e) => {
        setPersonalData({
            ...personalData,
            [e.target.name]: e.target.value
        });
    };

    const handleSelectApp = (e, name) => {
        setAppActive({
            ...appActive,
            [name]: !appActive[name]
        });

        setIcon({
            ...icon,
            [name]: !icon[name]
        });
        if (!appActive[name]) {
            getAppFeaturesData(name, (response) => {
                setFeatures({
                    ...features,
                    [name]: response.data
                });

                if (name === "af_schema") {
                    response.data.map((item) => {
                        setSchemaActiveFeatures({
                            ...schemaActiveFeatures,
                            [item]: true
                        });
                    });
                } else if (name === "af_secret_shop") {
                    response.data.map((item) => {
                        setSecretShopActiveFeatures({
                            ...activeSecretShopFeatures,
                            [item]: true
                        });
                    });
                }
            });
        }
    };

    const handleTeamSave = () => {
        if (
            personalData.first_name === "" ||
            personalData.email === "" ||
            !CONSTANR.validateEmail(personalData.email) ||
            personalData.role === ""
        ) {
            setIsError(true);
            ShowAlerts(
                "Opps ! Some information's are missing or invalid",
                CONSTANR.ALERT_TYPE_ERROR
            );
            setDrawerOpen(true);
            return;
        }

        const inputData = {
            first_name: personalData.first_name,
            last_name: personalData.last_name,
            email: personalData.email,
            phone_number: personalData.phone_number,
            role: personalData.role,
            uuid
        };

        if (personalData.role !== CONSTANR.USER_ROLE_ADMIN) {
            if (
                personalData.role === CONSTANR.USER_ROLE_USER &&
                Object.keys(getAppAndFeatureData()).length === 0
            ) {
                ShowAlerts("Please select app and feature", CONSTANR.ALERT_TYPE_ERROR);
                return;
            }

            inputData.app_features = { ...getAppAndFeatureData() };
            // data['app_features'] = JSON.stringify(getAppAndFeatureData());
        }

        editTeamMemberData(inputData, (response) => {
            if (response.status) {
                setPersonalData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    role: CONSTANR.USER_ROLE_USER
                });
                ShowAlerts(response.message, CONSTANR.ALERT_TYPE_SUCCESS);

                setAppActive({
                    af_schema: false,
                    af_secret_shop: false,
                    af_ghl_report: false
                });
                setFeatures({
                    af_schema: [],
                    af_secret_shop: [],
                    af_ghl_report: []
                });
                setIcon({
                    af_schema: false,
                    af_secret_shop: false,
                    af_ghl_report: false
                });

                setDrawerOpen(false);
                getTeamDataTableList();
                setUuid("");
            }
        });
    };

    return getTeamSuccess ? (
        <Box className="drawer__wrapper">
            <form onSubmit={handleSubmit(handleTeamSave)}>
                <Grid item xs={12} sx={{ marginBottom: "15px" }}>
                    <Typography
                        variant="subtitle2"
                        sx={{ paddingBottom: "8px", borderBottom: ` 1px solid divider` }}
                    >
                        User Info {personalData.first_name}
                    </Typography>
                </Grid>

                <Grid container spacing={2} className="customRow">
                    <Grid item xs={6}>
                        <CustomTextField
                            type="text"
                            name="first_name"
                            label="First Name*"
                            size="small"
                            placeholder="First Name"
                            error={isError && personalData.first_name === ""}
                            handleChange={handleInputChange}
                            value={personalData.first_name}
                            helperText={
                                isError &&
                                personalData.first_name === "" && (
                                    <Typography variant="subtitle2" color="error">
                                        First Name is Required
                                    </Typography>
                                )
                            }
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <CustomTextField
                            type="text"
                            name="last_name"
                            label="Last Name*"
                            size="small"
                            placeholder="Last Name"
                            handleChange={handleInputChange}
                            value={personalData.last_name}
                            error={isError && personalData.first_name === ""}
                            helperText={
                                isError &&
                                personalData.first_name === "" && (
                                    <Typography variant="subtitle2" color="error">
                                        Last Name is Required
                                    </Typography>
                                )
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextField
                            type="email"
                            name="email"
                            label="Email*"
                            size="small"
                            placeholder="Email"
                            error={
                                isError &&
                                (personalData.email === "" ||
                                    !CONSTANR.validateEmail(personalData.email))
                            }
                            handleChange={handleInputChange}
                            value={personalData.email}
                            helperText={
                                (isError && personalData.email === "" && (
                                    <Typography variant="subtitle2" color="error">
                                        Email is Required
                                    </Typography>
                                )) ||
                                (isError && !CONSTANR.validateEmail(personalData.email) && (
                                    <Typography variant="subtitle2" color="error">
                                        Invalid Email
                                    </Typography>
                                ))
                            }
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <CustomTextField
                            type="text"
                            name="phone_number"
                            size="small"
                            label="Phone Number*"
                            placeholder="Phone Number"
                            handleChange={handleInputChange}
                            value={personalData.phone_number}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            className="drawer__title"
                            sx={{ paddingBottom: "8px" }}
                        >
                            User Roles
                        </Typography>
                    </Grid>

                    <Grid item xs={7} className="select_user_roles">
                        {getSelectBox()}
                    </Grid>

                    {personalData.role === CONSTANR.USER_ROLE_USER ||
                    personalData.role === CONSTANR.USER_ROLE_AGENCY_USER ? (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="drawer__title"
                                    sx={{ paddingBottom: "8px" }}
                                >
                                    Apps & Features Permissions
                                </Typography>
                            </Grid>

                            <Grid spacing={2} item xs={12} className="drawer__custom_tabs">
                                <Box className="drawer__buttons_group">
                                    {loadAppList && apps.haveSubscription
                                        ? appList.map((item) => {
                                              if (item.subscribed) {
                                                  return (
                                                      <CustomButton
                                                          variant="outlined"
                                                          size="medium"
                                                          onClick={(e) =>
                                                              handleSelectApp(e, item.identifier)
                                                          }
                                                          title={item.name}
                                                          endIcon={
                                                              icon[item.identifier] ? (
                                                                  <RadioButtonChecked color="success" />
                                                              ) : (
                                                                  <RadioButtonUnchecked />
                                                              )
                                                          }
                                                          color="secondary"
                                                      />
                                                  );
                                              }
                                          })
                                        : ""}
                                </Box>

                                <Box className="drawer__contents">
                                    {appActive.af_secret_shop ? (
                                        <Box className="drawer__single_item">
                                            <Box>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="drawer__title"
                                                    sx={{ paddingBottom: "8px" }}
                                                >
                                                    Secret Shop
                                                </Typography>
                                            </Box>
                                            <Grid container spacing={2} className="drawer__grid">
                                                {features.af_secret_shop &&
                                                    features.af_secret_shop.map((item, index) => {
                                                        return (
                                                            <Grid item xs={4} key={index}>
                                                                <SwitchWithLabel
                                                                    switchColor="secondary"
                                                                    checked={
                                                                        activeSecretShopFeatures
                                                                    }
                                                                    setChecked={
                                                                        setSecretShopActiveFeatures
                                                                    }
                                                                    label={item}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                    {appActive.af_schema ? (
                                        <div className="drawer__single_item">
                                            <Box>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="drawer__title"
                                                    sx={{ paddingBottom: "8px" }}
                                                >
                                                    Schema
                                                </Typography>
                                            </Box>
                                            <Grid container spacing={2} className="drawer__grid">
                                                {features.af_schema &&
                                                    features.af_schema.map((item, index) => {
                                                        return (
                                                            <Grid item xs={4} key={index}>
                                                                <SwitchWithLabel
                                                                    switchColor="secondary"
                                                                    checked={schemaActiveFeatures}
                                                                    setChecked={
                                                                        setSchemaActiveFeatures
                                                                    }
                                                                    label={item}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                </Grid>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 1,
                        m: 1,
                        bgcolor: "background.paper"
                    }}
                >
                    <CustomButton
                        className="af-btn af-btn-alt af-btn-no-border"
                        variant="contained"
                        color="info"
                        title="Cancel"
                        handleButton={() => setDrawerOpen(false)}
                        sx={{ marginRight: "20px" }}
                    />

                    <CustomButton
                        className="af-btn af-btn-alt"
                        variant="contained"
                        type="submit"
                        color="secondary"
                        title="Update"
                        handleButton={null}
                    />
                </Box>
            </form>
        </Box>
    ) : (
        ""
    );
};

const mapStateToProps = (state) => {
    return {
        apps: state.appReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAppFeaturesData: (data, callback) => dispatch(getAppFeatures(data, callback)),
        editTeamMemberData: (data, callback) => dispatch(editTeamMember(data, callback)),
        getTeamData: (data, callback) => dispatch(getTeam(data, callback)),
        getTeamDataTableList: (currentPage = 1, rowLimit = 10, teamRole = 0, searchKey = "") =>
            dispatch(
                getTeamDataTable({
                    currentPage,
                    rowLimit,
                    teamRole,
                    searchKey
                })
            )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);
