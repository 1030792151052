// GHL Ad source table constant
export const GHL_AD_SOURCE_TABLE_TYPE_CUSTOM = 0;
export const GHL_AD_SOURCE_TABLE_TYPE_FACEBOOK = 1;
export const GHL_AD_SOURCE_TABLE_TYPE_GOOGLE = 2;
export const GHL_AD_SOURCE_TABLE_TYPE_GHL = 3;
export const GHL_AD_SOURCE_TABLE_STATUS_INACTIVE = 0;
export const GHL_AD_SOURCE_TABLE_STATUS_ACTIVE = 1;
export const GHL_AD_SOURCE_TABLE_SYSTEM_DB_YES = 1;

export const ghlAdSourceType = {
    1: "FACEBOOK",
    2: "GOOGLE",
    3: "GHL"
};

export const ghlAdSourceTypeOptions = [
    { id: 1, name: ghlAdSourceType[1] },
    { id: 2, name: ghlAdSourceType[2] },
    { id: 3, name: ghlAdSourceType[3] }
];

// GHL Ad source column constant

export const ghlAdSourceDataTypes = {
    1: "NUMERIC",
    2: "STRING",
    3: "DATE"
};

export const ghlAdSourceColumnTypes = {
    1: "NUMERIC",
    2: "STRING",
    3: "DATE"
};

export const GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_ID_COLUMN_NO = 0;
export const GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_ID_COLUMN_YES = 1;

export const GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_NAME_COLUMN_NO = 0;
export const GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_NAME_COLUMN_YES = 1;

export const GHL_AD_SOURCE_COLUMN_STATUS_INACTIVE = 0;
export const GHL_AD_SOURCE_COLUMN_STATUS_ACTIVE = 1;

export const GHL_AD_SOURCE_COLUMN__IS_POPULAR_YES = 1;
export const GHL_AD_SOURCE_COLUMN__IS_POPULAR_NO = 0;

export const GHL_AD_SOURCE_COLUMN__IS_DATE_YES = 1;
export const GHL_AD_SOURCE_COLUMN__IS_DATE_NO = 0;

export const GHL_AD_SOURCE_COLUMN_TYPES = [
    { id: 1, name: ghlAdSourceColumnTypes[1] },
    { id: 2, name: ghlAdSourceColumnTypes[2] },
    { id: 3, name: ghlAdSourceColumnTypes[3] }
];
