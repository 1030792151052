import { sendRequestWithToken } from "./rootApi";

export const loginAsUserApi = async (payload) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/admin/login-as-user`,
        payload
    );
};

export const loginAsAdminApi = async (payload) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/admin/login-as-admin`,
        payload
    );
};

export const getAgencyListApi = async (payload) => {
    const { currentPage, itemPerPage, searchKey } = payload;
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/admin/agency/list?page=${currentPage}&limit=${itemPerPage}&search_key=${searchKey}`
    );
};

export const getUserListApi = async (payload) => {
    const { currentPage, itemPerPage, searchKey } = payload;
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/admin/user/list?page=${currentPage}&limit=${itemPerPage}&search_key=${searchKey}`
    );
};

export const getAgencyUsersListApi = async (payload) => {
    const { agencyId, currentPage, itemPerPage, searchKey } = payload;
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/admin/${agencyId}/users/list?page=${currentPage}&limit=${itemPerPage}&search_key=${searchKey}`
    );
};

export const userStatusChangeApi = async (payload) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/admin/user/status/change`,
        payload
    );
};

export const userPasswordChangeApi = async (payload) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/admin/user/password/change`,
        payload
    );
};

export const userAdminMakeApi = async (payload) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/admin/user/make/admin`,
        payload
    );
};

export const getAppListApi = async (payload) => {
    const { currentPage, itemPerPage, searchKey } = payload;
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/admin/app/list?page=${currentPage}&limit=${itemPerPage}&search_key=${searchKey}`
    );
};

export const createAppApi = async (payload) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/admin/app/create`,
        payload
    );
};

export const getAppDataForEditApi = async (payload) => {
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/admin/app/${payload.appId}`
    );
};

export const updateAppApi = async (payload) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/admin/app/${payload.appId}`,
        payload
    );
};

export const agencyStatusChangeApi = async (payload) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/admin/agency/status/change`,
        payload
    );
};

export const appStatusChangeApi = async (payload) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/admin/app/status/change`,
        payload
    );
};
export const addAdminAgencyTeamApi = async (param) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/admin/user/add`,
        param
    );
};
export const updateAdminAgencyTeamApi = async (param) => {
    const { uuid } = param;
    delete param.uuid;
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/admin/user/update/${uuid}`,
        param
    );
};
export const deleteAdminAgencyTeamApi = async (param) => {
    const { uuid } = param;
    delete param.uuid;
    return sendRequestWithToken(
        "delete",
        `${process.env.REACT_APP_API_BASE_URL}/admin/user/delete/${uuid}`
    );
};

export const ghlAdSourceSyncApi = async () => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/admin/report/ad-source/sync`
    );
};

export const ghlAdSourceTableListApi = async (payload) => {
    const { currentPage, rowLimit, searchKey, adSourceType } = payload;

    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/admin/report/ad-source/table/list?page=${currentPage}&limit=${rowLimit}&search_key=${searchKey}&adSourceType=${adSourceType}`
    );
};

export const ghlAdSourceTableColumnListApi = async (payload) => {
    const { currentPage, rowLimit, searchKey, tableId, status } = payload;

    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/admin/report/ad-source/table/${tableId}/column/list?page=${currentPage}&limit=${rowLimit}&search_key=${searchKey}&status=${status}`
    );
};

export const ghlAdSourceColumnUpdateApi = async (payload) => {
    const { columnId } = payload;

    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/admin/report/ad-source/column/${columnId}/update`,
        payload
    );
};

export const ghlAdSourceTableAddApi = async (payload) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/admin/report/ad-source/table`,
        payload
    );
};

export const ghlAdSourceTableColumnAddApi = async (payload) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/admin/report/ad-source/column`,
        payload
    );
};

export const ghlAdSourceTableCustomColumnAddApi = async (payload) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/admin/report/ad-source/column/custom`,
        payload
    );
};
