import * as ACTION from "./resetPasswordActionTypes";

export const sendForgetPasswordEmail = (payload, callback) => ({
    type: ACTION.SEND_EMAIL_FOR_PASSWORD_RESET,
    payload,
    callback
});

export const verifyForgetPasswordCode = (payload, callback) => ({
    type: ACTION.VERIFY_FORGET_PASSWORD_CODE,
    payload,
    callback
});

export const resetPassword = (payload, callback) => ({
    type: ACTION.RESET_PASSWORD,
    payload,
    callback
});
