import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    ghlAdSourceColumnUpdateApi,
    ghlAdSourceSyncApi,
    ghlAdSourceTableAddApi,
    ghlAdSourceTableColumnAddApi,
    ghlAdSourceTableColumnListApi,
    ghlAdSourceTableCustomColumnAddApi,
    ghlAdSourceTableListApi
} from "../../../api/adminApi";
import * as ACTION from "./GhlActionType";

function* syncGhlAdSourceSaga(action) {
    try {
        const response = yield call(ghlAdSourceSyncApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action?.callback(responseData);
        }
    } catch (error) {
        action?.callback({ status: false, message: "Something went wrong" });
    }
}

function* getGhlAdSourceTableListSaga(action) {
    try {
        const response = yield call(ghlAdSourceTableListApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            yield put({
                type: ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_SUCCESS,
                payload: responseData
            });
        } else {
            yield put({
                type: ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_FAILED,
                payload: []
            });
        }
    } catch (e) {
        yield put({
            type: ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* getGhlAdSourceTableColumnListSaga(action) {
    try {
        const response = yield call(ghlAdSourceTableColumnListApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            yield put({
                type: ACTION.GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE_SUCCESS,
                payload: responseData
            });
        } else {
            yield put({
                type: ACTION.GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE_FAILED,
                payload: []
            });
        }
    } catch (e) {
        yield put({
            type: ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* updateAdSourceTableColumnSaga(action) {
    try {
        const response = yield call(ghlAdSourceColumnUpdateApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action?.callback(responseData);
        }
    } catch (error) {
        action?.callback({ status: false, message: "Something went wrong" });
    }
}

function* addAdSourceTableSaga(action) {
    try {
        const response = yield call(ghlAdSourceTableAddApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action?.callback(responseData);
        }
    } catch (error) {
        action?.callback({ status: false, message: "Something went wrong" });
    }
}

function* addAdSourceTableColumnSaga(action) {
    try {
        const response = yield call(ghlAdSourceTableColumnAddApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action?.callback(responseData);
        }
    } catch (error) {
        action?.callback({ status: false, message: "Something went wrong" });
    }
}

function* addAdSourceCustomColumnSaga(action) {
    try {
        const response = yield call(ghlAdSourceTableCustomColumnAddApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action?.callback(responseData);
        }
    } catch (error) {
        console.log({ error });
        action?.callback({ status: false, message: "Something went wronggggs" });
    }
}

function* ghlApiWatcher() {
    yield takeEvery(ACTION.GHL_AD_SOURCE_TABLE_AND_COLUMN_SYNC, syncGhlAdSourceSaga);
    yield takeEvery(ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE, getGhlAdSourceTableListSaga);
    yield takeEvery(ACTION.GHL_UPDATE_AD_SOURCE_TABLE_COLUMN, updateAdSourceTableColumnSaga);
    yield takeEvery(ACTION.GHL_ADD_AD_SOURCE_TABLE, addAdSourceTableSaga);
    yield takeEvery(ACTION.GHL_ADD_AD_SOURCE_TABLE_COLUMN, addAdSourceTableColumnSaga);
    yield takeEvery(ACTION.GHL_ADD_AD_SOURCE_CUSTOM_COLUMN, addAdSourceCustomColumnSaga);
    yield takeEvery(
        ACTION.GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE,
        getGhlAdSourceTableColumnListSaga
    );
}

export default function* adminGhlSaga() {
    yield all([ghlApiWatcher()]);
}
