import { CustomSkeleton } from "@hipdevteam/afglobalcomponents";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";

const BreadrumSkeleton = ({ customStyle = "" }) => {
    return (
        <Grid container className={customStyle}>
            <Grid item xs={8}>
                <Typography component="span" variant="h5" color="text.fill">
                    <CustomSkeleton variant="text" width="20%" />
                </Typography>
                <Box>
                    <CustomSkeleton variant="rectangular" width="18%" />
                </Box>
            </Grid>
        </Grid>
    );
};

export default BreadrumSkeleton;
