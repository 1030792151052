import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import PageWrapper from "../components/layouts/UserLayout";
import { getCookie } from "../helpers/Cookie";

const UserLayoutRouter = ({
    component: Component,
    pageTitle,
    appName,
    haveAccess = true,
    userInfo,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (getCookie(process.env.REACT_APP_REFRESH_TOKEN) !== "") {
                    if (
                        getCookie(process.env.REACT_APP_EMAIL_VERIFIED_STATUS) === "false" ||
                        getCookie(process.env.REACT_APP_EMAIL_VERIFIED_STATUS) === false
                    ) {
                        return <Redirect to="/user/email-verification" />;
                    }
                    return (
                        <PageWrapper
                            pageTitle={pageTitle}
                            appName={appName}
                            haveAccess={haveAccess}
                        >
                            <Component {...props} />
                        </PageWrapper>
                    );
                }
                return <Redirect to="/login" />;
            }}
        />
    );
};

const mapStateToProps = (state) => ({
    userInfo: state.loginReducer.user
});

export default connect(mapStateToProps, null)(UserLayoutRouter);
