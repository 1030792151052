import { Box, Typography } from "@mui/material";

const Instruction = () => {
    return (
        <Box fullWidth sx={{ display: "flex", flexDirection: "column", paddingBottom: "20px" }}>
            <Box
                sx={{
                    backgroundColor: "primary.main",
                    borderRadius: "8px",
                    padding: "14px 30px 14px 30px"
                }}
            >
                <Typography variant="subtitle1" color="common.white">
                    Instruction For Main Domain
                </Typography>
            </Box>
            <Box sx={{ padding: "6px 6px 6px 6px", textAlign: "center" }}>
                <img
                    src="https://agencyframework.s3.amazonaws.com/others/20220111T105944808Z604572.png"
                    alt="subdomain"
                    width="719px"
                    height="477px"
                />
            </Box>
            <Box
                sx={{
                    backgroundColor: "primary.main",
                    borderRadius: "8px",
                    padding: "14px 30px 14px 30px"
                }}
            >
                <Typography variant="subtitle1" color="common.white">
                    Instruction For Sub Domain
                </Typography>
            </Box>
            <Box sx={{ padding: "6px 6px 6px 6px", textAlign: "center" }}>
                <img
                    src="https://agencyframework.s3.amazonaws.com/others/20220111T110151710Z336764.png"
                    alt="subdomain"
                    width="719px"
                    height="477px"
                />
            </Box>
        </Box>
    );
};

export default Instruction;
