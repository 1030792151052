export const GET_GEOGRID_API_AUTH_DATA_TABLE = "GET_GEOGRID_API_AUTH_DATA_TABLE";
export const GET_GEOGRID_API_AUTH_DATA_TABLE_SUCCESS = "GET_GEOGRID_API_AUTH_DATA_TABLE_SUCCESS";
export const GET_GEOGRID_API_AUTH_DATA_TABLE_FAILED = "GET_GEOGRID_API_AUTH_DATA_TABLE_FAILED";

export const GEOGRID_API_AUTH_CREATE = "GEOGRID_API_AUTH_CREATE";

export const GET_GEOGRID_API_AUTH = "GET_GEOGRID_API_AUTH";

export const UPDATE_GEOGRID_API_AUTH = "UPDATE_GEOGRID_API_AUTH";

export const UPDATE_GEOGRID_API_AUTH_STATUS = "UPDATE_GEOGRID_API_AUTH_STATUS";
