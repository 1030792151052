/* eslint-disable prettier/prettier */
import {
    CustomButton,
    CustomTextFieldPassword
} from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import ShowAlerts from "../../../../helpers/ShowAlerts";
import { userPasswordChange } from "../../../../statemanagement/Admin/Agencies/agencyAction";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../../utils/Constant/GlobalConstant";

const UserPasswordUpdateForm = (props) => {
    const { user_uuid, changeUserPassword, setOpenStatus } = props;

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const schema = yup.object({
        new_password: yup
            .string()
            .required("No password provided.")
            .min(8, "Password is too short - should be 8 chars minimum."),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const onSubmit = (data) => {
        data.user_uuid = user_uuid;

        changeUserPassword(data, (response) => {
            if (response.status) {
                setOpenStatus(false);
                ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
            }
        });
    };

    const handlePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const renderErrorMessage = (error, message) => {
        if (error !== undefined) {
            return (
                <Typography variant="subtitle2" component="span" color="error">
                    {message}
                </Typography>
            );
        }
        return "";
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
                <Controller
                    render={({ field }) => (
                        <CustomTextFieldPassword
                            InputLabelProps={{ shrink: true }}
                            show={isPasswordVisible}
                            id=""
                            type={isPasswordVisible ? "text" : "password"}
                            label="New Password"
                            handleClickShowPassword={handlePasswordVisibility}
                            register={field}
                            error={!!errors.new_password}
                            helperText={renderErrorMessage(
                                errors.new_password,
                                errors.new_password?.message
                            )}
                        />
                    )}
                    name="new_password"
                    control={control}
                />
            </Grid>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                }}
            >
                <CustomButton
                    handleButton={null}
                    title="Update"
                    variant="contained"
                    type="submit"
                    color="secondary"
                />
            </Box>
        </form>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        changeUserPassword: (payload, callback) => dispatch(userPasswordChange(payload, callback)),
    };
};
export default connect(null, mapDispatchToProps)(UserPasswordUpdateForm);
