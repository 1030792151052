import { all, call, takeEvery } from "redux-saga/effects";
import { authCredentialsGetApi, authCredentialsUpdateApi } from "../../api/authApi";
import * as ACTION from "./apiAuthActionTypes";

function* getAuthCredentialsSaga(action) {
    try {
        const response = yield call(authCredentialsGetApi, action.payload);
        if (action.callback) {
            action.callback(response);
        }
    } catch (err) {
        if (action.callback) {
            action.callback(err);
        }
    }
}

function* updateAuthCredentialsSaga(action) {
    try {
        const response = yield call(authCredentialsUpdateApi, action.payload);
        if (action.callback) {
            action.callback(response);
        }
    } catch (err) {
        if (action.callback) {
            action.callback(err);
        }
    }
}
function* apiAuthWatcher() {
    yield takeEvery(ACTION.AUTH_CREDENTIALS_GET, getAuthCredentialsSaga);
    yield takeEvery(ACTION.AUTH_CREDENTIALS_UPDATE, updateAuthCredentialsSaga);
}
export default function* apiAuthSaga() {
    yield all([apiAuthWatcher()]);
}
