import * as ACTION from "./loginActionTypes";

const initialState = {
    user: "",
    isLoading: false,
    error: false,
    errorMessage: "",
    loginSuccessStatus: false,
    emailExist: false,
    forgetError: false,
    forgetErrorMessage: "",
    isForgetPassword: false
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.LOGIN_ATTEMPT:
            return {
                ...state,
                user: [],
                error: false,
                errorMessage: "",
                isLoading: true,
                loginSuccessStatus: false
            };
        case ACTION.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                errorMessage: "",
                isLoading: false,
                loginSuccessStatus: true
            };
        case ACTION.LOGIN_ERROR:
            return {
                ...state,
                user: [],
                errorMessage: action.payload,
                isLoading: false,
                error: true
            };
        case ACTION.LOGOUT_SUCCESS:
            return {
                ...state,
                user: [],
                errorMessage: "",
                isLoading: false
            };
        case ACTION.CHECK_EMAIL_EXIST:
            return {
                ...state,
                errorMessage: "",
                isLoading: true,
                error: false,
                emailExist: false
            };
        case ACTION.CHECK_EMAIL_EXIST_SUCCESS:
            return { ...state, errorMessage: "", error: false, emailExist: true };
        case ACTION.CHECK_EMAIL_EXIST_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                error: true,
                emailExist: false,
                isLoading: false
            };
        case ACTION.VERIFICATION_CODE_SEND_SUCCESS:
            return { ...state, isLoading: false };
        case ACTION.VERIFICATION_CODE_SEND_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload.message,
                error: true
            };
        case ACTION.CHECKATTEMP_PASSWORD_FORGET:
            return {
                ...state,
                forgetErrorMessage: "",
                isLoading: true,
                forgetError: false,
                isForgetPassword: false
            };
        case ACTION.CHECKATTEMP_PASSWORD_FORGET_SUCCESS:
            return {
                ...state,
                forgetErrorMessage: "",
                isLoading: false,
                forgetError: false,
                isForgetPassword: true
            };
        case ACTION.CHECKATTEMP_PASSWORD_FORGET_ERROR:
            return {
                ...state,
                forgetErrorMessage: action.payload.message,
                isLoading: false,
                forgetError: true
            };
        case ACTION.CLEAR_ERROR:
            return {
                ...state,
                isLoading: false,
                error: false,
                errorMessage: "",
                loginSuccessStatus: false,
                emailExist: false,
                forgetError: false,
                forgetErrorMessage: "",
                isForgetPassword: false
            };

        default:
            return state;
    }
};

export default loginReducer;
