import { GlobalBreadcrumbs } from "@hipdevteam/afglobalcomponents";
import { Grid, Typography } from "@mui/material";
import React from "react";
import "./custom.style.css";

function LocalHeader({ headerText = "header", components = [], buttons = null, customStyle = "" }) {
    return (
        <Grid container className={customStyle}>
            <Grid item xs={5}>
                <Typography component="span" variant="h5" color="text.fill">
                    {headerText}
                </Typography>
                <GlobalBreadcrumbs components={components} />
            </Grid>
            <Grid item xs={7} sx={{ alignSelf: "center" }}>
                <Grid container sx={{ justifyContent: "flex-end" }} className="button-wrapper">
                    {buttons !== null ? buttons() : ""}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LocalHeader;
