const Icons = {
    googleIconDisable: (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            // xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.74 11.891a3.044 3.044 0 0 1-2.495-1.3 3.044 3.044 0 0 1-4.988 0 3.044 3.044 0 0 1-4.988 0 3.044 3.044 0 0 1-3.332 1.182v6.56h16.64v-6.56a3.039 3.039 0 0 1-.838.118Z"
                fill="#B1B1B2"
                fillOpacity=".26"
            />
            <path
                d="m14.66 1.667 1.05 6.005h3.745l-2.097-6.005H14.66Zm-3.85 6.005h3.778L13.54 1.667h-2.73v6.005Zm-1.105 0V1.667h-2.73L5.926 7.671h3.779Zm-4.9 0 1.049-6.005H3.157L1.059 7.671h3.746Zm-2.03 3.115c1.07 0 1.942-.872 1.942-1.942v-.07H.833v.07c0 1.07.871 1.941 1.942 1.941Zm6.93-1.942v-.07H5.821v.07c0 1.07.871 1.941 1.942 1.941 1.07 0 1.942-.87 1.942-1.941Zm4.988 0v-.07h-3.884v.07c0 1.07.871 1.941 1.942 1.941 1.07 0 1.942-.87 1.942-1.941Zm3.047 1.942c1.07 0 1.941-.872 1.941-1.942v-.07h-3.884v.07c0 1.07.872 1.941 1.942 1.941Z"
                fill="#B1B1B2"
                fillOpacity=".54"
            />
        </svg>
    ),
    googleIconEnable: (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            // xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.74 11.891a3.044 3.044 0 0 1-2.495-1.3 3.044 3.044 0 0 1-4.988 0 3.044 3.044 0 0 1-4.988 0 3.044 3.044 0 0 1-3.332 1.182v6.56h16.64v-6.56a3.039 3.039 0 0 1-.838.118Z"
                fill="#2578FF"
            />
            <path
                d="M17.74 11.891a3.044 3.044 0 0 1-2.495-1.3 3.044 3.044 0 0 1-4.988 0 3.044 3.044 0 0 1-4.988 0 3.044 3.044 0 0 1-3.332 1.182v6.56h16.64v-6.56a3.039 3.039 0 0 1-.838.118Z"
                fill="#2A2E34"
                fillOpacity=".3"
            />
            <path
                d="m14.66 1.667 1.05 6.005h3.745l-2.097-6.005H14.66Zm-3.85 6.005h3.778L13.54 1.667h-2.73v6.005Zm-1.105 0V1.667h-2.73L5.926 7.671h3.779Zm-4.9 0 1.049-6.005H3.157L1.059 7.671h3.746Zm-2.03 3.115c1.07 0 1.942-.872 1.942-1.942v-.07H.833v.07c0 1.07.871 1.941 1.942 1.941Zm6.93-1.942v-.07H5.821v.07c0 1.07.871 1.941 1.942 1.941 1.07 0 1.942-.87 1.942-1.941Zm4.988 0v-.07h-3.884v.07c0 1.07.871 1.941 1.942 1.941 1.07 0 1.942-.87 1.942-1.941Zm3.047 1.942c1.07 0 1.941-.872 1.941-1.942v-.07h-3.884v.07c0 1.07.872 1.941 1.942 1.941Z"
                fill="#2578FF"
            />
        </svg>
    ),
    twilioIconLight: (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.98975 2C5.13616 2 2 5.13616 2 8.98975C2 12.8433 5.13616 15.9795 8.98975 15.9795C12.8638 16 16 12.8638 16 8.98975C16 5.11567 12.8638 2 8.98975 2ZM8.98975 14.1552C6.16105 14.1552 3.8448 11.8389 3.8448 8.98975C3.8448 6.16105 6.16105 3.8448 8.98975 3.8448C11.8389 3.8448 14.1552 6.16105 14.1552 8.98975C14.1552 11.8389 11.8389 14.1552 8.98975 14.1552Z"
                fill="#1B1464"
            />
            <path
                d="M10.7321 8.70268C11.5358 8.70268 12.1874 8.0511 12.1874 7.24734C12.1874 6.44357 11.5358 5.79199 10.7321 5.79199C9.9283 5.79199 9.27672 6.44357 9.27672 7.24734C9.27672 8.0511 9.9283 8.70268 10.7321 8.70268Z"
                fill="#1B1464"
            />
            <path
                d="M10.7321 12.1875C11.5358 12.1875 12.1874 11.536 12.1874 10.7322C12.1874 9.92843 11.5358 9.27686 10.7321 9.27686C9.9283 9.27686 9.27672 9.92843 9.27672 10.7322C9.27672 11.536 9.9283 12.1875 10.7321 12.1875Z"
                fill="#1B1464"
            />
            <path
                d="M7.24744 12.1875C8.0512 12.1875 8.70278 11.536 8.70278 10.7322C8.70278 9.92843 8.0512 9.27686 7.24744 9.27686C6.44367 9.27686 5.79209 9.92843 5.79209 10.7322C5.79209 11.536 6.44367 12.1875 7.24744 12.1875Z"
                fill="#1B1464"
            />
            <path
                d="M7.24744 8.70268C8.0512 8.70268 8.70278 8.0511 8.70278 7.24734C8.70278 6.44357 8.0512 5.79199 7.24744 5.79199C6.44367 5.79199 5.79209 6.44357 5.79209 7.24734C5.79209 8.0511 6.44367 8.70268 7.24744 8.70268Z"
                fill="#1B1464"
            />
        </svg>
    ),
    twilioIconButton: (
        <svg
            version="1.1"
            id="katman_1"
            x="0px"
            y="0px"
            width="25px"
            viewBox="0 0 2500 2500"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="#FFFF"
                    d="M1248.6,1996.3c-411.4,0-744.9-333.5-744.9-744.9c0-411.4,333.5-744.9,744.9-744.9
		c411.4,0,744.9,333.5,744.9,744.9C1993.5,1662.8,1660,1996.3,1248.6,1996.3L1248.6,1996.3z M1248.6,239.9
		C690,239.9,237.2,692.8,237.2,1251.4c0,558.6,452.8,1011.4,1011.5,1011.4c558.6,0,1011.4-452.8,1011.4-1011.4
		C2260.1,692.8,1807.2,239.9,1248.6,239.9L1248.6,239.9z"
                />
                <path
                    fill="#FFFF"
                    className="st0"
                    d="M1290.2,999.5c0-116.1,94.2-210.3,210.3-210.3s210.3,94.2,210.3,210.3c0,116.2-94.2,210.3-210.3,210.3
		C1384.4,1209.8,1290.2,1115.6,1290.2,999.5 M1290.2,1503.3c0-116.1,94.2-210.3,210.3-210.3s210.3,94.2,210.3,210.3
		c0,116.2-94.2,210.3-210.3,210.3C1384.4,1713.6,1290.2,1619.4,1290.2,1503.3 M786.4,1503.3c0-116.1,94.2-210.3,210.3-210.3
		c116.2,0,210.3,94.2,210.3,210.3c0,116.2-94.2,210.3-210.3,210.3C880.6,1713.6,786.4,1619.4,786.4,1503.3 M786.4,999.5
		c0-116.2,94.2-210.3,210.3-210.3c116.2,0,210.3,94.2,210.3,210.3c0,116.2-94.2,210.3-210.3,210.3
		C880.6,1209.8,786.4,1115.6,786.4,999.5"
                />
            </g>
        </svg>
    ),
    twilioIconDark: (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.98975 2C5.13616 2 2 5.13616 2 8.98975C2 12.8433 5.13616 15.9795 8.98975 15.9795C12.8638 16 16 12.8638 16 8.98975C16 5.11567 12.8638 2 8.98975 2ZM8.98975 14.1552C6.16105 14.1552 3.8448 11.8389 3.8448 8.98975C3.8448 6.16105 6.16105 3.8448 8.98975 3.8448C11.8389 3.8448 14.1552 6.16105 14.1552 8.98975C14.1552 11.8389 11.8389 14.1552 8.98975 14.1552Z"
                fill="#FFFFFF"
            />
            <path
                d="M10.7321 8.70268C11.5358 8.70268 12.1874 8.0511 12.1874 7.24734C12.1874 6.44357 11.5358 5.79199 10.7321 5.79199C9.9283 5.79199 9.27672 6.44357 9.27672 7.24734C9.27672 8.0511 9.9283 8.70268 10.7321 8.70268Z"
                fill="#FFFFFF"
            />
            <path
                d="M10.7321 12.1875C11.5358 12.1875 12.1874 11.536 12.1874 10.7322C12.1874 9.92843 11.5358 9.27686 10.7321 9.27686C9.9283 9.27686 9.27672 9.92843 9.27672 10.7322C9.27672 11.536 9.9283 12.1875 10.7321 12.1875Z"
                fill="#FFFFFF"
            />
            <path
                d="M7.24744 12.1875C8.0512 12.1875 8.70278 11.536 8.70278 10.7322C8.70278 9.92843 8.0512 9.27686 7.24744 9.27686C6.44367 9.27686 5.79209 9.92843 5.79209 10.7322C5.79209 11.536 6.44367 12.1875 7.24744 12.1875Z"
                fill="#FFFFFF"
            />
            <path
                d="M7.24744 8.70268C8.0512 8.70268 8.70278 8.0511 8.70278 7.24734C8.70278 6.44357 8.0512 5.79199 7.24744 5.79199C6.44367 5.79199 5.79209 6.44357 5.79209 7.24734C5.79209 8.0511 6.44367 8.70268 7.24744 8.70268Z"
                fill="#FFFFFF"
            />
        </svg>
    ),
    mailgunIconLight: (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.01444 4.35312C5.551 4.35312 4.361 5.54159 4.361 7C4.361 8.45841 5.55166 9.64687 7.01444 9.64687C8.47722 9.64687 9.66788 8.45841 9.66788 7C9.66788 5.54159 8.47722 4.35312 7.01444 4.35312ZM2.88334 7C2.88334 4.72325 4.73397 2.87612 7.01444 2.87612C9.29491 2.87612 11.1453 4.72544 11.1453 7.00197C11.1453 7.15947 11.1309 7.30275 11.1169 7.44603C11.0884 7.847 11.375 8.14756 11.7766 8.14756C12.4508 8.14756 12.5204 7.27409 12.5204 6.98775C12.5204 3.93772 10.0529 1.47525 6.99781 1.47525C3.94275 1.47525 1.47744 3.93772 1.47744 6.98775C1.47744 10.0378 3.94472 12.5002 7 12.5002C8.61875 12.5002 10.0697 11.7987 11.0884 10.696L12.2216 11.641C10.9449 13.0729 9.08031 13.9751 7 13.9751C3.12703 13.9755 0 10.8395 0 6.98775C0 3.12156 3.14125 0 7 0C10.873 0 14 3.136 14 6.98775C14 8.53431 13.2562 9.79431 11.7766 9.79431C11.1169 9.79431 10.7295 9.49353 10.5 9.16431C9.7685 10.3386 8.47744 11.1116 6.98556 11.1116C4.73353 11.1258 2.88313 9.2785 2.88313 7.00219L2.88334 7ZM7.01444 5.78287C7.68863 5.78287 8.23375 6.32691 8.23375 6.98556C8.23375 7.65866 7.68863 8.20269 7.01444 8.20269C6.34025 8.20269 5.79513 7.66084 5.79513 6.98775C5.80956 6.32931 6.34025 5.78506 7.01444 5.78506V5.78287Z"
                fill="#1B1464"
            />
        </svg>
    ),
    mailgunIconDark: (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.01444 4.35312C5.551 4.35312 4.361 5.54159 4.361 7C4.361 8.45841 5.55166 9.64687 7.01444 9.64687C8.47722 9.64687 9.66788 8.45841 9.66788 7C9.66788 5.54159 8.47722 4.35312 7.01444 4.35312ZM2.88334 7C2.88334 4.72325 4.73397 2.87612 7.01444 2.87612C9.29491 2.87612 11.1453 4.72544 11.1453 7.00197C11.1453 7.15947 11.1309 7.30275 11.1169 7.44603C11.0884 7.847 11.375 8.14756 11.7766 8.14756C12.4508 8.14756 12.5204 7.27409 12.5204 6.98775C12.5204 3.93772 10.0529 1.47525 6.99781 1.47525C3.94275 1.47525 1.47744 3.93772 1.47744 6.98775C1.47744 10.0378 3.94472 12.5002 7 12.5002C8.61875 12.5002 10.0697 11.7987 11.0884 10.696L12.2216 11.641C10.9449 13.0729 9.08031 13.9751 7 13.9751C3.12703 13.9755 0 10.8395 0 6.98775C0 3.12156 3.14125 0 7 0C10.873 0 14 3.136 14 6.98775C14 8.53431 13.2562 9.79431 11.7766 9.79431C11.1169 9.79431 10.7295 9.49353 10.5 9.16431C9.7685 10.3386 8.47744 11.1116 6.98556 11.1116C4.73353 11.1258 2.88313 9.2785 2.88313 7.00219L2.88334 7ZM7.01444 5.78287C7.68863 5.78287 8.23375 6.32691 8.23375 6.98556C8.23375 7.65866 7.68863 8.20269 7.01444 8.20269C6.34025 8.20269 5.79513 7.66084 5.79513 6.98775C5.80956 6.32931 6.34025 5.78506 7.01444 5.78506V5.78287Z"
                fill="#FFFFFF"
            />
        </svg>
    ),
    ghlIconLight: (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2 5.42521H7.8647L4.87881 2L2 5.42521Z" fill="#1B1464" />
            <path d="M6.07869 10.5439H11.9434L8.9575 7.11865L6.07869 10.5439Z" fill="#1B1464" />
            <path d="M10.1353 5.42521H16L13.0141 2L10.1353 5.42521Z" fill="#1B1464" />
            <path d="M5.88773 5.42529H3.83679V16.2893H5.88773V5.42529Z" fill="#1B1464" />
            <path d="M10.036 10.4399H7.98502V16.2892H10.036V10.4399Z" fill="#1B1464" />
            <path d="M14.0937 5.42529H12.0427V16.2893H14.0937V5.42529Z" fill="#1B1464" />
            <path d="M3.83679 5.42529L5.88662 7.43428V5.42529H3.83679Z" fill="#1B1464" />
            <path d="M7.98502 10.5439L10.036 12.5529V10.5439H7.98502Z" fill="#1B1464" />
            <path d="M12.0427 5.42529L14.0926 7.43428V5.42529H12.0427Z" fill="#1B1464" />
        </svg>
    ),
    ghlIconDark: (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2 5.42521H7.8647L4.87881 2L2 5.42521Z" fill="white" />
            <path d="M6.07869 10.5439H11.9434L8.9575 7.11865L6.07869 10.5439Z" fill="white" />
            <path d="M10.1353 5.42521H16L13.0141 2L10.1353 5.42521Z" fill="white" />
            <path d="M5.88773 5.42529H3.83679V16.2893H5.88773V5.42529Z" fill="white" />
            <path d="M10.036 10.4399H7.98502V16.2892H10.036V10.4399Z" fill="white" />
            <path d="M14.0937 5.42529H12.0427V16.2893H14.0937V5.42529Z" fill="white" />
            <path d="M3.83679 5.42529L5.88662 7.43428V5.42529H3.83679Z" fill="white" />
            <path d="M7.98502 10.5439L10.036 12.5529V10.5439H7.98502Z" fill="white" />
            <path d="M12.0427 5.42529L14.0926 7.43428V5.42529H12.0427Z" fill="white" />
        </svg>
    )
};

export default Icons;
