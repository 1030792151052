import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
    createGeogridApiAuthApi,
    geogridApiAuthStatusChangeApi,
    getGeogridApiAuthApi,
    getGeogridApiAuthListApi,
    updateGeogridApiAuthApi
} from "../../../api/geogridApiAuth";
import * as ACTION from "./geogridApiAuthActionType";

function* getGeogridApiAuthDataTableSaga(action) {
    try {
        const response = yield call(getGeogridApiAuthListApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            yield put({
                type: ACTION.GET_GEOGRID_API_AUTH_DATA_TABLE_SUCCESS,
                payload: responseData
            });
        } else {
            yield put({
                type: ACTION.GET_GEOGRID_API_AUTH_DATA_TABLE_FAILED,
                payload: []
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_GEOGRID_API_AUTH_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* getGeogridApiAuthSaga(action) {
    try {
        const response = yield call(getGeogridApiAuthApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* createGeogridApiAuthSaga(action) {
    try {
        const response = yield call(createGeogridApiAuthApi, action.payload);
        const responseData = response.data;

        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* updateGeogridApiAuthDataSaga(action) {
    try {
        const response = yield call(updateGeogridApiAuthApi, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* changeGeogridApiAuthStatusSaga(action) {
    try {
        const response = yield call(geogridApiAuthStatusChangeApi, action.payload);
        const responseData = response.data;

        action.callback(responseData);
    } catch (error) {
        action.callback(error);
    }
}

function* geogridApiAuthWatcher() {
    yield takeLatest(ACTION.GET_GEOGRID_API_AUTH_DATA_TABLE, getGeogridApiAuthDataTableSaga);
    yield takeEvery(ACTION.GET_GEOGRID_API_AUTH, getGeogridApiAuthSaga);
    yield takeEvery(ACTION.GEOGRID_API_AUTH_CREATE, createGeogridApiAuthSaga);
    yield takeEvery(ACTION.UPDATE_GEOGRID_API_AUTH, updateGeogridApiAuthDataSaga);
    yield takeEvery(ACTION.UPDATE_GEOGRID_API_AUTH_STATUS, changeGeogridApiAuthStatusSaga);
}

export default function* geogridApiAuthSaga() {
    yield all([geogridApiAuthWatcher()]);
}
