/* eslint-disable prettier/prettier */
import {
    CustomBadge,
    CustomDropDownButtonMenu,
    CustomHeader,
    GlobalDataTable
} from "@hipdevteam/afglobalcomponents";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PauseIcon from "@mui/icons-material/Pause";
import PeopleIcon from "@mui/icons-material/People";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Container, Grid, StyledEngineProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TableSkeleton from "../../../components/SkeletonLoader/Table/TableSkeleton";
import ShowAlerts from "../../../helpers/ShowAlerts";
import { changeAgencyStatus, getAgencyDataTable } from "../../../statemanagement/Admin/Agencies/agencyAction";
import {
    AGENCY_ACTIVE,
    AGENCY_INACTIVE,
    ALERT_TYPE_ERROR,
    ALERT_TYPE_SUCCESS
} from "../../../utils/Constant/GlobalConstant";
import "../../Team/team.css";

const AdminAgency = (props) => {
    const history = useHistory();
    const { agencyData, isSuccess, getAgencyListDataTable, updateAgencyStatus } = props;

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [progressing, setProgressing] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [filterSearch, setFilterSearch] = useState("");

    const components = [
        {
            name: "Dashboard",
            link: "/Dashboard",
        },
        {
            name: "Admin",
            link: "/admin/agencies",
        },
        { name: "Agency List", link: "#" },
    ];

    useEffect(() => {
        setProgressing(true);
        getAgencyListDataTable(currentPage, itemPerPage, filterSearch);
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setData(agencyData.data);
            setProgressing(false);
            setTotalPages(agencyData.totalPages);
            setTotalItems(agencyData.totalItems);
            setCurrentPage(agencyData.currentPage);
        }
    }, [isSuccess]);

    const changePage = (currentRowsPerPage, currentNewPage) => {
        getAgencyListDataTable(currentNewPage, currentRowsPerPage, filterSearch);
        setItemPerPage(currentRowsPerPage);
        setCurrentPage(currentNewPage);
    };

    const getData = (currentNewPage) => {
        getAgencyListDataTable(currentNewPage, itemPerPage, filterSearch);
        setCurrentPage(currentNewPage);
    };

    const handleFilter = async (searchKey) => {
        setFilterSearch(searchKey);
        getAgencyListDataTable(currentPage, itemPerPage, searchKey);
    };

    const handleAgencyStatusChange = (agencyId, agencyStatus) => {
        updateAgencyStatus({
            agency_id: agencyId,
            agency_status: agencyStatus
        }, (response) => {
            if (response.status) {
                getAgencyListDataTable(currentPage, itemPerPage, filterSearch);
                ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
            }
        })
    }

    const renderActionOptions = (row) => {
        return [
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        <PeopleIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important",
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            View Users
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    history.push(`/admin/${row.id}/users`);
                },
            },

            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        {row.status === AGENCY_INACTIVE ? (
                            (
                                <PlayArrowIcon
                                    sx={{
                                    color: "action.active",
                                    paddingRight: "10px",
                                    width: "2rem !important",
                                }}
                              />
                            )

                        ) : <PauseIcon
                            sx={{
                              color: "action.active",
                              paddingRight: "10px",
                              width: "2rem !important",
                          }}
                        />}
                        
                        <Typography variant="body1" color="text.primary">
                            {row.status === AGENCY_INACTIVE ? 'Active' : 'Inactive'}
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    const status = (row.status === AGENCY_ACTIVE || row.status === null) ?  AGENCY_INACTIVE : AGENCY_ACTIVE
                    handleAgencyStatusChange(row.id, status )
                },
            },
        ];
    };

    const column = [
        {
            name: "Name",
            selector: (row) => {
                return (
                    <Box>
                        {!row.agency_name && <CustomBadge label="Not Found" color="info" />}
                        {row.agency_name && (
                            <Typography variant="body2">{row.agency_name}</Typography>
                        )}
                    </Box>
                );
            },
            sortable: true,
        },
        {
            name: "Email",
            cell: (row) => row.agency_email,
        },
        {
            name: "Phone No",
            selector: (row) => {
                return (
                    <Box>
                        {!row.agency_phone && <CustomBadge label="Not Found" color="info" />}
                        {row.agency_phone && (
                            <Typography variant="body2">{row.agency_phone}</Typography>
                        )}
                    </Box>
                );
            },
        },
        {
            name: "Website",
            selector: (row) => {
                return (
                    <Box>
                        {!row.agency_website && <CustomBadge label="Not Found" color="info" />}
                        {row.agency_website && (
                            <Typography variant="body2">{row.agency_website}</Typography>
                        )}
                    </Box>
                );
            },
        },
        {
            name: "Logo",
            minWidth: "150px",
            selector: (row) => {
                return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {!row.agency_logo && <CustomBadge label="Not Found" color="info" />}
                        {row.agency_logo && (
                            <img
                                src={row.agency_logo}
                                alt="Agency Logo"
                                height="18px"
                                width="18px"
                            />
                        )}
                    </Box>
                );
            },
        },
        {
            name: "Status",
            selector: (row) => {
                return (
                    <Typography variant="body1" component="p">
                        {row.status === AGENCY_INACTIVE ? (
                            <CustomBadge color="warning" label="Inactive" />
                        ) : (
                            <CustomBadge color="success" label="Active" />
                        )}
                    </Typography>
                );
            },
        },
        {
            name: "Actions",
            selector: (row) => (
                <Box>
                    <CustomDropDownButtonMenu
                        buttonContent={<MoreVertIcon color="action.active" />}
                        options={renderActionOptions(row)}
                    />
                </Box>
            ),
        },
    ];
    const headerButtons = [
        {
            name: "Back",
            color: "secondary",
            startIcon: <ArrowBackIcon />,
            action: () => history.goBack(),
        },]
    return (
        <StyledEngineProvider injectFirst>
            <Box className="team-wrapper">
                <Container
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingTop: "10px",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ width: 1, mb: "30px" }}>
                                <CustomHeader
                                    components={components}
                                    headerText="Agency Management"
                                    buttons={history.location.key != undefined ? headerButtons : []}
                                    color="secondary"
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: 1,
                                    padding: "30px 16px",
                                    backgroundColor: "background.paper",
                                    boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                    borderRadius: "4px",
                                }}
                            >
                                {!progressing ? (
                                    <GlobalDataTable
                                        data={data}
                                        columns={column}
                                        subHeader={false}
                                        paginationServer
                                        paginationTotalRows={totalItems}
                                        progressPending={progressing}
                                        selectableRows={false}
                                        noHeader
                                        className="af-table"
                                        onChangePage={getData}
                                        onChangeRowsPerPage={changePage}
                                        handleFilter={handleFilter}
                                        noDataComponent={<p>No User Available</p>}
                                        filterName="Search Agency"
                                    />
                                ) : (
                                    <TableSkeleton />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </StyledEngineProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        agencyData: state.adminAgencyReducer.tableData,
        isSuccess: state.adminAgencyReducer.isSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAgencyListDataTable: (currentPage = 1, itemPerPage = 10, searchKey = "") =>
            dispatch(
                getAgencyDataTable({
                    currentPage,
                    itemPerPage,
                    searchKey,
                })
            ),
        updateAgencyStatus: (payload, callback) => dispatch(changeAgencyStatus(payload, callback)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminAgency);
