export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const CHECK_EMAIL_EXIST = "CHECK_EMAIL_EXIST";
export const CHECK_EMAIL_EXIST_SUCCESS = "CHECK_EMAIL_EXIST_SUCCESS";
export const CHECK_EMAIL_EXIST_ERROR = "CHECK_EMAIL_EXIST_ERROR";

export const CHECKATTEMP_PASSWORD_FORGET = "CHECKATTEMP_PASSWORD_FORGET";
export const CHECKATTEMP_PASSWORD_FORGET_SUCCESS = "CHECKATTEMP_PASSWORD_FORGET_SUCCESS";
export const CHECKATTEMP_PASSWORD_FORGET_ERROR = "CHECKATTEMP_PASSWORD_FORGET_ERROR";

export const VERIFICATION_CODE_SEND_SUCCESS = "VERIFICATION_CODE_SEND_SUCCESS";
export const VERIFICATION_CODE_SEND_ERROR = "VERIFICATION_CODE_SEND_ERROR";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const GOOGLE_LOGIN = "GOOGLE_LOGIN";

export const GOOGLE_SIGN_IN_NEW = "GOOGLE_SIGN_IN_NEW";

export const FETCH_USER_PROFILE_DATA_SUCCESS = "FETCH_USER_PROFILE_DATA_SUCCESS";
