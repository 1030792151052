import { sendRequestWithMultipartFormData } from "./rootApi";

const uploadS3FileApi = async (data) => {
    return sendRequestWithMultipartFormData(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/s3/file-upload`,
        data
    );
};
export default uploadS3FileApi;
