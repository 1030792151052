export const GET_TEAM_DATA_TABLE = "GET_TEAM_DATA_TABLE";
export const GET_TEAM_DATA_TABLE_SUCCESS = "GET_TEAM_DATA_TABLE_SUCCESS";
export const GET_TEAM_DATA_TABLE_FAILED = "GET_TEAM_DATA_TABLE_FAILED";

export const GET_APP_FEATURES = "GET_APP_FEATURES";

export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const EDIT_TEAM_MEMBER = "EDIT_TEAM_MEMBER";
export const DELETE_TEAM = "DELETE_TEAM";

export const GET_TEAM = "GET_TEAM";

export const GET_ASSIGNED_CLIENT_LIST = "GET_ASSIGNED_CLIENT_LIST";
export const GET_ASSIGNED_CLIENT_LIST_SUCCESS = "GET_ASSIGNED_CLIENT_LIST_SUCCESS";
export const GET_ASSIGNED_CLIENT_LIST_FAILED = "GET_ASSIGNED_CLIENT_LIST_FAILED";
export const ASSIGN_CLIENT = "ASSIGN_CLIENT";
