import storage from "local-storage-fallback";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    addAdminAgencyTeamApi,
    agencyStatusChangeApi,
    deleteAdminAgencyTeamApi,
    getAgencyListApi,
    getAgencyUsersListApi,
    loginAsAdminApi,
    loginAsUserApi,
    updateAdminAgencyTeamApi,
    userAdminMakeApi,
    userPasswordChangeApi,
    userStatusChangeApi
} from "../../../api/adminApi";
import { deleteCookie } from "../../../helpers/Cookie";
import ShowAlerts from "../../../helpers/ShowAlerts";
import * as CONSTANT from "../../../utils/Constant";
import * as USER_LOGIN_ACTION from "../../login/loginActionTypes";
import * as ACTION from "./agencyActionType";

const { REACT_APP_ACCESS_TOKEN, REACT_APP_REFRESH_TOKEN, REACT_APP_EMAIL_VERIFIED_STATUS } =
    process.env;

function* getAgencyListSaga(action) {
    try {
        const response = yield call(getAgencyListApi, action.payload);

        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.GET_AGENCIES_DATA_TABLE_SUCCESS,
                payload: responseData
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_AGENCIES_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* changeUserStatusSaga(action) {
    try {
        const response = yield call(userStatusChangeApi, action.payload);
        const responseData = response.data;

        action.callback(responseData);
    } catch (error) {
        action.callback(error);
    }
}

function* changeUserPasswordSaga(action) {
    try {
        const response = yield call(userPasswordChangeApi, action.payload);
        const responseData = response.data;

        action.callback(responseData);
    } catch (error) {
        action.callback(error);
    }
}

function* getAgencyUserListSaga(action) {
    try {
        const response = yield call(getAgencyUsersListApi, action.payload);

        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.GET_AGENCY_USER_DATA_TABLE_SUCCESS,
                payload: responseData
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_AGENCY_USER_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* loginAsUserSaga(action) {
    try {
        const response = yield call(loginAsUserApi, action.payload);
        const responseData = response.data;
        if (responseData.status) {
            deleteCookie(REACT_APP_ACCESS_TOKEN, "/admin");
            deleteCookie(REACT_APP_ACCESS_TOKEN, "/");
            deleteCookie(REACT_APP_REFRESH_TOKEN, "/admin");
            deleteCookie(REACT_APP_REFRESH_TOKEN, "/");
            deleteCookie(REACT_APP_EMAIL_VERIFIED_STATUS, "/admin");
            deleteCookie(REACT_APP_EMAIL_VERIFIED_STATUS, "/");
            deleteCookie("UUID", "/admin");
            deleteCookie("UUID", "/");
            deleteCookie("EMAIL", "/admin");
            deleteCookie("EMAIL", "/");

            // yield sleep(1000)

            if (action.callback) {
                action.callback(responseData);
            }

            yield put({
                type: USER_LOGIN_ACTION.LOGIN_SUCCESS,
                payload: responseData.data
            });
            yield put({
                type: USER_LOGIN_ACTION.FETCH_USER_PROFILE_DATA_SUCCESS,
                payload: responseData
            });
            storage.setItem("modePostfix", responseData.data.uuid);
        } else {
            yield put({
                type: USER_LOGIN_ACTION.LOGIN_ERROR,
                payload: responseData.message
            });

            if (action.callback) {
                action.callback(responseData);
            }
        }
    } catch (error) {
        yield put({
            type: USER_LOGIN_ACTION.LOGIN_ERROR,
            payload: error
        });
    }
}

function* loginAsAdminSaga(action) {
    try {
        const response = yield call(loginAsAdminApi, action.payload);
        const responseData = response.data;
        if (responseData.status) {
            deleteCookie(REACT_APP_ACCESS_TOKEN, "/admin");
            deleteCookie(REACT_APP_ACCESS_TOKEN, "/");
            deleteCookie(REACT_APP_REFRESH_TOKEN, "/admin");
            deleteCookie(REACT_APP_REFRESH_TOKEN, "/");
            deleteCookie(REACT_APP_EMAIL_VERIFIED_STATUS, "/admin");
            deleteCookie(REACT_APP_EMAIL_VERIFIED_STATUS, "/");
            deleteCookie("UUID", "/admin");
            deleteCookie("UUID", "/");
            deleteCookie("EMAIL", "/admin");
            deleteCookie("EMAIL", "/");

            // yield sleep(1000)

            if (action.callback) {
                action.callback(responseData);
            }

            yield put({
                type: USER_LOGIN_ACTION.LOGIN_SUCCESS,
                payload: responseData.data
            });
            yield put({
                type: USER_LOGIN_ACTION.FETCH_USER_PROFILE_DATA_SUCCESS,
                payload: responseData
            });
            storage.setItem("modePostfix", responseData.data.uuid);
        } else {
            yield put({
                type: USER_LOGIN_ACTION.LOGIN_ERROR,
                payload: responseData.message
            });

            if (action.callback) {
                action.callback(responseData);
            }
        }
    } catch (error) {
        yield put({
            type: USER_LOGIN_ACTION.LOGIN_ERROR,
            payload: error
        });
    }
}

function* makeUserAdminSaga(action) {
    try {
        const response = yield call(userAdminMakeApi, action.payload);
        const responseData = response.data;

        action.callback(responseData);
    } catch (error) {
        action.callback(error);
    }
}

function* changeAgencyStatusSaga(action) {
    try {
        const response = yield call(agencyStatusChangeApi, action.payload);
        const responseData = response.data;

        action.callback(responseData);
    } catch (error) {
        action.callback(error);
    }
}
function* addAdminAgencyTeamSaga(action) {
    try {
        const response = yield call(addAdminAgencyTeamApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        ShowAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}
function* updateAdminAgencyTeamSaga(action) {
    try {
        const response = yield call(updateAdminAgencyTeamApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        ShowAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}

function* deleteAdminAgencyTeamSaga(action) {
    try {
        const response = yield call(deleteAdminAgencyTeamApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        ShowAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}

function* adminAgencyWatcher() {
    yield takeEvery(ACTION.GET_AGENCIES_DATA_TABLE, getAgencyListSaga);
    yield takeEvery(ACTION.GET_AGENCY_USER_DATA_TABLE, getAgencyUserListSaga);
    yield takeEvery(ACTION.ADMIN_AS_USER_LOGIN_ATTEMPT, loginAsUserSaga);
    yield takeEvery(ACTION.ADMIN_AS_ADMIN_LOGIN_ATTEMPT, loginAsAdminSaga);
    yield takeEvery(ACTION.ADMIN__USER_STATUS_CHANGE, changeUserStatusSaga);
    yield takeEvery(ACTION.ADMIN__USER_PASSWORD_CHANGE, changeUserPasswordSaga);
    yield takeEvery(ACTION.ADMIN__USER_ADMIN_MAKE, makeUserAdminSaga);
    yield takeEvery(ACTION.ADMIN__AGENCY_STATUS_CHANGE, changeAgencyStatusSaga);
    yield takeEvery(ACTION.ADD_ADMIN_AGENCY_TEAM_MEMBER, addAdminAgencyTeamSaga);
    yield takeEvery(ACTION.UPDATE_ADMIN_AGENCY_TEAM_MEMBER, updateAdminAgencyTeamSaga);
    yield takeEvery(ACTION.DELETE_ADMIN_AGENCY_TEAM_MEMBER, deleteAdminAgencyTeamSaga);
}

export default function* adminAgencySaga() {
    yield all([adminAgencyWatcher()]);
}
