// Get User profile data
export const USER_PROFILE_DATA_GET = "USER_PROFILE_DATA_GET";
export const FETCH_USER_PROFILE_DATA_SUCCESS = "FETCH_USER_PROFILE_DATA_SUCCESS";

// Profile Info Update
export const PROFILE_INFO_UPDATE_ATTEMPT = "PROFILE_INFO_UPDATE_ATTEMPT";
export const PROFILE_INFO_UPDATE_SUCCESS = "PROFILE_INFO_UPDATE_SUCCESS";
export const PROFILE_INFO_UPDATE_ERROR = "PROFILE_INFO_UPDATE_ERROR";

// Change User Password
export const USER_PASSWORD_CHANGE_ATTEMPT = "USER_PASSWORD_CHANGE_ATTEMPT";
export const USER_PASSWORD_CHANGE_ERROR = "USER_PASSWORD_CHANGE_ERROR";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";

// Image Changing Action Types
export const USER_PROFILE_IMAGE_CHANGE = "USER_PROFILE_IMAGE_CHANGE";
export const USER_PROFILE_IMAGE_CHANGE_SUCCESS = "USER_PROFILE_IMAGE_CHANGE_SUCCESS";
export const USER_PROFILE_IMAGE_CHANGE_ERROR = "USER_PROFILE_IMAGE_CHANGE_ERROR";
export const USER_PROFILE_IMAGE_CHANGE_ERROR_CLEAR = "USER_PROFILE_IMAGE_CHANGE_ERROR_CLEAR";
export const USER_PROFILE_IMAGE_CHANGE_ERROR_FOR_FORM_SUCCESS =
    "USER_PROFILE_IMAGE_CHANGE_ERROR_FOR_FORM_SUCCESS";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
