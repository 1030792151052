/* eslint-disable no-unused-vars */
import axios from "axios";
import { getCookie } from "../helpers/Cookie";

export const sendRequest = (method, url, payload = [], config = []) => {
    return axios.request({
        method,
        url,
        data: payload
    });
};

export const sendRequestWithToken = (method, url, payload = [], config = []) => {
    const bearerToken = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
    return axios.request({
        method,
        url,
        data: payload,
        headers: {
            Authorization: `Bearer ${bearerToken}`
        }
    });
};

export const sendRequestWithMultipartFormData = (method, url, payload = [], config = []) => {
    const bearerToken = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
    return axios.request({
        method,
        url,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bearerToken}`
        }
    });
};

export const sendRequestWithError = (method, url, payload = [], config = []) => {
    return axios.request({
        url,
        headers: {
            "Content-Type": "application/json"
        },
        method,
        data: payload,
        responseType: "json"
    });
};

export const sendRequestWithTokenAndError = (method, url, payload = [], config = []) => {
    const bearerToken = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
    const request = axios({
        url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`
        },
        method,
        data: payload,
        responseType: "json"
    });

    return request
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error.response.message;
        });
};
