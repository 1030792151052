import { CustomSkeleton } from "@hipdevteam/afglobalcomponents";
import { Box, Grid, Typography } from "@mui/material";

const FileUploadSkeleton = () => {
    return (
        <Grid container spacing={3} sx={{ marginBottom: "24px" }}>
            <Grid item xs={4}>
                <Box className="fileUploader">
                    <CustomSkeleton
                        variant="rectangular"
                        width={208}
                        height={161}
                        sx={{ borderRadius: "5px" }}
                    />
                </Box>
            </Grid>
            <Grid item xs={8} className="upload__left__side">
                <Typography variant="h5" color="text.secondary">
                    <CustomSkeleton />
                </Typography>
                <Typography variant="alertTitle" color="text.disabled">
                    <CustomSkeleton />
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "44px"
                    }}
                >
                    <CustomSkeleton
                        variant="rectangular"
                        width={114}
                        height={42}
                        sx={{ borderRadius: "4px", marginRight: "10px" }}
                    />
                    <CustomSkeleton
                        variant="rectangular"
                        width={114}
                        height={42}
                        sx={{ borderRadius: "4px" }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default FileUploadSkeleton;
