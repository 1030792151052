const breakpoints = () => {
    return {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1336,
            xl: 1536
        }
    };
};
export default breakpoints;
