import * as ACTION from "./appActionType";

export const getApps = (payload = null) => ({
    type: ACTION.GET_APPS,
    payload
});
export const appSbuscribe = (payload, callback) => ({
    type: ACTION.APP_SUBSCRIBE,
    payload,
    callback
});
export const appUnSbuscribe = (payload, callback) => ({
    type: ACTION.APP_UNSUBSCRIBE,
    payload,
    callback
});
