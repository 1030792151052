import { sendRequestWithError } from "./rootApi";

export const sendForgetPasswordEmailApi = async (params) => {
    return sendRequestWithError(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/send-reset-password-email`,
        params
    );
};

export const verifyForgetPasswordCodeApi = async (params) => {
    return sendRequestWithError(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/verify-forget-password-code?code=${params.code}`,
        params
    );
};

export const resetPasswordApi = async (params) => {
    return sendRequestWithError(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`,
        params
    );
};
