export const GET_APPS_DATA_TABLE = "GET_APPS_DATA_TABLE";
export const GET_APPS_DATA_TABLE_SUCCESS = "GET_APPS_DATA_TABLE_SUCCESS";
export const GET_APPS_DATA_TABLE_FAILED = "GET_APPS_DATA_TABLE_FAILED";

export const APP_CREATE = "APP_CREATE";

export const APP_DATA_GET_FOR_EDIT = "APP_DATA_GET_FOR_EDIT";

export const APP_DATA_UPDATE = "APP_DATA_UPDATE";

export const ADMIN__APP_STATUS_CHANGE = "ADMIN__APP_STATUS_CHANGE";
