import { sendRequestWithToken } from "./rootApi";

export const teamMemberDataTableApi = async (param) => {
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/team/list?page=${param.currentPage}&limit=${param.rowLimit}&team_role=${param.teamRole}&search_key=${param.searchKey}`
    );
};

export const getAppFeatureApi = async (param) => {
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/apps/features/${param}`
    );
};
export const addTeamApi = async (param) => {
    return sendRequestWithToken("post", `${process.env.REACT_APP_API_BASE_URL}/team`, param);
};
export const editTeamApi = async (param) => {
    const { uuid } = param;
    delete param.uuid;

    return sendRequestWithToken("put", `${process.env.REACT_APP_API_BASE_URL}/team/${uuid}`, param);
};
export const getTeamApi = async (param) => {
    return sendRequestWithToken("get", `${process.env.REACT_APP_API_BASE_URL}/team/${param}`);
};
export const deleteTeamApi = async (param) => {
    return sendRequestWithToken("delete", `${process.env.REACT_APP_API_BASE_URL}/team/${param}`);
};

export const getClientListApi = async (param) => {
    const { app_identifier, team_uuid, search_key, current_page, limit } = param;

    const url = `${process.env.REACT_APP_API_BASE_URL}/team/client/search?page=${current_page}&limit=${limit}&app_identifier=${app_identifier}&team_uuid=${team_uuid}&search_key=${search_key}`;

    return sendRequestWithToken("get", url);
};

export const assignClientApi = async (param) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/team/client/assign`,
        param
    );
};
