import * as ACTION from "./GhlActionType";

export const syncGhlAdSourceTableAndColumn = (payload, callback) => ({
    type: ACTION.GHL_AD_SOURCE_TABLE_AND_COLUMN_SYNC,
    payload,
    callback
});

export const getGhlAdSourceTableData = (payload) => ({
    type: ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE,
    payload
});

export const getGhlAdSourceTableColumnData = (payload) => ({
    type: ACTION.GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE,
    payload
});

export const updateGhlAdSourceTableColumn = (payload, callback) => ({
    type: ACTION.GHL_UPDATE_AD_SOURCE_TABLE_COLUMN,
    payload,
    callback
});

export const addGhlAdSourceTable = (payload, callback) => ({
    type: ACTION.GHL_ADD_AD_SOURCE_TABLE,
    payload,
    callback
});

export const addGhlAdSourceTableColumn = (payload, callback) => ({
    type: ACTION.GHL_ADD_AD_SOURCE_TABLE_COLUMN,
    payload,
    callback
});

export const addGhlAdSourceCustomColumn = (payload, callback) => ({
    type: ACTION.GHL_ADD_AD_SOURCE_CUSTOM_COLUMN,
    payload,
    callback
});
