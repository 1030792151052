import * as ACTION from "./AgencyActionType";

export const getAgencyData = (payload, callback) => ({
    type: ACTION.GET_AGENCY_DATA,
    payload,
    callback
});
export const updateOrCreateData = (payload, callback) => ({
    type: ACTION.UPDATE_OR_CREATE,
    payload,
    callback
});
