import {
    CustomButton,
    CustomHeader,
    CustomTextField,
    CustomTextFieldPassword,
    InputPhoneNumber
} from "@hipdevteam/afglobalcomponents";
import { Box, Grid, StyledEngineProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import UserSettingSkeleton from "../../components/SkeletonLoader/UserSettings/UserSettingSkeleton";
import ShowAlerts from "../../helpers/ShowAlerts";
import {
    attemptProfileUInfoUpdate,
    changePassword,
    getUserProfileData
} from "../../statemanagement/userProfileManage/userProfileManageAction";
import * as CONSTANT from "../../utils/Constant";
import "./custom.style.css";

const UserSetting = (props) => {
    const { getUserProfile, userProfileData, attemptProfileUInfoDataUpdate, passwordUpdate } =
        props;

    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        email: ""
    });
    const [showPassword, setShowPassword] = useState({
        oldPasswordShow: true,
        newPasswordShow: true,
        confirmPasswordShow: true
    });
    const [number, setNumber] = useState("");
    const [status, setStatus] = useState(false);
    const [isError, setIsError] = useState(false);

    const components = [
        {
            name: "Settings",
            link: "#",
            icon: null
        },
        { name: "User Settings", link: "#", icon: null }
    ];

    const {
        register,
        handleSubmit,

        formState: { errors }
    } = useForm();

    useEffect(() => {
        getUserProfile({}, (response) => {
            if (response.status && response.code === 200) {
                setUserData(response.data);
                setNumber(response.data.phone_number);
            }
        });
    }, []);

    useEffect(() => {
        if (userProfileData.getUserSuccess) {
            setNumber(userProfileData.userProfileData.phone_number);
        }

        setTimeout(() => {
            setStatus(true);
        }, 1000);
    }, [userProfileData.getUserSuccess]);

    const handleUserDataChange = (e) => {
        setUserData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const handleClickShowPassword = (name) => {
        if (name === "oldPasswordShow") {
            setShowPassword({
                ...showPassword,
                oldPasswordShow: !showPassword.oldPasswordShow
            });
        } else if (name === "newPasswordShow") {
            setShowPassword({
                ...showPassword,
                newPasswordShow: !showPassword.newPasswordShow
            });
        } else {
            setShowPassword({
                ...showPassword,
                confirmPasswordShow: !showPassword.confirmPasswordShow
            });
        }
    };

    const handleProfileUpdate = (e) => {
        e.preventDefault();

        if (userData.first_name === "") {
            setIsError(true);
            return false;
        }

        const data = {
            first_name: userData.first_name,
            last_name: userData.last_name,
            phone_number: number
        };

        attemptProfileUInfoDataUpdate(data, (response) => {
            if (response.status) {
                ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
            }
        });
    };

    const onPasswordChangeSubmit = (data, e) => {
        if (data.password !== data.confirm_password) {
            ShowAlerts("Mismatched Confirm Password!!", CONSTANT.ALERT_TYPE_ERROR);
            return;
        }

        passwordUpdate(data, (response) => {
            if (response.status) {
                ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                e.target.reset();
            }
        });
    };

    return (
        <StyledEngineProvider injectFirst>
            {status ? (
                <Box className="user_settings_container">
                    <Box sx={{ width: 1, mb: "30px" }} className="user_settings">
                        <Box fullWidth>
                            <Box sx={{ width: 1, mb: "30px" }}>
                                <CustomHeader components={components} headerText="User Settings" />
                            </Box>
                            <Grid
                                item
                                sx={{ backgroundColor: "background.paper" }}
                                xs={10}
                                md={12}
                                sm={12}
                                className="schema__form"
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Box>
                                                <Typography
                                                    variant="H5"
                                                    className="schema__form__header"
                                                    color="text.primary"
                                                >
                                                    User Profile
                                                </Typography>
                                                <form className="" onSubmit={handleProfileUpdate}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <CustomTextField
                                                                name="first_name"
                                                                label="First Name*"
                                                                id="firstName"
                                                                autoComplete="off"
                                                                type="text"
                                                                value={
                                                                    userData.first_name !== ""
                                                                        ? userData.first_name
                                                                        : ""
                                                                }
                                                                placeholder="Write your first name"
                                                                error={
                                                                    isError &&
                                                                    userData.first_name === ""
                                                                }
                                                                handleChange={handleUserDataChange}
                                                            />
                                                            {isError && userData.first_name === "" && (
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    align="left"
                                                                    color="error"
                                                                >
                                                                    First Name is required
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <CustomTextField
                                                                name="last_name"
                                                                label="Last Name"
                                                                autoComplete="off"
                                                                id="lastName"
                                                                type="text"
                                                                value={
                                                                    userData.last_name !== ""
                                                                        ? userData.last_name
                                                                        : ""
                                                                }
                                                                placeholder="Write your last name"
                                                                handleChange={handleUserDataChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CustomTextField
                                                                name="email"
                                                                label="Email Address"
                                                                id="emailAddress"
                                                                autoComplete="off"
                                                                type="text"
                                                                value={
                                                                    userData.email !== ""
                                                                        ? userData.email
                                                                        : ""
                                                                }
                                                                placeholder="Write your email"
                                                                handleOnBlur=""
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <InputPhoneNumber
                                                                phone_number={
                                                                    userData.phone_number !== ""
                                                                        ? userData.phone_number
                                                                        : ""
                                                                }
                                                                setNumber={setNumber}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            marginTop: "24px"
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={
                                                                <Typography variant="buttonLarge">
                                                                    Update Profile
                                                                </Typography>
                                                            }
                                                            variant="contained"
                                                            type="submit"
                                                            color="secondary"
                                                            handleButton=""
                                                        />
                                                    </Box>
                                                </form>
                                            </Box>

                                            {!userProfileData.userProfileData.google_id && (
                                                <Box className="change_password">
                                                    <Typography
                                                        variant="H5"
                                                        className="schema__form__header"
                                                        color="text.primary"
                                                    >
                                                        Change password
                                                    </Typography>
                                                    <form
                                                        className=""
                                                        autoComplete="off"
                                                        onSubmit={handleSubmit(
                                                            onPasswordChangeSubmit
                                                        )}
                                                    >
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <CustomTextFieldPassword
                                                                    show={
                                                                        showPassword.oldPasswordShow
                                                                    }
                                                                    id="oldPassword"
                                                                    handleClickShowPassword={() =>
                                                                        handleClickShowPassword(
                                                                            "oldPasswordShow"
                                                                        )
                                                                    }
                                                                    type={
                                                                        showPassword.oldPasswordShow
                                                                            ? "password"
                                                                            : "text"
                                                                    }
                                                                    className="single__text__field"
                                                                    size="small"
                                                                    label="Old Password"
                                                                    register={register(
                                                                        "old_password",
                                                                        {
                                                                            required:
                                                                                "Old Password required"
                                                                        }
                                                                    )}
                                                                    errors={errors}
                                                                />
                                                                {!!errors.old_password && (
                                                                    <Typography
                                                                        variant="inputLabel"
                                                                        align="left"
                                                                        color="error"
                                                                    >
                                                                        {
                                                                            errors.old_password
                                                                                .message
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <CustomTextFieldPassword
                                                                    show={
                                                                        showPassword.newPasswordShow
                                                                    }
                                                                    id="password"
                                                                    handleClickShowPassword={() =>
                                                                        handleClickShowPassword(
                                                                            "newPasswordShow"
                                                                        )
                                                                    }
                                                                    type={
                                                                        showPassword.newPasswordShow
                                                                            ? "password"
                                                                            : "text"
                                                                    }
                                                                    className="single__text__field"
                                                                    size="small"
                                                                    label="New Password"
                                                                    register={register("password", {
                                                                        required:
                                                                            "New Password required"
                                                                    })}
                                                                    errors={errors}
                                                                />
                                                                {!!errors.password && (
                                                                    <Typography
                                                                        variant="inputLabel"
                                                                        align="left"
                                                                        color="error"
                                                                    >
                                                                        {errors.password.message}
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <CustomTextFieldPassword
                                                                    show={
                                                                        showPassword.confirmPasswordShow
                                                                    }
                                                                    id="confirm_password"
                                                                    handleClickShowPassword={() =>
                                                                        handleClickShowPassword(
                                                                            "confirmPasswordShow"
                                                                        )
                                                                    }
                                                                    type={
                                                                        showPassword.confirmPasswordShow
                                                                            ? "password"
                                                                            : "text"
                                                                    }
                                                                    className="single__text__field"
                                                                    size="small"
                                                                    label="Confirm Password"
                                                                    register={register(
                                                                        "confirm_password",
                                                                        {
                                                                            required:
                                                                                "Confirm Password required"
                                                                        }
                                                                    )}
                                                                    errors={errors}
                                                                />
                                                                {!!errors.confirm_password && (
                                                                    <Typography
                                                                        variant="inputLabel"
                                                                        align="left"
                                                                        color="error"
                                                                    >
                                                                        {
                                                                            errors.confirm_password
                                                                                .message
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                marginTop: "20px"
                                                            }}
                                                        >
                                                            <CustomButton
                                                                title={
                                                                    <Typography variant="buttonLarge">
                                                                        Update Password
                                                                    </Typography>
                                                                }
                                                                variant="contained"
                                                                type="submit"
                                                                color="secondary"
                                                                handleButton=""
                                                            />
                                                        </Box>
                                                    </form>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <UserSettingSkeleton />
            )}
        </StyledEngineProvider>
    );
};
const mapStateToProps = (state) => {
    return {
        userProfileData: state.userProfileManageReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (params, callback) => dispatch(getUserProfileData(params, callback)),
        attemptProfileUInfoDataUpdate: (params, callback) =>
            dispatch(attemptProfileUInfoUpdate(params, callback)),
        passwordUpdate: (params, callback) => dispatch(changePassword(params, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSetting);
