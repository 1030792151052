import { sendRequestWithError, sendRequestWithTokenAndError } from "./rootApi";

export const signupRequest = async (request) => {
    return sendRequestWithError(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/register`,
        request
    );
};
export const googleSignInRequest = async (request) => {
    return sendRequestWithError(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/google-register`,
        request
    );
};
export const emailVerificationApi = async (request) => {
    return sendRequestWithTokenAndError(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/verify-register-code?code=${request.code}`
    );
};
export const sendEmailVerificationCode = async (request) => {
    return sendRequestWithTokenAndError(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/resend-email-verification-code`,
        request
    );
};

export const googleUserRegistrationApi = async (request) => {
    return sendRequestWithTokenAndError(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/google-user-registration`,
        request
    );
};
