import * as ACTION from "./GhlActionType";

const initialState = {
    tableData: [],
    isSuccess: false,
    loading: true,
    tableColumnData: [],
    isTableColumnDataSuccess: false,
    tableColumnLoading: true
};

const adminGhlReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE:
            return {
                ...state,
                tableData: [],
                isSuccess: false,
                loading: true
            };
        case ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_SUCCESS:
            return {
                ...state,
                tableData: action.payload.data,
                isSuccess: true,
                loading: false
            };
        case ACTION.GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_FAILED:
            return {
                ...state,
                tableData: [],
                isSuccess: false,
                loading: false
            };

        case ACTION.GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE:
            return {
                ...state,
                tableColumnData: [],
                isTableColumnDataSuccess: false,
                tableColumnLoading: true
            };
        case ACTION.GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE_SUCCESS:
            return {
                ...state,
                tableColumnData: action.payload.data,
                isTableColumnDataSuccess: true,
                tableColumnLoading: false
            };
        case ACTION.GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE_FAILED:
            return {
                ...state,
                tableColumnData: [],
                isTableColumnDataSuccess: false,
                tableColumnLoading: false
            };
        default:
            return state;
    }
};

export default adminGhlReducer;
