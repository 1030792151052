import * as ACTION from "./appActionType";

const initialState = {
    apps: null,
    isSuccess: false,
    haveSubscription: false
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.GET_APPS:
            return {
                ...state,
                isSuccess: false,
                haveSubscription: false
            };
        case ACTION.GET_APP_SUCCESS:
            return {
                ...state,
                apps: action.payload.data.apps,
                isSuccess: true,
                haveSubscription: action.payload.data.have_subscription
            };
        case ACTION.GET_APP_FAILED:
            return {
                ...state,
                apps: null,
                isSuccess: true
            };
        default:
            return state;
    }
};

export default appReducer;
