import { all } from "redux-saga/effects";
import adminAgencySaga from "./Admin/Agencies/adminAgencySaga";
import adminAppSaga from "./Admin/App/AdminAppSaga";
import geogridApiAuthSaga from "./Admin/GeogridApiAuth/geogridApiAuthSaga";
import adminGhlSaga from "./Admin/Ghl/adminGhlSaga";
import adminUserSaga from "./Admin/User/adminUserSaga";
import agencySaga from "./agency/AgencySaga";
import apiAuthSaga from "./apiAuth/apiAuthSaga";
import appSaga from "./app/appSaga";
import holidaySaga from "./holiday/holidaySaga";
import loginSaga from "./login/loginSaga";
import resetPasswordSaga from "./resetPassword/resetPasswordSaga";
import s3FileUploadSaga from "./s3FileUpload/s3FileUploadSaga";
import signupMiddleware from "./signup/signupMiddleware";
import teamSaga from "./team/teamSaga";
import userProfileManageSaga from "./userProfileManage/userProfileManageSaga";
import whiteLabelDomainSaga from "./whiteDomain/whiteDomainSaga";

export default function* rootSaga() {
    yield all([
        loginSaga(),
        signupMiddleware(),
        userProfileManageSaga(),
        resetPasswordSaga(),
        agencySaga(),
        teamSaga(),
        apiAuthSaga(),
        appSaga(),
        whiteLabelDomainSaga(),
        adminAgencySaga(),
        adminAppSaga(),
        adminUserSaga(),
        s3FileUploadSaga(),
        geogridApiAuthSaga(),
        holidaySaga(),
        adminGhlSaga()
    ]);
}
