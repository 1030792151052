import { all, call, takeEvery } from "redux-saga/effects";
import uploadS3FileApi from "../../api/s3FileUploadApi";
import * as ACTION from "./s3FileUploadActionType";

function* fileUploadSaga(action) {
    try {
        const response = yield call(uploadS3FileApi, action.payload);
        if (action.callback) action.callback(response.data);
    } catch (err) {
        if (action.callback) action.callback(err);
    }
}
function* s3FileUploadWatcher() {
    yield takeEvery(ACTION.S3_FILE_UPLOAD, fileUploadSaga);
}
export default function* s3FileUploadSaga() {
    yield all([s3FileUploadWatcher()]);
}
