import {
    CustomBadge,
    CustomButton,
    CustomDrawer,
    CustomDropDownButtonMenu,
    CustomTooltip,
    DataTablePro
} from "@hipdevteam/afglobalcomponents";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalHeader from "../../../components/local/LocalHeader/LocalHeader";
import TableSkeleton from "../../../components/SkeletonLoader/Table/TableSkeleton";
import ShowAlerts from "../../../helpers/ShowAlerts";
import {
    changeGeogridApiAuthStatus,
    getGeogridApiAuthDataTable
} from "../../../statemanagement/Admin/GeogridApiAuth/geogridApiAuthAction";
import { APP_STATUS_ACTIVE } from "../../../utils/Constant";
import {
    API_STATUS__ACTIVE,
    API_STATUS__INACTIVE,
    authType
} from "../../../utils/Constant/GeogridAuthApi";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../utils/Constant/GlobalConstant";
import ApiAdd from "./forms/ApiAdd";
import ApiEdit from "./forms/ApiEdit";

const GeogridApi = (props) => {
    const { getGeogridApiDataTable, geogridApiData, changeApiAuthStatus } = props;

    const [data, setData] = useState([]);
    const [progressing, setProgressing] = useState(true);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [filterSearch, setFilterSearch] = useState("");
    const [selectAppId, setSelectedAppId] = useState("");
    const [drawerOpenForAppAdd, setDrawerOpenForAppAdd] = useState(false);
    const [drawerOpenForAppEdit, setDrawerOpenForAppEdit] = useState(false);

    useEffect(() => {
        getGeogridApiDataTable(currentPage, itemPerPage, filterSearch);
    }, []);

    useEffect(() => {
        if (geogridApiData.isSuccess) {
            setData(geogridApiData.tableData.data);
            setProgressing(false);
            setLoading(false);
            setTotalItems(geogridApiData.tableData.totalItems);
            setCurrentPage(geogridApiData.tableData.currentPage);
        }
    }, [geogridApiData.isSuccess]);

    const changePage = (pageSize) => {
        setProgressing(true);
        setCurrentPage(1);
        getGeogridApiDataTable(1, pageSize, filterSearch);
        setItemPerPage(pageSize);
    };

    const getData = (currentNewPage) => {
        setProgressing(true);
        getGeogridApiDataTable(currentNewPage + 1, itemPerPage, filterSearch);
        setCurrentPage(currentNewPage + 1);
    };

    const handleFilter = (searchKey) => {
        setFilterSearch(searchKey);
        setProgressing(true);
        getGeogridApiDataTable(currentPage, itemPerPage, searchKey);
    };

    const handleAppEdit = (appId) => {
        setSelectedAppId(appId);
        setDrawerOpenForAppEdit(true);
    };

    const handleApiStatusChange = (id, status) => {
        changeApiAuthStatus({ id, status }, (response) => {
            if (response.status) {
                getGeogridApiDataTable(currentPage, itemPerPage, filterSearch);
                ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
            }
        });
    };

    const renderActionOptions = (row) => {
        return [
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        <EditIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Edit
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    handleAppEdit(row.id);
                }
            },
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        {row.status === APP_STATUS_ACTIVE ? (
                            <PlayArrowIcon
                                sx={{
                                    color: "action.active",
                                    paddingRight: "10px",
                                    width: "2rem !important"
                                }}
                            />
                        ) : (
                            <PauseIcon
                                sx={{
                                    color: "action.active",
                                    paddingRight: "10px",
                                    width: "2rem !important"
                                }}
                            />
                        )}

                        <Typography variant="body1" color="text.primary">
                            {row.status === APP_STATUS_ACTIVE ? "Inactive" : "Active"}
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    const status =
                        row.status === API_STATUS__ACTIVE
                            ? API_STATUS__INACTIVE
                            : API_STATUS__ACTIVE;
                    handleApiStatusChange(row.id, status);
                }
            }
        ];
    };

    const columns = [
        {
            field: "auth_type",
            flex: 2,
            headerName: (
                <Typography variant="tableHeader" color="text.primary">
                    Api
                </Typography>
            ),

            editable: false,
            renderCell: (params) => {
                return (
                    <CustomTooltip title={authType[params.value]} placement="top">
                        <Typography variant="body2">{authType[params.value]}</Typography>
                    </CustomTooltip>
                );
            }
        },

        {
            field: "auth_data",
            flex: 6,
            headerName: (
                <Typography variant="tableHeader" color="text.primary">
                    Api Details
                </Typography>
            ),

            editable: false,
            renderCell: (params) => {
                return (
                    <Typography component="code" variant="body2">
                        {JSON.stringify(params.value)}
                    </Typography>
                );
            }
        },

        {
            field: "status",
            headerName: (
                <Typography variant="tableHeader" color="text.primary">
                    Status
                </Typography>
            ),
            flex: 1,
            editable: false,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "120px"
                        }}
                    >
                        {params.value === APP_STATUS_ACTIVE ? (
                            <CustomBadge color="success" label="Active" />
                        ) : (
                            <CustomBadge color="warning" label="Inactive" />
                        )}
                    </Box>
                );
            }
        },
        {
            field: "actions",
            flex: 1,
            headerName: "Actions",
            editable: false,
            renderCell: (params) => {
                return (
                    <CustomDropDownButtonMenu
                        buttonContent={<MoreVertIcon color="action.active" />}
                        disabledStatus={false}
                        options={renderActionOptions(params.row)}
                    />
                );
            }
        }
    ];

    const components = [
        {
            name: "Dashboard",
            link: "/Dashboard"
        },
        {
            name: "Admin",
            link: "/admin/agencies"
        },
        { name: "Geogrid Api List", link: "#" }
    ];

    const localHeaderButtons = () => {
        return (
            <CustomButton
                title={<Typography variant="buttonLarge">Add New Api</Typography>}
                handleButton={() => setDrawerOpenForAppAdd(true)}
                color="secondary"
                variant="contained"
                startIcon={<AddIcon />}
                size="large"
            />
        );
    };

    return (
        <Box>
            <Container
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: "10px"
                    // height: "100vh",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box sx={{ width: 1, mb: "30px" }}>
                            <LocalHeader
                                components={components}
                                headerText="Geogrid Api List"
                                buttons={localHeaderButtons}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: 1,
                                padding: "30px 16px",
                                backgroundColor: "background.paper",
                                boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                borderRadius: "4px"
                            }}
                        >
                            {!loading ? (
                                <DataTablePro
                                    tableId="admin_apps_data_table"
                                    rows={data}
                                    autoHeight={false}
                                    sx={{
                                        height: "680px"
                                    }}
                                    columns={columns}
                                    loading={progressing}
                                    pagination
                                    rowsPerPageOptions={[5, 10, 15]}
                                    pageSize={itemPerPage}
                                    rowCount={totalItems}
                                    onPageChange={getData}
                                    onPageSizeChange={changePage}
                                    searchStatus={false}
                                    serachText="Search By Name"
                                    handleFilter={handleFilter}
                                />
                            ) : (
                                <TableSkeleton />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <CustomDrawer
                drawerOpen={drawerOpenForAppAdd}
                setDrawerOpen={setDrawerOpenForAppAdd}
                customClass={drawerOpenForAppAdd ? "active" : ""}
                heading="Add Geogrid Api"
            >
                <ApiAdd
                    setDrawerOpen={setDrawerOpenForAppAdd}
                    isDrawerOpen={drawerOpenForAppAdd}
                    reloadData={() =>
                        getGeogridApiDataTable(currentPage, itemPerPage, filterSearch)
                    }
                />
            </CustomDrawer>
            <CustomDrawer
                drawerOpen={drawerOpenForAppEdit}
                setDrawerOpen={setDrawerOpenForAppEdit}
                customClass={drawerOpenForAppEdit ? "active" : ""}
                heading="Edit Auth Api"
            >
                <ApiEdit
                    setDrawerOpen={setDrawerOpenForAppEdit}
                    isDrawerOpen={drawerOpenForAppEdit}
                    id={selectAppId}
                    reloadData={() =>
                        getGeogridApiDataTable(currentPage, itemPerPage, filterSearch)
                    }
                />
            </CustomDrawer>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        geogridApiData: state.geogridApiAuthReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGeogridApiDataTable: (currentPage = 1, itemPerPage = 10, searchKey = "") =>
            dispatch(
                getGeogridApiAuthDataTable({
                    currentPage,
                    itemPerPage,
                    searchKey
                })
            ),
        changeApiAuthStatus: (params, callback) =>
            dispatch(changeGeogridApiAuthStatus(params, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeogridApi);
