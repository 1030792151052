const tooltips = () => {
    return {
        styleOverrides: {
            tooltip: {
                fontSize: "12px",
                lineHeight: "18px"
            },
            arrow: {}
        }
    };
};
export default tooltips;
