/* eslint-disable no-useless-escape */
import * as CONSTANT from "./Constant";

export const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Object]";
};

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
};

export const isValidUrl = (url) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(url);
};

export const isImageUrl = (url) => {
    return String(url)
        .toLowerCase()
        .match(/data:image\/([a-zA-Z]*);base64,([^\"]*)/g);
};
export const isEmailValid = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
export const isValidTelephone = () => {
    return true;
    // return String(telephone)
    //     .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/);
};
export const isValidZipCodes = (zip) => {
    return String(zip).match("^[0-9]+$");
};

export const isValidImageUrl = (url) => {
    return !!(isValidUrl(url) || isImageUrl(url));
};

export const haveAdminAccess = (role) => {
    if (role === CONSTANT.USER_ROLE_ADMIN) {
        return true;
    }

    return false;
};

export const haveSuperAdminAccess = (superAdminRole) => {
    return superAdminRole === CONSTANT.SUPER_ADMIN_YES;
};

export const sleep = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};
