import * as ACTION from "./loginActionTypes";

export const updateUser = (payload) => ({
    type: ACTION.LOGIN_SUCCESS,
    payload
});

export const attemptLogin = (payload) => ({
    type: ACTION.LOGIN_ATTEMPT,
    payload
});
export const loginSuccess = (data) => ({
    type: ACTION.LOGIN_SUCCESS,
    payload: data
});
export const loginError = (data) => ({
    type: ACTION.LOGIN_ERROR,
    payload: data
});

export const logout = (data) => ({
    type: ACTION.LOGOUT_SUCCESS,
    payload: data
});
export const checkEmailExist = (data) => ({
    type: ACTION.CHECK_EMAIL_EXIST,
    payload: data
});

export const verificationCodeSendSuccess = () => ({
    type: ACTION.VERIFICATION_CODE_SEND_SUCCESS,
    payload: []
});
export const verificationCodeSendError = (data) => ({
    type: ACTION.VERIFICATION_CODE_SEND_ERROR,
    payload: data
});

export const attempPasswordForget = () => ({
    type: ACTION.CHECKATTEMP_PASSWORD_FORGET,
    payload: []
});
export const forgetPasswordSuccess = () => ({
    type: ACTION.CHECKATTEMP_PASSWORD_FORGET_SUCCESS,
    payload: []
});
export const forgetPasswordError = (data) => ({
    type: ACTION.CHECKATTEMP_PASSWORD_FORGET_ERROR,
    payload: data
});
export const clearError = () => ({
    type: ACTION.CLEAR_ERROR,
    payload: []
});

export const googleLogin = (data, callback) => ({
    type: ACTION.GOOGLE_LOGIN,
    payload: data,
    callback
});

export const googleSignIn = (data, callback) => ({
    type: ACTION.GOOGLE_SIGN_IN_NEW,
    payload: data,
    callback
});
