/* eslint-disable import/prefer-default-export */
import { ThemeProvider } from "@mui/material/styles";
import storage from "local-storage-fallback";
import React, { useMemo } from "react";
import customTheme from "../../theme/theme";

export const PublicLayout = ({ children }) => {
    const themeMode = storage.getItem("mode");
    const [mode, setMode] = React.useState(themeMode || "light");
    const theme = useMemo(customTheme(mode), [mode]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
