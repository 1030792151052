/* eslint-disable prettier/prettier */
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

let persistConfig = [];
if (process.env.REACT_APP_REDUX_PERSIST_DATA === "true") {
    persistConfig = {
        key: "root",
        storage,
        whitelist: []
    };
} else {
    persistConfig = {
        key: "root",
        storage,
        whitelist: []
    };
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };

