import {
    CustomBadge,
    CustomButton,
    CustomDrawer,
    CustomSelectBox,
    CustomSwitch,
    DataTablePro
} from "@hipdevteam/afglobalcomponents";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LocalHeader from "../../../../components/local/LocalHeader/LocalHeader";
import TableSkeleton from "../../../../components/SkeletonLoader/Table/TableSkeleton";
import ShowAlerts from "../../../../helpers/ShowAlerts";
import {
    getGhlAdSourceTableColumnData,
    updateGhlAdSourceTableColumn
} from "../../../../statemanagement/Admin/Ghl/GhlAction";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../../utils/Constant";
import {
    GHL_AD_SOURCE_COLUMN_STATUS_ACTIVE,
    GHL_AD_SOURCE_COLUMN_STATUS_INACTIVE,
    GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_ID_COLUMN_NO,
    GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_ID_COLUMN_YES,
    GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_NAME_COLUMN_NO,
    GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_NAME_COLUMN_YES,
    GHL_AD_SOURCE_COLUMN__IS_DATE_NO,
    GHL_AD_SOURCE_COLUMN__IS_DATE_YES,
    GHL_AD_SOURCE_COLUMN__IS_POPULAR_NO,
    GHL_AD_SOURCE_COLUMN__IS_POPULAR_YES
} from "../../../../utils/Constant/GhlConstant";
import AdSourceColumnAdd from "./forms/AdSourceColumnAdd";

const DataTableTableColumns = (props) => {
    const history = useHistory();
    const { tableId } = useParams();
    const { ghlAdSourceColumnData, getAdSourceTableColumnList, updateColumn } = props;
    const [loading, setLoading] = useState(true);
    const [progressing, setProgressing] = useState(true);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [filterSearch, setFilterSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const types = [
        { id: "All", name: "All" },
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" }
    ];
    const dataTypes = [
        { id: 1, name: "NUMERIC" },
        { id: 2, name: "STRING" },
        { id: 3, name: "DATE" }
    ];
    const [filterStatus, setFilterStatus] = useState(types[1].id);

    const handleUpdateColumn = (id, column_name, status) => {
        updateColumn({ columnId: id, updated_data: { [column_name]: status } }, (response) => {
            if (response.status) {
                ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
                getAdSourceTableColumnList({
                    currentPage,
                    rowLimit: itemPerPage,
                    searchKey: filterSearch,
                    tableId,
                    status: filterStatus
                });
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
            }
        });
    };

    const handleStatusChange = (e) => {
        setFilterSearch("");
        setFilterStatus(e.target.value);
        setProgressing(true);
        getAdSourceTableColumnList({
            currentPage,
            rowLimit: itemPerPage,
            searchKey: "",
            tableId,
            status: e.target.value
        });
    };

    const otherFilters = () => {
        return (
            <Box
                sx={{
                    width: "200px"
                }}
            >
                <CustomSelectBox
                    sx={{
                        maxWidth: "200px",
                        height: "40px"
                    }}
                    name="Status"
                    options={types}
                    defaultValue={types[0].id}
                    value={filterStatus}
                    handleChange={(e) => handleStatusChange(e)}
                    label="Status"
                />
            </Box>
        );
    };

    const column = [
        {
            field: "column_name",
            headerName: "Column Name",
            flex: 5,
            renderCell: (params) => {
                return (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {params.value ? (
                            <Typography variant="body2">{params.value}</Typography>
                        ) : (
                            <CustomBadge label="Not Found" color="info" />
                        )}
                    </Box>
                );
            }
        },
        {
            field: "type",
            headerName: "Column Type",
            flex: 4,
            renderCell: (params) => {
                const { id, type } = params.row;
                return (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CustomSelectBox
                            sx={{
                                maxWidth: "200px",
                                height: "40px"
                            }}
                            name=""
                            options={dataTypes}
                            defaultValue={type}
                            value={type}
                            handleChange={(e) => handleUpdateColumn(id, "type", e.target.value)}
                            label=""
                        />
                    </Box>
                );
            },
            sortable: false
        },
        {
            field: "status",
            headerName: "Status",
            flex: 4,
            renderCell: (params) => {
                const { id, status } = params.row;
                return (
                    <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                        <CustomSwitch
                            size="small"
                            handleChange={() =>
                                handleUpdateColumn(
                                    id,
                                    "status",
                                    status === GHL_AD_SOURCE_COLUMN_STATUS_INACTIVE
                                        ? GHL_AD_SOURCE_COLUMN_STATUS_ACTIVE
                                        : GHL_AD_SOURCE_COLUMN_STATUS_INACTIVE
                                )
                            }
                            checked={status === GHL_AD_SOURCE_COLUMN_STATUS_ACTIVE}
                        />
                    </Box>
                );
            },
            sortable: false
        },
        {
            field: "is_account_id_column",
            headerName: "Is Account Id Column",
            flex: 4,
            renderCell: (params) => {
                const { id, is_account_id_column } = params.row;
                return (
                    <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                        <CustomSwitch
                            size="small"
                            handleChange={() =>
                                handleUpdateColumn(
                                    id,
                                    "is_account_id_column",
                                    is_account_id_column ===
                                        GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_ID_COLUMN_NO
                                        ? GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_ID_COLUMN_YES
                                        : GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_ID_COLUMN_NO
                                )
                            }
                            checked={
                                is_account_id_column ===
                                GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_ID_COLUMN_YES
                            }
                        />
                    </Box>
                );
            },
            sortable: false
        },
        {
            field: "is_account_name_column",
            headerName: "Is Account Name Column",
            flex: 4,
            renderCell: (params) => {
                const { id, is_account_name_column } = params.row;
                return (
                    <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                        <CustomSwitch
                            size="small"
                            handleChange={() =>
                                handleUpdateColumn(
                                    id,
                                    "is_account_name_column",
                                    is_account_name_column ===
                                        GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_NAME_COLUMN_NO
                                        ? GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_NAME_COLUMN_YES
                                        : GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_NAME_COLUMN_NO
                                )
                            }
                            checked={
                                is_account_name_column ===
                                GHL_AD_SOURCE_COLUMN__IS_ACCOUNT_NAME_COLUMN_YES
                            }
                        />
                    </Box>
                );
            },
            sortable: false
        },
        {
            field: "is_date",
            headerName: "Is Date",
            flex: 4,
            renderCell: (params) => {
                const { id, is_date } = params.row;
                return (
                    <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                        <CustomSwitch
                            size="small"
                            handleChange={() =>
                                handleUpdateColumn(
                                    id,
                                    "is_date",
                                    is_date === GHL_AD_SOURCE_COLUMN__IS_DATE_NO
                                        ? GHL_AD_SOURCE_COLUMN__IS_DATE_YES
                                        : GHL_AD_SOURCE_COLUMN__IS_DATE_NO
                                )
                            }
                            checked={is_date === GHL_AD_SOURCE_COLUMN__IS_DATE_YES}
                        />
                    </Box>
                );
            },
            sortable: false
        },
        {
            field: "is_popular",
            headerName: "Popularity",
            flex: 4,
            renderCell: (params) => {
                const { id, is_popular } = params.row;
                return (
                    <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                        <CustomSwitch
                            size="small"
                            handleChange={() =>
                                handleUpdateColumn(
                                    id,
                                    "is_popular",
                                    is_popular === GHL_AD_SOURCE_COLUMN__IS_POPULAR_NO
                                        ? GHL_AD_SOURCE_COLUMN__IS_POPULAR_YES
                                        : GHL_AD_SOURCE_COLUMN__IS_POPULAR_NO
                                )
                            }
                            checked={is_popular === GHL_AD_SOURCE_COLUMN__IS_POPULAR_YES}
                        />
                    </Box>
                );
            },
            sortable: false
        }
    ];

    const components = [
        {
            name: "Dashboard",
            link: "/Dashboard"
        },
        {
            name: "Admin",
            link: "/admin/agencies"
        },
        { name: "Ad Source", link: "#" }
    ];

    const localHeaderButtons = () => {
        return (
            <>
                <CustomButton
                    title={<Typography variant="buttonLarge">Back</Typography>}
                    handleButton={() => history.goBack()}
                    color="secondary"
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    size="large"
                />
                <CustomButton
                    title={<Typography variant="buttonLarge">Add Column</Typography>}
                    handleButton={() => setIsDrawerOpen(true)}
                    color="secondary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    size="large"
                />
            </>
        );
    };

    const getData = (currentNewPage) => {
        setProgressing(true);
        getAdSourceTableColumnList({
            currentPage: currentNewPage + 1,
            rowLimit: itemPerPage,
            searchKey: filterSearch,
            tableId,
            status: filterStatus
        });
        setCurrentPage(currentNewPage + 1);
    };

    const changePage = (currentRowsPerPage) => {
        setProgressing(true);
        setCurrentPage(1);
        getAdSourceTableColumnList({
            currentPage: 1,
            rowLimit: currentRowsPerPage,
            searchKey: filterSearch,
            tableId,
            status: filterStatus
        });
        setItemPerPage(currentRowsPerPage);
    };

    const handleFilter = (searchKey) => {
        setProgressing(true);
        setFilterSearch(searchKey);
        getAdSourceTableColumnList({
            currentPage,
            rowLimit: itemPerPage,
            searchKey,
            tableId,
            status: filterStatus
        });
    };

    useEffect(() => {
        getAdSourceTableColumnList({
            currentPage,
            rowLimit: itemPerPage,
            searchKey: filterSearch,
            tableId,
            status: filterStatus
        });
    }, [tableId]);

    useEffect(() => {
        if (ghlAdSourceColumnData.isTableColumnDataSuccess) {
            setData(ghlAdSourceColumnData.tableColumnData.data);
            setProgressing(false);
            setLoading(false);
            setTotalPages(ghlAdSourceColumnData.tableColumnData.totalPages);
            setTotalItems(ghlAdSourceColumnData.tableColumnData.totalItems);
            setCurrentPage(ghlAdSourceColumnData.tableColumnData.currentPage);
        }
    }, [ghlAdSourceColumnData.isTableColumnDataSuccess]);

    const NoRowsOverlay = () => {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                <Typography variant="body1" color="text.primary">
                    No Column Found
                </Typography>
            </Stack>
        );
    };

    return (
        <Box className="team-wrapper">
            <Container
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: "10px",
                    height: "100vh"
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box sx={{ width: 1, mb: "30px" }}>
                            <LocalHeader
                                components={components}
                                headerText="Report | Ad Source"
                                buttons={localHeaderButtons}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: 1,
                                padding: "30px 16px",
                                backgroundColor: "background.paper",
                                boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                borderRadius: "4px"
                            }}
                        >
                            {!loading ? (
                                <DataTablePro
                                    tableId="ghl-column-data-table"
                                    rows={data}
                                    columns={column}
                                    loading={progressing}
                                    pagination
                                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100, 500]}
                                    rowCount={totalItems}
                                    pageSize={itemPerPage}
                                    onPageChange={getData}
                                    onPageSizeChange={changePage}
                                    handleFilter={handleFilter}
                                    searchText="Search Columns"
                                    components={{
                                        NoRowsOverlay,
                                        NoResultsOverlay: NoRowsOverlay
                                    }}
                                    otherFilters={otherFilters}
                                />
                            ) : (
                                <TableSkeleton />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            {isDrawerOpen ? (
                <CustomDrawer
                    drawerOpen={isDrawerOpen}
                    setDrawerOpen={setIsDrawerOpen}
                    customClass={isDrawerOpen ? "active" : ""}
                    heading="Add Column"
                >
                    <AdSourceColumnAdd
                        setDrawerOpen={setIsDrawerOpen}
                        tableId={tableId}
                        reloadData={() =>
                            getAdSourceTableColumnList({
                                currentPage,
                                rowLimit: itemPerPage,
                                searchKey: filterSearch,
                                tableId
                            })
                        }
                    />
                </CustomDrawer>
            ) : (
                ""
            )}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return { ghlAdSourceColumnData: state.adminGhlReducer };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAdSourceTableColumnList: (params) => dispatch(getGhlAdSourceTableColumnData(params)),
        updateColumn: (params, callback) => dispatch(updateGhlAdSourceTableColumn(params, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTableTableColumns);
