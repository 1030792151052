import {
    CustomButton,
    CustomHeader,
    CustomImageUploader,
    CustomModal,
    CustomTextField
} from "@hipdevteam/afglobalcomponents";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Divider, Grid, StyledEngineProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import FileUploadSkeleton from "../../components/SkeletonLoader/AgencySettings/FileUploadSkeleton";
import WhiteLabelDomainSkeleton from "../../components/SkeletonLoader/WhiteLabelDomain/WhiteLabelDomainSkeleton";
import ShowAlerts from "../../helpers/ShowAlerts";
import { uploadS3FileAction } from "../../statemanagement/s3FileUpload/s3FileUploadAction";
import {
    getWhiteLabelDomainData,
    updateOrCreateWhiteLabelDomainData
} from "../../statemanagement/whiteDomain/whiteDomainAction";
import * as CONSTANT from "../../utils/Constant";
import Instruction from "./Instruction";

const WhiteLabelDomain = (props) => {
    const { getDomainData, updateOrCreateDomainData, whiteLabelDomain, uploadWhiteLabelFavIcon } =
        props;
    const [imagePath, setImagePath] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingForUpload, setLoadingForUpload] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [logoFile, setLogoFile] = React.useState(null);
    const [logoFileName, setLogoFileName] = React.useState(null);
    const [openInstruction, setOpenInstruction] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [title, setTitle] = useState("");
    const { register, handleSubmit } = useForm();
    const components = [
        {
            name: "Settings",
            link: "#",
            icon: null
        },
        { name: "White Label Domain", link: "#", icon: null }
    ];
    useEffect(() => {
        getDomainData();
    }, []);

    useEffect(() => {
        if (whiteLabelDomain.getData) {
            setImagePath(
                typeof whiteLabelDomain.whiteLabelDomainData.fev_icon !== "undefined" &&
                    whiteLabelDomain.whiteLabelDomainData.fev_icon !== null
                    ? whiteLabelDomain.whiteLabelDomainData.fev_icon
                    : ""
            );
            setDomainName(
                typeof whiteLabelDomain.whiteLabelDomainData.domain !== "undefined" &&
                    whiteLabelDomain.whiteLabelDomainData.domain !== null
                    ? whiteLabelDomain.whiteLabelDomainData.domain
                    : ""
            );
            setTitle(
                typeof whiteLabelDomain.whiteLabelDomainData.title !== "undefined" &&
                    whiteLabelDomain.whiteLabelDomainData.title !== null
                    ? whiteLabelDomain.whiteLabelDomainData.title
                    : ""
            );

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [whiteLabelDomain.getData]);
    const handleLogoUpload = () => {
        if (!logoFile) return;

        const fileName = CONSTANT.getRandomFileName();
        const checkSize = CONSTANT.bytesToMb(logoFile[0].size);

        if (logoFile.length < 1) {
            return;
        }
        const fileType = logoFile[0].type;
        const validExtensions = ["image/jpeg", "image/jpg", "image/png"];
        if (validExtensions.includes(fileType) && checkSize < 2.5) {
            setLoadingForUpload(true);
            setLogoFileName(fileName);
            const file = new FormData();
            file.append("file", logoFile[0]);
            uploadWhiteLabelFavIcon(file, (response) => {
                if (response.status && response.data.location) {
                    const whiteLabelDomainData = {
                        fev_icon: response.data.location
                    };
                    updateOrCreateDomainData(whiteLabelDomainData, (response) => {
                        if (response.status) {
                            setImagePath(response.data.location);
                            setLoadingForUpload(false);
                            ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                        } else {
                            setTimeout(() => {
                                setLoading(false);
                            }, 1000);
                        }
                    });

                    ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                } else {
                    ShowAlerts(response.message, CONSTANT.ALERT_TYPE_ERROR);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            });
        } else {
            return false;
        }
    };
    const handleRemoveLogo = () => {
        setImagePath("");
        setLogoFile(null);
        setLogoFileName("");
        setIsDelete(false);
        if (
            typeof whiteLabelDomain.whiteLabelDomainData.fev_icon !== "undefined" &&
            whiteLabelDomain.whiteLabelDomainData.fev_icon !== null &&
            whiteLabelDomain.whiteLabelDomainData.fev_icon
        ) {
            updateOrCreateDomainData({ fev_icon: "" }, (response) => {
                if (response.status) {
                    ShowAlerts("Successfully removed!", CONSTANT.ALERT_TYPE_SUCCESS);
                } else {
                    ShowAlerts("Logo Removed Failed!", CONSTANT.ALERT_TYPE_ERROR);
                }
            });
        }
    };
    const handleDomainNameSave = (data) => {
        const whitelabel = {
            domain: data.domain
        };
        updateOrCreateDomainData(whitelabel, (res) => {
            if (res.status) {
                ShowAlerts(res.message, CONSTANT.ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(res.message, CONSTANT.ALERT_TYPE_ERROR);
            }
        });
    };
    const handleTitleSave = (data) => {
        const whitelabel = {
            title: data.title
        };
        if (
            whiteLabelDomain.whiteLabelDomainData.domain === undefined ||
            whiteLabelDomain.whiteLabelDomainData.domain === ""
        ) {
            ShowAlerts("Please Select your Domain first", CONSTANT.ALERT_TYPE_ERROR);
            return;
        }
        updateOrCreateDomainData(whitelabel, (res) => {
            if (res.status) {
                ShowAlerts(res.message, CONSTANT.ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(res.message, CONSTANT.ALERT_TYPE_ERROR);
            }
        });
    };
    return (
        <StyledEngineProvider injectFirst>
            {!loading ? (
                <Box className="user_settings_container">
                    <Box sx={{ width: 1, mb: "30px" }} className="user_settings">
                        <Box fullWidth>
                            <Box sx={{ width: 1, mb: "30px" }}>
                                <CustomHeader
                                    components={components}
                                    headerText="White Label Domain"
                                />
                            </Box>
                            <Grid
                                item
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "0px",
                                    padding: "20px 20px 20px 20px",
                                    boxShadow: "none",
                                    backgroundColor: "#1b1464"
                                }}
                                xs={10}
                                md={12}
                                sm={12}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gridGap: "6px"
                                    }}
                                >
                                    <ErrorOutlineOutlinedIcon
                                        sx={{ color: "common.white", fontSize: "medium" }}
                                    />

                                    <Typography
                                        variant="body1"
                                        component="span"
                                        color="common.white"
                                    >
                                        Tips
                                    </Typography>
                                </Box>
                                <Typography variant="body2" component="span" color="common.white">
                                    You can use your own domain name or sub-domain in the URL of
                                    reports and public links instead of our white label domain by
                                    following easy steps.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    backgroundColor: "background.paper",
                                    marginTop: "0px !important"
                                }}
                                xs={10}
                                md={12}
                                sm={12}
                                className="schema__form"
                            >
                                <Typography
                                    variant="h5"
                                    component="span"
                                    className="schema__form__header"
                                    color="text.fill"
                                >
                                    Write Your Domain Name
                                </Typography>
                                <form onSubmit={handleSubmit(handleDomainNameSave)}>
                                    {!loading && (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box>
                                                    <Box>
                                                        <Grid container spacing={3}>
                                                            <Grid item md={9} sm={8}>
                                                                <CustomTextField
                                                                    name="domain_name"
                                                                    label="Domain Name"
                                                                    autoComplete="off"
                                                                    id="domain"
                                                                    type="text"
                                                                    defaultValue={
                                                                        whiteLabelDomain.getData &&
                                                                        whiteLabelDomain.whiteLabelDomainData &&
                                                                        whiteLabelDomain
                                                                            .whiteLabelDomainData
                                                                            .domain
                                                                            ? whiteLabelDomain
                                                                                  .whiteLabelDomainData
                                                                                  .domain
                                                                            : ""
                                                                    }
                                                                    placeholder="Example: Domain.com"
                                                                    register={register("domain")}
                                                                />
                                                            </Grid>
                                                            <Grid item md={3} sm={4} align="right">
                                                                <CustomButton
                                                                    handleButton={null}
                                                                    title="Update Domain"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    type="submit"
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        flexDirection: "column"
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            alignItems: "baseline",
                                                                            gridGap: "6px"
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            component="span"
                                                                            color="text.primary"
                                                                        >
                                                                            Tag Url :
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="span"
                                                                            color="text.primary"
                                                                        >
                                                                            reports.agencyframework.io
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            alignItems: "baseline",
                                                                            gridGap: "6px"
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="span"
                                                                            color="text.primary"
                                                                        >
                                                                            Add this target url Add
                                                                            this target url as your
                                                                            NAME{" "}
                                                                        </Typography>
                                                                        <Typography
                                                                            component="span"
                                                                            onClick={() =>
                                                                                setOpenInstruction(
                                                                                    true
                                                                                )
                                                                            }
                                                                            color="secondary.main"
                                                                            sx={{
                                                                                cursor: "pointer"
                                                                            }}
                                                                        >
                                                                            Show Show Instruction
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider
                                                                    sx={{
                                                                        marginTop: "1rem",
                                                                        marginBottom: "1rem"
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <CustomModal
                                                            open={openInstruction}
                                                            setOpen={setOpenInstruction}
                                                            title="Instruction"
                                                            size="md"
                                                        >
                                                            <Instruction />
                                                        </CustomModal>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </form>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    backgroundColor: "background.paper",
                                    marginTop: "0px !important",
                                    paddingTop: "0px !important"
                                }}
                                xs={10}
                                md={12}
                                sm={12}
                                className="schema__form"
                            >
                                <Typography
                                    variant="h5"
                                    component="span"
                                    className="schema__form__header"
                                    color="text.fill"
                                >
                                    Your Website Title
                                </Typography>
                                <form onSubmit={handleSubmit(handleTitleSave)}>
                                    {!loading && (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box>
                                                    <Box>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <CustomTextField
                                                                    name="title"
                                                                    label="Website Title"
                                                                    autoComplete="off"
                                                                    id="title"
                                                                    type="text"
                                                                    placeholder="Example: Domain"
                                                                    defaultValue={
                                                                        whiteLabelDomain.getData &&
                                                                        whiteLabelDomain.whiteLabelDomainData &&
                                                                        whiteLabelDomain
                                                                            .whiteLabelDomainData
                                                                            .title
                                                                            ? whiteLabelDomain
                                                                                  .whiteLabelDomainData
                                                                                  .title
                                                                            : ""
                                                                    }
                                                                    register={register("title")}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <form className="agency_logo_upload">
                                                                    {!loading ? (
                                                                        <Box className="fileUploader">
                                                                            <CustomImageUploader
                                                                                title="Add Your Website Favicon Here"
                                                                                subtitle="Please Select your favicon (Dimension: 256x256)"
                                                                                setLogoFile={
                                                                                    setLogoFile
                                                                                }
                                                                                setImagePath={
                                                                                    setImagePath
                                                                                }
                                                                                imagePath={
                                                                                    imagePath
                                                                                }
                                                                                handleLogoUpload={
                                                                                    handleLogoUpload
                                                                                }
                                                                                setIsDelete={
                                                                                    setIsDelete
                                                                                }
                                                                                isDelete={isDelete}
                                                                                handleRemoveLogo={
                                                                                    handleRemoveLogo
                                                                                }
                                                                                loading={
                                                                                    loadingForUpload
                                                                                }
                                                                            />
                                                                        </Box>
                                                                    ) : (
                                                                        <FileUploadSkeleton />
                                                                    )}
                                                                </form>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={10}
                                                            md={12}
                                                            sm={12}
                                                            className="schema__form__btn__v1"
                                                        >
                                                            <Box
                                                                className="save_button"
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                    marginTop: "20px"
                                                                }}
                                                            >
                                                                <CustomButton
                                                                    handleButton={null}
                                                                    title="SAVE CHANGES"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    type="submit"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </form>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <WhiteLabelDomainSkeleton />
            )}
        </StyledEngineProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        whiteLabelDomain: state.whiteLabelDomainReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDomainData: (params, callback) => dispatch(getWhiteLabelDomainData(params, callback)),
        updateOrCreateDomainData: (params, callback) =>
            dispatch(updateOrCreateWhiteLabelDomainData(params, callback)),
        uploadWhiteLabelFavIcon: (params, callback) =>
            dispatch(uploadS3FileAction(params, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabelDomain);
