import { CustomHeader, CustomSkeleton } from "@hipdevteam/afglobalcomponents";
import { Box, Grid } from "@mui/material";

const UserSettingSkeleton = () => {
    const components = [
        {
            name: "Settings",
            link: "#",
            icon: null
        },
        { name: "User Settings", link: "#", icon: null }
    ];
    return (
        <Box className="user_settings_container">
            <Box sx={{ width: 1, mb: "30px" }} className="user_settings">
                <Box fullWidth>
                    <Box sx={{ width: 1, mb: "30px" }}>
                        <CustomHeader components={components} headerText="User Settings" />
                    </Box>
                    <Grid
                        item
                        sx={{ backgroundColor: "background.paper" }}
                        xs={10}
                        md={12}
                        sm={12}
                        className="schema__form"
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <Box>
                                        <CustomSkeleton variant="text" height={42} width={100} />
                                        <form>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <CustomSkeleton
                                                        variant="rectangular"
                                                        height={42}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomSkeleton
                                                        variant="rectangular"
                                                        height={42}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CustomSkeleton
                                                        variant="rectangular"
                                                        height={42}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CustomSkeleton
                                                        variant="rectangular"
                                                        height={42}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    marginTop: "24px"
                                                }}
                                            >
                                                <CustomSkeleton
                                                    variant="rectangular"
                                                    width={140}
                                                    height={42}
                                                />
                                            </Box>
                                        </form>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default UserSettingSkeleton;
