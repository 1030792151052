/* eslint-disable react/jsx-no-useless-fragment */
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import React from "react";

const AuthError = (props) => {
    const { type, errorText } = props;
    if (type === "error") {
        return (
            <Stack sx={{ width: "100%", marginBottom: "1rem" }}>
                <Alert severity="error" variant="filled">
                    <AlertTitle sx={{ marginBottom: 0 }}>
                        <strong>Error</strong>
                    </AlertTitle>
                    <Typography>{errorText}</Typography>
                </Alert>
            </Stack>
        );
    }
    if (type === "success") {
        return (
            <Stack sx={{ width: "100%", marginBottom: "1rem" }}>
                <Alert severity="success" variant="filled">
                    <AlertTitle sx={{ marginBottom: 0 }}>
                        <strong>Success</strong>
                    </AlertTitle>
                    <Typography>{errorText}</Typography>
                </Alert>
            </Stack>
        );
    }
    return <></>;
};

export default AuthError;
