import { sendRequestWithToken } from "./rootApi";

export const getAppLists = async (param) => {
    return sendRequestWithToken("get", `${process.env.REACT_APP_API_BASE_URL}/apps/`, param);
};

export const appSubscribeApi = async (param) => {
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/apps/subscribe/${param}`
    );
};

export const appUnSubscribeApi = async (param) => {
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/apps/unsubscribe/${param}`
    );
};
