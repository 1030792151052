import * as ACTION from "./signupActionTypes";

export const attemptSignup = (data, callback) => ({
    type: ACTION.SIGNUP_ATTEMPT,
    payload: data,
    callback
});

export const signUpSuccess = (data) => ({
    type: ACTION.SIGNUP_SUCCESS,
    payload: data
});
export const signUpError = (data) => ({
    type: ACTION.SIGNUP_ERROR,
    payload: data
});

export const googleSignIn = (data, callback) => ({
    type: ACTION.GOOGLE_SIGN_IN,
    payload: data,
    callback
});

export const googleRegistration = (data, callback) => ({
    type: ACTION.GOOGLE__REGISTRATION,
    payload: data,
    callback
});

export const emailVerification = (data, callback) => ({
    type: ACTION.EMAIL_VERIFICATION,
    payload: data,
    callback
});
export const resendEmailVerificationCode = (callback) => ({
    type: ACTION.SEND_EMAIL_VERIFICATION_CODE,
    payload: [],
    callback
});

export const makeSignUpSuccessFalse = () => ({
    type: ACTION.SIGNUP_SUCCESS_FALSE
});
