/* eslint-disable jsx-a11y/anchor-is-valid */
import { CustomButton } from "@hipdevteam/afglobalcomponents";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { setCookie, setOtherCookie } from "../../helpers/Cookie";
import ShowAlerts from "../../helpers/ShowAlerts";
import {
    sendForgetPasswordEmail,
    verifyForgetPasswordCode
} from "../../statemanagement/resetPassword/resetPasswordActions";
import { ALERT_TYPE_ERROR } from "../../utils/Constant";
import ResetPasswordForm from "./ResetPasswordForm";
import "./style/auth.css";

const style = {
    "&::before": {
        position: "absolute",
        content: '" "',
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        background: "rgba(27, 20, 100, 0.85)"
    }
};

const ForgetPasswordVerification = (props) => {
    const { verifyCode, sendForgetPasswordEmailData, email } = props;
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [status, setStatus] = useState(true);
    const [code, setCode] = useState("");
    const [isCodeVerified, setIsCodeVerified] = useState(false);
    const [counter, setCounter] = useState(59);
    const [resendCodeStatus, setResendCodeStatus] = useState(true);

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

        return () => {
            clearInterval(timer);
        };
    }, [counter]);

    useEffect(() => {
        if (counter === 0) {
            setResendCodeStatus(false);
        }
    }, [counter]);

    const handleCodeOnComplete = (e) => {
        setCode(e);
        setStatus(false);
    };

    const handleOnChange = (e) => {
        setCode(e);
        setStatus(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoader(true);
        const data = { code };

        verifyCode(data, (response) => {
            if (response.status) {
                setIsCodeVerified(true);
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
                setLoader(false);
            }
        });
    };

    const handleBackButton = (e) => {
        e.preventDefault();
        setCookie(process.env.REACT_APP_ACCESS_TOKEN, "", "1 sec");
        setCookie(process.env.REACT_APP_REFRESH_TOKEN, "", "1 sec");
        setOtherCookie(process.env.REACT_APP_EMAIL_VERIFIED_STATUS, "");
        history.push("/login");
    };

    const handleSignUpButton = (e) => {
        e.preventDefault();
        setCookie(process.env.REACT_APP_ACCESS_TOKEN, "", "1 sec");
        setCookie(process.env.REACT_APP_REFRESH_TOKEN, "", "1 sec");
        setOtherCookie(process.env.REACT_APP_EMAIL_VERIFIED_STATUS, "");
        history.push("/registration");
    };

    const handleResendCode = (e) => {
        e.preventDefault();
        sendForgetPasswordEmailData({ email }, (response) => {
            if (response.status) {
                setResendCodeStatus(true);
                setCounter(59);
            }
        });
    };

    if (isCodeVerified) {
        return <ResetPasswordForm email={email} />;
    }
    return (
        <Box sx={style} className="auth__wrapper">
            <Box className="auth__inner">
                <Box className="auth__inner__container">
                    <Box className="auth__email__verification__wr">
                        <Box className="auth__inner__top__extra">
                            <Typography className="" variant="h4" component="h4">
                                Verify your Email address
                            </Typography>
                            <Typography className="" variant="body2" component="p">
                                Please Enter the 6 Digit Code send to {email} or
                                <a
                                    href="#"
                                    onClick={handleSignUpButton}
                                    className="auth__verification__sign_text"
                                >
                                    SIGN UP
                                </a>
                            </Typography>
                        </Box>
                        <Box className="auth__form__wr">
                            <form>
                                <ReactCodeInput
                                    fields={6}
                                    type="text"
                                    autoFocus={false}
                                    onComplete={handleCodeOnComplete}
                                    onChange={handleOnChange}
                                />
                                {loader ? (
                                    <CustomButton
                                        variant="contained"
                                        color="primary"
                                        loader={loader}
                                        title="Submit"
                                        size="large"
                                        type="submit"
                                        customClass="auth__verification__btn"
                                        handleButton={handleSubmit}
                                    />
                                ) : (
                                    <CustomButton
                                        variant="contained"
                                        color="primary"
                                        disabled={status}
                                        title="Submit"
                                        size="large"
                                        type="submit"
                                        customClass="auth__verification__btn"
                                        handleButton={handleSubmit}
                                    />
                                )}
                            </form>
                            <Box className="auth__back__resend__btn">
                                <CustomButton
                                    variant="body2"
                                    title="Back"
                                    size="small"
                                    type="button"
                                    handleButton={handleBackButton}
                                    customClass="auth__back__btn"
                                />

                                {counter > 0 ? (
                                    <Typography>Resend Code in 00: {counter}</Typography>
                                ) : (
                                    ""
                                )}

                                <CustomButton
                                    variant="body2"
                                    title="Resend Code"
                                    size="small"
                                    disabled={resendCodeStatus}
                                    type="button"
                                    handleButton={handleResendCode}
                                    customClass="auth__resend__btn"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="auth__footer">
                <Typography variant="p" component="p">
                    @ {new Date().getFullYear()} Agency Framework - All Rights Reserved
                </Typography>

                <Box className="footer__link">
                    <Link to="/forgot-password">
                        <Typography variant="overline" component="span">
                            Privacy Policy
                        </Typography>
                    </Link>
                    <Link to="/forgot-password">
                        <Typography variant="overline" component="span">
                            terms and conditions
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    verifyCode: (data, callback) => dispatch(verifyForgetPasswordCode(data, callback)),
    sendForgetPasswordEmailData: (params, callback) =>
        dispatch(sendForgetPasswordEmail(params, callback))
});
export default connect(null, mapDispatchToProps)(ForgetPasswordVerification);
