/* eslint-disable react/no-this-in-sfc */
import { CustomButton, CustomLabel, CustomTextField } from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { resetPassword } from "../../statemanagement/resetPassword/resetPasswordActions";
import Logo from "./images/logo.png";
import "./style/auth.css";

const style = {
    "&::before": {
        position: "absolute",
        content: '" "',
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        background: "rgba(27, 20, 100, 0.85)"
    }
};

const ResetPasswordForm = (props) => {
    const { passwordReset, email } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const validator = yup.object().shape({
        password: yup
            .string()
            .required()
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),
        confirm_password: yup
            .string()
            .test("passwords-match", "Passwords must match", function (value) {
                return this.parent.password === value;
            })
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: "onChange", resolver: yupResolver(validator) });

    const onSubmit = (data) => {
        setLoading(true);

        const newData = {
            email,
            password: data.password,
            confirm_password: data.confirm_password
        };
        passwordReset(newData, (response) => {
            if (response.status) {
                reset();
                history.push("/login");
            }
            setLoading(false);
        });
    };

    return (
        <Box sx={style} className="auth__wrapper">
            <Box className="auth__inner">
                <Box className="auth__inner__container">
                    <Box className="auth__reset__wr">
                        <Box className="auth__inner__top">
                            <img src={Logo} alt="agency-framework" />
                            <Typography
                                className="auth__have__account"
                                variant="body2"
                                component="p"
                            >
                                Instead
                                <Link to="/login"> Sign In</Link>
                            </Typography>
                        </Box>
                        <Box className="auth__form__wr">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <CustomTextField
                                    className="single__text__field"
                                    label="New Password"
                                    type="password"
                                    register={register("password")}
                                    errors={errors}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <VisibilityOff />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {!!errors.password && (
                                    <CustomLabel variant="subtitle2" align="left" color="error">
                                        {errors.password.message}
                                    </CustomLabel>
                                )}

                                <CustomTextField
                                    className="single__text__field"
                                    label="Confirm Password"
                                    type="password"
                                    register={register("confirm_password")}
                                    errors={errors}
                                />
                                {!!errors.confirm_password && (
                                    <CustomLabel variant="subtitle2" align="left" color="error">
                                        {errors.confirm_password.message}
                                    </CustomLabel>
                                )}

                                <CustomButton
                                    variant="contained"
                                    loader={loading}
                                    color="primary"
                                    title="Reset"
                                    size="large"
                                    type="submit"
                                    customClass="auth__sign__in__btn"
                                />
                            </form>
                        </Box>
                    </Box>
                    <Box className="auth__footer">
                        <Typography variant="p" component="p">
                            @ {new Date().getFullYear()} Agency Framework - All Rights Reserved
                        </Typography>

                        <Box className="footer__link">
                            <Link to="/forgot-password">
                                <Typography variant="overline" component="span">
                                    Privacy Policy
                                </Typography>
                            </Link>
                            <Link to="/forgot-password">
                                <Typography variant="overline" component="span">
                                    terms and conditions
                                </Typography>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        passwordReset: (params, callback) => dispatch(resetPassword(params, callback))
    };
};

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
