import {
    CustomBadge,
    CustomButton,
    CustomConfirmModal,
    CustomDrawer,
    CustomHeader,
    CustomSelectBox,
    DataTablePro
} from "@hipdevteam/afglobalcomponents";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TableSkeleton from "../../../components/SkeletonLoader/Table/TableSkeleton";
import ShowAlert from "../../../helpers/ShowAlerts";
import {
    bulkDeleteHolidaysAction,
    getHolidayListDataTable,
    updateHolidayStatus
} from "../../../statemanagement/holiday/holidayAction";
import {
    ALERT_TYPE_ERROR,
    ALERT_TYPE_SUCCESS,
    STATUS_ACTIVE,
    STATUS_INACTIVE
} from "../../../utils/Constant/GlobalConstant";
import holidayYearFilterData from "../../../utils/Constant/holidayConstant";
import { formatTime } from "../../../utils/timeHelper";
import UploadFile from "./UploadFile";

const filterYears = holidayYearFilterData();

const Holidays = (props) => {
    const { getHolidayDataTableData, holidayData, isSuccess, updateStatus, bulkDeleteHolidays } =
        props;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [progressing, setProgressing] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [filterSearch, setFilterSearch] = useState("");
    const [inactiveModal, setInactiveModal] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [selectedHoliday, setSelectedHoliday] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const prevSelectionModel = React.useRef(selectedItems);
    const [bulkDelete, setBulkDelete] = useState(false);
    const [filterYear, setFilterYear] = useState(filterYears[3].id);
    const headerButtons = [
        {
            name: "Upload Holiday File",
            color: "secondary",
            startIcon: <FileUploadIcon />,
            action: () => setDrawerOpen(true)
        }
    ];
    const components = [
        {
            name: "Dashboard",
            link: "/Dashboard"
        },
        {
            name: "Admin",
            link: "/admin/holidays"
        },
        { name: "Holidays", link: "#" }
    ];

    // Data Table Start
    useEffect(() => {
        setProgressing(true);
        getHolidayDataTableData();
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setData(holidayData.data);
            setProgressing(false);
            setLoading(false);
            setTotalPages(holidayData.totalPages);
            setTotalItems(holidayData.totalItems);
            setCurrentPage(holidayData.currentPage);
        }
    }, [isSuccess]);

    const changePage = (pageSize) => {
        setProgressing(true);
        setCurrentPage(1);
        getHolidayDataTableData(1, pageSize, filterSearch, filterYear);
        setItemPerPage(pageSize);
    };

    // Get Data
    const getData = (currentPage) => {
        setProgressing(true);
        prevSelectionModel.current = selectedItems;
        getHolidayDataTableData(currentPage + 1, itemPerPage, filterSearch, filterYear);
        setCurrentPage(currentPage + 1);
    };
    // data table page change select
    useEffect(() => {
        setTimeout(() => {
            if (selectedItems.length > 0) {
                setSelectedItems(prevSelectionModel.current);
            }
        }, [1000]);
    }, [currentPage, data]);

    const handleFilter = async (searchKey) => {
        setFilterSearch(searchKey);
        getHolidayDataTableData(currentPage, itemPerPage, searchKey, filterYear);
    };
    const handleActiveInactiveConfirmation = (row) => {
        setSelectedHoliday(row);
        if (Number(row.status) === STATUS_ACTIVE) {
            setInactiveModal(true);
        }
        if (Number(row.status) === STATUS_INACTIVE) {
            setActiveModal(true);
        }
    };
    const handleStatusChange = (id, status) => {
        updateStatus({ id, status }, (response) => {
            if (response.status) {
                ShowAlert(response.message, ALERT_TYPE_SUCCESS);
                getHolidayDataTableData(currentPage, itemPerPage, filterSearch);
                if (status === STATUS_INACTIVE) {
                    setInactiveModal(false);
                }
                if (status === STATUS_ACTIVE) {
                    setActiveModal(false);
                }
            } else {
                ShowAlert(response.message, ALERT_TYPE_ERROR);
            }
        });
    };

    const columns = [
        {
            field: "holiday_name",
            flex: 3,
            headerName: <Typography variant="tableHeader">Holiday Name</Typography>,
            renderCell: (params) => {
                return (
                    <Box>
                        {params.value && <Typography variant="body2">{params.value}</Typography>}
                        {!params.value && <CustomBadge label="Not Found" color="info" />}
                    </Box>
                );
            }
        },
        {
            field: "day_of_the_week",
            flex: 3,
            headerName: <Typography variant="tableHeader">Day Of The Week</Typography>,
            renderCell: (params) => {
                return (
                    <Box>
                        {params.value && <Typography variant="body2">{params.value}</Typography>}
                        {!params.value && <CustomBadge label="Not Found" color="info" />}
                    </Box>
                );
            }
        },
        {
            field: "date",
            flex: 3,
            headerName: <Typography variant="tableHeader">Date</Typography>,
            renderCell: (params) => {
                return (
                    <Box>
                        {params.value && (
                            <Typography variant="body2">
                                {formatTime(params.value, "yyyy-MM-dd")}
                            </Typography>
                        )}
                        {!params.value && <CustomBadge label="Not Found" color="info" />}
                    </Box>
                );
            }
        },
        {
            field: "status",
            flex: 3,
            headerName: <Typography variant="tableHeader">Status</Typography>,
            renderCell: (params) => {
                return (
                    <Box>
                        {params.value == STATUS_ACTIVE ? (
                            <CustomBadge
                                label="Active"
                                color="success"
                                onClick={() => handleActiveInactiveConfirmation(params.row)}
                            />
                        ) : (
                            <CustomBadge
                                label="Inactive"
                                color="error"
                                onClick={() => handleActiveInactiveConfirmation(params.row)}
                            />
                        )}
                    </Box>
                );
            }
        }
    ];
    const headerActionButtonsForDeleteMultipleHolidays = () => {
        return (
            <Box className="header_buttons">
                <CustomButton
                    title={<Typography variant="buttonLarge">Delete</Typography>}
                    handleButton={() => setBulkDelete(true)}
                    color="warning"
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    size="large"
                />
            </Box>
        );
    };
    const handleBulkDeleteHolidays = () => {
        if (selectedItems.length > 0) {
            setBulkDelete(false);
            bulkDeleteHolidays({ holidayIds: selectedItems }, (response) => {
                if (response.status) {
                    setSelectedItems([]);
                    ShowAlert(response.message, ALERT_TYPE_SUCCESS);
                }
                getHolidayDataTableData(currentPage, itemPerPage, filterSearch);
            });
        }
    };
    const handleYearChange = (e) => {
        setFilterSearch("");
        setFilterYear(e.target.value);
        setProgressing(true);
        getHolidayDataTableData(currentPage, itemPerPage, "", e.target.value);
    };
    const otherFilters = () => {
        return (
            <Box
                sx={{
                    width: "200px"
                }}
            >
                <CustomSelectBox
                    sx={{
                        maxWidth: "200px",
                        height: "40px"
                    }}
                    name="Year"
                    options={filterYears}
                    defaultValue={filterYears[3].id}
                    value={filterYear}
                    handleChange={(e) => handleYearChange(e)}
                    // fieldDisable={selectedItems.length > 0}
                    label="Year"
                />
            </Box>
        );
    };

    return (
        <>
            <Box>
                <Container
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingTop: "10px"
                        // height: "100vh",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ width: 1, mb: "30px" }}>
                                <CustomHeader
                                    components={components}
                                    headerText="Holidays"
                                    buttons={headerButtons}
                                    color="secondary"
                                />
                                <Box
                                    sx={{
                                        width: 1,
                                        padding: "30px 16px",
                                        backgroundColor: "background.paper",
                                        boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                        borderRadius: "4px"
                                    }}
                                >
                                    {!loading ? (
                                        <DataTablePro
                                            tableId="af_core_holiday_data_table"
                                            rows={data}
                                            columns={columns}
                                            checkboxSelection={data.length > 0}
                                            loading={progressing}
                                            pagination
                                            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100, 500]}
                                            pageSize={itemPerPage}
                                            rowCount={totalItems}
                                            onPageChange={getData}
                                            onPageSizeChange={changePage}
                                            handleFilter={handleFilter}
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectedItems(newSelectionModel);
                                            }}
                                            searchStatus
                                            searchText="Filter By Holiday"
                                            // After Select Action
                                            selectedItems={selectedItems}
                                            customHeaderButtons={headerActionButtonsForDeleteMultipleHolidays()}
                                            disableSelectionOnClick
                                            otherFilters={otherFilters}
                                        />
                                    ) : (
                                        <TableSkeleton />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <CustomDrawer
                customClass={drawerOpen ? "active" : ""}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                heading="Upload Holiday File"
            >
                <UploadFile drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </CustomDrawer>

            <CustomConfirmModal
                title="Are you sure you want to Active that Holiday?"
                subtitle="This action will activate that holiday"
                disagreedButtonTitle="NO, KEEP IT"
                agreedButtonTitle="YES, ACTIVE IT"
                open={activeModal}
                setOpen={() => setActiveModal(false)}
                agreedButtonColor="success"
                handleSubmit={() => handleStatusChange(selectedHoliday.id, STATUS_ACTIVE)}
                handleCancel={() => setActiveModal(false)}
            />
            <CustomConfirmModal
                title="Are you sure you want to Inactive that Holiday?"
                subtitle="This action will inactivate that holiday"
                disagreedButtonTitle="NO, KEEP IT"
                agreedButtonTitle="YES, INACTIVE IT"
                open={inactiveModal}
                setOpen={() => setInactiveModal(false)}
                agreedButtonColor="warning"
                handleSubmit={() => handleStatusChange(selectedHoliday.id, STATUS_INACTIVE)}
                handleCancel={() => setInactiveModal(false)}
            />
            {/* Confirm alert For Bulk Delete    */}
            <CustomConfirmModal
                title="Are you sure you want to Delete selected Holidays?"
                subtitle="It will take some time to delete"
                disagreedButtonTitle="NO, KEEP THEM"
                agreedButtonTitle="YES, DELETE THEM"
                open={bulkDelete}
                setOpen={() => setBulkDelete(false)}
                agreedButtonColor="warning"
                handleSubmit={() => handleBulkDeleteHolidays()}
                handleCancel={() => setBulkDelete(false)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        holidayData: state.holidayReducer.holidayTableData,
        isSuccess: state.holidayReducer.isSuccess
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getHolidayDataTableData: (
            currentPage = 1,
            rowLimit = 10,
            searchKey = "",
            year = filterYears[3].id
        ) =>
            dispatch(
                getHolidayListDataTable({
                    currentPage,
                    rowLimit,
                    searchKey,
                    year
                })
            ),
        updateStatus: (payload, callback) => dispatch(updateHolidayStatus(payload, callback)),
        bulkDeleteHolidays: (payload, callback) =>
            dispatch(bulkDeleteHolidaysAction(payload, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
