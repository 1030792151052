const timeToMilliSecond = (time) => {
    const number = time.substring(0, time.indexOf(" "));
    switch (time.substring(time.indexOf(" ") + 1)) {
        case "day":
            return number * 24 * 60 * 60 * 1000;
        case "hour":
            return number * 60 * 60 * 1000;
        case "minute":
            return number * 60 * 1000;
        case "sec":
            return number * 1000;
        default:
            return number * 60 * 1000;
    }
};

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

export const setCookie = (cname, cvalue, exTime) => {
    const date = new Date();
    date.setTime(date.getTime() + timeToMilliSecond(exTime));

    document.cookie = `${cname}=${cvalue}; expires = ${date.toUTCString()};path=/`;
    // document.cookie = cname + "=" + cvalue + "; Secure; path=/";
};

export const setOtherCookie = (cname, cvalue) => {
    const date = new Date();
    date.setTime(date.getTime() + timeToMilliSecond("1 hour"));
    document.cookie = `${cname}=${cvalue};path=/`;
};

export const setCookieForRefresh = (cname, cvalue) => {
    const date = new Date();
    date.setTime(date.getTime() + timeToMilliSecond("1 day"));
    document.cookie = `${cname}=${cvalue}; Secure; path=/;  expires = ${date.toUTCString()};`;
};

export const getCookie = (cname) => {
    const name = `${cname}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const checkCookie = (cname) => {
    const cookieInfo = getCookie(cname);

    if (cookieInfo === "") {
        return "";
    }
    const decodedJwt = parseJwt(cookieInfo);
    if (!decodedJwt || !decodedJwt.exp) {
        return "";
    }
    if (decodedJwt.exp * 1000 < Date.now()) {
        return "";
    }
    return !!cookieInfo;
};

export const setCookieWithPath = (cname, cvalue, cpath = "/") => {
    document.cookie = `${cname}=${cvalue}; Secure; path=${cpath}`;
};

export const deleteCookie = (cname, cpath) => {
    if (getCookie(cname)) {
        document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${cpath}`;
    }
};
