import {
    CustomConfirmModal,
    CustomDrawer,
    CustomDropDownButtonMenu,
    CustomHeader,
    CustomSelectBox,
    GlobalDataTable
} from "@hipdevteam/afglobalcomponents";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Container, Grid, StyledEngineProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TableSkeleton from "../../components/SkeletonLoader/Table/TableSkeleton";
import ShowAlerts from "../../helpers/ShowAlerts";
import { deleteTeam, getTeamDataTable } from "../../statemanagement/team/teamAction";
import * as CONSTANT from "../../utils/Constant";
import AddTeam from "./AddTeam";
import EditTeam from "./EditTeam";
import "./team.css";

const Team = (props) => {
    const history = useHistory();
    const { getTeamDataTableData, isSuccess, teamData, deleteTeamData } = props;
    const [isDelete, setIsDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerOpenForEdit, setDrawerOpenForEdit] = useState(false);
    const [uuid, setUuid] = useState("");
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [progressing, setProgressing] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(5);
    const [teamRole, setTeamRole] = useState(0);
    const [filterSearch, setFilterSearch] = useState("");
    const roleFilterOption = [
        {
            name: "All",
            id: 0
        },
        {
            name: "Limited User",
            id: CONSTANT.USER_ROLE_USER
        },
        {
            name: "Agency User",
            id: CONSTANT.USER_ROLE_AGENCY_USER
        },
        {
            name: "Agency Admin",
            id: CONSTANT.USER_ROLE_ADMIN
        }
    ];
    const headerButtons = [
        {
            name: "Add Team Member",
            color: "secondary",
            startIcon: <AddIcon />,
            action: () => setDrawerOpen(true)
        }
    ];
    const components = [
        {
            name: "Settings",
            link: "/Dashboard"
        },
        { name: "Team Management", link: "#" }
    ];

    // Data Table Start

    useEffect(() => {
        setProgressing(true);
        getTeamDataTableData();
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setData(teamData.data);
            setProgressing(false);
            setTotalPages(teamData.totalPages);
            setTotalItems(teamData.totalItems);
            setCurrentPage(teamData.currentPage);
        }
    }, [isSuccess]);

    const changePage = (currentRowsPerPage, currentPage) => {
        getTeamDataTableData(currentPage, currentRowsPerPage, teamRole, filterSearch);
        setItemPerPage(currentRowsPerPage);
    };

    // Get Data
    const getData = (currentPage) => {
        getTeamDataTableData(currentPage, itemPerPage, teamRole, filterSearch);
    };

    const handleFilter = async (searchKey) => {
        setFilterSearch(searchKey);
        getTeamDataTableData(currentPage, itemPerPage, teamRole, searchKey);
    };

    const handleRoleChange = (e) => {
        setTeamRole(e.target.value);
        getTeamDataTableData(currentPage, itemPerPage, e.target.value, filterSearch);
    };

    const roleFilter = () => {
        return (
            <Box sx={{ width: "200px" }}>
                <CustomSelectBox
                    sx={{
                        maxWidth: "200px",
                        height: "40px"
                    }}
                    name="Select Role"
                    label="Select Role"
                    options={roleFilterOption}
                    defaultValue={teamRole}
                    handleChange={handleRoleChange}
                    //
                    error=""
                />
            </Box>
        );
    };

    const handleDelete = (item) => {
        deleteTeamData(item.uuid, (response) => {
            if (response.status) {
                getTeamDataTableData();
                ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
            }
        });
        setIsDelete(false);
    };
    const renderActionOptions = (row) => {
        const options = [
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        <EditIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Edit
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    setDrawerOpenForEdit(true);
                    setUuid(row.uuid);
                }
            },
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        <DeleteIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Delete
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    setIsDelete(true);
                    setSelectedItem(row);
                }
            }
        ];
        if (row.role !== CONSTANT.USER_ROLE_ADMIN && row.role !== CONSTANT.USER_ROLE_AGENCY_USER) {
            options.push({
                name: (
                    <Box sx={{ display: "flex" }}>
                        <GroupAddIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Assign Clients
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    history.push(`/team/assign-client/${row.uuid}`);
                }
            });
        }
        return options;
    };

    const getRole = (role) => {
        if (role === CONSTANT.USER_ROLE_USER) {
            return "Limited User";
        }
        if (role === CONSTANT.USER_ROLE_AGENCY_USER) {
            return "Agency User";
        }
        if (role === CONSTANT.USER_ROLE_ADMIN) {
            return "Admin";
        }
        return "";
    };

    const column = [
        {
            name: "Name",
            selector: (row) => {
                return (
                    <Box
                        sx={{
                            minHeight: "74px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}
                    >
                        <Typography
                            component="div"
                            color="text.primary"
                            variant="body2"
                        >{`${row.first_name} ${row.last_name}`}</Typography>
                        <Typography component="div" color="action.active" variant="body2">
                            {getRole(row.role)}
                        </Typography>
                    </Box>
                );
            },
            sortable: true
        },
        {
            name: "Email",
            cell: (row) => row.email
        },
        {
            name: "Phone",
            cell: (row) => row.phone_number
        },

        {
            name: "Actions",
            selector: (row) => (
                <Box>
                    <CustomDropDownButtonMenu
                        buttonContent={<MoreVertIcon color="action.active" />}
                        options={renderActionOptions(row)}
                    />
                </Box>
            )
        }
    ];

    return (
        <StyledEngineProvider injectFirst>
            <Box className="team-wrapper">
                <Container
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingTop: "10px"
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ width: 1, mb: "30px" }}>
                                <CustomHeader
                                    components={components}
                                    headerText="Team Management"
                                    buttons={headerButtons}
                                    color="secondary"
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: 1,
                                    padding: "30px 16px",
                                    backgroundColor: "background.paper",
                                    boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                    borderRadius: "4px"
                                }}
                            >
                                {!progressing ? (
                                    <GlobalDataTable
                                        data={data}
                                        columns={column}
                                        subHeader={false}
                                        paginationServer
                                        paginationTotalRows={totalItems}
                                        progressPending={progressing}
                                        selectableRows={false}
                                        noHeader
                                        className="af-table"
                                        onChangePage={getData}
                                        onChangeRowsPerPage={changePage}
                                        handleFilter={handleFilter}
                                        noDataComponent={<p>No User Available</p>}
                                        otherFilter={roleFilter}
                                        filterName="Search Team member"
                                    />
                                ) : (
                                    <TableSkeleton />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <CustomDrawer
                customClass={drawerOpen ? "active" : ""}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                heading="Add Team Member"
            >
                <AddTeam drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </CustomDrawer>
            <CustomDrawer
                customClass={drawerOpenForEdit ? "active" : ""}
                drawerOpen={drawerOpenForEdit}
                setDrawerOpen={setDrawerOpenForEdit}
                heading="Edit Team Member"
            >
                <EditTeam
                    drawerOpen={drawerOpenForEdit}
                    setDrawerOpen={setDrawerOpenForEdit}
                    uuid={uuid}
                    setUuid={setUuid}
                />
            </CustomDrawer>

            <CustomConfirmModal
                title="Are you sure you want To remove this team memeber?"
                subtitle="If you delete this data, you’ll not be able to recover it again."
                disagreedButtonTitle="NO, KEEP IT"
                agreedButtonTitle="YES, DELETE IT"
                open={isDelete}
                setOpen={() => setIsDelete(false)}
                agreedButtonColor="warning"
                handleSubmit={() => handleDelete(selectedItem)}
                handleCancel={() => setIsDelete(false)}
            />
        </StyledEngineProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        teamData: state.teamReducer.tableData,
        isSuccess: state.teamReducer.isSuccess,
        team: state.teamReducer,
        userData: state.userProfileManageReducer.userProfileData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTeamDataTableData: (currentPage = 1, rowLimit = 10, teamRole = 0, searchKey = "") =>
            dispatch(
                getTeamDataTable({
                    currentPage,
                    rowLimit,
                    teamRole,
                    searchKey
                })
            ),
        deleteTeamData: (data, callback) => dispatch(deleteTeam(data, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
