import * as ACTION from "./holidayActionType";

const initialState = {
    isSuccess: false,
    loading: false,
    holidayTableData: []
};

const holidayReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.GET_HOLIDAY_LIST:
            return {
                ...state,
                holidayTableData: [],
                isSuccess: false,
                loading: true
            };
        case ACTION.GET_HOLIDAY_LIST_SUCCESS:
            return {
                ...state,
                holidayTableData: action.payload.data,
                isSuccess: true,
                loading: false
            };
        case ACTION.GET_HOLIDAY_LIST_FAILED:
            return {
                ...state,
                holidayTableData: [],
                isSuccess: false,
                loading: false
            };

        default:
            return state;
    }
};

export default holidayReducer;
