import { sendRequestWithToken } from "./rootApi";

export const getGeogridApiAuthListApi = async (payload) => {
    const { currentPage, itemPerPage, searchKey } = payload;
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/geo-grid-api-auth/list?page=${currentPage}&limit=${itemPerPage}&search_key=${searchKey}`
    );
};

export const getGeogridApiAuthApi = async (payload) => {
    return sendRequestWithToken(
        "get",
        `${process.env.REACT_APP_API_BASE_URL}/geo-grid-api-auth/${payload.id}`
    );
};

export const createGeogridApiAuthApi = async (payload) => {
    return sendRequestWithToken(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/geo-grid-api-auth`,
        payload
    );
};

export const updateGeogridApiAuthApi = async (payload) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/geo-grid-api-auth/update/${payload.id}`,
        payload
    );
};

export const geogridApiAuthStatusChangeApi = async (payload) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/geo-grid-api-auth/update/status/${payload.id}`,
        payload
    );
};
