import { createTheme } from "@mui/material/styles";
import breakpoints from "./breakpoints";
import { colorsDark, colorsLight } from "./colors";
import { typographyStyle } from "./fonts";
import muiButton from "./muiButton";
import tooltip from "./tooltips";

const customTheme = (mode) => {
    const dark = colorsDark();
    const light = colorsLight();
    const colors = mode === "dark" ? dark : light;
    return () =>
        createTheme({
            breakpoints: { ...breakpoints() },
            palette: {
                mode,
                ...colors
            },
            typography: {
                ...typographyStyle()
            },
            components: {
                MuiButton: {
                    ...muiButton()
                },
                MuiTextField: {
                    variants: [
                        {
                            props: {
                                color: "secondary"
                            },
                            style: {
                                "& .MuiOutlinedInput-root": {
                                    // "&:hover fieldset": {
                                    //     borderColor: colorsLight().primary.main, // - Set the Input border when parent has :hover
                                    // },
                                    "&.Mui-focused fieldset": {
                                        // - Set the Input border when parent is focused
                                        borderColor: colorsLight().secondary.main
                                    }
                                }
                            }
                        }
                    ]
                },

                MuiTooltip: {
                    ...tooltip()
                },

                MuiFormHelperText: {
                    styleOverrides: {
                        // Name of the slot
                        root: {
                            // Some CSS
                            fontSize: "0.75rem",
                            margin: "4px 0 0 0"
                        }
                    }
                }
            }
        });
};

export default customTheme;
