import * as ACTION from "./teamActionType";

export const getTeamDataTable = (payload) => ({
    type: ACTION.GET_TEAM_DATA_TABLE,
    payload
});

export const getAppFeatures = (payload, callback) => ({
    type: ACTION.GET_APP_FEATURES,
    payload,
    callback
});

export const addTeamMember = (payload, callback) => ({
    type: ACTION.ADD_TEAM_MEMBER,
    payload,
    callback
});
export const getTeam = (payload, callback) => ({
    type: ACTION.GET_TEAM,
    payload,
    callback
});

export const editTeamMember = (payload, callback) => ({
    type: ACTION.EDIT_TEAM_MEMBER,
    payload,
    callback
});
export const deleteTeam = (payload, callback) => ({
    type: ACTION.DELETE_TEAM,
    payload,
    callback
});

export const getClientListDataTable = (payload) => ({
    type: ACTION.GET_ASSIGNED_CLIENT_LIST,
    payload
});

export const assignClient = (payload, callback) => ({
    type: ACTION.ASSIGN_CLIENT,
    payload,
    callback
});
