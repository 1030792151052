import { CustomButton, CustomCsvUploader } from "@hipdevteam/afglobalcomponents";
import { Box, Grid, Typography } from "@mui/material";
import Papa from "papaparse";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import ShowAlert from "../../../helpers/ShowAlerts";
import {
    getHolidayListDataTable,
    uploadHolidayFile
} from "../../../statemanagement/holiday/holidayAction";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../utils/Constant/GlobalConstant";
import holidayYearFilterData from "../../../utils/Constant/holidayConstant";

const filterYears = holidayYearFilterData();

const UploadFile = (props) => {
    const { setDrawerOpen, uploadHolidayFile, getHolidayDataTableData } = props;
    const [csvFileName, setCsvFileName] = useState("");
    const [csvFile, setCSVFile] = useState(null);
    const [csvPath, setCSVPath] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingForUpload, setLoadingForUpload] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { handleSubmit } = useForm();

    const handleCSVFileUpload = () => {
        const holidaysData = [];
        if (csvFile.length < 1) {
            return false;
        }
        const fileType = csvFile[0].type;
        const validExtensions = ["text/csv", "text/plain"];
        if (validExtensions.includes(fileType)) {
            setLoadingForUpload(true);

            Papa.parse(csvFile[0], {
                header: true,
                skipEmptyLines: true,
                complete(results) {
                    const { length } = results.data;
                    for (let i = 1; i < length; i += 1) {
                        holidaysData.push(Object.values(results.data[i]));
                    }
                    // Upload file from here
                    uploadHolidayFile(holidaysData, (response) => {
                        if (response.status) {
                            setLoadingForUpload(false);
                            getHolidayDataTableData();
                            ShowAlert(response.message, ALERT_TYPE_SUCCESS);
                            setDrawerOpen(false);
                        } else {
                            ShowAlert(response.message, ALERT_TYPE_ERROR);
                            setTimeout(() => {
                                setLoading(false);
                            }, 1000);
                        }
                    });
                }
            });
        }
    };

    return (
        <Box
            sx={{
                width: 1,
                padding: "30px 16px",
                backgroundColor: "background.paper",
                boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                borderRadius: "4px"
            }}
        >
            <form onSubmit={handleSubmit(handleCSVFileUpload)}>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                margin: "0 0 1.5rem 0",
                                display: "contents",
                                justifyContent: "space-between"
                            }}
                        >
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                item
                                xs={12}
                            >
                                <Grid>
                                    <Typography
                                        variant="subtitle1"
                                        component="span"
                                        sx={{ paddingBottom: "20px" }}
                                        color="text.primary"
                                    >
                                        Select a File:
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <CustomCsvUploader
                            setCsvFileName={setCsvFileName}
                            csvFileName={csvFileName}
                            setCSVFile={setCSVFile}
                            setCSVPath={setCSVPath}
                            csvPath={csvPath}
                            loading={loadingForUpload}
                        />
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        display: "flex",
                        marginTop: "20px"
                    }}
                >
                    <CustomButton
                        sx={{ marginRight: "20px" }}
                        handleButton={() => setDrawerOpen(false)}
                        title={<Typography variant="buttonMedium">Cancel</Typography>}
                        variant="contained"
                        color="info"
                    />
                    <CustomButton
                        disabled={isSubmitted}
                        handleButton={null}
                        title={<Typography variant="buttonMedium">SAVE CHANGES</Typography>}
                        variant="contained"
                        type="submit"
                        color="secondary"
                    />
                </Box>
            </form>
        </Box>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        uploadHolidayFile: (data, callback) => dispatch(uploadHolidayFile(data, callback)),
        getHolidayDataTableData: (
            currentPage = 1,
            rowLimit = 10,
            searchKey = "",
            year = filterYears[3].id
        ) =>
            dispatch(
                getHolidayListDataTable({
                    currentPage,
                    rowLimit,
                    searchKey,
                    year
                })
            )
    };
};
export default connect(null, mapDispatchToProps)(UploadFile);
