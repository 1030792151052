import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    getWhiteLabelDomainApi,
    updateOrCreateWhiteLabelDomainApi
} from "../../api/whiteLabelDomainApi";
import * as ACTION from "./whiteDomainActionTypes";

function* getWhiteLabelDomainDataSaga(action) {
    try {
        const response = yield call(getWhiteLabelDomainApi);
        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.GET_WHITE_LABEL_DOMAIN_DATA_SUCCESS,
                payload: responseData
            });
            if (action.callback) {
                action.callback(responseData);
            }
        } else {
            yield put({
                type: ACTION.GET_WHITE_LABEL_DOMAIN_DATA_FAILED,
                payload: []
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_WHITE_LABEL_DOMAIN_DATA_FAILED,
            payload: []
        });
    }
}

function* updateOrCreateWhiteLabelDomainSaga(action) {
    try {
        const response = yield call(updateOrCreateWhiteLabelDomainApi, action.payload);
        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_SUCCESS,
                payload: responseData
            });
            if (action.callback) {
                action.callback(responseData);
            }
        } else {
            yield put({
                type: ACTION.UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_FAILED,
                payload: []
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_FAILED,
            payload: []
        });
    }
}
function* whiteLabelDomainWatcher() {
    yield takeEvery(ACTION.GET_WHITE_LABEL_DOMAIN_DATA, getWhiteLabelDomainDataSaga);
    yield takeEvery(ACTION.UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN, updateOrCreateWhiteLabelDomainSaga);
}
export default function* whiteLabelDomainSaga() {
    yield all([whiteLabelDomainWatcher()]);
}
