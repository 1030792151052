import { all, call, put, takeEvery } from "redux-saga/effects";
import { getAgencyDataApi, updateOrCreateApi } from "../../api/agencyApi";
import * as ACTION from "./AgencyActionType";

function* getAgencyDataSaga(action) {
    try {
        const response = yield call(getAgencyDataApi);
        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.GET_AGENCY_DATA_SUCCESS,
                payload: responseData
            });
            if (action.callback) {
                action.callback(responseData);
            }
        } else {
            yield put({
                type: ACTION.GET_AGENCY_DATA_FAILED,
                payload: []
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_AGENCY_DATA_FAILED,
            payload: []
        });
    }
}

function* updateOrCreateSaga(action) {
    try {
        const response = yield call(updateOrCreateApi, action.payload);
        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.GET_AGENCY_DATA_SUCCESS,
                payload: responseData
            });
            if (action.callback) {
                action.callback(responseData);
            }
        } else {
            yield put({
                type: ACTION.GET_AGENCY_DATA_FAILED,
                payload: []
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_AGENCY_DATA_FAILED,
            payload: []
        });
    }
}
function* agencyWatcher() {
    yield takeEvery(ACTION.GET_AGENCY_DATA, getAgencyDataSaga);
    yield takeEvery(ACTION.UPDATE_OR_CREATE, updateOrCreateSaga);
}
export default function* agencySaga() {
    yield all([agencyWatcher()]);
}
