import { sendRequest } from "./rootApi";

export const checkEmailExistApi = async (request) => {
    return sendRequest("post", `${process.env.REACT_APP_API_BASE_URL}/auth/email-exists`, request);
};

export const loginRequest = async (loginCredential) => {
    return sendRequest("post", `${process.env.REACT_APP_API_BASE_URL}/auth/login`, loginCredential);
};

export const googleLoginApi = async (loginCredential) => {
    return sendRequest(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/google-login`,
        loginCredential
    );
};

export const logoutRequest = async (data) => {
    return sendRequest("post", `${process.env.REACT_APP_API_BASE_URL}/auth/logout`, data);
};

export const googleSignInApi = async (loginCredential) => {
    return sendRequest(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/auth/google-user-signin`,
        loginCredential
    );
};
