import * as ACTION from "./whiteDomainActionTypes";

export const getWhiteLabelDomainData = (payload, callback) => ({
    type: ACTION.GET_WHITE_LABEL_DOMAIN_DATA,
    payload,
    callback
});
export const updateOrCreateWhiteLabelDomainData = (payload, callback) => ({
    type: ACTION.UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN,
    payload,
    callback
});
