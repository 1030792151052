export default function holidayYearFilterData() {
    const currentDate = new Date();
    let yearsForFilter = [];
    const currentYear = currentDate.getFullYear();

    const previousYear = currentYear - 1;
    const previousSecondYear = previousYear - 1;
    const previousThirdYear = previousSecondYear - 1;

    const nextYear = currentYear + 1;
    const nextSecondYear = nextYear + 1;
    const nextThirdYear = nextSecondYear + 1;

    yearsForFilter = [
        {
            name: previousThirdYear.toString(),
            id: previousThirdYear
        },
        {
            name: previousSecondYear.toString(),
            id: previousSecondYear
        },
        {
            name: previousYear.toString(),
            id: previousYear
        },
        {
            name: currentYear.toString(),
            id: currentYear
        },
        {
            name: nextYear.toString(),
            id: nextYear
        },
        {
            name: nextSecondYear.toString(),
            id: nextSecondYear
        },
        {
            name: nextThirdYear.toString(),
            id: nextThirdYear
        }
    ];

    return yearsForFilter;
}
