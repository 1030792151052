/* eslint-disable prefer-destructuring */
import {
    CustomButton,
    CustomIconButton,
    CustomImageUploader,
    CustomTextField
} from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import ShowAlerts from "../../../../helpers/ShowAlerts";
import { createApp } from "../../../../statemanagement/Admin/App/AdminAppAction";
import { uploadS3FileAction } from "../../../../statemanagement/s3FileUpload/s3FileUploadAction";
import * as CONSTANT from "../../../../utils/Constant";
import { bytesToMb } from "../../../../utils/Constant";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../../utils/Constant/GlobalConstant";

const schema = yup.object({
    app_name: yup.string().required("App Name required").max(255),
    app_identifier: yup.string().required("App Identifier required").max(255),
    app_description: yup.string().max(255),
    appFeatures: yup.array().of(
        yup.object({
            app_feature: yup.string()
        })
    )
});

const AppAdd = (props) => {
    const { setDrawerOpen, addApp, reloadData, uploadAppLogo } = props;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [lightLogoFile, setLightLogoFile] = useState(null);
    const [darkLogoFile, setDarkLogoFile] = useState(null);
    const [lightImagePath, setLightImagePath] = useState("");
    const [darkImagePath, setDarkImagePath] = useState("");
    const [isLightLogoDelete, setIsLightLogoDelete] = useState(false);
    const [isDarkLogoDelete, setIsDarkLogoDelete] = useState(false);
    const [loadingForLightLogoUpload, setLoadingForLightLogoUpload] = useState(false);
    const [loadingForDarkLogoUpload, setLoadingDarkLogoForUpload] = useState(false);

    const [appLightLogoFile, setAppLightLogoFile] = useState(null);
    const [appDarkLogoFile, setAppDarkLogoFile] = useState(null);
    const [appLightImagePath, setAppLightImagePath] = useState("");
    const [appDarkImagePath, setAppDarkImagePath] = useState("");
    const [isAppLightLogoDelete, setIsAppLightLogoDelete] = useState(false);
    const [isAppDarkLogoDelete, setIsAppDarkLogoDelete] = useState(false);
    const [loadingForAppLightLogoUpload, setLoadingForAppLightLogoUpload] = useState(false);
    const [loadingForAppDarkLogoUpload, setLoadingAppDarkLogoForUpload] = useState(false);
    const [appLogo, setAppLogo] = useState({
        logo: "",
        darkLogo: "",
        appDarkLogo: "",
        appLightLogo: ""
    });
    const LIGHT_LOGO = 1;
    const DARK_LOGO = 2;
    const APP_LIGHT_LOGO = 3;
    const APP_DARK_LOGO = 4;

    const defaultValues = {
        app_name: "",
        appFeatures: [{ app_feature: "" }],
        app_identifier: "",
        app_description: ""
    };

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors, setErrors },
        reset
    } = useForm({ defaultValues, resolver: yupResolver(schema) });

    const {
        fields: appFeaturesFields,
        append: appFeaturesAppend,
        remove: appFeaturesRemove
    } = useFieldArray({ control, name: "appFeatures" });

    const onSubmit = (data) => {
        data.app_light_logo = appLogo.appLightLogo;
        data.app_dark_logo = appLogo.appDarkLogo;
        data.logo = appLogo.logo;
        data.dark_logo = appLogo.darkLogo;
        setIsSubmitted(true);
        addApp(data, (res) => {
            setIsSubmitted(false);
            if (res.status) {
                reset({
                    app_name: "",
                    app_identifier: "",
                    app_description: "",
                    appFeatures: [{ app_feature: "" }]
                });
                setLightImagePath("");
                setLightLogoFile("");
                setIsLightLogoDelete(false);

                setDarkImagePath("");
                setDarkLogoFile("");
                setIsDarkLogoDelete(false);

                setAppLightImagePath("");
                setAppLightLogoFile("");
                setIsAppLightLogoDelete(false);

                setAppDarkImagePath("");
                setAppDarkLogoFile("");
                setIsAppLightLogoDelete(false);
                reloadData();
                setDrawerOpen(false);
                ShowAlerts(res.message, ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(res.message, ALERT_TYPE_ERROR);
            }
        });
    };

    const renderErrorMessage = (error, message) => {
        if (error !== undefined) {
            return (
                <Typography variant="subtitle2" component="span" color="error">
                    {message}
                </Typography>
            );
        }
        return "";
    };

    const customAddAppFeature = (index) => {
        const prevAppFeatures = watch("appFeatures");

        if (prevAppFeatures[index].app_feature === "") {
            return ShowAlerts("Please enter app feature", ALERT_TYPE_ERROR);
        }
        appFeaturesAppend(index);
    };

    const handleAppFeatureOnBlur = (index) => {
        const prevAppFeatures = watch("appFeatures");
        if (prevAppFeatures[index].app_feature === "") {
            return ShowAlerts("Please enter enter app feature", ALERT_TYPE_ERROR);
        }
    };

    const handleLogoUpload = (logoType) => {
        let checkSize = "";

        if (logoType === LIGHT_LOGO) {
            if (lightLogoFile === null) return false;
            checkSize = bytesToMb(lightLogoFile[0].size);

            if (lightLogoFile.length < 1) {
                return false;
            }
        } else if (logoType === DARK_LOGO) {
            if (darkLogoFile === null) return false;
            checkSize = bytesToMb(darkLogoFile[0].size);

            if (darkLogoFile.length < 1) {
                return false;
            }
        }

        if (logoType === APP_LIGHT_LOGO) {
            if (appLightLogoFile === null) return false;
            checkSize = bytesToMb(appLightLogoFile[0].size);

            if (appLightLogoFile.length < 1) {
                return false;
            }
        } else if (logoType === APP_DARK_LOGO) {
            if (appDarkLogoFile === null) return false;
            checkSize = bytesToMb(appDarkLogoFile[0].size);

            if (appDarkLogoFile.length < 1) {
                return false;
            }
        }

        // const fileType = logoType === LIGHT_LOGO ? lightLogoFile[0].type : darkLogoFile[0].type;
        let fileType;
        if (logoType === LIGHT_LOGO) {
            fileType = lightLogoFile[0].type;
        } else if (logoType === DARK_LOGO) {
            fileType = darkLogoFile[0].type;
        } else if (logoType === APP_LIGHT_LOGO) {
            fileType = appLightLogoFile[0].type;
        } else if (logoType === APP_DARK_LOGO) {
            fileType = appDarkLogoFile[0].type;
        }
        const validExtensions = ["image/jpeg", "image/jpg", "image/png"];

        if (validExtensions.includes(fileType) && checkSize < 2.5) {
            let file;
            // logoType === LIGHT_LOGO
            //     ? setLoadingForLightLogoUpload(true)
            //     : setLoadingDarkLogoForUpload(true);

            if (logoType === LIGHT_LOGO) {
                setLoadingForLightLogoUpload(true);
                file = lightLogoFile[0];
            } else if (logoType === DARK_LOGO) {
                setLoadingDarkLogoForUpload(true);
                file = darkLogoFile[0];
            } else if (logoType === APP_LIGHT_LOGO) {
                setLoadingForAppLightLogoUpload(true);
                file = appLightLogoFile[0];
            } else if (logoType === APP_DARK_LOGO) {
                setLoadingAppDarkLogoForUpload(true);
                file = appDarkLogoFile[0];
            }
            // const file = logoType === LIGHT_LOGO ? lightLogoFile[0] : darkLogoFile[0];
            const logoFile = new FormData();
            logoFile.append("file", file);
            uploadAppLogo(logoFile, (response) => {
                if (response.status && response.data.location) {
                    if (logoType === LIGHT_LOGO) {
                        setLoadingForLightLogoUpload(false);
                        setAppLogo((prevState) => ({ ...prevState, logo: response.data.location }));
                        ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                    } else if (logoType === DARK_LOGO) {
                        setLoadingDarkLogoForUpload(false);
                        setAppLogo((prevState) => ({
                            ...prevState,
                            darkLogo: response.data.location
                        }));
                        ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                    } else if (logoType === APP_LIGHT_LOGO) {
                        setLoadingForAppLightLogoUpload(false);
                        setAppLogo((prevState) => ({
                            ...prevState,
                            appLightLogo: response.data.location
                        }));
                        ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                    } else if (logoType === APP_DARK_LOGO) {
                        setLoadingAppDarkLogoForUpload(false);
                        setAppLogo((prevState) => ({
                            ...prevState,
                            appDarkLogo: response.data.location
                        }));
                        ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                    }
                }
            });
        }
    };

    const handleRemoveLogo = (logoType) => {
        if (logoType === LIGHT_LOGO) {
            setLightImagePath("");
            setLightLogoFile("");
            setIsLightLogoDelete(false);
        } else if (logoType === DARK_LOGO) {
            setDarkImagePath("");
            setDarkLogoFile("");
            setIsDarkLogoDelete(false);
        } else if (logoType === APP_LIGHT_LOGO) {
            setAppLightImagePath("");
            setAppLightLogoFile("");
            setIsAppLightLogoDelete(false);
        } else if (logoType === APP_DARK_LOGO) {
            setAppDarkImagePath("");
            setAppDarkLogoFile("");
            setIsAppDarkLogoDelete(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1.5}>
                <Grid item xs={6}>
                    <Controller
                        render={({ field }) => (
                            <CustomTextField
                                id="app_name"
                                type="text"
                                label="App Name"
                                register={field}
                                error={!!errors.app_name}
                                helperText={renderErrorMessage(
                                    errors.app_name,
                                    errors.app_name?.message
                                )}
                            />
                        )}
                        name="app_name"
                        control={control}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Controller
                        render={({ field }) => (
                            <CustomTextField
                                id="app_identifier"
                                type="text"
                                label="App Identifier"
                                register={field}
                                error={!!errors.app_identifier}
                                helperText={renderErrorMessage(
                                    errors.app_identifier,
                                    errors.app_identifier?.message
                                )}
                            />
                        )}
                        name="app_identifier"
                        control={control}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        render={({ field }) => (
                            <CustomTextField
                                rows={4}
                                multiline
                                id="app_description"
                                type="text"
                                label="Description"
                                register={field}
                                error={!!errors.app_description}
                                helperText={renderErrorMessage(
                                    errors.app_description,
                                    errors.app_description?.message
                                )}
                            />
                        )}
                        name="app_description"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        render={({ field }) => (
                            <CustomTextField
                                id="app_url"
                                type="text"
                                label="App Url"
                                register={field}
                                error={!!errors.app_url}
                                helperText={renderErrorMessage(
                                    errors.app_url,
                                    errors.app_url?.message
                                )}
                            />
                        )}
                        name="app_url"
                        control={control}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        component="span"
                        align="left"
                        color="text.primary"
                    >
                        App Features
                    </Typography>
                    {appFeaturesFields.map((item, index) => {
                        return (
                            <Grid key={item.id} item xs={12} sx={{ marginTop: "10px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: "10px",
                                        alignItems: "center",
                                        margin: "10px, 0"
                                    }}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <CustomTextField
                                                id="app_feature"
                                                type="text"
                                                label="App Feature"
                                                register={field}
                                                error={
                                                    errors.appFeatures &&
                                                    errors.appFeatures[index] &&
                                                    errors.appFeatures[index].app_feature
                                                }
                                                helperText={renderErrorMessage(
                                                    errors.appFeatures && errors.appFeatures[index]
                                                        ? errors.appFeatures[index]
                                                        : undefined,
                                                    errors.appFeatures && errors.appFeatures[index]
                                                        ? "App Feature Required"
                                                        : "App Feature Required"
                                                )}
                                                handleOnBlur={() => handleAppFeatureOnBlur(index)}
                                            />
                                        )}
                                        name={`appFeatures[${index}].app_feature`}
                                        control={control}
                                    />
                                    {appFeaturesFields.length - 1 === index && (
                                        <CustomIconButton
                                            sx={{ alignItems: "flex-start" }}
                                            handleButton={() => customAddAppFeature(index)}
                                            color="secondary"
                                            size="small"
                                        >
                                            <AddCircleOutlineRoundedIcon size="small" />
                                        </CustomIconButton>
                                    )}
                                    {appFeaturesFields.length !== 1 && (
                                        <CustomIconButton
                                            handleButton={() => appFeaturesRemove(index)}
                                            sx={{
                                                height: "2.5rem",
                                                width: "2.5rem",
                                                marginLeft: "1rem"
                                            }}
                                        >
                                            <DeleteIcon />
                                        </CustomIconButton>
                                    )}
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        component="span"
                        align="left"
                        color="text.primary"
                    >
                        Light Logo
                    </Typography>
                    <Box className="fileUploader">
                        <CustomImageUploader
                            title="Please Select Logo"
                            subtitle="The proposed size is 512*512px no bigger than 2.5mb"
                            setLogoFile={setLightLogoFile}
                            setImagePath={setLightImagePath}
                            imagePath={lightImagePath}
                            handleLogoUpload={() => handleLogoUpload(LIGHT_LOGO)}
                            setIsDelete={setIsLightLogoDelete}
                            isDelete={isLightLogoDelete}
                            handleRemoveLogo={() => handleRemoveLogo(LIGHT_LOGO)}
                            loading={loadingForLightLogoUpload}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        component="span"
                        align="left"
                        color="text.primary"
                    >
                        Dark Logo
                    </Typography>
                    <Box className="fileUploader">
                        <CustomImageUploader
                            title="Please Select Logo"
                            subtitle="The proposed size is 512*512px no bigger than 2.5mb"
                            setLogoFile={setDarkLogoFile}
                            setImagePath={setDarkImagePath}
                            imagePath={darkImagePath}
                            handleLogoUpload={() => handleLogoUpload(DARK_LOGO)}
                            setIsDelete={setIsDarkLogoDelete}
                            isDelete={isDarkLogoDelete}
                            handleRemoveLogo={() => handleRemoveLogo(DARK_LOGO)}
                            loading={loadingForDarkLogoUpload}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        component="span"
                        align="left"
                        color="text.primary"
                    >
                        App Light Logo
                    </Typography>
                    <Box className="fileUploader">
                        <CustomImageUploader
                            title="Please Select App Logo Light"
                            subtitle="The proposed size is 512*512px no bigger than 2.5mb"
                            setLogoFile={setAppLightLogoFile}
                            setImagePath={setAppLightImagePath}
                            imagePath={appLightImagePath}
                            handleLogoUpload={() => handleLogoUpload(APP_LIGHT_LOGO)}
                            setIsDelete={setIsAppLightLogoDelete}
                            isDelete={isAppLightLogoDelete}
                            handleRemoveLogo={() => handleRemoveLogo(APP_LIGHT_LOGO)}
                            loading={loadingForAppLightLogoUpload}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        component="span"
                        align="left"
                        color="text.primary"
                    >
                        App Dark Logo
                    </Typography>
                    <Box className="fileUploader">
                        <CustomImageUploader
                            title="Please Select App Logo Dark"
                            subtitle="The proposed size is 512*512px no bigger than 2.5mb"
                            setLogoFile={setAppDarkLogoFile}
                            setImagePath={setAppDarkImagePath}
                            imagePath={appDarkImagePath}
                            handleLogoUpload={() => handleLogoUpload(APP_DARK_LOGO)}
                            setIsDelete={setIsAppDarkLogoDelete}
                            isDelete={isAppDarkLogoDelete}
                            handleRemoveLogo={() => handleRemoveLogo(APP_DARK_LOGO)}
                            loading={loadingForAppDarkLogoUpload}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px"
                }}
            >
                <CustomButton
                    sx={{ marginRight: "20px" }}
                    handleButton={() => setDrawerOpen(false)}
                    title="Cancel"
                    variant="contained"
                    color="info"
                />
                <CustomButton
                    disabled={isSubmitted}
                    handleButton={null}
                    title="Save Changes"
                    variant="contained"
                    type="submit"
                    color="secondary"
                />
            </Box>
        </form>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        addApp: (params, callback) => dispatch(createApp(params, callback)),
        uploadAppLogo: (params, callback) => dispatch(uploadS3FileAction(params, callback))
    };
};

export default connect(null, mapDispatchToProps)(AppAdd);
