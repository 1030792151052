import { all, call, put, takeEvery } from "redux-saga/effects";
import { appSubscribeApi, appUnSubscribeApi, getAppLists } from "../../api/appApi";
import showAlerts from "../../helpers/ShowAlerts";
import * as CONSTANT from "../../utils/Constant";
import * as ACTION from "./appActionType";

function* getAppSaga(action) {
    try {
        const response = yield call(getAppLists, action.payload);
        const responseData = response.data;
        if (responseData.status) {
            yield put({ type: ACTION.GET_APP_SUCCESS, payload: responseData });
        } else {
            yield put({ type: ACTION.GET_APP_FAILED, payload: [] });
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}
function* appSubscribeSaga(action) {
    try {
        const response = yield call(appSubscribeApi, action.payload);
        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}
function* appUnSubscribeSaga(action) {
    try {
        const response = yield call(appUnSubscribeApi, action.payload);
        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}
function* watcher() {
    yield takeEvery(ACTION.GET_APPS, getAppSaga);
    yield takeEvery(ACTION.APP_SUBSCRIBE, appSubscribeSaga);
    yield takeEvery(ACTION.APP_UNSUBSCRIBE, appUnSubscribeSaga);
}

export default function* appSaga() {
    yield all([watcher()]);
}
