import { sendRequestWithMultipartFormData, sendRequestWithToken } from "./rootApi";

export const getAgencyDataApi = async () => {
    return sendRequestWithToken("get", `${process.env.REACT_APP_API_BASE_URL}/agency`);
};
export const updateOrCreateApi = async (data) => {
    return sendRequestWithToken("post", `${process.env.REACT_APP_API_BASE_URL}/agency`, data);
};

export const uploadAgencyLogoApi = async (data) => {
    return sendRequestWithMultipartFormData(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}/s3/file-upload`,
        data
    );
};
