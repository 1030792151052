import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    addTeamApi,
    assignClientApi,
    deleteTeamApi,
    editTeamApi,
    getAppFeatureApi,
    getClientListApi,
    getTeamApi,
    teamMemberDataTableApi
} from "../../api/teamApi";
import showAlerts from "../../helpers/ShowAlerts";
import * as CONSTANT from "../../utils/Constant";
import * as ACTION from "./teamActionType";

function* getAssignClientListSaga(action) {
    try {
        const response = yield call(getClientListApi, action.payload);

        const responseData = response.data;

        if (response.status) {
            yield put({
                type: ACTION.GET_ASSIGNED_CLIENT_LIST_SUCCESS,
                payload: responseData
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_TEAM_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* assignClientSaga(action) {
    try {
        const response = yield call(assignClientApi, action.payload);

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        if (action.callback) {
            action.callback(error);
        }
    }
}

function* getTeamDataTableSaga(action) {
    try {
        const response = yield call(teamMemberDataTableApi, action.payload);

        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.GET_TEAM_DATA_TABLE_SUCCESS,
                payload: responseData
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_TEAM_DATA_TABLE_FAILED,
            payload: []
        });
    }
}

function* getAppFeatureSaga(action) {
    try {
        const response = yield call(getAppFeatureApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* addTeamSaga(action) {
    try {
        const response = yield call(addTeamApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}

function* editTeamSaga(action) {
    try {
        const response = yield call(editTeamApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}

function* getTeamSaga(action) {
    try {
        const response = yield call(getTeamApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}

function* deleteTeamSaga(action) {
    try {
        const response = yield call(deleteTeamApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}

function* teamWatcher() {
    yield takeEvery(ACTION.GET_TEAM_DATA_TABLE, getTeamDataTableSaga);
    yield takeEvery(ACTION.GET_APP_FEATURES, getAppFeatureSaga);
    yield takeEvery(ACTION.ADD_TEAM_MEMBER, addTeamSaga);
    yield takeEvery(ACTION.GET_TEAM, getTeamSaga);
    yield takeEvery(ACTION.EDIT_TEAM_MEMBER, editTeamSaga);
    yield takeEvery(ACTION.DELETE_TEAM, deleteTeamSaga);
    yield takeEvery(ACTION.GET_ASSIGNED_CLIENT_LIST, getAssignClientListSaga);
    yield takeEvery(ACTION.ASSIGN_CLIENT, assignClientSaga);
}

export default function* teamSaga() {
    yield all([teamWatcher()]);
}
