import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    resetPasswordApi,
    sendForgetPasswordEmailApi,
    verifyForgetPasswordCodeApi
} from "../../api/forgotPasswordApi";
import * as ACTION from "./resetPasswordActionTypes";

function* sendForgotPasswordEmail(action) {
    try {
        const response = yield call(sendForgetPasswordEmailApi, action.payload);
        const responseData = response.data;

        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* verifyForgetPasswordCode(action) {
    try {
        const response = yield call(verifyForgetPasswordCodeApi, action.payload);
        const responseData = response.data;

        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* resetPassword(action) {
    try {
        const response = yield call(resetPasswordApi, action.payload);
        const responseData = response.data;

        yield put({
            type: ACTION.RESET_PASSWORD_SUCCESS,
            payload: {}
        });

        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* resetPasswordWatcher() {
    yield takeEvery(ACTION.SEND_EMAIL_FOR_PASSWORD_RESET, sendForgotPasswordEmail);
    yield takeEvery(ACTION.VERIFY_FORGET_PASSWORD_CODE, verifyForgetPasswordCode);
    yield takeEvery(ACTION.RESET_PASSWORD, resetPassword);
}

export default function* resetPasswordSaga() {
    yield all([resetPasswordWatcher()]);
}
