export const colorsLight = () => {
    return {
        primary: {
            light: "rgba(27, 20, 100, 1)",
            main: "#1B1464",
            dark: "rgba(27, 20, 100, 0.7)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #1B1464",
            outlinedHoverBackground: "rgba(37, 120, 255, 0.1)",
            outlinedRestingHover: "rgba(27, 20, 100, 0.5)"
        },
        secondary: {
            light: "rgba(37, 120, 255, 0.3)",
            main: "#2578FF",
            dark: "rgba(37, 120, 255, 0.7)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #2578FF",
            outlinedHoverBackground: "rgba(37, 120, 255, 0.08)",
            outlinedRestingHover: "rgba(37, 120, 255, 0.5)"
        },
        text: {
            primary: "#2A2E34",
            secondary: "rgba(42, 46, 52, 0.6)",
            disabled: "rgba(42, 46, 52, 0.38)",
            info: "#756AEA",
            fill: "#1B1464"
        },

        divider: "rgba(42, 46, 52, 0.38)",

        action: {
            active: "rgba(42, 46, 52, 0.54)",
            hover: "rgba(37, 120, 255, 0.05)",
            selected: "rgba(42, 46, 52, 0.08)",
            disabled: "rgba(42, 46, 52, 0.26)",
            disabledBackground: "rgba(42, 46, 52, 0.12)",
            focus: "rgba(42, 46, 52, 0.12)"
        },
        error: {
            light: "rgba(221, 60, 113, 0.3)",
            main: "#DD3C71",
            dark: "rgba(221, 60, 113, 0.7)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #DD3C71",
            outlinedHoverBackground: "rgba(221, 60, 113, 0.05)",
            outlinedRestingHover: "rgba(221, 60, 113, 0.5)",
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #DD3C71",
            content: "linear-gradient(0deg, rgba(42, 46, 52, 0.6), rgba(42, 46, 52, 0.6)), #DD3C71"
        },
        info: {
            light: "rgba(117, 106, 234, 0.3)",
            main: "#756AEA",
            dark: "rgba(117, 106, 234, 0.7)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #756AEA",
            outlinedHoverBackground: "rgba(117, 106, 234, 0.05)",
            outlinedRestingHover: "rgba(117, 106, 234, 0.5)",
            content: "linear-gradient(0deg, rgba(42, 46, 52, 0.6), rgba(42, 46, 52, 0.6)), #756AEA",
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #756AEA",
            accentColor: "#DD13FF"
        },
        success: {
            light: "rgba(62, 208, 68, 0.7)",
            main: "#3ED044",
            dark: "rgba(62, 208, 68, 0.3)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #3ED044",
            outlinedHoverBackground: "rgba(62, 208, 68, 0.5)",
            outlinedRestingHover: "rgba(62, 208, 68, 0.05)",
            content: "linear-gradient(0deg, rgba(42, 46, 52, 0.6), rgba(42, 46, 52, 0.6)), #3ED044",
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3ED044"
        },
        warning: {
            light: "rgba(255, 87, 20, 0.7)",
            main: "#FF5714",
            dark: "rgba(255, 87, 20, 0.3)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #FF5714",
            outlinedHoverBackground: "rgba(255, 87, 20, 0.05)",
            outlinedRestingHover: "rgba(255, 87, 20, 0.5)",
            content: "linear-gradient(0deg, rgba(42, 46, 52, 0.6), rgba(42, 46, 52, 0.6)), #FF5714",
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF5714"
        },
        background: {
            paper: "#FFFFFF",
            default: "#FAFAFA"
        },
        others: {
            divider: "rgba(42, 46, 52, 0.23)",
            outlinedBorder: "rgba(42, 46, 52, 0.12)",
            standardInputLine: "rgba(42, 46, 52, 0.42)",
            backdropOverlay: "rgba(42, 46, 52, 0.5)",
            ratingActive: "#FBBF24",
            snackbarBackground: "#2A2E34"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2
    };
};

export const colorsDark = () => {
    return {
        primary: {
            light: "rgba(27, 20, 100, 0.3)",
            main: "#1B1464",
            dark: "rgba(27, 20, 100, 0.7)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #1B1464",
            outlinedHoverBackground: "rgba(37, 120, 255, 0.1)",
            outlinedRestingHover: "rgba(27, 20, 100, 0.5)"
        },
        secondary: {
            light: "rgba(37, 120, 255, 0.3)",
            main: "#2578FF",
            dark: "rgba(37, 120, 255, 0.7)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #2578FF",
            outlinedHoverBackground: "rgba(37, 120, 255, 0.08)",
            outlinedRestingHover: "rgba(37, 120, 255, 0.5)"
        },
        text: {
            primary: "#FFFFFF",
            secondary: "rgba(255, 255, 255, 0.6)",
            disabled: "rgba(255, 255, 255, 0.38)",
            info: "#756AEA",
            fill: "#FFFFFF"
        },

        divider: "rgba(255, 255, 255, 0.23)",

        action: {
            active: "rgba(255, 255, 255, 0.54)",
            hover: "rgba(255, 255, 255, 0.05)",
            selected: "rgba(255, 255, 255, 0.08)",
            disabled: "rgba(255, 255, 255, 0.26)",
            disabledBackground: "rgba(255, 255, 255, 0.12)",
            focus: "rgba(255, 255, 255, 0.12)"
        },
        error: {
            light: "rgba(221, 60, 113, 0.3)",
            main: "#DD3C71",
            dark: "rgba(221, 60, 113, 0.7)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #DD3C71",
            outlinedHoverBackground: "rgba(221, 60, 113, 0.05)",
            outlinedRestingHover: "rgba(221, 60, 113, 0.5)",
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #DD3C71",
            content: "linear-gradient(0deg, rgba(42, 46, 52, 0.6), rgba(42, 46, 52, 0.6)), #DD3C71"
        },
        info: {
            light: "rgba(117, 106, 234, 0.3)",
            main: "#756AEA",
            dark: "rgba(117, 106, 234, 0.7)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #756AEA",
            outlinedHoverBackground: "rgba(117, 106, 234, 0.05)",
            outlinedRestingHover: "rgba(117, 106, 234, 0.5)",
            content: "linear-gradient(0deg, rgba(42, 46, 52, 0.6), rgba(42, 46, 52, 0.6)), #756AEA",
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #756AEA",
            accentColor: "#DD13FF"
        },
        success: {
            light: "rgba(62, 208, 68, 0.7)",
            main: "#3ED044",
            dark: "rgba(62, 208, 68, 0.3)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #3ED044",
            outlinedHoverBackground: "rgba(62, 208, 68, 0.5)",
            outlinedRestingHover: "rgba(62, 208, 68, 0.05)",
            content: "linear-gradient(0deg, rgba(42, 46, 52, 0.6), rgba(42, 46, 52, 0.6)), #3ED044",
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3ED044"
        },
        warning: {
            light: "rgba(255, 87, 20, 0.7)",
            main: "#FF5714",
            dark: "rgba(255, 87, 20, 0.3)",
            contrastText: "#FFFFFF",
            containedHoverBackground:
                "linear-gradient(0deg, rgba(42, 46, 52, 0.3), rgba(42, 46, 52, 0.3)), #FF5714",
            outlinedHoverBackground: "rgba(255, 87, 20, 0.05)",
            outlinedRestingHover: "rgba(255, 87, 20, 0.5)",
            content: "linear-gradient(0deg, rgba(42, 46, 52, 0.6), rgba(42, 46, 52, 0.6)), #FF5714",
            background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF5714"
        },
        background: {
            paper: "#2E3238",
            default: "#2A2E34"
        },
        others: {
            divider: "rgba(255, 255, 255, 0.23)",
            outlinedBorder: "rgba(255, 255, 255, 0.12)",
            standardInputLine: "rgba(255, 255, 255, 0.42)",
            backdropOverlay: "rgba(255, 255, 255, 0.5)",
            ratingActive: "#FBBF24",
            snackbarBackground: "#FFFFFF"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2
    };
};
