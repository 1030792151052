export const GET_AGENCIES_DATA_TABLE = "GET_AGENCIES_DATA_TABLE";
export const GET_AGENCIES_DATA_TABLE_SUCCESS = "GET_AGENCIES_DATA_TABLE_SUCCESS";
export const GET_AGENCIES_DATA_TABLE_FAILED = "GET_AGENCIES_DATA_TABLE_FAILED";

export const GET_AGENCY_USER_DATA_TABLE = "GET_AGENCY_USER_DATA_TABLE";
export const GET_AGENCY_USER_DATA_TABLE_SUCCESS = "GET_AGENCY_USER_DATA_TABLE_SUCCESS";
export const GET_AGENCY_USER_DATA_TABLE_FAILED = "GET_AGENCY_USER_DATA_TABLE_FAILED";

export const ADMIN_AS_USER_LOGIN_SUCCESS = "ADMIN_AS_USER_LOGIN_SUCCESS";
export const ADMIN_AS_USER_LOGIN_ATTEMPT = "ADMIN_AS_USER_LOGIN_ATTEMPT";
export const ADMIN_AS_USER_LOGIN_ERROR = "ADMIN_AS_USER_LOGIN_ERROR";
export const ADMIN_AS_ADMIN_LOGIN_ATTEMPT = "ADMIN_AS_ADMIN_LOGIN_ATTEMPT";

export const ADMIN__USER_STATUS_CHANGE = "ADMIN__USER_STATUS_CHANGE";
export const ADMIN__USER_PASSWORD_CHANGE = "ADMIN__USER_PASSWORD_CHANGE";

export const ADMIN__LOG_INTO_USER_ACCOUNT = "ADMIN__LOG_INTO_USER_ACCOUNT";
export const ADMIN__LOG_OUT_USER_ACCOUNT = "ADMIN__LOG_OUT_USER_ACCOUNT";

export const ADMIN__USER_ADMIN_MAKE = "ADMIN__USER_ADMIN_MAKE";

export const ADMIN__AGENCY_STATUS_CHANGE = "ADMIN__AGENCY_STATUS_CHANGE";

export const ADD_ADMIN_AGENCY_TEAM_MEMBER = "ADD_ADMIN_AGENCY_TEAM_MEMBER";
export const UPDATE_ADMIN_AGENCY_TEAM_MEMBER = "UPDATE_ADMIN_AGENCY_TEAM_MEMBER";
export const DELETE_ADMIN_AGENCY_TEAM_MEMBER = "DELETE_ADMIN_AGENCY_TEAM_MEMBER";
