import * as ACTION from "./whiteDomainActionTypes";

const initialState = {
    whiteLabelDomainData: [],
    isLoading: false,
    getSuccess: false,
    getData: false,
    savedData: false
};

const whiteLabelDomainReducer = (state = initialState, action) => {
    switch (action.type) {
        // GET WHITE LABEL DOMAIN INFO
        case ACTION.GET_WHITE_LABEL_DOMAIN_DATA:
            return { ...state, whiteLabelDomainData: [], isLoading: true, getSuccess: false };
        case ACTION.GET_WHITE_LABEL_DOMAIN_DATA_SUCCESS:
            return {
                ...state,
                whiteLabelDomainData: action.payload.data,
                isLoading: false,
                getData: true,
                getSuccess: false
            };
        case ACTION.GET_WHITE_LABEL_DOMAIN_DATA_FAILED:
            return {
                ...state,
                whiteLabelDomainData: null,
                isLoading: false,
                getData: false,
                getSuccess: false
            };
        // UPDATE or CREATE WHITE LABEL DOMAIN INFO
        case ACTION.UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_ATTEMPTED:
            return {
                ...state,
                whiteLabelDomainData: null,
                savedData: false
            };
        case ACTION.UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_SUCCESS:
            return {
                ...state,
                whiteLabelDomainData: action.payload.data,
                savedData: true
            };

        case ACTION.UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_FAILED:
            return {
                ...state,
                whiteLabelDomainData: action.payload.data,
                savedData: false
            };
        default:
            return state;
    }
};

export default whiteLabelDomainReducer;
