import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    bulkDeleteHolidaysApi,
    getHolidayListApi,
    updateHolidayStatusApi,
    uploadHolidayListApi
} from "../../api/holidayApi";
import showAlerts from "../../helpers/ShowAlerts";
import * as CONSTANT from "../../utils/Constant";
import * as ACTION from "./holidayActionType";

function* uploadHolidayFileSaga(action) {
    try {
        const response = yield call(uploadHolidayListApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}
function* updateHolidayStatusSaga(action) {
    try {
        const response = yield call(updateHolidayStatusApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}
function* getHolidayDataTableSaga(action) {
    try {
        const response = yield call(getHolidayListApi, action.payload);

        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.GET_HOLIDAY_LIST_SUCCESS,
                payload: responseData
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.GET_HOLIDAY_LIST_FAILED,
            payload: []
        });
    }
}
function* deleteHolidaysFromListSaga(action) {
    try {
        const response = yield call(bulkDeleteHolidaysApi, action.payload);
        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}
function* holidayWatcher() {
    yield takeLatest(ACTION.UPLOAD_HOLIDAY_FILE, uploadHolidayFileSaga);
    yield takeLatest(ACTION.UPDATE_HOLIDAY_STATUS, updateHolidayStatusSaga);
    yield takeLatest(ACTION.GET_HOLIDAY_LIST, getHolidayDataTableSaga);
    yield takeLatest(ACTION.BULK_DELETE_HOLIDAY, deleteHolidaysFromListSaga);
}

export default function* holidaySaga() {
    yield all([holidayWatcher()]);
}
