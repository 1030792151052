import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Toolbar from "@mui/material/Toolbar";
// import DarkLogo from "../../../assets/images/agencyLogodark.png";
// import LightLogo from "../../../assets/images/agencyLogoLight.png";
import {
    AgencyFrameworkDark,
    AgencyFrameworkLight
} from "../../../assets/images/logo/AgencyFrameworkLogo";
import "./topbar_custom.css";

const TopBar = ({ mobileOpen, setMobileOpen }) => {
    // Please assign your default style classes which are include in style file

    const customTheme = useTheme();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <StyledEngineProvider injectFirst>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    color="background"
                    position="fixed"
                    sx={{
                        width: "100%",

                        boxShadow: "0px 1px 0px rgba(42, 46, 52, 0.12)",
                        backgroundImage: "none"
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ display: "inline-flex" }}>
                            {customTheme.palette.mode == "dark"
                                ? // <img src={DarkLogo} alt="logo" />
                                  AgencyFrameworkDark
                                : // <img src={LightLogo} alt="logo" />
                                  AgencyFrameworkLight}
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        </StyledEngineProvider>
    );
};

export default TopBar;
