import * as ACTION from "./userProfileManageActionType";

const initialState = {
    userProfileData: null,
    userProfileDetailsFormError: [],
    isFormSuccess: false,
    userPasswordData: [],
    userPasswordChangeFormError: [],
    userProfileImageData: [],
    userImageChangeFormError: [],
    getUserSuccess: false,

    isProfileUpdateSuccess: false,
    isLoading: true,
    errorMessage: "",
    error: false
};

const userProfileManageReducer = (state = initialState, action) => {
    switch (action.type) {
        // GET PROFILE INFO
        case ACTION.USER_PROFILE_DATA_GET:
            return { ...state, userProfileData: [], getUserSuccess: false };
        case ACTION.FETCH_USER_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                userProfileData: action.payload.data,
                getUserSuccess: true
            };

        // UPE PROFILE INFO
        case ACTION.PROFILE_INFO_UPDATE_ATTEMPT:
            return {
                ...state,
                isProfileUpdateSuccess: false,
                isLoading: true,
                errorMessage: "",
                error: false
            };
        case ACTION.PROFILE_INFO_UPDATE_SUCCESS:
            return {
                ...state,
                userProfileData: action.payload.data
            };

        case ACTION.PROFILE_INFO_UPDATE_ERROR:
            return {
                ...state,
                isSignUpSuccess: false,
                isLoading: false,
                errorMessage: action.payload,
                error: true
            };

        // USER PROFILE PASSWORD CHANGE

        // USER PROFILE IMAGE CHANGE
        case ACTION.USER_PROFILE_IMAGE_CHANGE:
            return { ...state, userProfileImageData: action.payload };

        case ACTION.USER_PROFILE_IMAGE_CHANGE_SUCCESS:
            return { ...state, userProfileImageData: action.payload };

        case ACTION.USER_PROFILE_IMAGE_CHANGE_ERROR:
            return { ...state, userImageChangeFormError: action.payload };

        case ACTION.USER_PROFILE_IMAGE_CHANGE_ERROR_CLEAR:
            return { ...state, userImageChangeFormError: [] };

        default:
            return state;
    }
};

export default userProfileManageReducer;
