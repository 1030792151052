/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import EmailVerification from "../components/auth/EmailVerification";
import ForgetPasswordForm from "../components/auth/ForgetPasswordForm";
import Login from "../components/auth/Login";
import Registration from "../components/auth/Registration";
import NotFound from "../components/error-page/NotFound";
import AdminAgency from "../pages/Admin/Agencies/Agencies";
import AdminAgencyUsers from "../pages/Admin/Agencies/Users";
import AdminApps from "../pages/Admin/Apps/Apps";
import GeogridApi from "../pages/Admin/GeogridApi/GeogridApi";
import AdSourceSetting from "../pages/Admin/Ghl/AdSourceSetting/AdSourceSetting";
import DataTableTableColumns from "../pages/Admin/Ghl/AdSourceSetting/DataTableTableColumn";
import Holidays from "../pages/Admin/Holidays/Holidays";
import AdminUsers from "../pages/Admin/Users/Users";
import AgencySetting from "../pages/AgencySettings/AgencySettings";
import Dashboard from "../pages/Dashboard/Dashboard";
import GhlSettings from "../pages/GhlSettings/GhlSettings";
import MailgunAuthSetup from "../pages/MailgunSettings/MailgunAuthSetup";
import AssignClient from "../pages/Team/AssignClient";
import Team from "../pages/Team/Team";
import TwilioSetups from "../pages/TwillioSettings/TwilioSetups";
import UserSetting from "../pages/UserSettings/UserSetting";
import WhiteLabelDomain from "../pages/WhiteLabelDomain/WhiteLabelDomain";
import * as CONSTANT from "../utils/Constant";
import * as HELPER from "../utils/helperFunctions";
import AuthLayout from "./AuthLayoutRouter";
import ErrorLayoutRouter from "./ErrorLayoutRouter";
import PublicLayoutRouter from "./PublicLayoutRouter";
import UserLayoutRouter from "./UserLayoutRouter";

const Routes = (props) => {
    const [userRole, setUserRole] = useState(0);
    const [superAdmin, setSuperAdmin] = useState(CONSTANT.SUPER_ADMIN_NO);

    useEffect(() => {
        if (props.userProfileData && props.userProfileData.role !== undefined) {
            setUserRole(props.userProfileData.role);

            if (props.userProfileData.is_super_admin !== undefined) {
                setSuperAdmin(props.userProfileData.is_super_admin);
            }
        }
    }, [props.userProfileData]);

    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/dashboard" />
                </Route>
                <PublicLayoutRouter exact path="/login" component={Login} />
                <PublicLayoutRouter exact path="/registration" component={Registration} />
                <AuthLayout exact path="/user/email-verification" component={EmailVerification} />
                <PublicLayoutRouter exact path="/forgot-password" component={ForgetPasswordForm} />
                <UserLayoutRouter
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    pageTitle="Agency Framework"
                    appName={CONSTANT.APP_NAME_CORE}
                />
                <UserLayoutRouter
                    exact
                    path="/team"
                    component={Team}
                    pageTitle="Team"
                    appName={CONSTANT.APP_NAME_CORE}
                    haveAccess={HELPER.haveAdminAccess(userRole)}
                />
                <UserLayoutRouter
                    exact
                    path="/team/assign-client/:uuid"
                    component={AssignClient}
                    pageTitle="Team"
                    appName={CONSTANT.APP_NAME_CORE}
                    haveAccess={HELPER.haveAdminAccess(userRole)}
                />
                <UserLayoutRouter
                    exact
                    path="/agency/white-label-domain"
                    component={WhiteLabelDomain}
                    pageTitle="White Label Domain"
                    appName={CONSTANT.APP_NAME_CORE}
                    haveAccess={HELPER.haveAdminAccess(userRole)}
                />
                <UserLayoutRouter
                    exact
                    path="/settings"
                    component={UserSetting}
                    pageTitle="User Setting"
                    appName={CONSTANT.APP_NAME_CORE}
                />
                <UserLayoutRouter
                    exact
                    path="/agency/settings"
                    component={AgencySetting}
                    pageTitle="Agency Setting"
                    appName={CONSTANT.APP_NAME_CORE}
                    haveAccess={HELPER.haveAdminAccess(userRole)}
                />
                <UserLayoutRouter
                    exact
                    path="/auth/mailgun"
                    component={MailgunAuthSetup}
                    pageTitle="Mailgun Auth Setup"
                    appName={CONSTANT.APP_NAME_CORE_SETTING}
                    haveAccess={HELPER.haveAdminAccess(userRole)}
                />
                <UserLayoutRouter
                    exact
                    path="/auth/twilio"
                    component={TwilioSetups}
                    pageTitle="Twilio Auth Setup"
                    appName={CONSTANT.APP_NAME_CORE_SETTING}
                    haveAccess={HELPER.haveAdminAccess(userRole)}
                />
                <UserLayoutRouter
                    exact
                    path="/auth/ghl"
                    component={GhlSettings}
                    pageTitle="GHL Auth Setup"
                    appName={CONSTANT.APP_NAME_CORE_SETTING}
                    haveAccess={HELPER.haveAdminAccess(userRole)}
                />
                <UserLayoutRouter
                    exact
                    path="/admin/users"
                    component={AdminUsers}
                    pageTitle="Users List"
                    appName={CONSTANT.APP_NAME_ADMIN}
                    haveAccess={HELPER.haveSuperAdminAccess(superAdmin)}
                />
                <UserLayoutRouter
                    exact
                    path="/admin/agencies"
                    component={AdminAgency}
                    pageTitle="Agency List"
                    appName={CONSTANT.APP_NAME_ADMIN}
                    haveAccess={HELPER.haveSuperAdminAccess(superAdmin)}
                />
                <UserLayoutRouter
                    exact
                    path="/admin/:agencyId/users"
                    component={AdminAgencyUsers}
                    pageTitle="Agency Users"
                    appName={CONSTANT.APP_NAME_ADMIN}
                    haveAccess={HELPER.haveSuperAdminAccess(superAdmin)}
                />
                <UserLayoutRouter
                    exact
                    path="/admin/apps"
                    component={AdminApps}
                    pageTitle="Apps List"
                    appName={CONSTANT.APP_NAME_ADMIN}
                    haveAccess={HELPER.haveSuperAdminAccess(superAdmin)}
                />
                <UserLayoutRouter
                    exact
                    path="/admin/geogrid_api"
                    component={GeogridApi}
                    pageTitle="Geogrid Api List"
                    appName={CONSTANT.APP_NAME_ADMIN}
                    haveAccess={HELPER.haveSuperAdminAccess(superAdmin)}
                />
                <UserLayoutRouter
                    exact
                    path="/admin/holidays"
                    component={Holidays}
                    pageTitle="Holidays"
                    appName={CONSTANT.APP_NAME_ADMIN}
                    haveAccess={HELPER.haveSuperAdminAccess(superAdmin)}
                />
                <UserLayoutRouter
                    exact
                    path="/admin/ghl/add-source"
                    component={AdSourceSetting}
                    pageTitle="GHL Ad Source | Setting"
                    appName={CONSTANT.APP_NAME_ADMIN}
                    haveAccess={HELPER.haveSuperAdminAccess(superAdmin)}
                />
                <UserLayoutRouter
                    exact
                    path="/admin/ghl/add-source/table/:tableId"
                    component={DataTableTableColumns}
                    pageTitle="GHL Ad Source | Setting"
                    appName={CONSTANT.APP_NAME_ADMIN}
                    haveAccess={HELPER.haveSuperAdminAccess(superAdmin)}
                />
                <ErrorLayoutRouter
                    path="*"
                    exact
                    component={NotFound}
                    pageTitle="404 Not Found"
                    appName=""
                />
            </Switch>
        </Router>
    );
};
const mapStateToProps = (state) => {
    return {
        userProfileData: state.userProfileManageReducer.userProfileData
    };
};

export default connect(mapStateToProps, null)(Routes);
