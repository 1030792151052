import * as ACTION from "./holidayActionType";

export const uploadHolidayFile = (payload, callback) => ({
    type: ACTION.UPLOAD_HOLIDAY_FILE,
    payload,
    callback
});

export const updateHolidayStatus = (payload, callback) => ({
    type: ACTION.UPDATE_HOLIDAY_STATUS,
    payload,
    callback
});

export const getHolidayListDataTable = (payload) => ({
    type: ACTION.GET_HOLIDAY_LIST,
    payload
});

export const bulkDeleteHolidaysAction = (payload, callback) => ({
    type: ACTION.BULK_DELETE_HOLIDAY,
    payload,
    callback
});
