import { CustomButton } from "@hipdevteam/afglobalcomponents";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { makeSignUpSuccessFalse } from "../../statemanagement/signup/signupAction";
import Congrasimg from "./images/congras.png";
import "./style/auth.css";

const style = {
    "&::before": {
        position: "absolute",
        content: '" "',
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        background: "rgba(27, 20, 100, 0.85)"
    }
};

const Congratulation = (props) => {
    const { makeSignUpSuccessFalseStatus } = props;
    const history = useHistory();
    const theme = useTheme();

    const handleGoToDashBoard = (e) => {
        e.preventDefault();

        makeSignUpSuccessFalseStatus();
        history.push("/dashboard");
    };

    return (
        <Box sx={style} className="auth__wrapper">
            <Box className="auth__inner">
                <Box className="auth__inner__container">
                    <Box className="auth__congratulation__wr">
                        <Box className="auth__inner__top">
                            <Typography
                                className="extra__type__heading"
                                color={theme.palette.primary.main}
                                variant="h4"
                                component="h4"
                            >
                                Congratulation!
                            </Typography>
                            <Typography
                                className="congras__type__para"
                                color={theme.palette.text.primary}
                                variant="body2"
                                component="p"
                            >
                                Your Account Is Created Successfully
                            </Typography>
                            <Box className="congras__img__wr">
                                <img src={Congrasimg} alt="congrats" />
                            </Box>
                        </Box>
                        <Box className="auth__form__wr">
                            <CustomButton
                                variant="contained"
                                color="primary"
                                title="Go to Dashboard"
                                size="large"
                                type="submit"
                                handleButton={handleGoToDashBoard}
                                customClass="auth__sign__in__btn"
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="auth__footer">
                <Typography
                    className="auth__footer__copy__right"
                    color={theme.palette.primary.contrastText}
                    variant="overline"
                    component="p"
                >
                    @ {new Date().getFullYear()} Agency Framework - All Rights Reserved
                </Typography>

                <Box className="footer__link">
                    <Link to="/forgot-password">
                        <Typography
                            color={theme.palette.primary.contrastText}
                            variant="overline"
                            component="span"
                        >
                            Privacy Policy
                        </Typography>
                    </Link>
                    <Link to="/forgot-password">
                        <Typography
                            color={theme.palette.primary.contrastText}
                            variant="overline"
                            component="span"
                        >
                            terms and conditions
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        makeSignUpSuccessFalseStatus: () => dispatch(makeSignUpSuccessFalse())
    };
};

export default connect(null, mapDispatchToProps)(Congratulation);
