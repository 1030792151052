import {
    CustomBadge,
    CustomButton,
    CustomDrawer,
    CustomDropDownButtonMenu,
    CustomTooltip,
    DataTablePro
} from "@hipdevteam/afglobalcomponents";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalHeader from "../../../components/local/LocalHeader/LocalHeader";
import TableSkeleton from "../../../components/SkeletonLoader/Table/TableSkeleton";
import ShowAlerts from "../../../helpers/ShowAlerts";
import {
    changeAppStatus,
    getAppsDataTable
} from "../../../statemanagement/Admin/App/AdminAppAction";
import { APP_STATUS_ACTIVE } from "../../../utils/Constant";
import {
    ALERT_TYPE_ERROR,
    ALERT_TYPE_SUCCESS,
    APP_STATUS__INACTIVE
} from "../../../utils/Constant/GlobalConstant";
import AppAdd from "./forms/AppAdd";
import AppEdit from "./forms/AppEdit";

const Apps = (props) => {
    const { getAppData, apps, updateAppStatus } = props;

    const [data, setData] = useState([]);
    const [progressing, setProgressing] = useState(true);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [filterSearch, setFilterSearch] = useState("");
    const [selectAppId, setSelectedAppId] = useState("");
    const [drawerOpenForAppAdd, setDrawerOpenForAppAdd] = useState(false);
    const [drawerOpenForAppEdit, setDrawerOpenForAppEdit] = useState(false);

    useEffect(() => {
        getAppData(currentPage, itemPerPage, filterSearch);
    }, []);

    useEffect(() => {
        if (apps.isSuccess) {
            setData(apps.tableData.data);
            setProgressing(false);
            setLoading(false);
            setTotalItems(apps.tableData.totalItems);
            setCurrentPage(apps.tableData.currentPage);
        }
    }, [apps.isSuccess]);

    const changePage = (pageSize) => {
        setProgressing(true);
        setCurrentPage(1);
        getAppData(1, pageSize, filterSearch);
        setItemPerPage(pageSize);
    };

    const getData = (currentNewPage) => {
        setProgressing(true);
        getAppData(currentNewPage + 1, itemPerPage, filterSearch);
        setCurrentPage(currentNewPage + 1);
    };

    const handleFilter = (searchKey) => {
        setFilterSearch(searchKey);
        setProgressing(true);
        getAppData(currentPage, itemPerPage, searchKey);
    };

    const handleAppEdit = (appId) => {
        setSelectedAppId(appId);
        setDrawerOpenForAppEdit(true);
    };

    const handleAppStatusChange = (appId, appStatus) => {
        updateAppStatus({ app_id: appId, app_status: appStatus }, (response) => {
            if (response.status) {
                getAppData(currentPage, itemPerPage, filterSearch);
                ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
            }
        });
    };

    const renderActionOptions = (row) => {
        return [
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        <EditIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Edit
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    handleAppEdit(row.id);
                }
            },
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        {row.status === APP_STATUS_ACTIVE ? (
                            <PlayArrowIcon
                                sx={{
                                    color: "action.active",
                                    paddingRight: "10px",
                                    width: "2rem !important"
                                }}
                            />
                        ) : (
                            <PauseIcon
                                sx={{
                                    color: "action.active",
                                    paddingRight: "10px",
                                    width: "2rem !important"
                                }}
                            />
                        )}

                        <Typography variant="body1" color="text.primary">
                            {row.status === APP_STATUS_ACTIVE ? "Inactive" : "Active"}
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    const status =
                        row.status === APP_STATUS_ACTIVE ? APP_STATUS__INACTIVE : APP_STATUS_ACTIVE;
                    handleAppStatusChange(row.id, status);
                }
            }
        ];
    };

    const columns = [
        {
            field: "name",
            flex: 4,
            headerName: (
                <Typography variant="tableHeader" color="text.primary">
                    App Name
                </Typography>
            ),
            width: 300,
            editable: false,
            renderCell: (params) => {
                return (
                    <CustomTooltip title={params.value} placement="top">
                        <Typography variant="body2">{params.value}</Typography>
                    </CustomTooltip>
                );
            }
        },
        {
            field: "logo",
            headerName: (
                <Typography variant="tableHeader" color="text.primary">
                    Logo
                </Typography>
            ),
            flex: 3,
            editable: false,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "120px"
                        }}
                    >
                        {params.value ? (
                            <img
                                src={params.value}
                                alt="Logo"
                                height="40px"
                                width="40px"
                                style={{
                                    borderRadius: "6px"
                                }}
                            />
                        ) : (
                            <CustomBadge label="No Logo" color="info" />
                        )}
                    </Box>
                );
            }
        },
        {
            field: "dark_logo",
            flex: 3,
            headerName: (
                <Typography variant="tableHeader" color="text.primary">
                    Dark Logo
                </Typography>
            ),
            width: 200,
            editable: false,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "120px"
                        }}
                    >
                        {params.value ? (
                            <img
                                src={params.value}
                                alt="Logo"
                                height="40px"
                                width="40px"
                                style={{
                                    borderRadius: "6px"
                                }}
                            />
                        ) : (
                            <CustomBadge label="No Logo" color="info" />
                        )}
                    </Box>
                );
            }
        },
        {
            field: "status",
            headerName: (
                <Typography variant="tableHeader" color="text.primary">
                    Status
                </Typography>
            ),
            flex: 1,
            editable: false,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "120px"
                        }}
                    >
                        {params.value === APP_STATUS_ACTIVE ? (
                            <CustomBadge color="success" label="Active" />
                        ) : (
                            <CustomBadge color="warning" label="Inactive" />
                        )}
                    </Box>
                );
            }
        },
        {
            field: "actions",
            flex: 1,
            headerName: "Actions",
            editable: false,
            renderCell: (params) => {
                return (
                    <CustomDropDownButtonMenu
                        buttonContent={<MoreVertIcon color="action.active" />}
                        disabledStatus={false}
                        options={renderActionOptions(params.row)}
                    />
                );
            }
        }
    ];

    const components = [
        {
            name: "Dashboard",
            link: "/Dashboard"
        },
        {
            name: "Admin",
            link: "/admin/agencies"
        },
        { name: "App List", link: "#" }
    ];

    const localHeaderButtons = () => {
        return (
            <CustomButton
                title={<Typography variant="buttonLarge">Add New App</Typography>}
                handleButton={() => setDrawerOpenForAppAdd(true)}
                color="secondary"
                variant="contained"
                startIcon={<AddIcon />}
                size="large"
            />
        );
    };

    return (
        <Box>
            <Container
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: "10px"
                    // height: "100vh",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box sx={{ width: 1, mb: "30px" }}>
                            <LocalHeader
                                components={components}
                                headerText="App List"
                                buttons={localHeaderButtons}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: 1,
                                padding: "30px 16px",
                                backgroundColor: "background.paper",
                                boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                borderRadius: "4px"
                            }}
                        >
                            {!loading ? (
                                <DataTablePro
                                    tableId="admin_apps_data_table"
                                    rows={data}
                                    autoHeight={false}
                                    sx={{
                                        height: "680px"
                                    }}
                                    columns={columns}
                                    loading={progressing}
                                    pagination
                                    rowsPerPageOptions={[5, 10, 15]}
                                    pageSize={itemPerPage}
                                    rowCount={totalItems}
                                    onPageChange={getData}
                                    onPageSizeChange={changePage}
                                    searchStatus
                                    serachText="Search By Name"
                                    handleFilter={handleFilter}
                                />
                            ) : (
                                <TableSkeleton />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <CustomDrawer
                drawerOpen={drawerOpenForAppAdd}
                setDrawerOpen={setDrawerOpenForAppAdd}
                customClass={drawerOpenForAppAdd ? "active" : ""}
                heading="Add App"
            >
                <AppAdd
                    setDrawerOpen={setDrawerOpenForAppAdd}
                    isDrawerOpen={drawerOpenForAppAdd}
                    reloadData={() => getAppData(currentPage, itemPerPage, filterSearch)}
                />
            </CustomDrawer>
            <CustomDrawer
                drawerOpen={drawerOpenForAppEdit}
                setDrawerOpen={setDrawerOpenForAppEdit}
                customClass={drawerOpenForAppEdit ? "active" : ""}
                heading="Edit App"
            >
                <AppEdit
                    setDrawerOpen={setDrawerOpenForAppEdit}
                    isDrawerOpen={drawerOpenForAppEdit}
                    reloadData={() => getAppData(currentPage, itemPerPage, filterSearch)}
                    appId={selectAppId}
                />
            </CustomDrawer>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        apps: state.adminAppReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAppData: (currentPage = 1, itemPerPage = 10, searchKey = "") =>
            dispatch(
                getAppsDataTable({
                    currentPage,
                    itemPerPage,
                    searchKey
                })
            ),
        updateAppStatus: (params, callback) => dispatch(changeAppStatus(params, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Apps);
