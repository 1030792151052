/* eslint-disable prefer-destructuring */
import { CustomButton, CustomSelectBox, CustomTextField } from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import ShowAlerts from "../../../../../helpers/ShowAlerts";
import { addGhlAdSourceTable } from "../../../../../statemanagement/Admin/Ghl/GhlAction";
import {
    ghlAdSourceTypeOptions,
    GHL_AD_SOURCE_TABLE_STATUS_ACTIVE,
    GHL_AD_SOURCE_TABLE_SYSTEM_DB_YES,
    GHL_AD_SOURCE_TABLE_TYPE_FACEBOOK
} from "../../../../../utils/Constant/GhlConstant";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../../../utils/Constant/GlobalConstant";

const schema = yup.object({
    type: yup.number().required("Type required"),
    table_name: yup.string().required("Table name required").max(255)
});

const AdSourceTableAdd = (props) => {
    const { setDrawerOpen, addAdSourceTable } = props;
    const [isSubmitted, setIsSubmitted] = useState(false);

    const defaultValues = {
        type: GHL_AD_SOURCE_TABLE_TYPE_FACEBOOK,
        table_name: ""
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({ defaultValues, resolver: yupResolver(schema) });

    const onSubmit = (data) => {
        data.status = GHL_AD_SOURCE_TABLE_STATUS_ACTIVE;
        data.is_system_db = GHL_AD_SOURCE_TABLE_SYSTEM_DB_YES;
        setIsSubmitted(true);
        addAdSourceTable(
            {
                table_data: data
            },
            (response) => {
                if (response.status) {
                    ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
                    setDrawerOpen(false);
                    reset({
                        table_name: "",
                        type: GHL_AD_SOURCE_TABLE_TYPE_FACEBOOK
                    });
                } else {
                    ShowAlerts(response.message, ALERT_TYPE_ERROR);
                }
                setIsSubmitted(false);
            }
        );
    };

    const renderErrorMessage = (error, message) => {
        if (error !== undefined) {
            return (
                <Typography variant="subtitle2" component="span" color="error">
                    {message}
                </Typography>
            );
        }
        return "";
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <Controller
                        render={({ field }) => (
                            <CustomTextField
                                id="table_name"
                                type="text"
                                label="Table Name"
                                register={field}
                                error={!!errors.table_name}
                                helperText={renderErrorMessage(
                                    errors.table_name,
                                    errors.table_name?.message
                                )}
                            />
                        )}
                        name="table_name"
                        control={control}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        render={({ field }) => (
                            <CustomSelectBox
                                options={ghlAdSourceTypeOptions}
                                color="secondary"
                                register={field}
                                error={errors?.type}
                                label="Type"
                            />
                        )}
                        control={control}
                        name="type"
                    />

                    <Typography component="p">
                        {renderErrorMessage(errors?.type, errors?.type?.message)}
                    </Typography>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px"
                }}
            >
                <CustomButton
                    sx={{ marginRight: "20px" }}
                    handleButton={() => setDrawerOpen(false)}
                    title="Cancel"
                    variant="contained"
                    color="info"
                />
                <CustomButton
                    disabled={isSubmitted}
                    handleButton={null}
                    title="Add Table"
                    variant="contained"
                    type="submit"
                    color="secondary"
                />
            </Box>
        </form>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAdSourceTable: (params, callback) => dispatch(addGhlAdSourceTable(params, callback))
    };
};

export default connect(null, mapDispatchToProps)(AdSourceTableAdd);
