/* eslint-disable prefer-destructuring */
import {
    CustomButton,
    CustomSelectBox,
    CustomSwitch,
    CustomTextField
} from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import ShowAlerts from "../../../../../helpers/ShowAlerts";
import { addGhlAdSourceTableColumn } from "../../../../../statemanagement/Admin/Ghl/GhlAction";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../../../utils/Constant/GlobalConstant";

const schema = yup.object({
    column_name: yup.string().required("Column name required").max(255)
});

const AdSourceColumnAdd = (props) => {
    const { setDrawerOpen, addAdSourceColumn, tableId, reloadData } = props;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [arithmeticChecked, setArithmeticChecked] = useState(false);
    const [accountIdColumnChecked, setAccountIdColumnChecked] = useState(false);
    const [accountNameChecked, setAccountNameChecked] = useState(false);
    const [columnType, setColumnType] = useState(1);

    const defaultValues = {
        column_name: ""
    };
    const dataTypes = [
        { id: 1, name: "NUMERIC" },
        { id: 2, name: "STRING" },
        { id: 3, name: "DATE" }
    ];

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({ defaultValues, resolver: yupResolver(schema) });

    const onSubmit = (data) => {
        setIsSubmitted(true);
        const tempData = {
            ...data,
            table_id: tableId,
            arithmetic_operation: 1,
            is_account_id_column: 0,
            is_account_name_column: 0,
            type: columnType
        };

        if (arithmeticChecked) {
            tempData.arithmetic_operation = 1;
        }
        if (accountIdColumnChecked) {
            tempData.is_account_id_column = 1;
        }

        if (accountNameChecked) {
            tempData.is_account_name_column = 1;
        }

        addAdSourceColumn(
            {
                columnData: tempData
            },
            (response) => {
                if (response.status) {
                    reloadData();
                    setDrawerOpen(false);
                    reset({
                        column_name: ""
                    });
                    ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
                } else {
                    ShowAlerts(response.message, ALERT_TYPE_ERROR);
                }
            }
        );
    };

    const renderErrorMessage = (error, message) => {
        if (error !== undefined) {
            return (
                <Typography variant="subtitle2" component="span" color="error">
                    {message}
                </Typography>
            );
        }
        return "";
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <Controller
                        render={({ field }) => (
                            <CustomTextField
                                id="column_name"
                                type="text"
                                label="Column Name "
                                register={field}
                                error={!!errors.column_name}
                                helperText={renderErrorMessage(
                                    errors.column_name,
                                    errors.column_name?.message
                                )}
                            />
                        )}
                        name="column_name"
                        control={control}
                    />
                </Grid>

                <Grid item xs={4}>
                    <CustomSwitch
                        label="Arithmetic Operation: "
                        size="small"
                        handleChange={() => setArithmeticChecked(!arithmeticChecked)}
                        checked={arithmeticChecked}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomSwitch
                        label="Account Id Column: "
                        size="small"
                        handleChange={() => setAccountIdColumnChecked(!accountIdColumnChecked)}
                        checked={accountIdColumnChecked}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomSwitch
                        label="Account Name Column: "
                        size="small"
                        handleChange={() => setAccountNameChecked(!accountNameChecked)}
                        checked={accountNameChecked}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomSelectBox
                        sx={{
                            maxWidth: "200px",
                            height: "40px"
                        }}
                        name="Column Type"
                        options={dataTypes}
                        defaultValue={columnType}
                        value={columnType}
                        handleChange={(e) => setColumnType(Number(e.target.value))}
                        label="Column Type"
                    />
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px"
                }}
            >
                <CustomButton
                    sx={{ marginRight: "20px" }}
                    handleButton={() => setDrawerOpen(false)}
                    title="Cancel"
                    variant="contained"
                    color="info"
                />
                <CustomButton
                    disabled={isSubmitted}
                    handleButton={null}
                    title="Save Changes"
                    variant="contained"
                    type="submit"
                    color="secondary"
                />
            </Box>
        </form>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAdSourceColumn: (params, callback) =>
            dispatch(addGhlAdSourceTableColumn(params, callback))
    };
};

export default connect(null, mapDispatchToProps)(AdSourceColumnAdd);
