import * as ACTION from "./resetPasswordActionTypes";

const initialState = {
    isResetPasswordSuccess: false
};

const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isResetPasswordSuccess: true
            };
        default:
            return state;
    }
};

export default resetPasswordReducer;
