import {
    CustomButton,
    CustomHeader,
    CustomImageUploader,
    CustomTextField,
    InputPhoneNumber
} from "@hipdevteam/afglobalcomponents";
import { Box, Grid, StyledEngineProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import FileUploadSkeleton from "../../components/SkeletonLoader/AgencySettings/FileUploadSkeleton";
import ShowAlerts from "../../helpers/ShowAlerts";
import { getAgencyData, updateOrCreateData } from "../../statemanagement/agency/AgencyAction";
import { uploadS3FileAction } from "../../statemanagement/s3FileUpload/s3FileUploadAction";
import * as CONSTANT from "../../utils/Constant";
import "./custom.style.css";

const AgencySettings = (props) => {
    const { getAgencyData, agency, updateOrCreateData, uploadAgencyLogo } = props;
    const [isDelete, setIsDelete] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [logoFileName, setLogoFileName] = useState(null);
    const [number, setNumber] = useState("");
    const [imagePath, setImagePath] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingForUpload, setLoadingForUpload] = useState(false);

    const { register, handleSubmit } = useForm();

    useEffect(() => {
        getAgencyData(null, (response) => {
            console.log(response);
        });
    }, []);

    useEffect(() => {
        if (agency.getData) {
            setNumber(
                typeof agency.agencyData.agency_phone !== "undefined" &&
                    agency.agencyData.agency_phone !== null
                    ? agency.agencyData.agency_phone
                    : ""
            );
            setImagePath(
                typeof agency.agencyData.agency_logo !== "undefined" &&
                    agency.agencyData.agency_logo !== null
                    ? agency.agencyData.agency_logo
                    : ""
            );

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [agency.getData]);

    const components = [
        {
            name: "Settings",
            link: "#",
            icon: null
        },
        {
            name: "Agency Settings",
            link: "#",
            icon: null
        }
    ];

    const handleLogoUpload = () => {
        if (!logoFile) return;

        const fileName = CONSTANT.getRandomFileName();
        const checkSize = CONSTANT.bytesToMb(logoFile[0].size);

        if (logoFile.length < 1) {
            return;
        }
        const fileType = logoFile[0].type;
        const validExtensions = ["image/jpeg", "image/jpg", "image/png"];
        if (validExtensions.includes(fileType) && checkSize < 2.5) {
            setLoadingForUpload(true);
            setLogoFileName(fileName);
            const file = new FormData();
            file.append("file", logoFile[0]);
            uploadAgencyLogo(file, (response) => {
                if (response.status && response.data.location) {
                    const agencyData = {
                        agency_logo: response.data.location
                    };
                    updateOrCreateData(agencyData, (response) => {
                        if (response.status) {
                            setImagePath(response.data.location);
                            setLoadingForUpload(false);
                            ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                        } else {
                            setTimeout(() => {
                                setLoading(false);
                            }, 1000);
                        }
                    });

                    ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                } else {
                    ShowAlerts(response.message, CONSTANT.ALERT_TYPE_ERROR);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            });
        } else {
            return false;
        }
    };
    const handleRemoveLogo = () => {
        setImagePath("");
        setLogoFile(null);
        setLogoFileName("");
        setIsDelete(false);
        if (
            typeof agency.agencyData.agency_logo !== "undefined" &&
            agency.agencyData.agency_logo !== null &&
            agency.agencyData.agency_logo
        ) {
            updateOrCreateData({ agency_logo: "" }, (response) => {
                if (response.status) {
                    ShowAlerts("Successfully removed!", CONSTANT.ALERT_TYPE_SUCCESS);
                }
            });
        }
    };

    const onSubmit = (data) => {
        const agencyData = {
            agency_email: data.agency_email,
            agency_name: data.agency_name,
            agency_website: data.agency_website,
            agency_phone: number,
            privacy_policy_url: data.privacy_policy_url
        };
        updateOrCreateData(agencyData, (response) => {
            if (response.status) {
                ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
            }
        });
    };
    const onSubmitAddress = (data) => {
        const agencyData = {
            address: data.address,
            city: data.city,
            zip: data.zip,
            state: data.state
        };
        updateOrCreateData(agencyData, (response) => {
            if (response.status) {
                ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
            }
        });
    };

    return (
        <StyledEngineProvider injectFirst>
            <Box className="user_settings_container">
                <Box sx={{ width: 1, mb: "30px" }} className="user_settings">
                    <Box fullWidth>
                        <Box sx={{ width: 1, mb: "30px" }}>
                            <CustomHeader components={components} headerText="Agency Settings" />
                        </Box>
                        <Grid
                            item
                            sx={{ backgroundColor: "background.paper" }}
                            xs={10}
                            md={12}
                            sm={12}
                            className="schema__form"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box>
                                            <Typography
                                                variant="H5"
                                                className="schema__form__header"
                                                color="text.fill"
                                            >
                                                Company Data
                                            </Typography>
                                            <form className="agency_logo_upload">
                                                {!loading ? (
                                                    <Box className="fileUploader">
                                                        <CustomImageUploader
                                                            title="Please Select Company Logo"
                                                            subtitle="The proposed size is 512*512px no bigger than 2.5mb"
                                                            setLogoFile={setLogoFile}
                                                            setImagePath={setImagePath}
                                                            imagePath={imagePath}
                                                            handleLogoUpload={handleLogoUpload}
                                                            setIsDelete={setIsDelete}
                                                            isDelete={isDelete}
                                                            handleRemoveLogo={handleRemoveLogo}
                                                            loading={loadingForUpload}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <FileUploadSkeleton />
                                                )}
                                            </form>
                                            {agency.getData ? (
                                                <form
                                                    className=""
                                                    onSubmit={handleSubmit(onSubmit)}
                                                >
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <CustomTextField
                                                                name="agency_name"
                                                                label="Agency Name"
                                                                id=""
                                                                autoComplete="off"
                                                                type="text"
                                                                defaultValue={
                                                                    agency.getData &&
                                                                    agency.agencyData &&
                                                                    agency.agencyData.agency_name
                                                                        ? agency.agencyData
                                                                              .agency_name
                                                                        : ""
                                                                }
                                                                placeholder="Write your agency name"
                                                                handleOnBlur=""
                                                                register={register("agency_name")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CustomTextField
                                                                name="agency_email"
                                                                label="Agency Email"
                                                                autoComplete="off"
                                                                id=""
                                                                type="text"
                                                                defaultValue={
                                                                    agency.getData &&
                                                                    agency.agencyData &&
                                                                    agency.agencyData.agency_email
                                                                        ? agency.agencyData
                                                                              .agency_email
                                                                        : ""
                                                                }
                                                                placeholder="Write your agency email"
                                                                handleOnBlur=""
                                                                register={register("agency_email")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <CustomTextField
                                                                name="agency_website"
                                                                label="Agency Website"
                                                                id="emailAddress"
                                                                autoComplete="off"
                                                                type="text"
                                                                defaultValue={
                                                                    agency.getData &&
                                                                    agency.agencyData &&
                                                                    agency.agencyData.agency_website
                                                                        ? agency.agencyData
                                                                              .agency_website
                                                                        : ""
                                                                }
                                                                placeholder="Write your agency website"
                                                                handleOnBlur=""
                                                                register={register(
                                                                    "agency_website"
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <InputPhoneNumber
                                                                phone_number={number}
                                                                setNumber={setNumber}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <CustomTextField
                                                                name="privacy_policy_url"
                                                                label="Privacy Policy URL"
                                                                id=""
                                                                autoComplete="off"
                                                                type="text"
                                                                defaultValue={
                                                                    agency.getData &&
                                                                    agency.agencyData &&
                                                                    agency.agencyData
                                                                        .privacy_policy_url
                                                                        ? agency.agencyData
                                                                              .privacy_policy_url
                                                                        : ""
                                                                }
                                                                placeholder="Write agency privacy policy"
                                                                handleOnBlur=""
                                                                register={register(
                                                                    "privacy_policy_url"
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            marginTop: "24px"
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={
                                                                <Typography variant="buttonLarge">
                                                                    Update Company
                                                                </Typography>
                                                            }
                                                            variant="contained"
                                                            type="submit"
                                                            color="secondary"
                                                            handleButton=""
                                                        />
                                                    </Box>
                                                </form>
                                            ) : (
                                                ""
                                            )}
                                        </Box>
                                        {agency.getData ? (
                                            <Box className="change_password">
                                                <Typography
                                                    variant="H5"
                                                    className="schema__form__header"
                                                    color="primary.fill"
                                                >
                                                    Company Address
                                                </Typography>
                                                <form
                                                    className=""
                                                    onSubmit={handleSubmit(onSubmitAddress)}
                                                >
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <CustomTextField
                                                                name="address"
                                                                label="Address"
                                                                id="existingPassword"
                                                                autoComplete="off"
                                                                type="text"
                                                                defaultValue={
                                                                    agency.getData &&
                                                                    agency.agencyData &&
                                                                    agency.agencyData.address
                                                                        ? agency.agencyData.address
                                                                        : ""
                                                                }
                                                                placeholder="Enter company address"
                                                                handleOnBlur=""
                                                                register={register("address")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <CustomTextField
                                                                name="city"
                                                                label="City"
                                                                id="newPassword"
                                                                type="text"
                                                                defaultValue={
                                                                    agency.getData &&
                                                                    agency.agencyData &&
                                                                    agency.agencyData.city
                                                                        ? agency.agencyData.city
                                                                        : ""
                                                                }
                                                                placeholder="Enter city name"
                                                                handleOnBlur=""
                                                                register={register("city")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <CustomTextField
                                                                name="zip"
                                                                label="Zip Code"
                                                                id="newPassword"
                                                                type="text"
                                                                defaultValue={
                                                                    agency.getData &&
                                                                    agency.agencyData &&
                                                                    agency.agencyData.zip
                                                                        ? agency.agencyData.zip
                                                                        : ""
                                                                }
                                                                placeholder="Enter Zip Code"
                                                                handleOnBlur=""
                                                                register={register("zip")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <CustomTextField
                                                                name="state"
                                                                label="State"
                                                                id="newPassword"
                                                                type="text"
                                                                defaultValue={
                                                                    agency.getData &&
                                                                    agency.agencyData &&
                                                                    agency.agencyData.state
                                                                        ? agency.agencyData.state
                                                                        : ""
                                                                }
                                                                placeholder="Enter state Code"
                                                                handleOnBlur=""
                                                                register={register("state")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} />
                                                    </Grid>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            marginTop: "20px"
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={
                                                                <Typography variant="buttonLarge">
                                                                    Update Address
                                                                </Typography>
                                                            }
                                                            variant="contained"
                                                            type="submit"
                                                            color="secondary"
                                                            handleButton=""
                                                        />
                                                    </Box>
                                                </form>
                                            </Box>
                                        ) : (
                                            ""
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </StyledEngineProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        agency: state.agencyReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAgencyData: (params, callback) => dispatch(getAgencyData(params, callback)),
        updateOrCreateData: (params, callback) => dispatch(updateOrCreateData(params, callback)),
        uploadAgencyLogo: (params, callback) => dispatch(uploadS3FileAction(params, callback))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgencySettings);
