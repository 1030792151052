import { combineReducers } from "redux";
import adminAgencyReducer from "./Admin/Agencies/adminAgencyReducer";
import adminAppReducer from "./Admin/App/AdminAppReducer";
import geogridApiAuthReducer from "./Admin/GeogridApiAuth/geogridApiAuthReducer";
import adminGhlReducer from "./Admin/Ghl/AdminGhlReducer";
import adminUserReducer from "./Admin/User/adminUserReducer";
import agencyReducer from "./agency/AgencyReducer";
import apiAuthReducer from "./apiAuth/apiAuthReducer";
import appReducer from "./app/appReducer";
import holidayReducer from "./holiday/holidayReducer";
import loginReducer from "./login/loginReducer";
import resetPasswordReducer from "./resetPassword/resetPasswordReducer";
import signupReducer from "./signup/signupReducer";
import teamReducer from "./team/teamReducer";
import userProfileManageReducer from "./userProfileManage/userProfileManageReducer";
import whiteLabelDomainReducer from "./whiteDomain/whiteDomainReducer";

export default combineReducers({
    loginReducer,
    signupReducer,
    userProfileManageReducer,
    resetPasswordReducer,
    agencyReducer,
    teamReducer,
    apiAuthReducer,
    appReducer,
    whiteLabelDomainReducer,
    adminAgencyReducer,
    adminAppReducer,
    adminUserReducer,
    geogridApiAuthReducer,
    holidayReducer,
    adminGhlReducer
});
