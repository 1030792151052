import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    changeUserPassword,
    changeUserProfileImage,
    fetchUserProfileData,
    userProfileDataUpdate
} from "../../api/userProfileManageApi";
import showAlerts from "../../helpers/ShowAlerts";
import * as CONSTANT from "../../utils/Constant";
import * as ACTION from "./userProfileManageActionType";

function* getUserProfileData(action) {
    try {
        const response = yield call(fetchUserProfileData);
        const responseData = response.data;
        if (response.status) {
            yield put({
                type: ACTION.FETCH_USER_PROFILE_DATA_SUCCESS,
                payload: responseData
            });
            if (action.callback) {
                action.callback(responseData);
            }
        }
    } catch (error) {
        action.callback(error);
    }
}

function* submitUserProfileData(action) {
    try {
        const response = yield call(userProfileDataUpdate, action.payload);
        const responseData = response.data;

        if (responseData.status) {
            yield put({
                type: ACTION.PROFILE_INFO_UPDATE_SUCCESS,
                payload: responseData
            });
            if (action.callback) {
                action.callback(responseData);
            }
        } else {
            yield put({
                type: ACTION.PROFILE_INFO_UPDATE_ERROR,
                payload: responseData.message
            });
        }
    } catch (error) {
        if (action.callback) {
            action.callback(error);
        }
    }
}

function* changePassword(action) {
    try {
        const response = yield call(changeUserPassword, action.payload);
        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        action.callback(error);
    }
}

function* changePasswordSaga(action) {
    try {
        const response = yield call(changeUserPassword, action.payload);
        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        showAlerts(error.message, CONSTANT.ALERT_TYPE_ERROR);
    }
}

function* changeImage(action) {
    try {
        const response = yield call(changeUserProfileImage, action.payload.userProfileImageData);
        const responseData = response.data;

        if (action.payload.callback) action.payload.callback(responseData);

        if (responseData.status) {
            yield put({ type: ACTION.USER_PROFILE_IMAGE_CHANGE_ERROR, payload: [] });
            yield put({
                type: ACTION.USER_PROFILE_IMAGE_CHANGE_SUCCESS,
                payload: action.payload.userProfileImageData
            });
        } else {
            yield put({
                type: ACTION.USER_PROFILE_IMAGE_CHANGE_ERROR,
                payload: responseData
            });
            yield put({
                type: ACTION.USER_PROFILE_IMAGE_CHANGE_SUCCESS,
                payload: false
            });
        }
    } catch (error) {
        action.callback(error);
    }
}
function* userProfileManageWatcher() {
    yield takeEvery(ACTION.USER_PROFILE_DATA_GET, getUserProfileData);
    yield takeEvery(ACTION.PROFILE_INFO_UPDATE_ATTEMPT, submitUserProfileData);
    yield takeEvery(ACTION.USER_PASSWORD_CHANGE_ATTEMPT, changePassword);
    yield takeEvery(ACTION.USER_PROFILE_IMAGE_CHANGE, changeImage);
    yield takeEvery(ACTION.CHANGE_PASSWORD, changePasswordSaga);
}

export default function* userProfileManageSaga() {
    yield all([userProfileManageWatcher()]);
}
