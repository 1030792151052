/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-this-in-sfc */
import {
    CustomButton,
    CustomCheckBox,
    CustomLabel,
    CustomTextField,
    CustomTextFieldPassword
} from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, SvgIcon, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useGoogleLogin } from "@react-oauth/google";
import storage from "local-storage-fallback";
import { useEffect, useState } from "react";
// import GoogleLogin from "react-google-login";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { setCookie, setCookieForRefresh } from "../../helpers/Cookie";
import { loginSuccess } from "../../statemanagement/login/loginAction";
import {
    attemptSignup,
    googleRegistration,
    googleSignIn,
    signUpSuccess
} from "../../statemanagement/signup/signupAction";
import AuthError from "./AuthError";
// import Logo from "./images/logo.png";

import { AgencyFrameworkLight } from "../../assets/images/logo/AgencyFrameworkLogo";
import "./style/auth.css";

const { REACT_APP_ACCESS_TOKEN, REACT_APP_ACCESS_TOKEN_VALIDITY, REACT_APP_REFRESH_TOKEN } =
    process.env;

const style = {
    "&::before": {
        position: "absolute",
        content: '" "',
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        background: "rgba(27, 20, 100, 0.85)"
    }
};

const GoogleIcon = () => {
    return (
        <SvgIcon
            width="18"
            height="18"
            xmlns="http://www.w3.org/2000/svg"
            sx={{ marginTop: "4px", marginRight: "0", marginLeft: "0" }}
        >
            <g fill="#000" fillRule="evenodd">
                <path
                    d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                    fill="#EA4335"
                />
                <path
                    d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                    fill="#4285F4"
                />
                <path
                    d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                    fill="#FBBC05"
                />
                <path
                    d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                    fill="#34A853"
                />
                <path fill="none" d="M0 0h18v18H0z" />
            </g>
        </SvgIcon>
    );
};

const Registration = (props) => {
    const {
        attemptSignupAction,
        signUpSuccessData,
        loginSuccessData,
        googleSignInData,
        signUp,
        googleUserRegister
    } = props;
    const theme = useTheme();
    const history = useHistory();
    const [errorStatus, setErrorStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [agreedTermCondition, setAgreedTermCondition] = useState(false);
    const [signIn, setSignIn] = useState({
        showPassword: true,
        showConfirmPassword: true
    });
    const validator = yup.object().shape({
        first_name: yup
            .string()
            .required("First Name Required")
            .matches(/^[A-Za-z .]+$/, "Invalid First Name"),
        last_name: yup
            .string()
            .required("Last Name Required")
            .matches(/^[A-Za-z .]+$/, "Invalid Last Name"),
        email: yup.string().email().required("Email Required"),
        password: yup
            .string()
            .required("Password is Required")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        confirm_password: yup
            .string()

            .required("Confirm Password is Required")
            .test("passwords-match", "Passwords must match", function (value) {
                return this.parent.password === value;
            }),
        agreed: yup.bool().oneOf([true], "You must agree to Terms and Conditions")
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({ mode: "onChange", resolver: yupResolver(validator) });

    useEffect(() => {
        if (signUp.error) {
            setErrorStatus(true);
            setLoading(false);
        } else {
            setErrorStatus(false);
        }
    }, [signUp.error]);

    useEffect(() => {
        if (signUp.isSignUpSuccess) {
            setLoading(false);
            reset();
        }
    }, [signUp.isSignUpSuccess]);

    const handleClickShowPassword = () => {
        setSignIn({
            ...signIn,
            showPassword: !signIn.showPassword
        });
    };

    const handleClickShowConfirmPassword = () => {
        setSignIn({
            ...signIn,
            showConfirmPassword: !signIn.showConfirmPassword
        });
    };

    const onSubmit = (data) => {
        setLoading(true);
        storage.setItem(process.env.REACT_APP_REDIRECT_TO, "/dashboard");
        const newData = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: data.password,
            confirm_password: data.confirm_password
        };

        attemptSignupAction(newData, (response) => {
            if (response.status) {
                signUpSuccessData(response);
                loginSuccessData(response.data);
                history.push("/user/email-verification");
            }
        });
    };

    const onGoogleSignInSuccess = (resp) => {
        storage.setItem(process.env.REACT_APP_REDIRECT_TO, "/dashboard");
        const data = {
            email: resp.profileObj.email,
            first_name: resp.profileObj.givenName,
            last_name: resp.profileObj.familyName,
            google_id: resp.profileObj.googleId
        };

        googleSignInData(data, (response) => {
            if (response.status) {
                setCookie(
                    REACT_APP_ACCESS_TOKEN,
                    response.tokens.access.token,
                    REACT_APP_ACCESS_TOKEN_VALIDITY
                );
                setCookieForRefresh(REACT_APP_REFRESH_TOKEN, response.tokens.refresh.token);
                signUpSuccessData(response);
                loginSuccessData(response);
                window.location.replace("/dashboard");
            }
        });
    };

    const onGoogleSignInFailed = (res) => {
        console.log(res);
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            console.log("call from register");
            googleUserRegister({ access_token: tokenResponse.access_token }, (response) => {
                if (response.status) {
                    setCookie(
                        REACT_APP_ACCESS_TOKEN,
                        response.tokens.access.token,
                        REACT_APP_ACCESS_TOKEN_VALIDITY
                    );
                    setCookieForRefresh(REACT_APP_REFRESH_TOKEN, response.tokens.refresh.token);
                    signUpSuccessData(response);
                    loginSuccessData(response);
                    window.location.replace("/dashboard");
                }
            });
        },
        onError: (errResponse) => {
            return errResponse;
        },
        scope: "email profile"
    });

    return (
        <Box sx={style} className="auth__wrapper">
            <Box className="auth__inner">
                <Box className="auth__inner__container">
                    <Box className="auth__inner__top">
                        {/* <img src={Logo} alt="agency-framework" /> */}
                        {AgencyFrameworkLight}
                        <Typography
                            className="auth__have__account"
                            color={theme.palette.text.primary}
                            variant="body2"
                            component="p"
                        >
                            Already Have an Account?
                            <Box component="span" sx={{ paddingLeft: "4px" }}>
                                <Link to="/login">
                                    <Typography
                                        color={theme.palette.secondary.main}
                                        variant="buttonLarge"
                                    >
                                        Sign In
                                    </Typography>
                                </Link>
                            </Box>
                        </Typography>
                    </Box>
                    <Box className="auth__form__wr">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {errorStatus === true && (
                                <AuthError
                                    type="error"
                                    errorText={signUp.errorMessage ? signUp.errorMessage : ""}
                                />
                            )}

                            {signUp.isSignUpSuccess === true && (
                                <AuthError
                                    type="success"
                                    errorText="Successfully register please login"
                                />
                            )}

                            <CustomTextField
                                className="single__text__field"
                                label="First Name"
                                register={register("first_name")}
                                size="large"
                                errors={errors}
                                color="secondary"
                            />
                            {!!errors.first_name && (
                                <CustomLabel variant="subtitle2" align="left" color="error">
                                    {errors.first_name.message}
                                </CustomLabel>
                            )}

                            <CustomTextField
                                className="single__text__field"
                                label="Last Name"
                                size="large"
                                register={register("last_name")}
                                errors={errors}
                                color="secondary"
                            />
                            {!!errors.last_name && (
                                <CustomLabel variant="subtitle2" align="left" color="error">
                                    {errors.last_name.message}
                                </CustomLabel>
                            )}

                            <CustomTextField
                                className="single__text__field"
                                label="Email"
                                size="large"
                                register={register("email")}
                                errors={errors}
                                color="secondary"
                            />
                            {!!errors.email && (
                                <Typography variant="subtitle2" align="left" color="error">
                                    {errors.email.message}
                                </Typography>
                            )}
                            <CustomTextFieldPassword
                                show={signIn.showPassword}
                                handleClickShowPassword={handleClickShowPassword}
                                type={signIn.showPassword ? "password" : "text"}
                                className="single__text__field"
                                size="large"
                                label="Password"
                                register={register("password")}
                                color="secondary"
                                errors={errors}
                            />
                            {!!errors.password && (
                                <CustomLabel variant="subtitle2" align="left" color="error">
                                    {errors.password.message}
                                </CustomLabel>
                            )}
                            <CustomTextFieldPassword
                                show={signIn.showConfirmPassword}
                                handleClickShowPassword={handleClickShowConfirmPassword}
                                type={signIn.showConfirmPassword ? "password" : "text"}
                                className="single__text__field"
                                size="large"
                                label="Confirm Password"
                                register={register("confirm_password")}
                                color="secondary"
                                errors={errors}
                            />

                            {!!errors.confirm_password && (
                                <CustomLabel variant="subtitle2" align="left" color="error">
                                    {errors.confirm_password.message}
                                </CustomLabel>
                            )}

                            <Box className="check__condition__wr">
                                <CustomCheckBox
                                    checked={agreedTermCondition}
                                    handleClick={() => setAgreedTermCondition(!agreedTermCondition)}
                                    color="secondary"
                                    register={register("agreed")}
                                />
                                <Typography
                                    color={theme.palette.text.secondary}
                                    variant="body2"
                                    component="span"
                                >
                                    {"I agree with "}
                                </Typography>

                                <Typography color={theme.palette.text.primary} variant="body2">
                                    <Link>Terms and Conditions</Link>
                                </Typography>
                            </Box>
                            {!!errors.agreed && (
                                <CustomLabel variant="subtitle2" align="left" color="error">
                                    {errors.agreed.message}
                                </CustomLabel>
                            )}

                            <CustomButton
                                variant="contained"
                                loader={loading}
                                color="secondary"
                                title="Sign Up"
                                size="large"
                                type="submit"
                                customClass="auth__sign__in__btn"
                            />
                            <Typography
                                className="sign__with__email"
                                color={theme.palette.text.secondary}
                                variant="caption"
                                component="p"
                            >
                                Or Sign In with email
                            </Typography>
                        </form>

                        <CustomButton
                            variant="outlined"
                            color="secondary"
                            sx={{
                                boxShadow: "none",
                                "& .MuiButton-startIcon": {
                                    // marginTop: "4px",
                                    marginRight: "0",
                                    marginLeft: "0"
                                }
                            }}
                            title="Sign Up With Google"
                            size="large"
                            type="button"
                            startIcon={<GoogleIcon />}
                            handleButton={handleGoogleLogin}
                            customClass="auth__sign__in__btn_google"
                        />

                        {/* <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Sign Up With Google"
                            className="auth__google__btn"
                            onSuccess={onGoogleSignInSuccess}
                            onFailure={onGoogleSignInFailed}
                            cookiePolicy="single_host_origin"
                        /> */}
                    </Box>
                </Box>

                <Box className="auth__footer">
                    <Typography
                        className="auth__footer__copy__right"
                        color={theme.palette.primary.contrastText}
                        variant="overline"
                        component="p"
                    >
                        @ {new Date().getFullYear()} Agency Framework - All Rights Reserved
                    </Typography>

                    <Box className="footer__link">
                        <Link to="/forgot-password">
                            <Typography
                                color={theme.palette.primary.contrastText}
                                variant="overline"
                                component="span"
                            >
                                Privacy Policy
                            </Typography>
                        </Link>
                        <Link to="/forgot-password">
                            <Typography
                                color={theme.palette.primary.contrastText}
                                variant="overline"
                                component="span"
                            >
                                terms and conditions
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        signUp: state.signupReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        attemptSignupAction: (params, callback) => dispatch(attemptSignup(params, callback)),
        signUpSuccessData: (params) => dispatch(signUpSuccess(params)),
        googleSignInData: (params, callback) => dispatch(googleSignIn(params, callback)),
        googleUserRegister: (params, callback) => dispatch(googleRegistration(params, callback)),
        loginSuccessData: (params) => dispatch(loginSuccess(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
