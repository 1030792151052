import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    emailVerificationApi,
    googleSignInRequest,
    googleUserRegistrationApi,
    sendEmailVerificationCode,
    signupRequest
} from "../../api/signupApi";
import { setCookie, setCookieForRefresh, setOtherCookie } from "../../helpers/Cookie";
import * as ACTION from "./signupActionTypes";

const {
    REACT_APP_ACCESS_TOKEN,
    REACT_APP_ACCESS_TOKEN_VALIDITY,
    REACT_APP_REFRESH_TOKEN,
    REACT_APP_EMAIL_VERIFIED_STATUS
} = process.env;

function* attemptSignUpSaga(action) {
    try {
        const response = yield call(signupRequest, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }

        if (responseData.status) {
            setCookie(
                REACT_APP_ACCESS_TOKEN,
                responseData.tokens.access.token,
                REACT_APP_ACCESS_TOKEN_VALIDITY
            );

            setCookieForRefresh(REACT_APP_REFRESH_TOKEN, responseData.tokens.refresh.token);
            setOtherCookie(REACT_APP_EMAIL_VERIFIED_STATUS, responseData.data.email_verified === 1);
            yield put({ type: ACTION.SIGNUP_SUCCESS, payload: responseData });
        } else {
            yield put({
                type: ACTION.SIGNUP_ERROR,
                payload: responseData.message
            });
        }
    } catch (error) {
        yield put({
            type: ACTION.SIGNUP_ERROR,
            payload: error.message
        });
    }
}

function* attemptGoogleSignInSaga(action) {
    try {
        const response = yield call(googleSignInRequest, action.payload);
        if (action.callback) {
            action.callback(response.data);
        }
    } catch (error) {
        yield put({
            type: ACTION.SIGNUP_ERROR,
            payload: error.message
        });
    }
}

function* attemptGoogleUserRegistrationSaga(action) {
    try {
        const response = yield call(googleUserRegistrationApi, action.payload);
        if (action.callback) {
            action.callback(response.data);
        }
    } catch (error) {
        yield put({
            type: ACTION.SIGNUP_ERROR,
            payload: error.message
        });
    }
}

function* emailVerificationSaga(action) {
    try {
        const response = yield call(emailVerificationApi, action.payload);
        if (action.callback) {
            action.callback(response.data);
        }
    } catch (error) {
        if (action.callback) {
            action.callback(error);
        }
    }
}

function* sendEmailVerificationSaga(action) {
    try {
        const response = yield call(sendEmailVerificationCode, action.payload);
        if (action.callback) {
            action.callback(response.data);
        }
    } catch (error) {
        if (action.callback) {
            action.callback(error);
        }
    }
}
function* signupWatcher() {
    yield takeEvery(ACTION.SIGNUP_ATTEMPT, attemptSignUpSaga);
    yield takeEvery(ACTION.GOOGLE_SIGN_IN, attemptGoogleSignInSaga);
    yield takeEvery(ACTION.GOOGLE__REGISTRATION, attemptGoogleUserRegistrationSaga);
    yield takeEvery(ACTION.EMAIL_VERIFICATION, emailVerificationSaga);
    yield takeEvery(ACTION.SEND_EMAIL_VERIFICATION_CODE, sendEmailVerificationSaga);
}

export default function* signupMiddleware() {
    yield all([signupWatcher()]);
}
