import { CustomButton, DataTablePro } from "@hipdevteam/afglobalcomponents";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TableSkeleton from "../../../../../components/SkeletonLoader/Table/TableSkeleton";
import { getGhlAdSourceTableData } from "../../../../../statemanagement/Admin/Ghl/GhlAction";
import { GHL_AD_SOURCE_TABLE_TYPE_GHL } from "../../../../../utils/Constant/GhlConstant";
import { convertTimezone, getBrowserTimeZone } from "../../../../../utils/timeHelper";

const DataTableGhl = (props) => {
    const history = useHistory();
    const { ghlAdSourceTableData, getAdSourceTableList } = props;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [progressing, setProgressing] = useState(true);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterSearch, setFilterSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);

    const handleTableColumnShow = (tableData) => {
        history.push(`/admin/ghl/add-source/table/${tableData.row.id}`);
    };
    const columns = [
        {
            field: "table_name",
            headerName: "Name",
            editable: false,
            flex: 4,
            renderCell: (params) => (
                <Typography variant="body1" component="p">
                    {params.value}
                </Typography>
            )
        },
        {
            field: "active_count",
            headerName: "Active Count",
            editable: false,
            flex: 4,
            renderCell: (params) => (
                <Typography variant="body1" component="p">
                    {params.value}
                </Typography>
            )
        },
        {
            field: "created_at",
            headerName: "Added on",
            editable: false,
            flex: 4,
            renderCell: (params) => (
                <Typography variant="body1" component="p">
                    {convertTimezone(params.value, "UTC", getBrowserTimeZone(), "MMM dd,yyyy ")}
                </Typography>
            )
        },
        {
            field: "actions",
            flex: 3,
            headerName: <Typography variant="tableHeader">Actions</Typography>,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box>
                        <CustomButton
                            handleButton={() => handleTableColumnShow(params)}
                            color="info"
                            size="small"
                            title={<Typography variant="buttonSmall">View Columns</Typography>}
                            variant="contained"
                        />
                    </Box>
                );
            }
        }
    ];

    useEffect(() => {
        getAdSourceTableList({
            currentPage,
            rowLimit: itemPerPage,
            searchKey: filterSearch,
            adSourceType: GHL_AD_SOURCE_TABLE_TYPE_GHL
        });
    }, []);

    useEffect(() => {
        if (ghlAdSourceTableData.isSuccess) {
            setData(ghlAdSourceTableData.tableData.data);
            setProgressing(false);
            setLoading(false);
            setTotalPages(ghlAdSourceTableData.tableData.totalPages);
            setTotalItems(ghlAdSourceTableData.tableData.totalItems);
            setCurrentPage(ghlAdSourceTableData.tableData.currentPage);
        }
    }, [ghlAdSourceTableData.isSuccess]);

    const getData = (currentPage) => {
        setProgressing(true);
        getAdSourceTableList({
            currentPage: currentPage + 1,
            rowLimit: itemPerPage,
            searchKey: filterSearch,
            adSourceType: GHL_AD_SOURCE_TABLE_TYPE_GHL
        });
        setCurrentPage(currentPage + 1);
    };

    const changePage = (currentRowsPerPage) => {
        setProgressing(true);
        setCurrentPage(1);
        getAdSourceTableList({
            currentPage: 1,
            rowLimit: currentRowsPerPage,
            searchKey: filterSearch,
            adSourceType: GHL_AD_SOURCE_TABLE_TYPE_GHL
        });
        setItemPerPage(currentRowsPerPage);
    };

    const handleFilter = async (searchKey) => {
        setProgressing(true);
        setFilterSearch(searchKey);
        getAdSourceTableList({
            currentPage: 1,
            rowLimit: itemPerPage,
            searchKey,
            adSourceType: GHL_AD_SOURCE_TABLE_TYPE_GHL
        });
    };

    return (
        <Box
            sx={{
                width: 1,
                padding: "30px 16px",
                backgroundColor: "background.paper",
                boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                borderRadius: "4px"
            }}
        >
            {!loading ? (
                <DataTablePro
                    tableId="ghl-tag-data-table"
                    rows={data}
                    columns={columns}
                    loading={progressing}
                    paginationMode="client"
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                    pageSize={itemPerPage}
                    onPageChange={getData}
                    onPageSizeChange={changePage}
                    searchText="Search... "
                    handleFilter={handleFilter}
                />
            ) : (
                <TableSkeleton />
            )}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return { ghlAdSourceTableData: state.adminGhlReducer };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAdSourceTableList: (params) => dispatch(getGhlAdSourceTableData(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTableGhl);
