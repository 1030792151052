export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_SUCCESS_STEP_TWO = "SIGNUP_SUCCESS_STEP_TWO";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_ATTEMPT = "SIGNUP_ATTEMPT";
export const SIGNUP_SUCCESS_FALSE = "SIGNUP_SUCCESS_FALSE";

export const GOOGLE_SIGN_IN = "GOOGLE_SIGN_IN";
export const GOOGLE__REGISTRATION = "GOOGLE__REGISTRATION";

export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";

export const SEND_EMAIL_VERIFICATION_CODE = "SEND_EMAIL_VERIFICATION_CODE";
