import {
    CustomBadge,
    CustomConfirmModal,
    CustomDrawer,
    CustomDropDownButtonMenu,
    CustomHeader,
    CustomModal,
    CustomTooltip,
    GlobalDataTable
} from "@hipdevteam/afglobalcomponents";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from "@mui/icons-material/Login";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PasswordIcon from "@mui/icons-material/Password";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import { Box, Container, Grid, StyledEngineProvider, Typography } from "@mui/material";
import storage from "local-storage-fallback";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TableSkeleton from "../../../components/SkeletonLoader/Table/TableSkeleton";
import { setCookie, setCookieForRefresh, setCookieWithPath } from "../../../helpers/Cookie";
import ShowAlerts from "../../../helpers/ShowAlerts";
import {
    deleteAdminAgencyTeamMember,
    loginAsUser,
    makeUserAdmin,
    userStatusChange
} from "../../../statemanagement/Admin/Agencies/agencyAction";
import { getUserDataTable } from "../../../statemanagement/Admin/User/adminUserAction";
import * as CONSTANT from "../../../utils/Constant";
import {
    ADMIN_LOGIN_UUID,
    ALERT_TYPE_ERROR,
    ALERT_TYPE_SUCCESS,
    IS_ADMIN_LOGGED_IN_AS_USER,
    STATUS_ACTIVE,
    STATUS_INACTIVE,
    STATUS_REMOVED,
    SUPER_ADMIN_NO,
    SUPER_ADMIN_YES,
    USER_ROLE_ADMIN
} from "../../../utils/Constant/GlobalConstant";
import * as timeHelper from "../../../utils/timeHelper";
import "../../Team/team.css";
import EditTeamAdmin from "../Agencies/EditTeamAdmin";
import UserPasswordUpdateForm from "../Agencies/Forms/UserPasswordUpdateForm";

const AdminUsers = (props) => {
    const history = useHistory();
    const {
        users,
        isSuccess,
        getUserData,
        forceLoginAsUser,
        changeUserStatus,
        currentUserData,
        makeUserAnAdmin,
        deleteTeamData
    } = props;

    const [data, setData] = useState([]);
    const [progressing, setProgressing] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [filterSearch, setFilterSearch] = useState("");
    const [userUuid, setUserUuid] = useState("");
    const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
    const [inactiveModal, setInactiveModal] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [drawerOpenForEdit, setDrawerOpenForEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [uuid, setUuid] = useState("");
    const [editSuccess, setEditSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const components = [
        {
            name: "Settings",
            link: "/Dashboard"
        },
        { name: "Users", link: "#" }
    ];

    const {
        REACT_APP_ACCESS_TOKEN,
        REACT_APP_ACCESS_TOKEN_VALIDITY,
        REACT_APP_REFRESH_TOKEN,
        REACT_APP_EMAIL_VERIFIED_STATUS
    } = process.env;

    useEffect(() => {
        setProgressing(true);
        getUserData(currentPage, itemPerPage, filterSearch);
    }, []);
    useEffect(() => {
        if (editSuccess || deleteSuccess) {
            getUserData(currentPage, itemPerPage, filterSearch);
        }
    }, [editSuccess, deleteSuccess]);
    useEffect(() => {
        if (isSuccess) {
            setData(users.data);
            setProgressing(false);
            setTotalItems(users.totalItems);
            setCurrentPage(users.currentPage);
        }
    }, [isSuccess]);

    const changePage = (currentRowsPerPage, currentNewPage) => {
        getUserData(currentNewPage, currentRowsPerPage, filterSearch);
        setItemPerPage(currentRowsPerPage);
        setCurrentPage(currentNewPage);
    };

    const getData = (currentNewPage) => {
        getUserData(currentNewPage, itemPerPage, filterSearch);
        setCurrentPage(currentNewPage);
    };

    const handleFilter = async (searchKey) => {
        setFilterSearch(searchKey);
        getUserData(currentPage, itemPerPage, searchKey);
    };

    const handleLoginAsUser = (user) => {
        forceLoginAsUser({ user_uuid: user.uuid }, (response) => {
            if (response.status) {
                storage.setItem(IS_ADMIN_LOGGED_IN_AS_USER, "true");
                storage.setItem(ADMIN_LOGIN_UUID, currentUserData.uuid);
                setCookie(
                    REACT_APP_ACCESS_TOKEN,
                    response.tokens.access.token,
                    REACT_APP_ACCESS_TOKEN_VALIDITY
                );
                setCookieForRefresh(REACT_APP_REFRESH_TOKEN, response.tokens.refresh.token);
                setCookieWithPath(
                    REACT_APP_EMAIL_VERIFIED_STATUS,
                    Number(response.data.email_verified) === 1,
                    "/"
                );
                setCookieWithPath("UUID", response.data.uuid, "/");
                setCookieWithPath("EMAIL", response.data.email, "/");
                window.location.replace("/dashboard");
            }
        });
    };

    const handleActiveInactiveConfirmation = (row) => {
        setSelectedUser(row);
        if (Number(row.status) === STATUS_ACTIVE) {
            setInactiveModal(true);
        }
        if (Number(row.status) === STATUS_INACTIVE) {
            setActiveModal(true);
        }
    };
    const handleStatusChange = (uuid, status) => {
        changeUserStatus({ user_uuid: uuid, user_status: status }, (response) => {
            if (response.status) {
                ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
                getUserData(currentPage, itemPerPage, filterSearch);
                if (status === STATUS_INACTIVE) {
                    setInactiveModal(false);
                }
                if (status === STATUS_ACTIVE) {
                    setActiveModal(false);
                }
            } else {
                ShowAlerts(response.message, ALERT_TYPE_ERROR);
            }
        });
    };

    const handleUserPasswordChange = (uuid) => {
        setIsPasswordChangeModalOpen(true);
        setUserUuid(uuid);
    };

    const handleAdminPrivilege = (uuid, privilege = SUPER_ADMIN_NO) => {
        makeUserAnAdmin({ user_uuid: uuid, privilege }, (response) => {
            if (response.status) {
                ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
                getUserData(currentPage, itemPerPage, filterSearch);
            } else {
                ShowAlerts("Failed to update user status", ALERT_TYPE_ERROR);
            }
        });
    };

    const renderActionOptions = (row) => {
        const options = [
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        <LoginIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Login As User
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    handleLoginAsUser(row);
                },
                disabled: row.status === STATUS_REMOVED
            },
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        <PasswordIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Change Password
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    handleUserPasswordChange(row.uuid);
                },
                disabled: row.status === STATUS_REMOVED
            },
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        {row.status === STATUS_ACTIVE ? (
                            <>
                                <PauseIcon
                                    sx={{
                                        color: "error.main",
                                        paddingRight: "10px",
                                        width: "2rem !important"
                                    }}
                                />
                                <Typography variant="body1" color="error.main">
                                    Inactive
                                </Typography>
                            </>
                        ) : (
                            <>
                                <PlayArrowIcon
                                    sx={{
                                        color: "action.active",
                                        paddingRight: "10px",
                                        width: "2rem !important"
                                    }}
                                />
                                <Typography variant="body1" color="text.primary">
                                    Active
                                </Typography>
                            </>
                        )}
                    </Box>
                ),
                handleClick: () => {
                    handleActiveInactiveConfirmation(row);
                },
                disabled: row.status === STATUS_REMOVED
            },
            {
                name: (
                    <Box sx={{ display: "flex" }}>
                        <EditIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Edit
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    setDrawerOpenForEdit(true);
                    setUuid(row.uuid);
                },
                disabled: row.status === STATUS_REMOVED
            }
        ];

        if (row.role === USER_ROLE_ADMIN && row.is_super_admin !== SUPER_ADMIN_YES) {
            options.push({
                name: (
                    <Box sx={{ display: "flex" }}>
                        <AddModeratorIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Make Admin
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    handleAdminPrivilege(row.uuid, SUPER_ADMIN_YES);
                },
                disabled: row.status === STATUS_REMOVED
            });
        }

        if (row.is_super_admin === SUPER_ADMIN_YES) {
            options.push({
                name: (
                    <Box sx={{ display: "flex" }}>
                        <RemoveModeratorIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Remove Admin Privilege
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    handleAdminPrivilege(row.uuid, SUPER_ADMIN_NO);
                }
            });
        }
        if (row.status !== STATUS_REMOVED) {
            options.push({
                name: (
                    <Box sx={{ display: "flex" }}>
                        <DeleteIcon
                            sx={{
                                color: "action.active",
                                paddingRight: "10px",
                                width: "2rem !important"
                            }}
                        />
                        <Typography variant="body1" color="text.primary">
                            Delete
                        </Typography>
                    </Box>
                ),
                handleClick: () => {
                    setIsDelete(true);
                    setSelectedItem({ ...row });
                }
            });
        }

        return options;
    };
    const handleDelete = () => {
        deleteTeamData({ uuid: selectedItem.uuid }, (response) => {
            if (response.status) {
                // getUserData(currentPage, itemPerPage, filterSearch);
                setDeleteSuccess(true);
                ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
            }
        });
        setIsDelete(false);
    };

    const column = [
        {
            name: "Name",
            selector: (row) => {
                return (
                    <Box
                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                    >
                        <Typography component="div" color="text.primary" variant="body2">
                            {`${row.first_name} ${row.last_name}`}
                        </Typography>
                        <Box>
                            {/* <Typography variant="body2" color="text.primary">
                                {timeHelper.convertTimezone(
                                    row.execution_time,
                                    "UTC",
                                    timeHelper.getBrowserTimeZone(),
                                    "MMM dd,yyyy"
                                )}
                            </Typography> */}
                            <Typography variant="body2" sx={{ color: "action.active" }}>
                                {timeHelper.convertTimezone(
                                    row.execution_time,
                                    "UTC",
                                    timeHelper.getBrowserTimeZone(),
                                    "MMM dd,yyyy hh:mm a zzz"
                                )}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
            sortable: true
        },
        {
            name: "Email",
            selector: (row) => {
                return (
                    <Box>
                        {!row.email && <CustomBadge label="Not Found" color="info" />}
                        {row.email && <Typography variant="body2">{row.email}</Typography>}
                    </Box>
                );
            }
        },
        {
            name: "Phone No",
            selector: (row) => {
                return (
                    <Box>
                        {!row.phone_number && <CustomBadge label="Not Found" color="info" />}
                        {row.phone_number && (
                            <Typography variant="body2">{row.phone_number}</Typography>
                        )}
                    </Box>
                );
            }
        },
        {
            name: "Role",
            selector: (row) => {
                let color = "default";
                let label = "User";

                if (row.role === USER_ROLE_ADMIN) {
                    color = "secondary";
                    label = "Admin";
                }

                return (
                    <Typography variant="body1" component="p">
                        <CustomBadge color={color} label={label} />
                    </Typography>
                );
            }
        },
        {
            name: "Agency",
            selector: (row) => {
                if (row?.agency?.agency_name) {
                    return (
                        <Typography variant="body1" component="p">
                            {row?.agency?.agency_name}
                        </Typography>
                    );
                }
                return (
                    <CustomTooltip title={row?.agency?.agency_email} placement="top">
                        <Typography variant="body1" component="p">
                            {row?.agency?.agency_email}
                        </Typography>
                    </CustomTooltip>
                );
            }
        },
        {
            name: "Status",
            selector: (row) => {
                return (
                    <Typography variant="body1" component="p">
                        {row.status === STATUS_INACTIVE && (
                            <CustomBadge color="warning" label="Inactive" />
                        )}
                        {row.status === STATUS_ACTIVE && (
                            <CustomBadge color="success" label="Active" />
                        )}
                        {row.status == STATUS_REMOVED && (
                            <CustomBadge color="error" label="Removed" />
                        )}
                    </Typography>
                );
            }
        },
        {
            name: "Actions",
            selector: (row) => (
                <Box>
                    <CustomDropDownButtonMenu
                        buttonContent={<MoreVertIcon color="action.active" />}
                        options={renderActionOptions(row)}
                    />
                </Box>
            )
        }
    ];
    const headerButtons = [
        {
            name: "Back",
            color: "secondary",
            startIcon: <ArrowBackIcon />,
            action: () => history.goBack()
        }
    ];

    return (
        <StyledEngineProvider injectFirst>
            <Box className="team-wrapper">
                <Container
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingTop: "10px",
                        height: "100vh"
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ width: 1, mb: "30px" }}>
                                <CustomHeader
                                    components={components}
                                    headerText="User Management"
                                    color="secondary"
                                    buttons={history.location.key != undefined ? headerButtons : []}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: 1,
                                    padding: "30px 16px",
                                    backgroundColor: "background.paper",
                                    boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                    borderRadius: "4px"
                                }}
                            >
                                {!progressing ? (
                                    <GlobalDataTable
                                        data={data}
                                        columns={column}
                                        subHeader={false}
                                        paginationServer
                                        paginationTotalRows={totalItems}
                                        progressPending={progressing}
                                        selectableRows={false}
                                        noHeader
                                        className="af-table"
                                        onChangePage={getData}
                                        onChangeRowsPerPage={changePage}
                                        handleFilter={handleFilter}
                                        noDataComponent={<p>No User Available</p>}
                                        filterName="Search Users"
                                    />
                                ) : (
                                    <TableSkeleton />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <CustomModal
                    open={isPasswordChangeModalOpen}
                    setOpen={setIsPasswordChangeModalOpen}
                    title="Update User Password"
                >
                    <Box sx={{ padding: "16px" }}>
                        <UserPasswordUpdateForm
                            setOpenStatus={setIsPasswordChangeModalOpen}
                            user_uuid={userUuid}
                        />
                    </Box>
                </CustomModal>
                <CustomConfirmModal
                    title="Are you sure you want to Active that user?"
                    subtitle="This action will activate that user and he/she will allowed to be access."
                    disagreedButtonTitle="NO, KEEP IT"
                    agreedButtonTitle="YES, ACTIVE IT"
                    open={activeModal}
                    setOpen={() => setActiveModal(false)}
                    agreedButtonColor="success"
                    handleSubmit={() => handleStatusChange(selectedUser.uuid, STATUS_ACTIVE)}
                    handleCancel={() => setActiveModal(false)}
                />
                <CustomConfirmModal
                    title="Are you sure you want to Inactive that user?"
                    subtitle="This action will inactivate that user and he/she will not allowed to be access."
                    disagreedButtonTitle="NO, KEEP IT"
                    agreedButtonTitle="YES, INACTIVE IT"
                    open={inactiveModal}
                    setOpen={() => setInactiveModal(false)}
                    agreedButtonColor="warning"
                    handleSubmit={() => handleStatusChange(selectedUser.uuid, STATUS_INACTIVE)}
                    handleCancel={() => setInactiveModal(false)}
                />
                <CustomDrawer
                    customClass={drawerOpenForEdit ? "active" : ""}
                    drawerOpen={drawerOpenForEdit}
                    setDrawerOpen={setDrawerOpenForEdit}
                    heading="Edit Team Member"
                >
                    <EditTeamAdmin
                        drawerOpen={drawerOpenForEdit}
                        setDrawerOpen={setDrawerOpenForEdit}
                        uuid={uuid}
                        setEditSuccess={setEditSuccess}
                        editSuccess={editSuccess}
                        setUuid={setUuid}
                    />
                </CustomDrawer>
                <CustomConfirmModal
                    title="Are you sure you want To remove this team memeber?"
                    subtitle="If you delete this data, you’ll not be able to recover it again."
                    disagreedButtonTitle="NO, KEEP IT"
                    agreedButtonTitle="YES, DELETE IT"
                    open={isDelete}
                    setOpen={() => setIsDelete(false)}
                    agreedButtonColor="warning"
                    handleSubmit={() => handleDelete()}
                    handleCancel={() => setIsDelete(false)}
                />
            </Box>
        </StyledEngineProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        users: state.adminUserReducer.tableData,
        isSuccess: state.adminUserReducer.isSuccess,
        currentUserData: state.userProfileManageReducer.userProfileData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserData: (currentPage = 1, itemPerPage = 10, searchKey = "") =>
            dispatch(
                getUserDataTable({
                    currentPage,
                    itemPerPage,
                    searchKey
                })
            ),
        forceLoginAsUser: (payload, callback) => dispatch(loginAsUser(payload, callback)),
        changeUserStatus: (payload, callback) => dispatch(userStatusChange(payload, callback)),
        makeUserAnAdmin: (payload, callback) => dispatch(makeUserAdmin(payload, callback)),
        deleteTeamData: (data, callback) => dispatch(deleteAdminAgencyTeamMember(data, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
