import { createGenerateClassName, StylesProvider } from "@mui/styles";
import { init as initApm } from "@elastic/apm-rum";
import { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Routes from "./routes/Routes";
import { logout } from "./statemanagement/login/loginAction";
import { getUserProfileData } from "./statemanagement/userProfileManage/userProfileManageAction";

const {
    REACT_APP_ACCESS_TOKEN,
    REACT_APP_EMAIL_VERIFIED_STATUS,
    REACT_APP_REFRESH_TOKEN,
    REACT_APP_LOGIN_URL,
    REACT_APP_IDLE_TIME,
    REACT_APP_APM_SERVICENAME,
    REACT_APP_APM_SERVERURL,
    REACT_APP_APM_SERVICEVERSION,
    REACT_APP_APM_ENVIRONMENT
} = process.env;

initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: REACT_APP_APM_SERVICENAME,

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: REACT_APP_APM_SERVERURL,

    // Set service version (required for sourcemap feature)
    serviceVersion: REACT_APP_APM_SERVICEVERSION,

    // Set the service environment
    environment: REACT_APP_APM_ENVIRONMENT,

    Type: ["page-load", "history", "xmlhttprequest", "fetch", "error"],
    disableInstrumentations: ["eventtarget", "click"]
});

const generateClassName = createGenerateClassName({
    productionPrefix: "prnt"
});

const App = (props) => {
    const { getUserProfileData } = props;
    // const timeout = REACT_APP_IDLE_TIME;

    useEffect(() => {
        if (window.location.pathname !== "/login" && window.location.pathname !== "/register") {
            getUserProfileData();
        }
    }, []);

    // const handleOnActive = () => {
    //     return null;
    // };
    // const handleOnIdle = () => {
    //     const currentURL = window.location.href;
    //     if (currentURL !== REACT_APP_LOGIN_URL) {
    //         let formBody = [];
    //         formBody.push("access_token=" + getCookie(REACT_APP_ACCESS_TOKEN));
    //         formBody.push("refresh_token=" + getCookie(REACT_APP_REFRESH_TOKEN));
    //         formBody = formBody.join("&");
    //         props.logout(formBody);
    //         setCookie(REACT_APP_ACCESS_TOKEN, "", "1 sec");
    //         setCookie(REACT_APP_REFRESH_TOKEN, "", "1 sec");
    //         setOtherCookie(REACT_APP_EMAIL_VERIFIED_STATUS, "");
    //         setCookieForRefresh(REACT_APP_REFRESH_TOKEN, "");
    //         window.location.replace(REACT_APP_LOGIN_URL);
    //     }
    // };

    // const {getRemainingTime, getLastActiveTime} = useIdleTimer({
    //     timeout,
    //     onActive: handleOnActive,
    //     onIdle: handleOnIdle,
    //     crossTab: {
    //         emitOnAllTabs: true,
    //     },
    // });

    return (
        <Router>
            <StylesProvider generateClassName={generateClassName}>
                <ToastContainer />
                <Routes />
            </StylesProvider>
        </Router>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (params) => dispatch(logout(params)),
        getUserProfileData: (params) => dispatch(getUserProfileData(params))
    };
};
export default connect(null, mapDispatchToProps)(App);
