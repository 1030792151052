import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { ThemeProvider } from "@mui/material/styles";
import storage from "local-storage-fallback";
import React, { useMemo } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import customTheme from "../../theme/theme";
import "./style/userLayout_custom.css";

const ColorModeContext = React.createContext({
    toggleColorMode: () => {
        console.log("toggleColor");
    }
});

const ErrorLayout = ({ pageTitle, children }) => {
    const themeMode = storage.getItem("mode");

    const [mode, setMode] = React.useState(themeMode || "light");

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
                storage.setItem("mode", mode === "light" ? "dark" : "light");
            }
        }),
        [mode]
    );

    const theme = useMemo(customTheme(mode), [mode]);

    const renderMain = () => {
        return (
            <HelmetProvider>
                {pageTitle && (
                    <Helmet defer={false}>
                        <title>{pageTitle}</title>
                    </Helmet>
                )}
                <ColorModeContext.Provider value={colorMode}>
                    <ThemeProvider theme={theme}>{children}</ThemeProvider>
                </ColorModeContext.Provider>
            </HelmetProvider>
        );
    };

    return <StyledEngineProvider injectFirst>{renderMain()}</StyledEngineProvider>;
};

export default ErrorLayout;
