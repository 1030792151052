/* eslint-disable prettier/prettier */
import { CustomBadge, CustomConfirmModal, CustomDrawer, CustomDropDownButtonMenu, CustomHeader, CustomModal, GlobalDataTable } from "@hipdevteam/afglobalcomponents";
import AddIcon from "@mui/icons-material/Add";
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from "@mui/icons-material/Login";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PasswordIcon from "@mui/icons-material/Password";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import { Box, Container, Grid, StyledEngineProvider, Typography } from "@mui/material";
import storage from "local-storage-fallback";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import TableSkeleton from "../../../components/SkeletonLoader/Table/TableSkeleton";
import { setCookie, setCookieForRefresh, setCookieWithPath } from "../../../helpers/Cookie";
import ShowAlerts from "../../../helpers/ShowAlerts";
import {
    deleteAdminAgencyTeamMember,
    getAgencyUsersDataTable,
    loginAsUser, makeUserAdmin,
    userStatusChange
} from "../../../statemanagement/Admin/Agencies/agencyAction";
import * as CONSTANT from "../../../utils/Constant";
import {
    ADMIN_LOGIN_UUID,
    ALERT_TYPE_ERROR,
    ALERT_TYPE_SUCCESS,
    IS_ADMIN_LOGGED_IN_AS_USER,
    STATUS_ACTIVE,
    STATUS_INACTIVE, SUPER_ADMIN_NO, SUPER_ADMIN_YES,
    USER_ROLE_ADMIN
} from "../../../utils/Constant/GlobalConstant";
import "../../Team/team.css";
import AddTeamAdmin from "./AddTeamAdmin";
import EditTeamAdmin from "./EditTeamAdmin";
import UserPasswordUpdateForm from "./Forms/UserPasswordUpdateForm";

const AdminAgencyUsers = (props) => {
    const history = useHistory();
    const { agencyId } = useParams();
    const {
        userData,
        isSuccess,
        getAgencyUsersListDataTable,
        forceLoginAsUser,
        changeUserStatus,
        currentUserData,
        makeUserAnAdmin,
        deleteTeamData
    } = props;

    const [data, setData] = useState([]);
    const [progressing, setProgressing] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [filterSearch, setFilterSearch] = useState("");
    const [userUuid, setUserUuid] = useState("");
    const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
    const [drawerOpenForEdit, setDrawerOpenForEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editSuccess, setEditSuccess] = useState(false)

    const [uuid, setUuid] = useState("");
    
    const components = [
        {
            name: "Settings",
            link: "/Dashboard",
        },
        { name: "Agency Users", link: "#" },
    ];

    // Data Table Start

    useEffect(() => {
        setProgressing(true);
        getAgencyUsersListDataTable(agencyId, currentPage, itemPerPage, filterSearch);
    }, []);
    useEffect(() => {
        if (editSuccess) {
            getAgencyUsersListDataTable(agencyId, currentPage, itemPerPage, filterSearch);
    }
},[editSuccess])
    useEffect(() => {
        if (isSuccess) {
            setData(userData.data);
            setProgressing(false);
            setTotalItems(userData.totalItems);
            setCurrentPage(userData.currentPage);
        }
    }, [isSuccess]);

    const changePage = (currentRowsPerPage, currentNewPage) => {
        getAgencyUsersListDataTable(agencyId, currentNewPage, currentRowsPerPage, filterSearch);
        setItemPerPage(currentRowsPerPage);
        setCurrentPage(currentNewPage);
    };

    const getData = (currentNewPage) => {
        getAgencyUsersListDataTable(agencyId, currentNewPage, itemPerPage, filterSearch);
        setCurrentPage(currentNewPage);
    };

    const handleFilter = async (searchKey) => {
        setFilterSearch(searchKey);
        getAgencyUsersListDataTable(agencyId, currentPage, itemPerPage, searchKey);
    };

    const {
        REACT_APP_ACCESS_TOKEN,
        REACT_APP_ACCESS_TOKEN_VALIDITY,
        REACT_APP_REFRESH_TOKEN,
        REACT_APP_EMAIL_VERIFIED_STATUS,
    } = process.env;

    const handleLoginAsUser = (user) => {
        forceLoginAsUser({ user_uuid: user.uuid }, (response) => {
            if (response.status) {
                storage.setItem(IS_ADMIN_LOGGED_IN_AS_USER, "true");
                storage.setItem(ADMIN_LOGIN_UUID, currentUserData.uuid);
                setCookie(
                    REACT_APP_ACCESS_TOKEN,
                    response.tokens.access.token,
                    REACT_APP_ACCESS_TOKEN_VALIDITY
                );
                setCookieForRefresh(REACT_APP_REFRESH_TOKEN, response.tokens.refresh.token);
                setCookieWithPath(
                    REACT_APP_EMAIL_VERIFIED_STATUS,
                    Number(response.data.email_verified) === 1,
                    "/"
                );
                setCookieWithPath("UUID", response.data.uuid, "/");
                setCookieWithPath("EMAIL", response.data.email, "/");
                window.location.replace("/dashboard");
            }
        });
    };

    const handleStatusChange = (uuid, status) => {
        changeUserStatus({ user_uuid: uuid, user_status: status }, (response) => {
            if (response.status) {
              ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
                getAgencyUsersListDataTable(agencyId, currentPage, itemPerPage, filterSearch);
            } else {
              ShowAlerts("Failed to update user status", ALERT_TYPE_ERROR);
            }
        });
    };

    const handleUserPasswordChange = (uuid) => {
        setIsPasswordChangeModalOpen(true);
        setUserUuid(uuid);
    };

    const handleAdminPrivilege = (uuid, privilege = SUPER_ADMIN_NO) => {
      makeUserAnAdmin({ user_uuid: uuid, privilege}, (response) => {

        if (response.status) {
          ShowAlerts(response.message, ALERT_TYPE_SUCCESS);
          getAgencyUsersListDataTable(agencyId, currentPage, itemPerPage, filterSearch);
        } else {
          ShowAlerts("Failed to update user status", ALERT_TYPE_ERROR);
        }
      });
    }

    const renderActionOptions = (row) => {
        const options = [
          {
            name: (
                <Box sx={{ display: "flex" }}>
                    <LoginIcon
                        sx={{
                    color: "action.active",
                    paddingRight: "10px",
                    width: "2rem !important",
                  }}
                />
                    <Typography variant="body1" color="text.primary">
                        Login As User
                    </Typography>
                </Box>
            ),
            handleClick: () => {
              handleLoginAsUser(row);
            },
          },
          {
            name: (
                <Box sx={{ display: "flex" }}>
                    {row.status === STATUS_ACTIVE ? (
                        <PauseIcon
                            sx={{
                      color: "action.active",
                      paddingRight: "10px",
                      width: "2rem !important",
                    }}
                  />
                ) : (
                    <PlayArrowIcon
                        sx={{
                      color: "action.active",
                      paddingRight: "10px",
                      width: "2rem !important",
                    }}
                  />
                )}
                    <Typography variant="body1" color="text.primary">
                        {row.status === STATUS_ACTIVE ? "Inactive" : "Active"}
                    </Typography>
                </Box>
            ),
            handleClick: () => {
              handleStatusChange(
                row.uuid,
                Number(row.status) === STATUS_ACTIVE ? STATUS_INACTIVE : STATUS_ACTIVE
              );
            },
          },
          {
            name: (
                <Box sx={{ display: "flex" }}>
                    <PasswordIcon
                        sx={{
                    color: "action.active",
                    paddingRight: "10px",
                    width: "2rem !important",
                  }}
                />
                    <Typography variant="body1" color="text.primary">
                        Change Password
                    </Typography>
                </Box>
            ),
            handleClick: () => {
              handleUserPasswordChange(row.uuid);
            },
          },
          {
            name: (
                <Box sx={{ display: "flex" }}>
                    <EditIcon
                        sx={{
                            color: "action.active",
                            paddingRight: "10px",
                            width: "2rem !important"
                        }}
                    />
                    <Typography variant="body1" color="text.primary">
                        Edit
                    </Typography>
                </Box>
            ),
            handleClick: () => {
                setDrawerOpenForEdit(true);
                setUuid(row.uuid);
            }
        },
        {
            name: (
                <Box sx={{ display: "flex" }}>
                    <DeleteIcon
                        sx={{
                            color: "action.active",
                            paddingRight: "10px",
                            width: "2rem !important"
                        }}
                    />
                    <Typography variant="body1" color="text.primary">
                        Delete
                    </Typography>
                </Box>
            ),
            handleClick: () => {
                setIsDelete(true);
                setSelectedItem({...row});
            }
        }
        ];

        if ( row.role === USER_ROLE_ADMIN && row.is_super_admin !== SUPER_ADMIN_YES ) {
          options.push({
            name: (
                <Box sx={{ display: "flex" }}>
                    <AddModeratorIcon
                        sx={{
                    color: "action.active",
                    paddingRight: "10px",
                    width: "2rem !important",
                  }}
                />
                    <Typography variant="body1" color="text.primary">
                        Make Admin
                    </Typography>
                </Box>
            ),
            handleClick: () => {
              handleAdminPrivilege(row.uuid, SUPER_ADMIN_YES);
            },
          })
        }

        if ( row.is_super_admin === SUPER_ADMIN_YES) {
          options.push({
            name: (
                <Box sx={{ display: "flex" }}>
                    <RemoveModeratorIcon
                        sx={{
                    color: "action.active",
                    paddingRight: "10px",
                    width: "2rem !important",
                  }}
                />
                    <Typography variant="body1" color="text.primary">
                        Remove Admin Privilege
                    </Typography>
                </Box>
            ),
            handleClick: () => {
              handleAdminPrivilege(row.uuid, SUPER_ADMIN_NO);
            },
          })
        }

        return options;
    };
    const handleDelete = () => {
        deleteTeamData({uuid:selectedItem.uuid}, (response) => {
            if (response.status) {
                getAgencyUsersListDataTable(agencyId, currentPage, itemPerPage, filterSearch);
                ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
            }
        });
        setIsDelete(false);
    };

  const column = [
    {
      name: "Name",
      selector: (row) => {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography component="div" color="text.primary" variant="body2">
                    {`${row.first_name  } ${  row.last_name}`}
                </Typography>
            </Box>);
      },
      sortable: true
    },
    {
      name: "Email",
      selector: (row) => {
        return (
            <Box>
                {!row.email && <CustomBadge label="Not Found" color="info" />}
                {row.email && (
                <Typography variant="body2">{row.email}</Typography>
            )}
            </Box>
        );
      }
    },
    {
      name: "Phone No",
      selector: (row) => {
        return (
            <Box>
                {!row.phone_number && <CustomBadge label="Not Found" color="info" />}
                {row.phone_number && (
                <Typography variant="body2">{row.phone_number}</Typography>
            )}
            </Box>
        );
      }
    },
    {
      name: "Role",
      selector: (row) => {

        let color = 'default';
        let label = 'User';

        if ( row.role === USER_ROLE_ADMIN ) {
          color = 'secondary';
          label = 'Admin';
        }

        return (
            <Typography variant="body1" component="p">
                <CustomBadge color={color} label={label} />
            </Typography>
        );
      }
    },
    {
      name: "Status",
      selector: (row) => {
        return (
            <Typography variant="body1" component="p">
                {row.status === STATUS_INACTIVE ? (
                    <CustomBadge color="warning" label="Inactive" />
            ) : (
                <CustomBadge color="success" label="Active" />
            )}
            </Typography>
        );
      }
    },
    {
      name: "Actions",
      selector: (row) => (
          <Box>
             
              <CustomDropDownButtonMenu
                  buttonContent={<MoreVertIcon color="action.active" />}
                  options={renderActionOptions(row)}
          />
          </Box>

      )
    }
  ];
  const headerButtons = [
    {
        name: "Add Team Member",
        color: "secondary",
        startIcon: <AddIcon />,
        action: () => setDrawerOpen(true)
    },
    {
        name: "Back",
        color: "secondary",
        startIcon: <ArrowBackIcon />,
        action: () => history.goBack(),
    },
  
];

    return (
        <StyledEngineProvider injectFirst>
            <Box className="team-wrapper">
                <Container
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingTop: "10px",
                        height: "100vh",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ width: 1, mb: "30px" }}>
                                <CustomHeader
                                    components={components}
                                    headerText="Agency Users"
                                    color="secondary"
                                    buttons={history.location.key != undefined ? headerButtons : []}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: 1,
                                    padding: "30px 16px",
                                    backgroundColor: "background.paper",
                                    boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                    borderRadius: "4px",
                                }}
                            >
                                {!progressing ? (
                                    <GlobalDataTable
                                        data={data}
                                        columns={column}
                                        subHeader={false}
                                        paginationServer
                                        paginationTotalRows={totalItems}
                                        progressPending={progressing}
                                        selectableRows={false}
                                        noHeader
                                        className="af-table"
                                        onChangePage={getData}
                                        onChangeRowsPerPage={changePage}
                                        handleFilter={handleFilter}
                                        noDataComponent={<p>No User Available</p>}
                                        filterName="Search Users"
                                    />
                                ) : (
                                    <TableSkeleton />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <CustomModal
                    open={isPasswordChangeModalOpen}
                    setOpen={setIsPasswordChangeModalOpen}
                    title="Update User Password"
                >
                    <Box sx={{ padding: "16px" }}>
                        <UserPasswordUpdateForm
                            setOpenStatus={setIsPasswordChangeModalOpen}
                            user_uuid={userUuid}
                        />
                    </Box>
                </CustomModal>
            </Box>
            <CustomDrawer
                customClass={drawerOpen ? "active" : ""}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                heading="Add Team Member"
            >
                <AddTeamAdmin drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </CustomDrawer>
            <CustomDrawer
                customClass={drawerOpenForEdit ? "active" : ""}
                drawerOpen={drawerOpenForEdit}
                setDrawerOpen={setDrawerOpenForEdit}
                heading="Edit Team Member"
            >
                <EditTeamAdmin
                    drawerOpen={drawerOpenForEdit}
                    setDrawerOpen={setDrawerOpenForEdit}
                    uuid={uuid}
                    setUuid={setUuid}
                    setEditSuccess={setEditSuccess}
                    editSuccess = {editSuccess}
                />
            </CustomDrawer>
            <CustomConfirmModal
                title="Are you sure you want To remove this team memeber?"
                subtitle="If you delete this data, you’ll not be able to recover it again."
                disagreedButtonTitle="NO, KEEP IT"
                agreedButtonTitle="YES, DELETE IT"
                open={isDelete}
                setOpen={() => setIsDelete(false)}
                agreedButtonColor="warning"
                handleSubmit={() => handleDelete()}
                handleCancel={() => setIsDelete(false)}
            />
        </StyledEngineProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state.adminAgencyReducer.agencyUserTableData,
        isSuccess: state.adminAgencyReducer.isAgencyUserSuccess,
        currentUserData: state.userProfileManageReducer.userProfileData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAgencyUsersListDataTable: (
            agencyId = null,
            currentPage = 1,
            itemPerPage = 10,
            searchKey = ""
        ) =>
            dispatch(
                getAgencyUsersDataTable({
                    agencyId,
                    currentPage,
                    itemPerPage,
                    searchKey,
                })
            ),
        forceLoginAsUser: (payload, callback) => dispatch(loginAsUser(payload, callback)),
        changeUserStatus: (payload, callback) => dispatch(userStatusChange(payload, callback)),
        makeUserAnAdmin: (payload, callback) => dispatch(makeUserAdmin(payload, callback)),
        deleteTeamData: (data, callback) => dispatch(deleteAdminAgencyTeamMember(data, callback))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminAgencyUsers);
