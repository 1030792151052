import { CustomButton, CustomHeader, CustomTextField } from "@hipdevteam/afglobalcomponents";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Box, Grid, StyledEngineProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ShowAlerts from "../../helpers/ShowAlerts";
import {
    getAuthCredentials,
    updateAuthCredentials
} from "../../statemanagement/apiAuth/apiAuthAction";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, AUTH_TYPE_MAILGUN } from "../../utils/Constant";
import { isEmailValid } from "../../utils/helperFunctions";

const MailgunAuthSetup = (props) => {
    const { getAuthCredentialsData, updateAuthCredentialsData } = props;
    const [mailgunData, setMailgunData] = useState({
        mailgun_email: "",
        mailgun_domain: "",
        mailgun_key: ""
    });
    const [isError, setIsError] = useState(false);
    const components = [
        {
            name: "Dashboard",
            link: "/sstool/dashboard",
            icon: null
        },
        {
            name: "Settings",
            link: "#",
            icon: null
        },
        {
            name: "Integrations",
            link: "#",
            icon: null
        },
        { name: "Mailgun Auth", link: "#", icon: null }
    ];

    useEffect(() => {
        getAuthCredentialsData({ auth_type: AUTH_TYPE_MAILGUN }, (response) => {
            if (response.data && response.data.status) {
                setMailgunData(response.data.data.auth_data);
            }
        });
    }, []);

    const handleChange = (e) => {
        setMailgunData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            mailgunData.mailgun_email === "" ||
            !isEmailValid(mailgunData.mailgun_email) ||
            mailgunData.mailgun_domain === "" ||
            mailgunData.mailgun_key === ""
        ) {
            setIsError(true);
            return;
        }

        const payload = {
            type: AUTH_TYPE_MAILGUN,
            auth_data: mailgunData
        };
        updateAuthCredentialsData(payload, (response) => {
            if (response.data.status) {
                ShowAlerts(response.data.message, ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(response.data.message, ALERT_TYPE_ERROR);
            }
        });
    };

    return (
        <StyledEngineProvider injectFirst>
            <Box className="user_settings_container">
                <Box sx={{ width: 1, mb: "30px" }} className="user_settings">
                    <Box fullWidth>
                        <Box sx={{ width: 1, mb: "30px" }}>
                            <CustomHeader components={components} headerText="Mailgun Auth Setup" />
                        </Box>
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "0px",
                                padding: "20px 0px 20px 20px",
                                boxShadow: "none",
                                paddingBottom: "0px",
                                backgroundColor: "#1b1464"
                            }}
                            xs={10}
                            md={12}
                            sm={12}
                        >
                            <Typography
                                variant="subtitle1"
                                component="span"
                                sx={{ paddingBottom: "20px" }}
                                color="common.white"
                            >
                                Mailgun Auth Setup
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                backgroundColor: "background.paper",
                                marginTop: "0px !important"
                            }}
                            xs={10}
                            md={12}
                            sm={12}
                            className="schema__form"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box>
                                            <form className="" onSubmit={handleSubmit}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <CustomTextField
                                                            name="mailgun_email"
                                                            label="Mailgun Email*"
                                                            id="mailgun_email"
                                                            autoComplete="off"
                                                            type="text"
                                                            value={
                                                                mailgunData.mailgun_email !== ""
                                                                    ? mailgunData.mailgun_email
                                                                    : ""
                                                            }
                                                            placeholder="Write your Mailgun Email"
                                                            handleChange={handleChange}
                                                            error={
                                                                isError &&
                                                                (mailgunData.mailgun_email === "" ||
                                                                    !isEmailValid(
                                                                        mailgunData.mailgun_email
                                                                    ))
                                                            }
                                                        />
                                                        {isError &&
                                                            mailgunData.mailgun_email === "" &&
                                                            isEmailValid(
                                                                mailgunData.mailgun_email
                                                            ) && (
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    align="left"
                                                                    color="error"
                                                                >
                                                                    Mailgun Email required
                                                                </Typography>
                                                            )}
                                                        {isError &&
                                                            !isEmailValid(
                                                                mailgunData.mailgun_email
                                                            ) &&
                                                            mailgunData.mailgun_email !== "" && (
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    align="left"
                                                                    color="error"
                                                                >
                                                                    Invalid Email
                                                                </Typography>
                                                            )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CustomTextField
                                                            name="mailgun_domain"
                                                            label="Mailgun Domain*"
                                                            autoComplete="off"
                                                            id="mailgun_domain"
                                                            type="text"
                                                            placeholder="Write your Mailgun Domain"
                                                            handleChange={handleChange}
                                                            value={
                                                                mailgunData.mailgun_domain !== ""
                                                                    ? mailgunData.mailgun_domain
                                                                    : ""
                                                            }
                                                            error={
                                                                isError &&
                                                                mailgunData.mailgun_domain === ""
                                                            }
                                                        />
                                                        {isError &&
                                                            mailgunData.mailgun_domain === "" && (
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    align="left"
                                                                    color="error"
                                                                >
                                                                    Mailgun Domain required
                                                                </Typography>
                                                            )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CustomTextField
                                                            name="mailgun_key"
                                                            label="Mailgun Key*"
                                                            id="mailgun_key"
                                                            autoComplete="off"
                                                            type="text"
                                                            placeholder="Write your Mailgun Key"
                                                            value={
                                                                mailgunData.mailgun_key !== ""
                                                                    ? mailgunData.mailgun_key
                                                                    : ""
                                                            }
                                                            error={
                                                                isError &&
                                                                mailgunData.mailgun_key === ""
                                                            }
                                                            handleChange={handleChange}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <VpnKeyIcon color="disabled" />
                                                                )
                                                            }}
                                                        />
                                                        {isError && mailgunData.mailgun_key === "" && (
                                                            <Typography
                                                                variant="subtitle2"
                                                                align="left"
                                                                color="error"
                                                            >
                                                                Mailgun Key required
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                    md={12}
                                                    sm={12}
                                                    className="schema__form__btn__v1"
                                                >
                                                    <Box
                                                        className="save_button"
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            marginTop: "20px"
                                                        }}
                                                    >
                                                        <CustomButton
                                                            handleButton={null}
                                                            title="SAVE CHANGES"
                                                            variant="contained"
                                                            color="secondary"
                                                            type="submit"
                                                        />
                                                    </Box>
                                                </Grid>
                                            </form>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAuthCredentialsData: (params, callback) =>
            dispatch(getAuthCredentials(params, callback)),
        updateAuthCredentialsData: (params, callback) =>
            dispatch(updateAuthCredentials(params, callback))
    };
};

export default connect(null, mapDispatchToProps)(MailgunAuthSetup);
