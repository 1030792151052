export const GHL_AD_SOURCE_TABLE_AND_COLUMN_SYNC = "GHL_AD_SOURCE_TABLE_AND_COLUMN_SYNC";
export const GET_GHL_AD_SOURCE_TABLE_DATA_TABLE = "GET_GHL_AD_SOURCE_TABLE_DATA_TABLE";
export const GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_SUCCESS =
    "GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_SUCCESS";
export const GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_FAILED =
    "GET_GHL_AD_SOURCE_TABLE_DATA_TABLE_FAILED";

export const GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE =
    "GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE";
export const GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE_SUCCESS =
    "GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE_SUCCESS";
export const GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE_FAILED =
    "GET_GHL_AD_SOURCE_TABLE_COLUMN_DATA_TABLE_FAILED";

export const GHL_UPDATE_AD_SOURCE_TABLE_COLUMN = "GHL_UPDATE_AD_SOURCE_TABLE_COLUMN";
export const GHL_ADD_AD_SOURCE_TABLE = "GHL_ADD_AD_SOURCE_TABLE";
export const GHL_ADD_AD_SOURCE_TABLE_COLUMN = "GHL_ADD_AD_SOURCE_TABLE_COLUMN";
export const GHL_ADD_AD_SOURCE_CUSTOM_COLUMN = "GHL_ADD_AD_SOURCE_CUSTOM_COLUMN";
