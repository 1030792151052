import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as CONSTANT from "../utils/Constant";

const ShowAlerts = (message, type) => {
    const defaultMessage = "Sorry Something Wrong please try again";
    message = message !== undefined ? message : defaultMessage;

    switch (type) {
        case CONSTANT.ALERT_TYPE_SUCCESS:
            return toast.success(message);
        case CONSTANT.ALERT_TYPE_ERROR:
            return toast.error(message);
        case CONSTANT.ALERT_TYPE_WARNING:
            return toast.warning(message);
        default:
            return toast.warning(defaultMessage);
    }
};
export default ShowAlerts;
