import React from "react";
import { Route } from "react-router-dom";
import ErrorLayout from "../components/layouts/ErrorLayout";

const PublicLayoutRoute = ({ component: Component, pageTitle, appName, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <ErrorLayout pageTitle={pageTitle} appName={appName}>
                    <Component {...props} />
                </ErrorLayout>
            )}
        />
    );
};

export default PublicLayoutRoute;
