/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
import {
    CustomButton,
    CustomIconButton,
    CustomSelectBox,
    CustomTextField
} from "@hipdevteam/afglobalcomponents";
import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import ShowAlerts from "../../../../helpers/ShowAlerts";
import {
    getGeogridApiAuth,
    updateGeogridApiAuthData
} from "../../../../statemanagement/Admin/GeogridApiAuth/geogridApiAuthAction";
import { authTypeOptions } from "../../../../utils/Constant/GeogridAuthApi";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from "../../../../utils/Constant/GlobalConstant";

const schema = yup.object({
    auth_type: yup.string().required("App Name required").max(255),
    authData: yup.array().of(
        yup.object({
            field_name: yup.string(),
            field_value: yup.string()
        })
    )
});

const ApiAdd = (props) => {
    const {
        setDrawerOpen,
        getGeogridApiAuthData,
        updateApiAuthData,
        reloadData,
        id,
        isDrawerOpen
    } = props;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const defaultValues = {
        auth_type: "",
        authData: []
    };
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
        setValue,
        reset
    } = useForm({ defaultValues, resolver: yupResolver(schema) });

    const {
        fields: authDataFields,
        append: authDataFieldAppend,
        remove: authDataFieldRemove
    } = useFieldArray({ control, name: "authData" });

    const dataDeStructuring = (authData) => {
        const deStructuredAuthData = [];
        for (const key in authData) {
            if (Object.hasOwnProperty.call(authData, key)) {
                const element = authData[key];

                deStructuredAuthData.push({ field_name: key, field_value: element });
            }
        }
        return deStructuredAuthData;
    };

    useEffect(() => {
        if (!isDrawerOpen || !id) return;
        getGeogridApiAuthData({ id }, (response) => {
            const data = response.data;
            const deStructuredAuthData = dataDeStructuring(data.auth_data);

            setValue("auth_type", data.auth_type);
            setValue("authData", deStructuredAuthData);
        });
    }, [id]);

    const dataProcessing = (authData) => {
        let processedAuthData;
        for (let i = 0; i < authData.length; i += 1) {
            const element = authData[i];

            processedAuthData = { ...processedAuthData, [element.field_name]: element.field_value };
        }

        return processedAuthData;
    };

    const onSubmit = (data) => {
        const processedAuthData = dataProcessing(data.authData);
        const formData = { id, auth_type: data.auth_type, auth_data: processedAuthData };
        setIsSubmitted(true);
        updateApiAuthData(formData, (res) => {
            setIsSubmitted(false);
            if (res.status) {
                reset({
                    auth_type: "",
                    authData: [{ field_name: "", field_value: "" }]
                });
                reloadData();
                setDrawerOpen(false);
                ShowAlerts(res.message, ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts(res.message, ALERT_TYPE_ERROR);
            }
        });
    };

    const renderErrorMessage = (error, message) => {
        if (error !== undefined) {
            return (
                <Typography variant="subtitle2" component="span" color="error">
                    {message}
                </Typography>
            );
        }
        return "";
    };

    const customAddAuthData = (index) => {
        const prevAuthDataFields = watch("authData");
        const len = prevAuthDataFields.length - 1;
        for (let i = 0; i < len; i += 1) {
            const element = prevAuthDataFields[i];
            if (element.field_name == prevAuthDataFields[index].field_name) {
                ShowAlerts("Keys should be unique.", ALERT_TYPE_ERROR);
                return;
            }
        }
        if (
            prevAuthDataFields[index].field_name == "" ||
            prevAuthDataFields[index].field_name == undefined ||
            prevAuthDataFields[index].field_value == "" ||
            prevAuthDataFields[index].field_value == undefined
        ) {
            ShowAlerts("Please enter previous row first.", ALERT_TYPE_ERROR);
        } else {
            authDataFieldAppend(index);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <Controller
                        render={({ field }) => (
                            <CustomSelectBox
                                options={authTypeOptions}
                                color="secondary"
                                register={field}
                                error={errors?.auth_type}
                                label="Auth Type"
                            />
                        )}
                        control={control}
                        name="auth_type"
                    />

                    <Typography component="p">
                        {renderErrorMessage(errors?.auth_type, errors?.auth_type?.message)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        component="fieldset"
                        sx={{
                            marginRight: 0,
                            marginLeft: 0,
                            marginTop: "1.5rem",
                            borderRadius: "4px",
                            padding: "15px 30px 30px 30px",
                            borderColor: "text.info",
                            borderStyle: "dashed",
                            borderWidth: "1px"
                        }}
                    >
                        <legend>
                            <Box sx={{ transform: " scale(0.80)" }} color="text.info">
                                Api Auth Data
                            </Box>
                        </legend>
                        <Box>
                            {authDataFields.map((item, index) => {
                                return (
                                    <Box
                                        key={item.id}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gridGap: "12px",
                                            marginTop: "12px"
                                        }}
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <CustomTextField
                                                    id="field_name"
                                                    type="text"
                                                    placeholder="Field Name"
                                                    register={field}
                                                    error={
                                                        errors?.authData &&
                                                        errors.authData[index] &&
                                                        errors.authData[index].field_name
                                                    }
                                                    helperText={renderErrorMessage(
                                                        errors.authData && errors.authData[index]
                                                            ? errors.authData[index]
                                                            : undefined,
                                                        errors.authData && errors.authData[index]
                                                            ? "Name Required"
                                                            : "Name Required"
                                                    )}
                                                />
                                            )}
                                            name={`authData[${index}].field_name`}
                                            control={control}
                                        />
                                        <Controller
                                            render={({ field }) => (
                                                <CustomTextField
                                                    id="field_value"
                                                    type="text"
                                                    label="Field Value"
                                                    register={field}
                                                    error={
                                                        errors.authData &&
                                                        errors.authData[index] &&
                                                        errors.authData[index].field_value
                                                    }
                                                    helperText={renderErrorMessage(
                                                        errors.authData && errors.authData[index]
                                                            ? errors.authData[index]
                                                            : undefined,
                                                        errors.authData && errors.authData[index]
                                                            ? "Value Required"
                                                            : "Value Required"
                                                    )}
                                                />
                                            )}
                                            name={`authData[${index}].field_value`}
                                            control={control}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gridGap: "12px"
                                            }}
                                        >
                                            {authDataFields.length - 1 === index && (
                                                <CustomIconButton
                                                    // sx={{ alignItems: "flex-start" }}
                                                    handleButton={() => customAddAuthData(index)}
                                                    color="secondary"
                                                >
                                                    <AddCircleOutlineRoundedIcon />
                                                </CustomIconButton>
                                            )}
                                            {authDataFields.length !== 1 && (
                                                <CustomIconButton
                                                    handleButton={() => authDataFieldRemove(index)}
                                                    color="secondary"
                                                >
                                                    <DeleteIcon />
                                                </CustomIconButton>
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px"
                }}
            >
                <CustomButton
                    sx={{ marginRight: "20px" }}
                    handleButton={() => setDrawerOpen(false)}
                    title="Cancel"
                    variant="contained"
                    color="info"
                />
                <CustomButton
                    disabled={isSubmitted}
                    handleButton={null}
                    title="Save Changes"
                    variant="contained"
                    type="submit"
                    color="secondary"
                />
            </Box>
        </form>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateApiAuthData: (params, callback) =>
            dispatch(updateGeogridApiAuthData(params, callback)),
        getGeogridApiAuthData: (params, callback) => dispatch(getGeogridApiAuth(params, callback))
    };
};

export default connect(null, mapDispatchToProps)(ApiAdd);
