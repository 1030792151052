import * as ACTION from "./apiAuthActionTypes";

export const getAuthCredentials = (payload, callback) => ({
    type: ACTION.AUTH_CREDENTIALS_GET,
    payload,
    callback
});

export const updateAuthCredentials = (payload, callback) => ({
    type: ACTION.AUTH_CREDENTIALS_UPDATE,
    payload,
    callback
});
