import storage from "local-storage-fallback";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { googleLoginApi, googleSignInApi, loginRequest, logoutRequest } from "../../api/loginApi";
import { setCookie, setCookieForRefresh, setOtherCookie } from "../../helpers/Cookie";
import * as ACTION from "./loginActionTypes";

const {
    REACT_APP_ACCESS_TOKEN,
    REACT_APP_ACCESS_TOKEN_VALIDITY,
    REACT_APP_REFRESH_TOKEN,
    REACT_APP_EMAIL_VERIFIED_STATUS
} = process.env;

function* loginAttemptSaga(action) {
    try {
        const response = yield call(loginRequest, action.payload);
        const responseData = response.data;
        if (responseData.status) {
            setCookie(
                REACT_APP_ACCESS_TOKEN,
                responseData.tokens.access.token,
                REACT_APP_ACCESS_TOKEN_VALIDITY
            );
            setCookieForRefresh(REACT_APP_REFRESH_TOKEN, responseData.tokens.refresh.token);

            setOtherCookie(
                REACT_APP_EMAIL_VERIFIED_STATUS,
                Number(responseData.data.email_verified) === 1
            );

            setOtherCookie("UUID", responseData.data.uuid);
            setOtherCookie("EMAIL", responseData.data.email);

            yield put({
                type: ACTION.LOGIN_SUCCESS,
                payload: responseData.data
            });
            yield put({
                type: ACTION.FETCH_USER_PROFILE_DATA_SUCCESS,
                payload: responseData
            });
            storage.setItem("modePostfix", responseData.data.uuid);
        } else {
            yield put({
                type: ACTION.LOGIN_ERROR,
                payload: responseData.message
            });
        }
    } catch (error) {
        console.log(error);
        yield put({
            type: ACTION.LOGIN_ERROR,
            payload: error.message
        });
    }
}

function* googleLoginAttempSaga(action) {
    try {
        const response = yield call(googleLoginApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        yield put({
            type: ACTION.LOGIN_ERROR,
            payload: error.message
        });
    }
}

function* googleSignInSaga(action) {
    try {
        const response = yield call(googleSignInApi, action.payload);

        const responseData = response.data;
        if (action.callback) {
            action.callback(responseData);
        }
    } catch (error) {
        yield put({
            type: ACTION.LOGIN_ERROR,
            payload: error.message
        });
    }
}

function* logoutSaga(action) {
    try {
        yield call(logoutRequest, action.payload);
    } catch (error) {
        console.log(error);
    }
}
function* loginWatcher() {
    yield takeEvery(ACTION.LOGIN_ATTEMPT, loginAttemptSaga);
    yield takeEvery(ACTION.GOOGLE_LOGIN, googleLoginAttempSaga);
    yield takeEvery(ACTION.LOGOUT_SUCCESS, logoutSaga);
    yield takeEvery(ACTION.GOOGLE_SIGN_IN_NEW, googleSignInSaga);
}

export default function* loginSaga() {
    yield all([loginWatcher()]);
}
