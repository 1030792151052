import { CustomSkeleton } from "@hipdevteam/afglobalcomponents";
import { Box, Divider, Grid, Typography } from "@mui/material";
import FileUploadSkeleton from "../AgencySettings/FileUploadSkeleton";
import BreadrumSkeleton from "../Header/BreadrumSkeleton";

const WhiteLabelDomainSkeleton = () => {
    return (
        <Box className="user_settings_container">
            <Box sx={{ width: 1, mb: "30px" }} className="user_settings">
                <Box fullWidth>
                    <Box sx={{ width: 1, mb: "30px" }}>
                        <BreadrumSkeleton />
                    </Box>
                    <Grid
                        item
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0px",
                            padding: "20px 20px 20px 20px",
                            boxShadow: "none",
                            backgroundColor: "#1b1464"
                        }}
                        xs={10}
                        md={12}
                        sm={12}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gridGap: "6px"
                            }}
                        >
                            <CustomSkeleton variant="text" height={20} width={50} />
                        </Box>
                        <CustomSkeleton variant="text" width="100%" />
                        <CustomSkeleton variant="text" width="100%" />
                    </Grid>
                    <Grid
                        item
                        sx={{ backgroundColor: "background.paper", marginTop: "0px !important" }}
                        xs={10}
                        md={12}
                        sm={12}
                        className="schema__form"
                    >
                        <Typography
                            variant="h5"
                            component="span"
                            className="schema__form__header"
                            color="text.fill"
                        >
                            <CustomSkeleton variant="text" width={50} />
                        </Typography>
                        <form>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box>
                                            <Grid container spacing={3}>
                                                <Grid item xs={9}>
                                                    <CustomSkeleton
                                                        variant="rectangular"
                                                        height={42}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <CustomSkeleton
                                                        variant="rectangular"
                                                        width={140}
                                                        height={42}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "baseline",
                                                                gridGap: "6px"
                                                            }}
                                                        >
                                                            <CustomSkeleton
                                                                variant="text"
                                                                height={42}
                                                                width={100}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider
                                                        sx={{
                                                            marginTop: "1rem",
                                                            marginBottom: "1rem"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            backgroundColor: "background.paper",
                            marginTop: "0px !important",
                            paddingTop: "0px !important"
                        }}
                        xs={10}
                        md={12}
                        sm={12}
                        className="schema__form"
                    >
                        <CustomSkeleton variant="text" height={42} width={100} />
                        <form>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <CustomSkeleton
                                                        variant="rectangular"
                                                        height={42}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <form className="agency_logo_upload">
                                                        <FileUploadSkeleton />
                                                    </form>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={10}
                                                md={12}
                                                sm={12}
                                                className="schema__form__btn__v1"
                                            >
                                                <Box
                                                    className="save_button"
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        marginTop: "20px"
                                                    }}
                                                >
                                                    <CustomSkeleton
                                                        variant="rectangular"
                                                        width={140}
                                                        height={42}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default WhiteLabelDomainSkeleton;
