import { sendRequestWithToken } from "./rootApi";

export const uploadHolidayListApi = async (data) => {
    return sendRequestWithToken("post", `${process.env.REACT_APP_API_BASE_URL}/holiday/`, data);
};
export const updateHolidayStatusApi = async (param) => {
    const { id } = param;

    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/holiday/${id}`,
        param
    );
};

export const getHolidayListApi = async (param) => {
    const { currentPage, rowLimit, searchKey, year } = param;

    const url = `${process.env.REACT_APP_API_BASE_URL}/holiday/list?page=${currentPage}&limit=${rowLimit}&search_key=${searchKey}&year=${year}`;

    return sendRequestWithToken("get", url);
};

export const bulkDeleteHolidaysApi = async (data) => {
    return sendRequestWithToken(
        "delete",
        `${process.env.REACT_APP_API_BASE_URL}/holiday/delete`,
        data
    );
};
