import * as ACTION from "./userProfileManageActionType";

export const getUserProfileData = (payload, callback) => ({
    type: ACTION.USER_PROFILE_DATA_GET,
    payload,
    callback
});

export const getUserProfileDataSuccess = (payload) => ({
    type: ACTION.FETCH_USER_PROFILE_DATA_SUCCESS,
    payload
});

// Update Profile
export const attemptProfileUInfoUpdate = (payload, callback) => ({
    type: ACTION.PROFILE_INFO_UPDATE_ATTEMPT,
    payload,
    callback
});

export const profileUInfoUpdateSuccess = (data) => ({
    type: ACTION.PROFILE_INFO_UPDATE_SUCCESS,
    payload: data
});
export const profileUInfoUpdateError = (data) => ({
    type: ACTION.PROFILE_INFO_UPDATE_ERROR,
    payload: data
});

// Password Changing Actions
export const attemptChangeUserPassword = (payload, callback) => ({
    type: ACTION.USER_PASSWORD_CHANGE_ATTEMPT,
    payload,
    callback
});

export const changeUserPasswordSuccess = () => ({
    type: ACTION.USER_PASSWORD_CHANGE_SUCCESS,
    payload: []
});

export const changeUserPasswordError = (payload) => ({
    type: ACTION.USER_PASSWORD_CHANGE_ERROR,
    payload
});

// Image  Changing Actions
export const changeUserProfileImage = (userProfileImageData, callback) => ({
    type: ACTION.USER_PROFILE_IMAGE_CHANGE,
    payload: {
        userProfileImageData,
        callback
    }
});

export const getUserProfileImageChangeError = (payload) => ({
    type: ACTION.USER_PROFILE_IMAGE_CHANGE_ERROR,
    payload
});

export const userProfileImageChangeErrorsClear = () => ({
    type: ACTION.USER_PROFILE_IMAGE_CHANGE_ERROR_CLEAR,
    payload: []
});

export const userProfileImageFormSuccess = (payload) => ({
    type: ACTION.USER_PROFILE_IMAGE_CHANGE_ERROR_FOR_FORM_SUCCESS,
    payload: false
});

export const changePassword = (payload, callback) => ({
    type: ACTION.CHANGE_PASSWORD,
    payload,
    callback
});
