/* eslint-disable no-nested-ternary */
import { CustomButton, CustomIconButton, CustomTooltip } from "@hipdevteam/afglobalcomponents";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GridOnIcon from "@mui/icons-material/GridOn";
import GroupsIcon from "@mui/icons-material/Groups";
import LoginIcon from "@mui/icons-material/Login";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { Box, Typography, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import storage from "local-storage-fallback";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Icons from "../../../assets/Icons/Icons";
import {
    getCookie,
    setCookie,
    setCookieForRefresh,
    setCookieWithPath,
    setOtherCookie
} from "../../../helpers/Cookie";
import { loginAsAdmin } from "../../../statemanagement/Admin/Agencies/agencyAction";
import { getApps } from "../../../statemanagement/app/appAction";
import { logout } from "../../../statemanagement/login/loginAction";
import * as CONSTANT from "../../../utils/Constant";
import {
    ADMIN_LOGIN_UUID,
    IS_ADMIN_LOGGED_IN_AS_USER
} from "../../../utils/Constant/GlobalConstant";
import SideBarSkeleton from "../../SkeletonLoader/Sidebar/SideBarSkeleton";
import "./core_sidebar.css";
import GetIconByIdentifier from "./SideBarIcons";

const SideBar = ({
    drawerWidth,
    mobileOpen,
    setMobileOpen,
    ThemeToggleButton,
    customClass = null,
    appName,
    userProfileData,
    getApps,
    apps,
    logout,
    forceLoginAsUser
}) => {
    const history = useHistory();
    const theme = useTheme();
    const [activeRoute, setActiveRoute] = useState("");
    const [loadAppList, setLoadAppList] = useState(false);
    const [haveSubscription, setHaveSubscription] = useState(false);
    const [appList, setAppList] = useState(null);
    const [userRole, setUserRole] = useState(0);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isAdminLoggedInAsUser, setIsAdminLoggedInAsUser] = useState(false);

    const {
        REACT_APP_ACCESS_TOKEN,
        REACT_APP_ACCESS_TOKEN_VALIDITY,
        REACT_APP_REFRESH_TOKEN,
        REACT_APP_EMAIL_VERIFIED_STATUS
    } = process.env;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        setActiveRoute(history.location.pathname);
        getApps({});
    }, []);

    useEffect(() => {
        if (apps.isSuccess) {
            setAppList(apps.apps);
            setHaveSubscription(apps.haveSubscription);
            setTimeout(() => {
                setLoadAppList(true);
            }, 1000);
        }
    }, [apps.isSuccess]);

    useEffect(() => {
        const adminLoggedInAsUser = storage.getItem(IS_ADMIN_LOGGED_IN_AS_USER);
        if (adminLoggedInAsUser === true || adminLoggedInAsUser === "true") {
            setIsAdminLoggedInAsUser(true);
        }
    }, []);

    const handleMenuClick = (route) => {
        history.push(route);
        setActiveRoute(route);
        handleDrawerToggle();
    };

    useEffect(() => {
        if (userProfileData && userProfileData.role !== undefined) {
            setUserRole(userProfileData.role);
        }
        if (
            userProfileData &&
            userProfileData.is_super_admin !== undefined &&
            userProfileData.is_super_admin === CONSTANT.SUPER_ADMIN_YES
        ) {
            setIsSuperAdmin(true);
        } else {
            setIsSuperAdmin(false);
        }
    }, [userProfileData]);

    let defaultClasses;
    let sidebar;

    if (appName === CONSTANT.APP_NAME_CORE) {
        defaultClasses = "app_sidebar";
        sidebar = (
            <>
                <List className="sidebar_menu">
                    <ListItem
                        button
                        onClick={() => {
                            handleMenuClick("/dashboard");
                        }}
                        className={activeRoute === "/dashboard" ? "main-menu" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            <DashboardIcon sx={{ color: "text.fill" }} />
                        </ListItemIcon>
                        <ListItemText
                            sx={{ paddingLeft: "1rem" }}
                            primary={
                                <Typography component="span" variant="subtitle2">
                                    Agency Dashboard
                                </Typography>
                            }
                        />
                    </ListItem>

                    {haveSubscription ? (
                        <ListItem button className="sidebar_submenu_container">
                            <Box className="sidebar_menu_item">
                                <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                                    <ManageAccountsIcon
                                        sx={{
                                            color: "text.fill",
                                            fontSize: "1.3rem"
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ paddingLeft: "1rem" }}
                                    primary={
                                        <Typography component="span" variant="subtitle2">
                                            Apps
                                        </Typography>
                                    }
                                />
                            </Box>
                            <List className="sidebar_submenu">
                                {appList.map((item) => {
                                    if (item.subscribed) {
                                        return (
                                            <ListItem
                                                onClick={() =>
                                                    window.location.replace(item.app_url)
                                                }
                                                button
                                            >
                                                <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                                                    <GetIconByIdentifier
                                                        identifier={item.identifier}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={{
                                                        paddingLeft: "1rem",
                                                        color: "text.primary"
                                                    }}
                                                    primary={item.name}
                                                />
                                                <ListItemIcon
                                                    sx={{ paddingLeft: "1rem" }}
                                                    className="right_arrow_icon"
                                                >
                                                    <ArrowForwardIcon
                                                        sx={{
                                                            fontSize: "1.3rem"
                                                        }}
                                                        color="secondary"
                                                    />
                                                </ListItemIcon>
                                            </ListItem>
                                        );
                                    }
                                })}
                            </List>
                        </ListItem>
                    ) : (
                        ""
                    )}
                </List>

                <List className="sidebar_menu settings_menu">
                    <ListItem button className="sidebar_submenu_container">
                        <Box className="sidebar_menu_item">
                            <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                                <SettingsApplicationsIcon
                                    sx={{
                                        color: "text.fill",
                                        fontSize: "1.3rem"
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                sx={{ paddingLeft: "1rem" }}
                                primary={
                                    <Typography component="span" variant="subtitle2">
                                        Settings
                                    </Typography>
                                }
                            />
                        </Box>
                        <List className="sidebar_submenu">
                            {userRole === CONSTANT.USER_ROLE_ADMIN ? (
                                <>
                                    <ListItem
                                        button
                                        onClick={() => handleMenuClick("/settings")}
                                        className={activeRoute === "/settings" ? "active" : ""}
                                    >
                                        <ListItemText
                                            sx={{ paddingLeft: "1rem" }}
                                            primary="User Settings"
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() => handleMenuClick("/agency/settings")}
                                        className={
                                            activeRoute === "/agency/settings" ? "active" : ""
                                        }
                                    >
                                        <ListItemText
                                            sx={{ paddingLeft: "1rem" }}
                                            primary="Agency Settings"
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() => handleMenuClick("/team")}
                                        className={activeRoute === "/team" ? "active" : ""}
                                    >
                                        <ListItemText
                                            sx={{ paddingLeft: "1rem" }}
                                            primary="Team Management"
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            handleMenuClick("/agency/white-label-domain")
                                        }
                                        className={
                                            activeRoute === "/agency/white-label-domain"
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <ListItemText
                                            sx={{ paddingLeft: "1rem" }}
                                            primary="White Label Domain"
                                        />
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() => handleMenuClick("/auth/mailgun")}
                                    >
                                        <ListItemText
                                            sx={{ paddingLeft: "1rem" }}
                                            primary="Integration"
                                        />
                                    </ListItem>
                                </>
                            ) : (
                                <ListItem
                                    button
                                    onClick={() => handleMenuClick("/settings")}
                                    className={activeRoute === "/settings" ? "active" : ""}
                                >
                                    <ListItemText
                                        sx={{ paddingLeft: "1rem" }}
                                        primary="User Settings"
                                    />
                                </ListItem>
                            )}
                        </List>
                    </ListItem>
                </List>
                {isSuperAdmin ? (
                    <List className="sidebar_menu settings_menu">
                        <ListItem
                            button
                            className="sidebar_menu_container"
                            onClick={() => {
                                handleMenuClick("/admin/users");
                            }}
                        >
                            <Box className="sidebar_menu_item">
                                <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                                    <AdminPanelSettingsIcon
                                        sx={{ color: "text.fill", fontSize: "1.3rem" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ paddingLeft: "1rem" }}
                                    primary={
                                        <Typography component="span" variant="subtitle2">
                                            Admin
                                        </Typography>
                                    }
                                />
                            </Box>
                            {/* <List className="sidebar_submenu">
                                <ListItem
                                    button
                                    onClick={() => handleMenuClick("/admin/agencies")}
                                    className={activeRoute === "/admin/agencies" ? "active" : ""}
                                >
                                    <ListItemText sx={{ paddingLeft: "1rem" }} primary="Agencies" />
                                </ListItem>
                            </List>
                            <List className="sidebar_submenu">
                                <ListItem
                                    button
                                    onClick={() => handleMenuClick("/admin/users")}
                                    className={activeRoute === "/admin/users" ? "active" : ""}
                                >
                                    <ListItemText sx={{ paddingLeft: "1rem" }} primary="Users" />
                                </ListItem>
                            </List>
                            <List className="sidebar_submenu">
                                <ListItem
                                    button
                                    onClick={() => handleMenuClick("/admin/apps")}
                                    className={activeRoute === "/admin/apps" ? "active" : ""}
                                >
                                    <ListItemText sx={{ paddingLeft: "1rem" }} primary="Apps" />
                                </ListItem>
                            </List>
                            <List className="sidebar_submenu">
                                <ListItem
                                    button
                                    onClick={() => handleMenuClick("/admin/geogrid_api")}
                                    className={activeRoute === "/admin/geogrid_api" ? "active" : ""}
                                >
                                    <ListItemText
                                        sx={{ paddingLeft: "1rem" }}
                                        primary="Geogrid Api"
                                    />
                                </ListItem>
                            </List> */}
                        </ListItem>
                    </List>
                ) : (
                    ""
                )}
            </>
        );
    }
    if (appName === CONSTANT.APP_NAME_CORE_SETTING) {
        defaultClasses = "core_integration_sidebar";
        sidebar = (
            <>
                <Box className="goback_link" onClick={() => history.push("/dashboard")}>
                    <ArrowBackIcon />
                    <CustomButton
                        title={<Typography variant="buttonMedium">Agency Dashboard</Typography>}
                        variant="text"
                        size="medium"
                    />
                </Box>
                <List className="sidebar_menu">
                    <ListItem
                        button
                        onClick={() => handleMenuClick("/auth/mailgun")}
                        className={activeRoute === "/auth/mailgun" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            {theme.palette.mode === "light"
                                ? Icons.mailgunIconLight
                                : Icons.mailgunIconDark}
                        </ListItemIcon>
                        <ListItemText
                            sx={{ paddingLeft: "1rem" }}
                            primary={
                                <Typography component="span" variant="subtitle2">
                                    Mailgun Auth
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => handleMenuClick("/auth/ghl")}
                        className={activeRoute === "/auth/ghl" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            {theme.palette.mode === "light"
                                ? Icons.ghlIconLight
                                : Icons.ghlIconDark}
                        </ListItemIcon>
                        <ListItemText
                            sx={{ paddingLeft: "1rem" }}
                            primary={
                                <Typography component="span" variant="subtitle2">
                                    GHL Auth
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => handleMenuClick("/auth/twilio")}
                        className={activeRoute === "/auth/twilio" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            {theme.palette.mode === "light"
                                ? Icons.twilioIconLight
                                : Icons.twilioIconDark}
                        </ListItemIcon>
                        <ListItemText
                            sx={{ paddingLeft: "1rem" }}
                            primary={
                                <Typography component="span" variant="subtitle2">
                                    Twilio Auth
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </>
        );
    }
    if (appName === CONSTANT.APP_NAME_ADMIN) {
        defaultClasses = "core_integration_sidebar";
        sidebar = (
            <>
                <Box className="goback_link" onClick={() => history.push("/dashboard")}>
                    <ArrowBackIcon />
                    <CustomButton
                        title={<Typography variant="buttonMedium">Agency Dashboard</Typography>}
                        variant="text"
                        size="medium"
                    />
                </Box>
                <List className="sidebar_menu">
                    <ListItem
                        button
                        onClick={() => handleMenuClick("/admin/users")}
                        className={activeRoute === "/admin/users" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            <PeopleIcon sx={{ color: "text.fill", fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText sx={{ paddingLeft: "1rem" }} primary="Users" />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => handleMenuClick("/admin/agencies")}
                        className={activeRoute === "/admin/agencies" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            <GroupsIcon sx={{ color: "text.fill", fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText sx={{ paddingLeft: "1rem" }} primary="Agencies" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => handleMenuClick("/admin/apps")}
                        className={activeRoute === "/admin/apps" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            <AppsIcon sx={{ color: "text.fill", fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText sx={{ paddingLeft: "1rem" }} primary="Apps" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => handleMenuClick("/admin/geogrid_api")}
                        className={activeRoute === "/admin/geogrid_api" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            <GridOnIcon sx={{ color: "text.fill", fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText sx={{ paddingLeft: "1rem" }} primary="Geogrid Api" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => handleMenuClick("/admin/holidays")}
                        className={activeRoute === "/admin/holidays" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            <CalendarMonthIcon sx={{ color: "text.fill", fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText sx={{ paddingLeft: "1rem" }} primary="Holidays" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => handleMenuClick("/admin/ghl/add-source")}
                        className={activeRoute === "/admin/ghl/add-source" ? "active" : ""}
                    >
                        <ListItemIcon sx={{ paddingLeft: "1rem" }}>
                            <AssessmentIcon sx={{ color: "text.fill", fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText sx={{ paddingLeft: "1rem" }} primary="Report | Ad Source" />
                    </ListItem>
                </List>
            </>
        );
    }

    const handleLogout = (e) => {
        e.preventDefault();
        storage.removeItem(IS_ADMIN_LOGGED_IN_AS_USER);
        storage.removeItem(ADMIN_LOGIN_UUID);
        let formBody = [];
        formBody.push(`access_token=${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`);
        formBody.push(`refresh_token=${getCookie(process.env.REACT_APP_REFRESH_TOKEN)}`);
        formBody = formBody.join("&");
        logout(formBody);

        setCookie(process.env.REACT_APP_ACCESS_TOKEN, "", "1 sec");
        setCookie(process.env.REACT_APP_REFRESH_TOKEN, "", "1 sec");
        setOtherCookie(process.env.REACT_APP_EMAIL_VERIFIED_STATUS, "");
        storage.setItem(process.env.REACT_APP_REDIRECT_TO, "/dashboard");

        history.push("/login");
    };

    const generateName = (firstName, lastName) => {
        let name;
        if (firstName !== "") {
            name = firstName.substring(0, 1).toUpperCase();
        }

        if (lastName !== "") {
            name += lastName.substring(0, 1).toUpperCase();
        } else {
            name = firstName.substring(0, 2).toUpperCase();
        }

        return name;
    };

    const handleBackToAdminPanel = () => {
        forceLoginAsUser({ user_uuid: storage.getItem(ADMIN_LOGIN_UUID) }, (response) => {
            if (response.status) {
                storage.removeItem(IS_ADMIN_LOGGED_IN_AS_USER);
                storage.removeItem(ADMIN_LOGIN_UUID);
                setCookie(
                    REACT_APP_ACCESS_TOKEN,
                    response.tokens.access.token,
                    REACT_APP_ACCESS_TOKEN_VALIDITY
                );
                setCookieForRefresh(REACT_APP_REFRESH_TOKEN, response.tokens.refresh.token);
                setCookieWithPath(
                    REACT_APP_EMAIL_VERIFIED_STATUS,
                    Number(response.data.email_verified) === 1,
                    "/"
                );
                setCookieWithPath("UUID", response.data.uuid, "/");
                setCookieWithPath("EMAIL", response.data.email, "/");
                window.location.replace("/dashboard");
            }
        });
    };

    const classes = `${defaultClasses} ${customClass}`;

    return (
        <StyledEngineProvider injectFirst>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }, zIndex: "1" }}
                aria-label="mailbox folders"
                className={classes}
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    className={classes}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "block", md: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            backgroundImage: "none"
                        }
                    }}
                >
                    {loadAppList ? (
                        <>
                            {sidebar}
                            <Box
                                className="user_logout"
                                sx={{ backgroundColor: "background.paper" }}
                            >
                                <Box className="dark_toggle">
                                    <Typography variant="subtitle2">Dark Mode</Typography>
                                    <ThemeToggleButton />
                                </Box>
                                <Box className="logout">
                                    <Typography variant="h4">
                                        {generateName(
                                            userProfileData && userProfileData.first_name
                                                ? userProfileData.first_name
                                                : "",
                                            userProfileData && userProfileData.last_name
                                                ? userProfileData.last_name
                                                : ""
                                        )}
                                    </Typography>
                                    <CustomButton
                                        title="Logout"
                                        handleButton={(e) => handleLogout(e)}
                                        variant="text"
                                        disableRipple
                                        sx={{
                                            color: "text.primary",
                                            "&:hover": {
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <SideBarSkeleton />
                    )}
                </Drawer>

                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "none", md: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            marginTop: "64px",
                            height: "calc(100% - 64px) !important"
                        }
                    }}
                    open
                >
                    {loadAppList ? (
                        <>
                            {sidebar}

                            <Box
                                className="user_logout"
                                sx={{ backgroundColor: "background.paper" }}
                            >
                                <Box className="dark_toggle">
                                    <Typography variant="subtitle2">Dark Mode</Typography>
                                    <ThemeToggleButton />
                                </Box>
                                {isAdminLoggedInAsUser && (
                                    <Box className="dark_toggle">
                                        <Typography variant="subtitle2">Back to Admin</Typography>
                                        <CustomIconButton
                                            color={
                                                theme.palette.mode === "dark"
                                                    ? "secondary"
                                                    : "primary"
                                            }
                                            sx={{
                                                ml: 1,
                                                marginLeft: "30px",
                                                color: "secondary"
                                            }}
                                            handleButton={handleBackToAdminPanel}
                                        >
                                            <LoginIcon
                                                sx={{
                                                    paddingRight: "10px",
                                                    width: "2rem !important"
                                                }}
                                            />
                                        </CustomIconButton>
                                    </Box>
                                )}
                                <Box className="logout">
                                    <CustomTooltip
                                        placement="right-end"
                                        title={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}
                                            >
                                                <Typography variant="tooltip">
                                                    Name:
                                                    {userProfileData && userProfileData.first_name
                                                        ? userProfileData.first_name
                                                        : ` ${userProfileData}` &&
                                                          userProfileData.last_name
                                                        ? userProfileData.last_name
                                                        : ""}
                                                </Typography>
                                                <Typography variant="tooltip">
                                                    Email:{" "}
                                                    {userProfileData && userProfileData.email
                                                        ? userProfileData.email
                                                        : ""}
                                                </Typography>
                                            </Box>
                                        }
                                    >
                                        <Typography variant="h4">
                                            {generateName(
                                                userProfileData && userProfileData.first_name
                                                    ? userProfileData.first_name
                                                    : "",
                                                userProfileData && userProfileData.last_name
                                                    ? userProfileData.last_name
                                                    : ""
                                            )}
                                        </Typography>
                                    </CustomTooltip>
                                    <CustomButton
                                        title="Logout"
                                        handleButton={(e) => handleLogout(e)}
                                        variant="text"
                                        disableRipple
                                        sx={{
                                            color: "text.primary",
                                            "&:hover": {
                                                backgroundColor: "transparent"
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <SideBarSkeleton />
                    )}
                </Drawer>
            </Box>
        </StyledEngineProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        userProfileData: state.userProfileManageReducer.userProfileData,
        apps: state.appReducer
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        logout: (params) => dispatch(logout(params)),
        getApps: (params) => dispatch(getApps(params)),
        forceLoginAsUser: (payload, callback) => dispatch(loginAsAdmin(payload, callback))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
