import * as ACTION from "./teamActionType";

const initialState = {
    tableData: [],
    isSuccess: false,
    projectData: {},
    isCreateSuccess: false,
    loading: false,
    isEditSuccess: false,
    issDeleteSuccess: false,
    issDeleteObjectSuccess: false,
    issGetSuccess: false,
    isAddObject: false,
    clientTableData: [],
    isSuccessForClient: false
};

const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        // GET LOCATION DATA
        case ACTION.GET_TEAM_DATA_TABLE:
            return {
                ...state,
                tableData: [],
                isSuccess: false,
                isCreateSuccess: false
            };
        case ACTION.GET_TEAM_DATA_TABLE_SUCCESS:
            return {
                ...state,
                tableData: action.payload.data,
                isSuccess: true
            };
        case ACTION.GET_TEAM_DATA_TABLE_FAILED:
            return {
                ...state,
                tableData: [],
                isSuccess: false
            };

        // GET CLIENT LIST
        case ACTION.GET_ASSIGNED_CLIENT_LIST:
            return {
                ...state,
                clientTableData: [],
                isSuccessForClient: false,
                isCreateSuccess: false
            };
        case ACTION.GET_ASSIGNED_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                clientTableData: action.payload.data,
                isSuccessForClient: true
            };
        case ACTION.GET_ASSIGNED_CLIENT_LIST_FAILED:
            return {
                ...state,
                clientTableData: [],
                isSuccessForClient: false
            };

        default:
            return state;
    }
};

export default teamReducer;
