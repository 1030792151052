import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import storage from "local-storage-fallback";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { connect } from "react-redux";
import customTheme from "../../theme/theme";
import Forbidden from "../error-page/Forbidden";
import SideBar from "./sidebar/SideBar";
import "./style/userLayout_custom.css";
import TopBar from "./topbar/TopBar";

const drawerWidth = 240;

const ColorModeContext = React.createContext({
    toggleColorMode: () => {
        console.log("toggleColor");
    }
});

function ThemeToggleButton() {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    return (
        <IconButton
            sx={{ ml: 1, marginLeft: "30px", color: "secondary" }}
            onClick={() => {
                colorMode.toggleColorMode();
                if (typeof window.changeChildColorMode !== "undefined")
                    window.changeChildColorMode();
            }}
        >
            {theme.palette.mode === "dark" ? (
                <Brightness7Icon color="secondary" />
            ) : (
                <Brightness4Icon color="primary" />
            )}
        </IconButton>
    );
}

const PageWrapper = ({
    pageTitle,
    children,
    customClass = "",
    appName,
    haveAccess,
    userProfileData
}) => {
    const [sideBarStatus, setSideBarStatus] = useState(true);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [mode, setMode] = React.useState("light");

    const [uuid, setUuid] = useState("");
    storage.setItem("modePostfix", uuid);
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));

                storage.setItem(`mode_${uuid}`, mode === "light" ? "dark" : "light");
            }
        }),
        [mode, uuid]
    );

    const theme = useMemo(customTheme(mode), [mode]);

    // Please assign your default style classes which are include in style file
    const defaultClasses = "user_layout";
    const classes = `${defaultClasses} ${customClass}`;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (userProfileData && userProfileData.role !== undefined) {
            setIsLoading(false);
            setUuid(userProfileData.uuid);

            const themeMode = storage.getItem(`mode_${userProfileData.uuid}`);
            setMode(themeMode || "light");
        }
    }, [userProfileData]);

    const renderComponent = () => {
        if (haveAccess) {
            return (
                <Box className={classes}>
                    <CssBaseline />

                    <TopBar
                        drawerWidth={drawerWidth}
                        mobileOpen={mobileOpen}
                        setMobileOpen={setMobileOpen}
                        setSideBarStatus={setSideBarStatus}
                        sideBarStatus={sideBarStatus}
                        ThemeToggleButton={ThemeToggleButton}
                    />

                    <Box sx={{ display: "flex", marginTop: "64px" }}>
                        <SideBar
                            drawerWidth={drawerWidth}
                            mobileOpen={mobileOpen}
                            setMobileOpen={setMobileOpen}
                            sideBarStatus={sideBarStatus}
                            ThemeToggleButton={ThemeToggleButton}
                            appName={appName}
                        />
                        <Box
                            style={{
                                width: "100%",
                                backgroundColor: "background.default"
                            }}
                        >
                            {children}
                        </Box>
                    </Box>
                </Box>
            );
        }
        return <Forbidden />;
    };

    const renderMain = () => {
        if (!isLoading) {
            return (
                <HelmetProvider>
                    {pageTitle && (
                        <Helmet defer={false}>
                            <title>{pageTitle}</title>
                        </Helmet>
                    )}
                    <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>{renderComponent()}</ThemeProvider>
                    </ColorModeContext.Provider>
                </HelmetProvider>
            );
        }
    };

    return <StyledEngineProvider injectFirst>{renderMain()}</StyledEngineProvider>;
};

const mapStateToProps = (state) => {
    return {
        userProfileData: state.userProfileManageReducer.userProfileData
    };
};
export default connect(mapStateToProps, null)(PageWrapper);
