import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardSkeleton from "../../components/SkeletonLoader/Dashboard/DashboardSkeleton";
import ShowAlerts from "../../helpers/ShowAlerts";
import { appSbuscribe, appUnSbuscribe, getApps } from "../../statemanagement/app/appAction";
import * as CONSTANT from "../../utils/Constant";
import AppCard from "./AppCard";
import "./dashboard.css";

const Dashboard = (props) => {
    const { apps, userData, appSbuscribeData, appUnSbuscribeData, getAppsData } = props;
    const [loadAppList, setLoadAppList] = useState(false);
    const [appList, setAppList] = useState(null);
    const [userRole, setUserRole] = useState(0);
    useEffect(() => {
        if (apps.isSuccess) {
            setAppList(apps.apps);
            setUserRole(userData.role);
            setTimeout(() => {
                setLoadAppList(true);
            }, 1000);
        }
    }, [apps.isSuccess]);

    const handleSubscribe = (item) => {
        if (!item.subscribed) {
            appSbuscribeData(item.identifier, (response) => {
                if (response.status) {
                    getAppsData({});
                    ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                }
            });
        }
    };
    const handleUnSubscribe = (item) => {
        if (item.subscribed) {
            appUnSbuscribeData(item.identifier, (response) => {
                if (response.status) {
                    getAppsData({});
                    ShowAlerts(response.message, CONSTANT.ALERT_TYPE_SUCCESS);
                }
            });
        }
    };

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {loadAppList ? (
                    <Grid item xs={12} md={12}>
                        <Box className="dashboard-header">
                            <Typography variant="h5" color="text.fill">
                                Our platform here to help your business growth
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Let control and provide better experience to the end-user with
                                advanced modification management features
                            </Typography>
                        </Box>
                        <Box className="dashboard-content">
                            <Grid container spacing={1}>
                                {appList.map((item) => {
                                    if (userRole === CONSTANT.USER_ROLE_ADMIN) {
                                        return (
                                            <Grid item xs={4} md={4} lg={3} xl={2}>
                                                <AppCard
                                                    appName={item.name}
                                                    appIconDark={item.dark_logo}
                                                    appIconLight={item.logo}
                                                    appLink={item.app_url}
                                                    subscribed={item.subscribed}
                                                    item={item}
                                                    handleSubscribe={() => handleSubscribe(item)}
                                                    handleUnSubscribe={() =>
                                                        handleUnSubscribe(item)
                                                    }
                                                />
                                            </Grid>
                                        );
                                    }
                                    if (item.subscribed) {
                                        return (
                                            <Grid item xs={4} md={4} lg={3} xl={2}>
                                                <AppCard
                                                    appName={item.name}
                                                    appIconDark={item.dark_logo}
                                                    appIconLight={item.logo}
                                                    appLink={item.app_url}
                                                    subscribed={item.subscribed}
                                                    item={item}
                                                    handleSubscribe={() => handleSubscribe(item)}
                                                    handleUnSubscribe={() =>
                                                        handleUnSubscribe(item)
                                                    }
                                                />
                                            </Grid>
                                        );
                                    }
                                })}
                            </Grid>
                        </Box>
                    </Grid>
                ) : (
                    <DashboardSkeleton />
                )}
            </Grid>
        </Container>
    );
};
const mapStateToProps = (state) => {
    return {
        apps: state.appReducer,
        userData: state.userProfileManageReducer.userProfileData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAppsData: (data) => dispatch(getApps(data)),
        appSbuscribeData: (data, callback) => dispatch(appSbuscribe(data, callback)),
        appUnSbuscribeData: (data, callback) => dispatch(appUnSbuscribe(data, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
