import { sendRequest, sendRequestWithToken } from "./rootApi";

export const fetchUserProfileData = async () => {
    return sendRequestWithToken("get", `${process.env.REACT_APP_API_BASE_URL}/user`);
};

export const userProfileDataUpdate = async (request) => {
    return sendRequestWithToken("put", `${process.env.REACT_APP_API_BASE_URL}/user`, request);
};
export const changeUserPassword = async (request) => {
    return sendRequestWithToken(
        "put",
        `${process.env.REACT_APP_API_BASE_URL}/auth/change-password`,
        request
    );
};

export const changeUserProfileImage = async (request) => {
    return sendRequest(
        "post",
        `${process.env.REACT_APP_API_BASE_URL}api/v1/user/setting/photo-change`,
        request
    );
};
