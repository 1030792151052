import { CustomButton, CustomHeader, CustomTextField } from "@hipdevteam/afglobalcomponents";
import AddIcon from "@mui/icons-material/Add";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Box, Grid, StyledEngineProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ShowAlerts from "../../helpers/ShowAlerts";
import {
    getAuthCredentials,
    updateAuthCredentials
} from "../../statemanagement/apiAuth/apiAuthAction";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, AUTH_TYPE_GHL } from "../../utils/Constant";
import "./custom.style.css";

const GhlSettings = (props) => {
    const { getAuthCredentialsData, updateAuthCredentialsData } = props;
    const [apiKey, setApiKey] = useState("");
    const [isError, setIsError] = useState(false);

    const components = [
        {
            name: "Dashboard",
            link: "/sstool/dashboard",
            icon: null
        },
        {
            name: "Settings",
            link: "#",
            icon: null
        },
        {
            name: "Integrations",
            link: "#",
            icon: null
        },
        { name: "GHL Auth", link: "#", icon: null }
    ];

    useEffect(() => {
        getAuthCredentialsData({ auth_type: AUTH_TYPE_GHL }, (response) => {
            if (response.data && response.data.status) {
                setApiKey(response.data.data.auth_data.api_key);
            }
        });
    }, []);

    const handleApiKeyChange = (e) => {
        setApiKey(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (apiKey.length === 0) {
            setIsError(true);
            return;
        }
        const payload = {
            type: AUTH_TYPE_GHL,
            auth_data: {
                api_key: apiKey
            }
        };

        updateAuthCredentialsData(payload, (response) => {
            console.log(response);
            if (response.status === 201 && response.data.status) {
                ShowAlerts(response.data.message, ALERT_TYPE_SUCCESS);
            } else {
                ShowAlerts("Something went wrong. Try again later", ALERT_TYPE_ERROR);
            }
        });
    };

    return (
        <StyledEngineProvider injectFirst>
            <Box className="user_settings_container">
                <Box sx={{ width: 1, mb: "30px" }} className="user_settings">
                    <Box fullWidth>
                        <Box sx={{ width: 1, mb: "30px" }}>
                            <CustomHeader components={components} headerText="GHL Auth Setup" />
                        </Box>
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "0px",
                                padding: "20px 0px 20px 20px",
                                boxShadow: "none",
                                paddingBottom: "0px",
                                backgroundColor: "#1b1464"
                            }}
                            xs={10}
                            md={12}
                            sm={12}
                        >
                            <Typography
                                variant="subtitle1"
                                component="span"
                                sx={{ paddingBottom: "20px" }}
                                color="common.white"
                            >
                                GHL Auth Setup
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                backgroundColor: "background.paper",
                                marginTop: "0px !important"
                            }}
                            xs={10}
                            md={12}
                            sm={12}
                            className="schema__form"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box>
                                            <form className="" onSubmit={handleSubmit}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <CustomTextField
                                                            name="api_key"
                                                            label="Api Key*"
                                                            id="api_key"
                                                            autoComplete="off"
                                                            type="text"
                                                            value={apiKey !== "" ? apiKey : ""}
                                                            handleChange={handleApiKeyChange}
                                                            placeholder="API Key"
                                                            error={isError && apiKey === ""}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <VpnKeyIcon
                                                                        sx={{
                                                                            color: "action.active"
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        {isError && apiKey.length === 0 && (
                                                            <Typography
                                                                variant="subtitle2"
                                                                align="left"
                                                                color="error"
                                                            >
                                                                API key can not be empty
                                                            </Typography>
                                                        )}
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={10}
                                                        md={12}
                                                        sm={12}
                                                        className="schema__form__btn__v1"
                                                    >
                                                        <Box
                                                            className="save_button"
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                marginTop: "20px"
                                                            }}
                                                        >
                                                            <CustomButton
                                                                handleButton={null}
                                                                title="SAVE CHANGES"
                                                                startIcon={<AddIcon />}
                                                                variant="contained"
                                                                color="secondary"
                                                                type="submit"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAuthCredentialsData: (params, callback) =>
            dispatch(getAuthCredentials(params, callback)),
        updateAuthCredentialsData: (params, callback) =>
            dispatch(updateAuthCredentials(params, callback))
    };
};

export default connect(null, mapDispatchToProps)(GhlSettings);
