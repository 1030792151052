import { CustomButton } from "@hipdevteam/afglobalcomponents";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";

const AppCard = ({
    appIconLight,
    appIconDark,
    appName,
    appLink,
    subscribed,
    handleSubscribe,
    handleUnSubscribe
}) => {
    const [isHover, setIsHover] = useState(false);
    const customTheme = useTheme();
    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return (
        <Box
            className="app__container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                backgroundColor: "background.paper",
                borderColor: "others.outlinedBorder",
                borderWidth: "1px",
                borderStyle: "solid"
            }}
        >
            <Box className="app__icon">
                {customTheme.palette.mode == "dark" ? (
                    <img src={appIconDark} alt="logo" style={{ height: "48px", width: "48px" }} />
                ) : (
                    <img src={appIconLight} alt="logo" style={{ height: "48px", width: "48px" }} />
                )}
            </Box>
            <Box className="app__details">
                <Typography className="app__name" variant="h5" color="text.primary">
                    {appName}
                </Typography>
            </Box>
            <Box className="app__action__buttons">
                {!subscribed ? (
                    <CustomButton
                        handleButton={handleSubscribe}
                        variant="contained"
                        color="secondary"
                        size="large"
                        title="Subscribe"
                        sx={{ marginTop: "29px" }}
                    />
                ) : (
                    ""
                )}

                {subscribed ? (
                    <CustomButton
                        handleButton={() => window.location.replace(appLink)}
                        title="Go to App"
                        sx={{ marginTop: "15px" }}
                        color="info"
                        size="large"
                        endIcon={<ArrowForwardIcon />}
                    />
                ) : (
                    ""
                )}

                {subscribed && isHover ? (
                    <CustomButton
                        handleButton={handleUnSubscribe}
                        variant="contained"
                        color="error"
                        size="large"
                        title="Unsubscribe"
                    />
                ) : (
                    ""
                )}

                {subscribed && !isHover ? (
                    <CustomButton
                        title="Subscribed"
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<DoneIcon />}
                    />
                ) : (
                    ""
                )}
            </Box>
        </Box>
    );
};
export default AppCard;
