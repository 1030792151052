import * as ACTION from "./geogridApiAuthActionType";

export const getGeogridApiAuthDataTable = (payload) => ({
    type: ACTION.GET_GEOGRID_API_AUTH_DATA_TABLE,
    payload
});

export const createGeogridApiAuth = (payload, callback) => ({
    type: ACTION.GEOGRID_API_AUTH_CREATE,
    payload,
    callback
});

export const getGeogridApiAuth = (payload, callback) => ({
    type: ACTION.GET_GEOGRID_API_AUTH,
    payload,
    callback
});

export const updateGeogridApiAuthData = (payload, callback) => ({
    type: ACTION.UPDATE_GEOGRID_API_AUTH,
    payload,
    callback
});

export const changeGeogridApiAuthStatus = (payload, callback) => ({
    type: ACTION.UPDATE_GEOGRID_API_AUTH_STATUS,
    payload,
    callback
});
