import {
    CustomBadge,
    CustomButton,
    CustomHeader,
    CustomSelectBox,
    GlobalDataTable
} from "@hipdevteam/afglobalcomponents";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import GhlIcon from "../../assets/images/clients/GHLIcon.png";
import WebhookIcon from "../../assets/images/clients/WebhookIcon.png";
import ZapierIcon from "../../assets/images/clients/zapierIcon.png";
import TableSkeleton from "../../components/SkeletonLoader/Table/TableSkeleton";
import ShowAlerts from "../../helpers/ShowAlerts";
import { assignClient, getClientListDataTable } from "../../statemanagement/team/teamAction";
import {
    ALERT_TYPE_SUCCESS,
    CLIENT_ASSIGN_TYPE__ASSIGN,
    CLIENT_ASSIGN_TYPE__REMOVE,
    identifier,
    LEAD_POST_TYPE
} from "../../utils/Constant";

const AssignClient = (props) => {
    const { uuid } = useParams();
    const { getClientList, isSuccess, assignClientData, clientData } = props;
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [progressing, setProgressing] = useState(true);
    const [appIdentifier, setAppIdentifier] = useState("af_secret_shop");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [filterSearch, setFilterSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [noHeader, setNoHeader] = useState(true);
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedClientIds, setSelectedClientIds] = useState([]);

    const components = [
        {
            name: "Dashboard",
            link: "/Dashboard"
        },
        { name: "Team Management", link: "/team" },
        { name: "Assign Clients", link: "#" }
    ];
    const column = [
        {
            name: "Location Name",
            cell: (row) => (row.location_name !== null ? row.location_name : "N/A")
        },
        {
            name: "Email",
            cell: (row) => row.email
        },
        {
            name: "Connection",
            cell: (row) => {
                const { lead_post_type } = row;
                if (lead_post_type === LEAD_POST_TYPE.LEAD_POST_TO_WEBHOOK) {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img src={WebhookIcon} alt="Webhook" height="18px" width="18px" />
                            <Typography variant="body2" color="text.primary" ml={1}>
                                Webhook
                            </Typography>
                        </Box>
                    );
                }
                if (lead_post_type === LEAD_POST_TYPE.LEAD_POST_TO_GHL) {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img src={GhlIcon} alt="GHL" height="18px" width="18px" />
                            <Typography variant="body2" color="text.primary" ml={1}>
                                {" "}
                                GHL
                            </Typography>
                        </Box>
                    );
                }
                if (lead_post_type === LEAD_POST_TYPE.LEAD_POST_TO_ZAPIER) {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img src={ZapierIcon} alt="zapier" height="18px" width="18px" />
                            <Typography
                                sx={{ display: "flex", flexDirection: "column" }}
                                variant="body2"
                                color="text.primary"
                                ml={1}
                            >
                                Zapier
                                {row.webhook === null ? (
                                    <Typography variant="tootTip" color="warning.main">
                                        Need to connect with Zapier
                                    </Typography>
                                ) : (
                                    ""
                                )}
                            </Typography>
                        </Box>
                    );
                }
            }
        },

        {
            name: "Status",
            cell: (row) => {
                if (row.assigned) {
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CustomBadge color="info" label="Assigned" />
                        </Box>
                    );
                }
                return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CustomBadge
                            color="warning"
                            ml={1}
                            label="Not Assigned"
                            endIcon={<LinkOffIcon />}
                            handleClick={null}
                        />
                    </Box>
                );
            }
        }
    ];

    useEffect(() => {
        setProgressing(true);
        getClientList({
            app_identifier: appIdentifier,
            team_uuid: uuid,
            search_key: filterSearch,
            current_page: currentPage,
            limit: itemPerPage
        });
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setData(clientData.data);
            setProgressing(false);
            setTotalPages(clientData.totalPages);
            setTotalItems(clientData.totalItems);
            setCurrentPage(clientData.currentPage);
        }
    }, [isSuccess]);

    const getClients = (identifier, clientSearchKey, numberOfPage = 1, perPageItem = 10) => {
        getClientList({
            app_identifier: identifier,
            team_uuid: uuid,
            search_key: clientSearchKey,
            current_page: numberOfPage,
            limit: perPageItem
        });
    };

    // Get Data
    const getData = (newCurrentPage) => {
        setCurrentPage(newCurrentPage);
        getClients(appIdentifier, filterSearch, newCurrentPage, itemPerPage);
    };

    const changePage = (currentRowsPerPage, currentPage) => {
        getClients(appIdentifier, filterSearch, currentPage, currentRowsPerPage);
        setItemPerPage(currentRowsPerPage);
    };

    const handleFilter = async (searchKey) => {
        setFilterSearch(searchKey);
        getClients(appIdentifier, searchKey, currentPage, itemPerPage);
    };

    const handleAppIdentifierChange = (e) => {
        const { value } = e.target;
        setAppIdentifier(value);
        getClients(value, filterSearch, currentPage, itemPerPage);
    };

    const appIdentifierFilter = () => {
        return (
            <CustomSelectBox
                sx={{
                    maxWidth: "200px",
                    height: "40px"
                }}
                name="Select App"
                label="Select App"
                options={identifier}
                defaultValue={appIdentifier}
                handleChange={handleAppIdentifierChange}
                error=""
            />
        );
    };

    const handleAssignClient = (assignType, clientIds = selectedClientIds) => {
        assignClientData(
            {
                client_ids: clientIds,
                app_identifier: appIdentifier,
                team_uuid: uuid,
                assign_type:
                    assignType === CLIENT_ASSIGN_TYPE__ASSIGN
                        ? CLIENT_ASSIGN_TYPE__ASSIGN
                        : CLIENT_ASSIGN_TYPE__REMOVE
            },
            (response) => {
                if (response.data && response.data.status) {
                    ShowAlerts(response.data.message, ALERT_TYPE_SUCCESS);
                    getClientList({
                        app_identifier: appIdentifier,
                        team_uuid: uuid,
                        search_key: filterSearch,
                        current_page: currentPage,
                        limit: itemPerPage
                    });
                }
            }
        );
    };

    const headerActionButtonsForActiveClientLists = () => {
        return (
            <>
                <CustomButton
                    title={<Typography variant="buttonLarge">Assign</Typography>}
                    handleButton={() =>
                        handleAssignClient(CLIENT_ASSIGN_TYPE__ASSIGN, selectedClientIds)
                    }
                    color="secondary"
                    variant="contained"
                    startIcon={<PersonAddIcon />}
                    size="large"
                />
                <CustomButton
                    sx={{ marginLeft: "12px" }}
                    title={<Typography variant="buttonLarge">Remove</Typography>}
                    handleButton={() =>
                        handleAssignClient(CLIENT_ASSIGN_TYPE__REMOVE, selectedClientIds)
                    }
                    color="warning"
                    variant="contained"
                    startIcon={<PersonRemoveIcon />}
                    size="large"
                />
            </>
        );
    };

    return (
        <Box className="team-wrapper">
            <Container
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: "10px"
                }}
            >
                <CustomHeader
                    components={components}
                    headerText="Team Management"
                    buttons={[]}
                    color="secondary"
                />

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box sx={{ width: 1, mb: "30px" }} />
                        <Box
                            sx={{
                                width: 1,
                                padding: "30px 16px",
                                backgroundColor: "background.paper",
                                boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.02)",
                                borderRadius: "4px"
                            }}
                        >
                            {!progressing ? (
                                <GlobalDataTable
                                    data={data}
                                    columns={column}
                                    subHeader={false}
                                    paginationServer
                                    paginationTotalRows={totalItems}
                                    progressPending={progressing}
                                    className="af-table"
                                    onChangePage={getData}
                                    onChangeRowsPerPage={changePage}
                                    handleFilter={handleFilter}
                                    noDataComponent={<p>No Client Available</p>}
                                    otherFilter={appIdentifierFilter}
                                    filterName="Search Client"
                                    selectableRows
                                    selectableRowsData={{
                                        selectableRowsNoSelectAll: progressing
                                            ? true
                                            : data !== undefined && data.length === 0,
                                        selectableRowsHighlight: false,
                                        onSelectedRowsChange: (state) => {
                                            if (state.selectedRows.length === 0) {
                                                setNoHeader(true);
                                            } else {
                                                setNoHeader(false);
                                            }
                                            setSelectedClients(state.selectedRows);
                                            const clientIds = [];
                                            state.selectedRows.forEach((element) => {
                                                clientIds.push(element.id);
                                            });
                                            setSelectedClientIds(clientIds);
                                        }
                                    }}
                                    noHeader={noHeader}
                                    headerActionButtons={headerActionButtonsForActiveClientLists()}
                                />
                            ) : (
                                <TableSkeleton />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        clientData: state.teamReducer.clientTableData,
        isSuccess: state.teamReducer.isSuccessForClient,
        team: state.teamReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClientList: (param) => dispatch(getClientListDataTable(param)),
        assignClientData: (param, callback) => dispatch(assignClient(param, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignClient);
