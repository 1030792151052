import * as ACTION from "./agencyActionType";

export const getAgencyDataTable = (payload) => ({
    type: ACTION.GET_AGENCIES_DATA_TABLE,
    payload
});

export const getAgencyUsersDataTable = (payload) => ({
    type: ACTION.GET_AGENCY_USER_DATA_TABLE,
    payload
});

export const loginAsUser = (payload, callback) => ({
    type: ACTION.ADMIN_AS_USER_LOGIN_ATTEMPT,
    payload,
    callback
});

export const loginAsAdmin = (payload, callback) => ({
    type: ACTION.ADMIN_AS_ADMIN_LOGIN_ATTEMPT,
    payload,
    callback
});

export const userStatusChange = (payload, callback) => ({
    type: ACTION.ADMIN__USER_STATUS_CHANGE,
    payload,
    callback
});

export const userPasswordChange = (payload, callback) => ({
    type: ACTION.ADMIN__USER_PASSWORD_CHANGE,
    payload,
    callback
});

export const makeUserAdmin = (payload, callback) => ({
    type: ACTION.ADMIN__USER_ADMIN_MAKE,
    payload,
    callback
});

export const changeAgencyStatus = (payload, callback) => ({
    type: ACTION.ADMIN__AGENCY_STATUS_CHANGE,
    payload,
    callback
});

export const addAdminAgencyTeamMember = (payload, callback) => ({
    type: ACTION.ADD_ADMIN_AGENCY_TEAM_MEMBER,
    payload,
    callback
});
export const updateAdminAgencyTeamMember = (payload, callback) => ({
    type: ACTION.UPDATE_ADMIN_AGENCY_TEAM_MEMBER,
    payload,
    callback
});
export const deleteAdminAgencyTeamMember = (payload, callback) => ({
    type: ACTION.DELETE_ADMIN_AGENCY_TEAM_MEMBER,
    payload,
    callback
});
