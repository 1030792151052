import * as ACTION from "./AdminAppActionType";

export const getAppsDataTable = (payload) => ({
    type: ACTION.GET_APPS_DATA_TABLE,
    payload
});

export const getAppFeatures = (payload) => ({
    type: ACTION.GET_APPS_DATA_TABLE,
    payload
});

export const createApp = (payload, callback) => ({
    type: ACTION.APP_CREATE,
    payload,
    callback
});

export const getAppDataForEdit = (payload, callback) => ({
    type: ACTION.APP_DATA_GET_FOR_EDIT,
    payload,
    callback
});

export const updateAppData = (payload, callback) => ({
    type: ACTION.APP_DATA_UPDATE,
    payload,
    callback
});

export const changeAppStatus = (payload, callback) => ({
    type: ACTION.ADMIN__APP_STATUS_CHANGE,
    payload,
    callback
});
