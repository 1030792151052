/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
export const APP_NAME_CORE = "APP_NAME_CORE";
export const APP_NAME_CORE_SETTING = "APP_NAME_CORE_SETTING";
export const APP_NAME_SCHEMA = "APP_NAME_SCHEMA";

export const USER_EMAIL_VERIFIED_TRUE = 1;
export const USER_EMAIL_VERIFIED_FALSE = 0;
export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = 0;
export const STATUS_REMOVED = 2;
export const USER_ROLE_ADMIN = 1;
export const USER_ROLE_USER = 2;
export const DEFAULT_USER_TRUE = 1;
export const DEFAULT_USER_FALSE = 0;
export const SUPER_ADMIN_YES = 1;
export const SUPER_ADMIN_NO = 0;

export const IS_ADMIN_LOGGED_IN_AS_USER = "IS_ADMIN_LOGGED_IN_AS_USER";
export const ADMIN_LOGIN_UUID = "ADMIN_LOGIN_UUID";

// agency constant
export const AGENCY_ACTIVE = 1;
export const AGENCY_INACTIVE = 0;

// agency constant
export const APP_STATUS_ACTIVE = 1;
export const APP_STATUS__INACTIVE = 0;

// Alerts Type
export const ALERT_TYPE_SUCCESS = "ALERT_TYPE_SUCCESS";
export const ALERT_TYPE_ERROR = "ALERT_TYPE_ERROR";
export const ALERT_TYPE_INFO = "ALERT_TYPE_INFO";
export const ALERT_TYPE_WARNING = "ALERT_TYPE_WARNING";

// Credentials
export const AUTH_TYPE_GHL = 2;
export const AUTH_TYPE_MAILGUN = 1;
export const AUTH_TYPE_TWILIO = 0;
// USER

// App Identifier
export const identifier = [
    {
        name: "Secret Shop",
        id: "af_secret_shop"
    },
    {
        name: "Schema",
        id: "af_schema"
    }
];

// Lead Post Type
export const LEAD_POST_TYPE = {
    LEAD_POST_TO_WEBHOOK: 0,
    LEAD_POST_TO_GHL: 1,
    LEAD_POST_TO_ZAPIER: 2
};

// Client Assign Type
export const CLIENT_ASSIGN_TYPE__ASSIGN = 1;
export const CLIENT_ASSIGN_TYPE__REMOVE = 0;

export const getRandomFileName = () => {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const random = `${Math.random()}`.substring(2, 8);
    return timestamp + random;
};

export const bytesToMb = (bytes, decimals = 2) => {
    if (bytes === 0) return 0;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    return parseFloat((bytes / 1048576).toFixed(dm));
};

export const validateEmail = (emailAddress) => {
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return emailAddress.match(regexEmail);
};

export const appUrls = {
    af_schema: "/schema/projects",
    af_secret_shop: "/sstool/dashboard"
};
