export const GET_WHITE_LABEL_DOMAIN_DATA = "GET_WHITE_LABEL_DOMAIN_DATA";
export const GET_WHITE_LABEL_DOMAIN_DATA_SUCCESS = "GET_WHITE_LABEL_DOMAIN_DATA_SUCCESS";
export const GET_WHITE_LABEL_DOMAIN_DATA_FAILED = "GET_WHITE_LABEL_DOMAIN_DATA_FAILED";

export const UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN = "UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN";
export const UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_ATTEMPTED =
    "UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_ATTEMPTED";
export const UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_SUCCESS =
    "UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_SUCCESS";
export const UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_FAILED =
    "UPDATE_OR_CREATE_UPDATE_OR_CREATE_WHITE_LABEL_DOMAIN_FAILED";
