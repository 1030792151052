import { CustomSkeleton } from "@hipdevteam/afglobalcomponents";
import { Box, Grid, Typography } from "@mui/material";
import "../../../pages/Dashboard/dashboard.css";

const DashboardSkeleton = () => {
    const count = [1, 2, 3, 4, 5];
    return (
        <Grid item xs={12} md={12}>
            <Box className="dashboard-header">
                <CustomSkeleton variant="rectangular" height={32} width="80%" />
                <Typography variant="body2" color="text.secondary">
                    <CustomSkeleton variant="text" height={32} width="60%" />
                </Typography>
            </Box>
            <Box className="header-content">
                <Grid container spacing={2}>
                    {count.map((data, index) => (
                        <Grid item xs={4} md={3} lg={2} key={index.toString()}>
                            <Box
                                className="app__container"
                                sx={{
                                    backgroundColor: "background.paper",
                                    borderColor: "others.outlinedBorder",
                                    borderWidth: "1px",
                                    borderStyle: "solid"
                                }}
                            >
                                <Box className="app__icon">
                                    <CustomSkeleton variant="rectangular" height={52} width={52} />
                                </Box>

                                <Box className="app__details">
                                    <Typography
                                        className="app__name"
                                        variant="h5"
                                        color="text.primary"
                                    >
                                        <CustomSkeleton variant="text" height={32} width={40} />
                                    </Typography>

                                    <Typography
                                        className="app__type"
                                        variant="body1"
                                        color="text.disabled"
                                    >
                                        <CustomSkeleton variant="text" height={42} width={70} />
                                    </Typography>
                                </Box>

                                <Box className="app__action__buttons">
                                    <CustomSkeleton
                                        variant="rectangular"
                                        height={42}
                                        width="150px"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Grid>
    );
};

export default DashboardSkeleton;
